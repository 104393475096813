<template>
    <div class="wscSystem">
        <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat">
                <div class="banner-words-box">
                    <h1>美匣云小程序商城</h1>
                    <h2>一站式综合电商平台，整合商家激活客户</h2>
                    <h2 class="wap-h3">一站式综合电商平台<br />整合商家激活客户</h2>
                    <div class="little-words">
                        <div class="">旅行社</div>
                        <div class="">酒店住宿</div>
                        <div class="">景区景点</div>
                    </div>
                    <p style="color:#999;margin:10px 0 0 0;"><span style="color:red;margin-right:4px;">*</span>赠送配套的专业旅游ERP系统</p>
                    <div >
                        <router-link to="/register" class="free-try-btn" target="_blank">立即试用体验</router-link>
                    </div>
                </div>
                <div class="banner-imgs">
                    <img src="../../public/img/wscSystem/wsc_banner_img1.png" alt="" class="lazyload" draggable="false">
                </div>
            </div>
        </div>
        <div class="index-content wsc-p1">
            <div class="center-box wsc-p1-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">旅游商家小程序商城<div class="divider"></div></h2>
                    <h3>适用于旅行社·酒店民宿·景区景点等场景使用</h3>
                </div>
                <div class="content-box">
                    <ul>
                        <li class="item">
                            <div>
                                <img src="../../public/img/wscSystem/A.png" style="width:360px;height:94px"/>
                                <h1>旅行社</h1>
                            </div>
                            <ul>
                                <li><span></span>营销联动，微信公众号+小程序+朋友圈</li>
                                <li><span></span>展示销售，路线/文创/特产等上架售卖</li>
                                <li><span></span>ERP系统，管理旅行社内部业务+资源</li>
                                <li><span></span>SCRM系统，管理客户助力精准营销</li>
                                <li><span></span>商业智能，多维度精准统计各项数据</li>
                            </ul>
                            
                        </li>

                        <li class="item">
                            <div>
                                <img src="../../public/img/wscSystem/B.png" style="width:360px;height:94px"/>
                                <h1>酒店民宿</h1>
                            </div>
                            <ul>
                                <li><span></span>收客下单，官方直营小程序微商城</li>
                                <li><span></span>日历房+套餐，支持日历房和酒店套餐</li>
                                <li><span></span>房态库存，支持管理房态和共享库存</li>
                                <li><span></span>同业分销，分销商在线查位下单对账</li>
                                <li><span></span>会员制，会员积分制和会员等级权益制</li>
                            </ul>
                        </li>
                        <li class="item">
                            <div>
                                <img src="../../public/img/wscSystem/C.png" style="width:360px;height:94px"/>
                                <h1>景区景点</h1>
                            </div>
                            <ul>
                                <li><span></span>售票，公众号微商城+微信小程序</li>
                                <li><span></span>取票，窗口取票+自助售取票机取票</li>
                                <li><span></span>检票，纸质门票+电子凭证核销验票</li>
                                <li><span></span>管票，管理票务库存+入园人数时间</li>
                                <li><span></span>销售，票/吃/住/行/游/购/娱皆可售</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--p2-->
        <div class="index-content wsc-p2">
            <div class="center-box wsc-p2-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">旅游商家私域流量营销商城<div class="divider"></div></h2>
                    <h3>商家为什么要打造自己的小程序商城？</h3>
                </div>
                <div class="content-box ">
                   <div class="left-benefit">
                       <div class="left-benefit-title">
                           <img src="../../public/img/wscSystem/p2/leftTop.png" alt="">
                           <h2>打造私域流量商城的好处</h2>
                       </div>
                        <div class="left-benefit-item">
                            <div class="benefits">
                                <div>
                                    <span>01</span>
                                    <span>树立品牌</span>
                                </div>
                                <p>树立企业品牌形象，再小的品牌<br/>也有自己的店</p>
                            </div>
                            <div class="benefits">
                                <div>
                                    <span>02</span>
                                    <span>留存客户</span>
                                </div>
                                <p>将所有渠道的客户都留存到<br/>企业的私域流量池</p>
                            </div>
                            <div class="benefits">
                                <div>
                                    <span>03</span>
                                    <span>复购裂变</span>
                                </div>
                                <p>提升复购，降低流失，社交<br/>裂变更多的新客户</p>
                            </div>
                            <div class="benefits">
                                <div>
                                    <span>04</span>
                                    <span>会员营销</span>
                                </div>
                                <p>借助线上的交易场景，实现<br/>精准化的会员营销</p>
                            </div>
                        </div>

                   </div>
                   <div class="right-choice">
                        <div class="right-choice-title">
                           <img src="../../public/img/wscSystem/p2/rightTop.png" alt="">
                           <p>线上转型有哪些渠道可选?</p>
                        </div>
                        <div class="right-choice-item">
                            <div class="channel">
                                <img src="../../public/img/wscSystem/p2/num.png" alt="">
                                <div>
                                    <p>传统OTA平台开店</p>
                                    <p>产品同质化严重，缺少运营经验，流量越来越少，获客成本越来越高</p>
                                    <p>
                                        <span>难度指数<img src="../../public/img/wscSystem/p2/star1.png" alt=""></span>
                                        <span>风险指数<img src="../../public/img/wscSystem/p2/star2.png" alt=""></span>
                                        <span>推荐指数<img src="../../public/img/wscSystem/p2/star3.png" alt=""></span>
                                    </p>
                                </div>
                            </div>
                            <div class="channel">
                                <img src="../../public/img/wscSystem/p2/num.png" alt="">
                                <div>
                                    <p>头条、抖音、快手、小红书等内容电商平台</p>
                                    <p>需要有很强的产品包装能力和内容输出能力，以及自己粉丝经营能力</p>
                                    <p>
                                        <span>难度指数<img src="../../public/img/wscSystem/p2/star1.png" alt=""></span>
                                        <span>风险指数<img src="../../public/img/wscSystem/p2/star2.png" alt=""></span>
                                        <span>推荐指数<img src="../../public/img/wscSystem/p2/star3.png" alt=""></span>
                                    </p>
                                </div>
                            </div>
                            <div class="channel">
                                <img src="../../public/img/wscSystem/p2/num.png" alt="">
                                <div>
                                    <p>百度/头条/腾讯广告投放</p>
                                    <p>对产品要求比较高，适合高利润产品，投入产出比太低，操作难度大</p>
                                    <p>
                                        <span>难度指数<img src="../../public/img/wscSystem/p2/star1.png" alt=""></span>
                                        <span>风险指数<img src="../../public/img/wscSystem/p2/star2.png" alt=""></span>
                                        <span>推荐指数<img src="../../public/img/wscSystem/p2/star3.png" alt=""></span>
                                    </p>
                                </div>
                            </div>
                            <div class="channel">
                                <img src="../../public/img/wscSystem/p2/num.png" alt="">
                                <div>
                                    <p>其他旅游同业销售平台</p>
                                    <p>同业平台流量少，费用高，回款慢，产品维护成本高，订单效果一般 </p>
                                    <p>
                                        <span>难度指数<img src="../../public/img/wscSystem/p2/star1.png" alt=""></span>
                                        <span>风险指数<img src="../../public/img/wscSystem/p2/star2.png" alt=""></span>
                                        <span>推荐指数<img src="../../public/img/wscSystem/p2/star3.png" alt=""></span>
                                    </p>
                                </div>
                            </div>
                            <div class="channel">
                                <img src="../../public/img/wscSystem/p2/num.png" alt="">
                                <div>
                                    <p>打造自己的私域流量商城</p>
                                    <p>对产品没有限制，对运营要求不高，投入成本更低，效果也越来越好</p>
                                    <p>
                                        <span>难度指数<img src="../../public/img/wscSystem/p2/star1.png" alt=""></span>
                                        <span>风险指数<img src="../../public/img/wscSystem/p2/star2.png" alt=""></span>
                                        <span>推荐指数<img src="../../public/img/wscSystem/p2/star3.png" alt=""></span>
                                    </p>
                                </div>
                            </div>
                        </div>

                   </div>
                </div>
            </div>
        </div>
        <!--p3 copy-->
        <!-- <div class="index-content wsc-p3">
            <div class="center-box wsc-p3-inner wsc-p3-inner-copy">
                <div class="content-box ">
                    <div class="left">
                        <img src="../../public/img/wscSystem/wsc_p3_img.png" alt="" class="wsc-p-img" draggable="false">
                    </div>
                    <div class="right">
                        <div class="wsc-p-tit-box">
                            <h1>丰富的旅游产品，一键上架至商城售卖</h1>
                            <p>美匣云资源市场提供丰富的旅游产品资源，各类爆款产品可一键申请分销，一键上架至商城售卖</p>
                            <p>再也不愁无货可卖，卖出去以后由供应商负责订单确认和处理</p>
                            <p>在线支付货款给供应商，省时，省力，省心</p>
                        </div>
                        <div class="wap-wsc-p-tit-box">
                            <h1>丰富的旅游产品<br/>一键上架至商城售卖</h1>
                            <p>美匣云资源市场提供丰富的旅游产品资源，各类爆款产品可一键申请分销，一键上架至微商城售卖，再也不愁无货可卖，卖出去以后由供应商负责订单确认和处理，在线支付货款给供应商，省时，省力，省心</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!--p4 copy-->
        <!-- <div class="index-content wsc-p4">
            <div class="center-box wsc-p4-inner wsc-p4-inner-copy">
                <div class="content-box ">
                    <div class="left">
                        <div class="wsc-p-tit-box">
                            <h1>赠送配套的专业旅游ERP系统</h1>
                            <p>选择美匣云微商城小程序，还将赠送配套的专业旅游ERP系统，所有销售渠道产生的订单</p>
                            <p>都可汇集到美匣云ERP系统进行集中化的管理和处理，财务对账结算更方便，大大提升</p>
                            <p>公司内部的办公效率！</p>
                        </div>
                        <div class="wap-wsc-p-tit-box">
                            <h1>赠送配套的专业旅游ERP系统</h1>
                            <p>选择美匣云微商城小程序，还将赠送配套的专业旅游ERP系统，所有销售渠道产生的订单都可汇集到美匣云ERP系统进行集中化的管理和处理，财务对账结算更方便，大大提升公司内部的办公效率！</p>
                        </div>

                    </div>
                    <div class="right">
                        <img src="../../public/img/wscSystem/wsc_p4_img.png" alt="" class="wsc-p-img" draggable="false">
                    </div>
                </div>
            </div>
        </div>   -->
        
        <!--p6-->
        <!-- <div class="index-content wsc-p5">
            <div class="center-box wsc-p5-inner">
                <div class="content-box ">
                    <div class="left">
                        <img src="../../public/img/wscSystem/wsc_p6_img.png" alt="" class="wsc-p-img" draggable="false">
                    </div>
                    <div class="right">
                        <div class="wsc-p-tit-box">
                            <h1>资金即时到账，无中间商环节</h1>
                            <p>客人通过商城在线下单付款后，资金即时到账您公司的微信商户<br/>
                                无中间商收款环节，无任何资金风险</p>
                        </div>
                        <div class="wap-wsc-p-tit-box">
                            <h1>资金即时到账，无中间商环节</h1>
                            <p>客人通过商城在线下单付款后，资金即时到账您公司的微信商户，无中间商收款环节，无任何资金风险</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


        <!--p3-->
        <!-- <div class="index-content wsc-p4">
            <div class="center-box wsc-p4-inner wsc-p4-inner-original">
                <div class="content-box ">
                    <div class="left">
                        <div class="wsc-p-tit-box">
                            <h1>旅游和实物产品都可销售</h1>
                            <p>既能销售国内、外跟团游线路产品，又能销售酒店、门票、签证、邮轮等单项旅游产品<br/>
                                还能销售特产、生鲜等实物类产品，实现7*24小时自动收客</p>
                        </div>
                        <div class="wap-wsc-p-tit-box">
                            <h1>旅游和实物产品都可销售</h1>
                            <p>既能销售国内、外跟团游线路产品，又能销售酒店、门票、签证、邮轮等单项旅游产品，还能销售特产、生鲜等实物类产品，实现7*24小时自动收客</p>
                        </div>
                    </div>
                    <div class="right">
                        <img src="../../public/img/wscSystem/wsc_p4_img_add.png" alt="" class="wsc-p-img" draggable="false">
                    </div>
                </div>  
            </div>
        </div> -->
        <!--p4-->
        <!-- <div class="index-content wsc-p3">
            <div class="center-box wsc-p3-inner wsc-p3-inner-original">
                <div class="content-box ">
                    <div class="left">
                        <img src="../../public/img/wscSystem/wsc_p3_img_add.png" alt="" class="wsc-p-img" draggable="false">
                    </div>
                    <div class="right">
                        <div class="wsc-p-tit-box">
                            <h1>两级分销返佣，提升销售业绩</h1>
                            <p>无分销，不商城！线上商城离不开分销玩法，旅游电商也是一样<br/>
                                两级分销返佣模式激发全民帮您卖货社交裂变式营销玩法助您快速成单</p>
                        </div>
                        <div class="wap-wsc-p-tit-box">
                            <h1>两级分销返佣，提升销售业绩</h1>
                            <p>无分销，不商城！线上商城离不开分销玩法，旅游电商也是一样，两级分销返佣模式激发全民帮您卖货社交裂变式营销玩法助您快速成单</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!--p5-->
        <!-- <div class="index-content wsc-p6">
            <div class="center-box wsc-p6-inner">
                <div class="content-box ">
                    <div class="left">
                        <div class="wsc-p-tit-box">
                            <h1>支持多种转发分享模式</h1>
                            <p>一键生成产品宣传海报，一键生成产品二维码<br/>
                                一键转发商城链接和产品链接至朋友圈产品分享传播更简单</p>
                        </div>
                        <div class="wap-wsc-p-tit-box">
                            <h1>支持多种转发分享模式</h1>
                            <p>一键生成产品宣传海报，一键生成产品二维码，一键转发商城链接和产品链接至朋友圈产品分享传播更简单</p>
                        </div> 
                    </div>
                    <div class="right">
                        <img src="../../public/img/wscSystem/wsc_p5_img.png" alt="" class="wsc-p-img" draggable="false">
                    </div>
                </div>
            </div>
        </div> -->
        

        <!--p7-->
        <div class="index-content wsc-p7">
            <div class="center-box wsc-p7-inner">
                <div class="content-box ">
                    <div class="p-tit-box">
                        <div class="p-tit">3分钟快速上手，4步生成商城<div class="divider"></div></div>
                        <p>微信小程序+抖音小程序，打通微信和抖音数据</p>
                    </div>
                    <div class="wsc-p7-img">
                        <img src="../../public/img/wscSystem/wsc_p7_img.png" alt="微信抖音小程序" draggable="false">
                    </div>
                </div>
            </div>
        </div>
        <!--p10 特色卖点-->
        <div class="index-content wsc-p10">
            <div class="center-box wsc-p10-inner">
                <div class="content-box ">
                    <div class="p-tit-box">
                        <h2 class="p-tit">小程序商城功能开发<div class="divider"></div></h2>
                        <h3>自备多种特色功能，满足多场景应用</h3>
                    </div>
                    <div class="process-box">
                        <ul>
                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #fda9f4, #d683f2);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon1.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">旅行社</li>

                                    <li>收客，国内外线路在线售卖</li>
                                    <li>销售，酒店/门票/签证/邮轮</li>
                                    <li>OTA，同步订单+游客数据信息</li>
                                    <li>分销，OTA+C端直客+B端同行</li>
                                    <li>营销，分销到微信群+朋友圈</li>
                                </ul>
                            </li>
                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon2.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">酒店民宿</li>

                                    <li>酒店套餐，支持酒店套餐售卖</li>
                                    <li>日历房，支持日历房功能设置</li>
                                    <li>订单，直连各大主流OTA平台</li>
                                    <li>API对接，可对接第三方系统</li>
                                    <li><span class="wsc-p10-item-star">*</span>会员制，会员折扣/免单功能</li>
                                </ul>
                            </li>
                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #f7d178, #fd8f54);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon3.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">景区景点</li>

                                    <li>入园，分时预约控制人数</li>
                                    <li>售票，窗口+自助售取票</li>
                                    <li>取票，二维码/身份证/手机号</li>
                                    <li>检票，纸质门票+电子票</li>
                                    <li>营销，游/吃/住/行/购皆可售</li>
                                    <li class="wsc-p10-item-jump"><router-link to="/scenicTicket" >点击了解更多</router-link></li>
                                </ul>
                            </li>
                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #76daf8, #579efe);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon4.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">综合电商</li>
                                    <li>支持景区/酒店/餐饮等场景</li>
                                    <li>游/吃/住/行/购/娱皆可售</li>
                                    <li>虚拟+实物多种商品都可售</li>
                                    <li>搭配ERP系统+SCRM系统</li>
                                    <li>链接资源市场共享商品资源</li>
                                </ul>
                            </li>


                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #a4adfd, #5e6cea);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon5.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">二级分销</li>

                                    <li>B2B同业在线申请分销</li>
                                    <li>B2C直客分享分销返佣</li>
                                    <li>支持在线查位预订下单</li>
                                    <li>同业/直客自主对账结算</li>
                                    <li>一键生成宣传海报分享</li>
                                </ul>
                            </li>

                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #fda0c8, #f76795);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon6.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">会员营销</li>
                                    <li>VIP会员等级权益制</li>
                                    <li>会员积分制抵扣消费</li>
                                    <li>发放满减/折扣优惠券</li>
                                    <li>会员卡/权益可转赠他人</li>
                                    <li><span class="wsc-p10-item-star">*</span>会员折扣/免单制</li>
                                </ul>
                            </li>
                            
                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #6feeee, #3ebbc9);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon7.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">数据统计</li>
                                    <li>订单日报、周报、月报</li>
                                    <li>销售员业绩情况分析</li>
                                    <li>产品销售情况分析</li>
                                    <li>渠道销售情况分析</li>
                                    <li>多维度数据统计分析</li>
                                </ul>
                            </li>
                            <li class="wsc-p10-item">
                                <ul class="wsc-p10-item-box">
                                    <li>
                                        <div class="wsc-p10-item-box-img" style="background-image: linear-gradient(to bottom, #ffbbbb, #f36969);">
                                            <img src="../../public/img/wscSystem/wsc-p10-icon8.png" alt="">
                                        </div>
                                    </li>
                                    <li class="wsc-p10-item-box-title">发码核销</li>
                                    <li>支持景区、酒店、餐饮等场景</li>
                                    <li>客人下单自动发送核销码</li>
                                    <li>一个核销码可多次使用</li>
                                    <li>支持微信扫码核销</li>
                                    <li>支持手持设备核销、闸机核销</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- p9 -->
        <div class="index-content wsc-p9">
            <div class="center-box wsc-p9-inner">
                <div class="content-box">
                    <div class="p-tit-box">
                        <div class="p-tit">小程序商城案例</div>
                        <!-- <p>轻松开通专属微商城小程序，快速拥有高销量自营商城</p> -->
                    </div>
                    <div class="process-box">
                        <ul>
                            <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_1.png" alt="">
                                </div>
                                <p>上海中青旅 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_2.png" alt="">
                                </div>
                                <p>贵州趣游吧 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_3.png" alt="">
                                </div>
                                <p>南京盖亚文化 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_4.png" alt="">
                                </div>
                                <p>成都环球国际 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_5.png" alt="">
                                </div>
                                <p>浙旅投会员入住 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_6.png" alt="">
                                </div>
                                <p>聚客游会员旅游 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_7.png" alt="">
                                </div>
                                <p>太翔生活会员折扣卡 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_8.png" alt="">
                                </div>
                                <p>任渥游会员旅游 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_9.png" alt="">
                                </div>
                                <p>达人旅业酒店民宿 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_10.png" alt="">
                                </div>
                                <p>惊喜度假高端酒店 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_11.png" alt="">
                                </div>
                                <p>达人民宿度假村 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_12.png" alt="">
                                </div>
                                <p>统领国际酒店住宿 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_13.png" alt="">
                                </div>
                                <p>宁波达人村 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_14.png" alt="">
                                </div>
                                <p>无锡荡口古镇 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_15.png" alt="">
                                </div>
                                <p>上海黄浦江 </p>
                            </li>
                             <li class="wsc-p9-item">
                                <div class="wsc-p9-item-imgbox">
                                    <img class="wsc-p9-item-img" src="../../public/img/wscSystem/logo/wsc_p9_img_16.png" alt="">
                                </div>
                                <p>甘肃敦煌游 </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!--p8-->
        <div class="index-content wsc-p8">
            <div class="center-box wsc-p8-inner">
                <div class="content-box ">
                    <div class="p-tit-box">
                        <div class="p-tit">4步开通专属小程序微商城<div class="divider"></div></div>
                        <p>后台快速配置上线，全程操作简单快捷</p>
                    </div>
                    <div class="process-box">
                        <ul>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>1</span></i>
                                    <div>
                                        <h1>立即申请开通</h1>
                                        <p>3秒即可拥有专属体验账号</p>
                                    </div>
                                </div>
                            </li>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>2</span></i>
                                    <div>
                                        <h1>店铺DIY装修</h1>
                                        <p>店铺模块和内容任意组合编辑</p>
                                    </div>

                                </div>
                            </li>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>3</span></i>
                                    <div>
                                        <h1>收付款配置</h1>
                                        <p>设置小程序微商城收付款信息</p>
                                    </div>
                                </div>
                            </li>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>4</span></i>
                                    <div>
                                        <h1>商品上架运营</h1>
                                        <p>上架实物/虚拟商品推广运营</p>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- <router-link to="/register" class="free-try-btn">立即试用体验</router-link> -->
                </div>
            </div>
        </div>

        <free-try :msg="msg" :btn="btn"></free-try>
        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>

<script>
    import FreeTry from '@/components/FreeTry.vue'
    import FootBox from '@/components/Footer.vue'
    import PopWindow from '@/components/PopWindow.vue'
    let vm;
    export default {
        name: "WscSystem",
        data(){
            return {
                msg: '立即试用体验美匣云微商城小程序',
                btn: '立即试用体验'
            }
        },
        components: {
            FreeTry,
            FootBox,
            PopWindow,
        },

        methods: {

            // 动态效果
            _move() {

                if($('.wscSystem').length > 0) {
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离
                    // 大标题
                    let $pTit1 = $('.wsc-p1-inner')
                    let $pTit2 = $('.wsc-p2-inner')
                    let $pTit7 = $('.wsc-p7-inner')
                    let $pTit8 = $('.wsc-p8-inner')
                    let $pTit9 = $('.wsc-p9-inner')
                    let $pTit10 = $('.wsc-p10-inner')
                   
                    let moveBoxArr = [$pTit1,$pTit2,$pTit7,$pTit8,$pTit9,$pTit10]
                    moveBoxArr.forEach( (item,index) => {
                        if (($winHeight + $winScrollHeight) >= item.offset().top) {
                            $(item).find('.p-tit-box').addClass('animated fadeInUp')
                        }
                    })

                    // p3 p4 p5 p6 左右滑入
                    // let $pTit3 = $('.wsc-p3-inner-original')
                    // let $pTit4 = $('.wsc-p4-inner-original')
                    // let $pTit3_1 = $('.wsc-p3-inner-copy')
                    // let $pTit4_1 = $('.wsc-p4-inner-copy')
                     
                    // let $pTit5 = $('.wsc-p5-inner')
                    // let $pTit6 = $('.wsc-p6-inner')
                    // let moveBoxArr2 = [$pTit3_1,$pTit4_1,$pTit3,$pTit4,$pTit5,,$pTit6]
                    // moveBoxArr2.forEach( (item,index) => {
                    //     if (($winHeight + $winScrollHeight) >= item.offset().top) {
                    //         $(item).find('.right').addClass('animated fadeInRight')
                    //         $(item).find('.left').addClass('animated fadeInLeft')
                    //     }
                    // })

                    // wsc  p2 动效
                    if (($winHeight + $winScrollHeight) >= $('.wsc-p2-inner .content-box ').offset().top ) {
                        $('.wsc-p2-inner .left-benefit-title').addClass('animated fadeInDown')
                        $('.wsc-p2-inner .right-choice-title').addClass('animated fadeInDown')
                        $('.wsc-p2-inner .left-benefit-item .benefits').each(function (index,item) {
                            $(item).addClass('animated fadeInLeft')
                            $(item).css({
                                "animation-delay": .4 * index + "s"
                            })
                        })
                        $('.wsc-p2-inner .right-choice-item .channel').each(function (index,item) {
                            $(item).addClass('animated fadeInRight')
                            $(item).css({
                                "animation-delay": .4 * index + "s"
                            })
                        })
                    }

                    // wsc  p7 动效
                    if (($winHeight + $winScrollHeight) >= $('.wsc-p7-inner .content-box ').offset().top ) {
                        $('.wsc-p7-inner .wsc-p7-img ').addClass('animated zoomIn')
                        $('.wsc-p7-inner .wsc-p7-img ').css({
                            "animation-delay": .3 + "s"
                        })
                    }

                    // wsc  p8 动效
                    if (($winHeight + $winScrollHeight) >= $('.wsc-p8-inner .content-box ').offset().top ) {
                        $('.wsc-p8-inner .wsc-p8-item').each(function (index,item) {
                            $(item).find('.wsc-p8-icons').addClass('animated fadeInLeft')
                            $(item).find('.wsc-p8-icons').css({
                                "animation-delay": .4 * index + "s"
                            })
                        })

                    }

                    // wsc  p9 动效
                    if (($winHeight + $winScrollHeight) >= $('.wsc-p9-inner .content-box ').offset().top ) {
                        $('.wsc-p9-inner .wsc-p9-item').each(function (index,item) {
                            $(item).addClass('animated fadeInLeft')
                            $(item).css({
                                "animation-delay": .05 * index + "s"
                            })
                        })

                    }

                }


            },


        },
        mounted(){
            vm = this
           // 滚动屏幕触发
            let canRun = true;
            let timer;
            vm._move()
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._move()
                    canRun = true
                }, 300)

            })
        },

    }
</script>

<style lang="less" >
    @bgBlue: #3b7ffc;
    /*公共部分*/
    .banner-words-box {

        h1, h2, h3 {
        color: #fff;
        }
        h1 {
        font-weight: bold;
        margin-bottom: 20px;
            font-size: 40px;
        }
        h2 {
            // margin-bottom: 15px;
            // font-size: 30px;
            margin-bottom: 55px;
            font-size: 20px;
        }
        h3 {
        margin-bottom: 55px;
            font-size: 20px;
        }
    }

    .p-tit-box {

        p:before {
            display: none;
        }
        h3{
            display: block;
            position: relative;
            margin-top: 26px;
            line-height: 20px;
            color: #333;
            font-size: 16px;
        }
    }
    .wsc-p5 {
        background-color: #f8f8f8;
    }
    .wscSystem {
        min-width: 1200px;
        width: 100%;
        .little-words {
            font-size: 16px;
            div {
                position: relative;
                display: inline-block;
                margin-right: 30px;
                padding-left: 25px;
                color: #fff;
                i {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #5ddfff;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 8px;
                    height: 8px;
                    border: 3px solid rgba(79,134,242);
                    border-radius: 50%;
                    background-color: #5ddfff;
                }
            }
        }
        // .p-tit-box, .tree-box,  .tree-box2 .tree-box2-tit, .tree-box2 .two-tit-box, .left,.right {
        //     opacity: 0;
        // }

        .wsc-p3,.wsc-p4,.wsc-p5,.wsc-p6 {
            height: 600px;
            .content-box {
                display: flex;
                justify-content: space-between;
                .wsc-p-tit-box {
                    padding-top: 218px;
                }
            }
        }
        .wsc-p-img {
            transition: all .5s linear;
        }
        .wsc-p-img:hover {
            transform: scale(1.02);
        }
    }
    .wsc-p-tit-box {
        h1 {
            display: inline-block;
            position: relative;
            margin-bottom: 45px;
            font-size: 36px;
            color: #333;
            white-space: nowrap;
            &:before {
                content: '';
                position: absolute;
                top: -15px;
                left: -25px;
                display: block;
                width: 73px;
                height: 57px;

            }
            &:after {
                content: '';
                position: absolute;
                bottom: -23px;
                right: 0;
                display: block;
                width: 245px;
                height: 2px;
                background: linear-gradient(to left, @bgBlue 35%, rgba(255,255,255,.1));
            }
        }
        p {
            width: 610px;

            font-size: 14px;
            line-height: 26px;
            display: inline-block;
            color: #333;
        }
    }
    .wap-wsc-p-tit-box {
        display: none;
    }


    .wsc-p3-inner, .wsc-p5-inner {
        .wsc-p-tit-box {
            text-align: right;
        }
    }
    .wsc-p4-inner, .wsc-p6-inner {
        .wsc-p-tit-box {
            h1 {
                &:after {
                    left: 0;
                    right: auto;
                    background: linear-gradient(to right, @bgBlue 35%, rgba(255,255,255,.1));
                }
            }
        }
    }

    /*微商城页面*/
    .wscSystem {
        .banner-box {
            width: 100%;
            height: 600px;
            background-image: url("../../public/img/wscSystem/wsc_banner_bg.png");
            background-size: cover;
        }
        .banner-imgs {
            top: 114px;
        }
        .banner-words-box {
            margin-top: 190px;
            h3 {
                span {
                    color: #5ddfff;
                }
            }
            .wap-h3 {
                display: none;
            }
        }
    }

    /*wsc p1*/
    .wsc-p1 {
        height: auto;
        // overflow: visible!important;
    }
    .wsc-p1-inner {
        position: relative;
        height: auto;
        padding-bottom: 70px;

        .content-box{
            margin-top: 90px;
            text-align: center;

            &>ul {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            .item {
                width: 360px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                transition: all .2s linear;
                &:hover {
                    transform: translateY(-12px);
                }
                .wsc-p1-icon {
                    width: 40px;
                    height: 37px;
                    background-image: url("../../public/img/wscSystem/wsc_p1_icons.png");
                    background-repeat: no-repeat;
                    background-size: cover;

                }
                .icon1 {

                    background-position: 0 0;
                }
                .icon2 {
                    background-position: -169px 0;
                }
                .icon3 {
                    background-position: -339px 0;
                }
                .icon4 {
                    background-position: -511px 0;
                }
                .icon5 {
                    background-position: -684px 0;
                }
                .icon6 {
                    background-position: -855px 0;
                }
                div:first-child{
                    position: relative;
                    h1{
                        position: absolute;
                        top: 50%;
                        left: 45px;
                        color: #fff;
                        font-size: 24px;
                        margin-top: -15px;
                    }

                }
                ul{
                    padding:20px 36px 56px;
                    li{
                        line-height:48px;
                        font-size: 14px;
                        text-align: left;
                        color: #333;
                        span{
                            display:inline-block;
                            width:6px;
                            height:6px;
                            border-radius: 50%;
                            background:#e49435;
                            margin-right: 10px;
                            vertical-align:middle
                        }
                    }
                }
            }
            .item:nth-child(1){
                ul{
                    li{
                        span{
                            background:#45a4ee;
                        }
                    }
                }
            }
            .item:nth-child(2){
                ul{
                    li{
                        span{
                            background:#e49435;
                        }
                    }
                }
            }
            .item:nth-child(3){
                ul{
                    li{
                        span{
                            background:#47b94c;
                        }
                    }
                }
            }
        }
    }




    /*wsc p2*/
    .wsc-p2 {
        background-color: #f8f8f8;
    }
    .wsc-p2-inner {
        .content-box{
            padding: 105px 0 115px;
            display: flex;
            justify-content: center;
        }
        .left-benefit{
            width: 569px;
            border-right: 1px solid #eee;
            padding-right: 60px;
            .left-benefit-title{
                text-align: center;
                margin-bottom: 99px;
                img{
                    margin-right: 8px;
                    vertical-align: bottom;
                }
                h2{
                    font-size: 32px;
                    display: inline-block;
                    line-height: 32px;
                }
            }
            .left-benefit-item{
                display: grid;
                grid-template-columns: repeat(2,50%);
                grid-row-gap: 70px;
                .benefits{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .benefits div{
                    width: 145px;
                    height: 145px;
                    background: url("../../public/img/wscSystem/p2/leftBg.png") no-repeat center/cover;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span:first-child{
                        position: absolute;
                        font-size:72px;
                        font-weight:bold;
                        color:rgba(255,255,255,.1);
                    }
                    span:last-child{
                        position: absolute;
                        font-size:24px;
                        font-weight:bold;
                        color:rgba(255,255,255);
                    }

                }
                .benefits p{
                    margin-top: 24px;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    color: #545454;
                }
            }
            
        }

        .right-choice{
            padding-left: 70px;
            .right-choice-title{
                margin-bottom: 36px;
                img{
                    margin-right: 8px;
                    vertical-align: bottom;
                }
                p{
                    font-size: 18px;
                    display: inline-block;
                    line-height: 18px;
                    font-weight: bold;
                    color: #3b7ffc;
                }
            }
            .right-choice-item{
                .channel:not(:last-child){
                    margin-bottom: 56px;
                }
                .channel{
                    img{
                        margin-right: 12px;
                        vertical-align: top;
                    }
                    div{
                        display: inline-block;
                        p:nth-child(1){
                            font-size: 20px;
                            line-height: 17px;
                            color: #333;
                            margin-bottom: 19px;
                        }
                        p:nth-child(2){
                            font-size: 14px;
                            color: #545454;
                            margin-bottom: 12px;
                        }
                        p:nth-child(3){
                            display: grid;
                            grid-template-columns: repeat(3,1fr);
                            span{
                                font-size: 14px;
                                // line-height: 14px;
                                font-weight: bold;
                                color: #6a6b76;
                            }
                            img{
                                height: 19px;
                            }
                        }
                    }
                }
            }
        }

    }
    .wap-content-box {
        display: none;
    }


    /*wsc p3*/
    .wsc-p3{
        background-color: #f8f8f8;
    }
    .wsc-p3-inner {
        .wsc-p-tit-box {
            /*padding-top: 218px;*/
            h1 {
                &:before {
                    display: none;
                    background: url("../../public/img/common/num_1.png") no-repeat center/cover;
                }
            }
        }
        .content-box {
            .wsc-p-img {
                padding-top: 144px;
            }
        }
    }

    /*wsc p4*/
    .wsc-p4-inner {
        .wsc-p-tit-box {

            h1 {

            }
        }
        .content-box {
            .wsc-p-img {
                padding-top: 143px;
            }
        }
    }

    /*wsc p5*/
    .wsc-p5-inner {
        .wsc-p-tit-box {

            h1 {

            }
        }
        .content-box {
            .wsc-p-img {
                padding-top: 115px;
            }
        }
    }

    /*wsc p6*/
    .wsc-p6-inner {
        .wsc-p-tit-box {

            h1 {

            }
        }
        .content-box {
            .wsc-p-img {
                padding-top: 150px;
            }
        }
    }

    /*wsc p7*/
    .wsc-p7 {
        height: 900px;
        background: url("../../public/img/wscSystem/wsc_p7_bg.png") no-repeat center/cover;
    }
    .wsc-p7-inner {
        .p-tit-box {
            p {
                color: #fff;
            }
            .p-tit {
                color: #fff;
                &:before {
                    background: url("../../public/img/index/tit_left_white.png") no-repeat center/contain;
                }
                &:after {
                    background: url("../../public/img/index/tit_right_white.png") no-repeat center/contain;
                }

            }
        }
        .wsc-p7-img {
            img {

                padding-left: 76px;
                padding-top: 25px;
                transition: all .5s linear;

            }

        }

    }

    /*wsc p8*/
    .wsc-p8-inner {
        padding-bottom: 103px;
        .free-try-btn {
            display:  block;
            background-color: @bgBlue;
            margin: 95px auto 0;
            font-size: 18px;
        }
    }
    .process-box {
        padding-top: 105px;
        ul {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        .wsc-p8-item {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: center;
            .wsc-p8-icons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                i {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 89px;
                    height: 89px;
                    background: url('../../public/img/wscSystem/wsc_p8_bg.png') no-repeat center/cover;
                    span {
                        color: #fff;
                        font-size: 24px;
                        font-weight: bold;
                    }
                    &:after {
                        position: absolute;
                        content: '';
                        display: block;
                        width: 157px;
                        height: 4px;
                        left: 126px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: linear-gradient(12deg,
                        #49a1de 0%,
                        #57b2ef 0%,
                        #65c3ff 0%,
                        #7290fd 67%,
                        #7f5cfa 100%)

                    }
                }

                h1 {
                    font-size: 16px;
                    color: #333;
                    margin: 35px 0 20px;
                    font-weight: bold;
                }
                p {
                    font-size: 14px;
                    color: #808080;
                    line-height: 20px;
                }
            }
        }
        .wsc-p8-item:last-child {
            .wsc-p8-icons {
                i {
                    &:after {
                        display: none;
                    }

                }
            }
        }
    }

    // wsc p9
    .wsc-p9{
        background:#F8F8F8;
    }
    .wsc-p9-inner {
        padding-bottom: 103px;
        .process-box{
            ul{
                flex-wrap: wrap;
            }
            .wsc-p9-item{
                margin:10px 20px;
                float: left;
                width: 250px;
                
                .wsc-p9-item-imgbox{
                    position:relative;
                    width: 140px;
                    height: 140px;
                    background:#fff;
                    border-radius: 50%;
                    margin: 0 auto;
                    
                }
                p{
                    text-align: center;
                    margin-top:10px;
                }
                
                img{
                   position: absolute;
                    transition: all 0.5s;
                    width: 140px;
                    text-align: center;
                    // top: 7px;
                    // left: 8px;
                }
            }
        }
    }

    // p10
    .wsc-p10{
        background:#fff;
        .wsc-p10-inner {
            padding-bottom: 103px;
            .process-box{
                &>ul{
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .wsc-p10-item{
                        margin:20px 20px;
                        float: left;
                        color:#666;
                        font-size: 14px;
                        background:#fff;
                        width: 250px;
                        text-align: center;
                        border-radius: 4px;
                        box-shadow: 0px 0px 10px #d9d9d9;
                        transition: all 0.2s linear;
                        .wsc-p10-item-star{
                            color: red;
                        }
                        .wsc-p10-item-jump{
                            margin-top: 10px;
                            a{
                                font-size: 16px;
                                color: #3b7ffc;
                                font-weight: bold;
                            }
                        }
                        &:hover{
                            background-image: linear-gradient(to bottom right, #66c2ff, #7f5cfa);
                            color: #fff;
                            .wsc-p10-item-box-title{
                                color: #fff;
                            }
                            .wsc-p10-item-box-img{
                                background-image: url(../../public/img/wscSystem/wsc-p10-icon-img.png) !important;
                                background-repeat: no-repeat;
                                background-size:100% 100%;
                                width: 92px !important;
                                transform: translateX(15px);
                                text-align: left;
                                img{
                                    margin-left: 14px;
                                }
                            }
                            .wsc-p10-item-jump{
                                a{
                                    color: #fff;
                                }
                            }
                        }
                        .wsc-p10-item-box{
                            display: block;
                            padding: 24px 0;
                            li{
                                line-height:24px;
                                .wsc-p10-item-box-img{
                                    background-image: linear-gradient(to bottom, #c3e75e, #5dce73);
                                    border-radius: 40%;
                                    width:64px;
                                    height: 64px;
                                    display:inline-block;
                                    line-height: 60px;
                                    img{
                                        width: 34px;
                                        height: 34px;
                                        vertical-align:middle;
                                    }
                                    
                                }
                            }
                            &-title{
                                font-size: 18px;
                                color: #333;
                                font-weight: bold;
                                margin: 18px 0;
                            }
                        }
                       
                    }
                }
                
            }
        }
    }
    


    @media all and (max-width: 750px) {
        .wscSystem {
            min-width: auto;
            .banner-inner {
                width: 7.5rem;
            }
            .free-try-btn{
                font-size: .25rem;
                margin-top: .2rem;
            }
        }
        /*公共*/
        /*.center-box {*/
            /*width: 7.5rem;*/
            /*padding: 0 .2rem 1rem;*/
        /*}*/


        .wscSystem .banner-box {
            height: 100vh;
            background-position: center;
        }
        .wscSystem .banner-words-box {
            margin-top: 6.5rem;
            h2{
                margin-bottom: .2rem;
            }
            h3 {
                display: none;
            }
            .wap-h3 {
                display: block;
                line-height: .6rem;
            }
        }
        .wscSystem .banner-imgs {
            top: 2.2rem;
        }
        .wscSystem .banner-imgs img {
            width: 4.7rem;
        }
        .wsc-p1-inner {
            // height: 4rem;
        }
        .wsc-p1-inner .content-box {
            margin-top: 1rem;
        }
        .wsc-p1-inner .content-box>ul {
            width: 100%;
            display:block;
            // flex:1;
            // justify-content: space-between;
        }
        .wsc-p1-inner .content-box .item {
            width: 90%;
            // height: 4.2rem;
            // padding: .2rem;
            margin: 0 auto .5rem;
            
            img{
                width:100% !important;
                height:1.88rem !important;
                
            }
            h1{
                font-size: .32rem;
                // top:.88rem !important;
                left:.9rem !important;
                // font-size: 20px !important;
            }
            ul{
                padding: .2rem .36rem .8rem;
                li{
                    line-height: .68rem;
                    font-size: 12px !important;
                }
            }
        }
        // .wsc-p1-inner .content-box .item p {
        //     line-height: 1.5;
        //     font-size: .26rem;
        // }


        /*图标*/

        .wsc-p1-inner .content-box .item:nth-child(1)  .icon1{
            width: .78rem;
            height: .68rem;
            background-image: url(../../public/img/wscSystem/wap-wsc_p1_icon1.png);

        }
        .wsc-p1-inner .content-box .item:nth-child(2)  .icon3{
            width: .78rem;
            height: .64rem;
            background-image: url(../../public/img/wscSystem/wap-wsc_p1_icon2.png);

        }
        .wsc-p1-inner .content-box .item:nth-child(3) .icon4{
            width: .8rem;
            height: .74rem;
            background-image: url(../../public/img/wscSystem/wap-wsc_p1_icon3.png);

        }
        .wsc-p1-inner .content-box .item:nth-child(4)  .icon6{
            width: .76rem;
            height: .76rem;
            background-image: url(../../public/img/wscSystem/wap-wsc_p1_icon4.png);

        }


        .wsc-p1-inner .content-box .item .wsc-p1-icon {
            background-position: 0 0;
        }

        .wsc-p9-item img{
            width:90% !important;
            top: .12rem !important;
            left: .12rem !important;
        }

        .wap-content-box {
            display: block;
            padding-top: 4rem;
            .tree-box {
                display: flex;
                justify-content: center;
                width: 6.9rem;
                height: 2.96rem;
                margin: 0 auto;
                background: url("../../public/img/wscSystem/wsc_p2_circle_line-wap.png") no-repeat center/cover;

                .tree-tit1 {
                    margin-top: .6rem;
                    color: #fff;
                    text-align: center;
                    h1 {
                        font-size: .35rem;
                        color: #fff;
                    }
                    p {
                        font-size: .25rem;
                    }
                }
            }
            .tree-box2 {
                width: 100%;
                margin: 0 auto;
                ul {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    .tree-box2-item {
                        display: flex;
                        flex-direction: column;
                        width: 3.3rem;
                        text-align: center;
                        .tree-box2-tit {
                            position: relative;
                            width: 1.6rem;
                            height: .68rem;
                            line-height: .68rem;
                            margin: 0 auto;
                            font-size: .25rem;
                            background-color: @bgBlue;
                            color: #fff;
                            border-radius: 23px;
                            &:after {
                                content: '';
                                display: block;
                                width: 1.73rem;
                                height: .68rem;
                                position: absolute;
                                bottom: -.69rem;
                                left: 50%;
                                transform: translateX(-50%);
                                background: url("../../public/img/wscSystem/wsc_p2_line-wap.png") no-repeat center/cover;
                            }
                        }
                        .two-container {
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: center;
                        }
                        .two-tit-box {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            justify-content: space-between;

                            .two-tit {
                                width: 1.5rem;
                                height: .6rem;
                                line-height: .6rem;
                                background-color: #77a7ff;
                                border-radius: .3rem;
                                margin-top: .68rem;
                                margin-bottom: 17px;
                                color: #fff;
                                font-size: 12px;
                                font-weight: normal;
                            }
                            .three-tit-box {
                                .three-tit {
                                    width: 1.5rem;
                                    height: .6rem;
                                    line-height: .6rem;
                                    margin: 0 auto 8px;
                                    background-color: #dde6f8;
                                    border-radius: .3rem;
                                    font-size: 12px;
                                    font-weight: normal;
                                    color: #5e7195;
                                }
                                .three-tit:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .two-tit-box:nth-child(1) {
                            margin-right: .2rem
                        }
                    }

                }

            }
        }



        /*wsc-p2*/
        .wsc-p2 {
            .content-box {
                padding: 1rem 0 1.15rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }
        }
        .wsc-p2-inner {
            height: auto;
            .left-benefit {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid #eee;
                padding-right: 0;
                .left-benefit-title{
                    margin-bottom: 1rem;
                    h2{
                        font-size: .5rem;
                    }
                }
                .left-benefit-item{
                    padding-bottom: 1rem;
                    .benefits p{
                        font-size: .08rem;
                    }
                } 
            }
            .right-choice{
                padding-left: 0;
                margin-top: 1rem;
                .channel{
                    display: flex;
                    justify-content: flex-start;
                    img{
                        height: 19px;
                    }
                    div p:nth-child(1){
                        line-height: 1 !important;
                    }
                }
            }
        }


        .wscSystem .p-tit-box, .wscSystem .tree-box, .wscSystem .tree-box2 .tree-box2-tit, .wscSystem .tree-box2 .two-tit-box, .wscSystem .left, .wscSystem .right {
            opacity: 1;
        }

        .wscSystem .wsc-p3 .content-box, .wscSystem .wsc-p4 .content-box, .wscSystem .wsc-p5 .content-box, .wscSystem .wsc-p6 .content-box {
            flex-wrap: wrap;
            position: relative;
            justify-content: center;
        }
        .wscSystem .wsc-p3, .wscSystem .wsc-p4, .wscSystem .wsc-p5, .wscSystem .wsc-p6 {
            height: auto;

        }
        .wscSystem .wsc-p3 .content-box .wsc-p-tit-box, .wscSystem .wsc-p4 .content-box .wsc-p-tit-box, .wscSystem .wsc-p5 .content-box .wsc-p-tit-box, .wscSystem .wsc-p6 .content-box .wsc-p-tit-box {
            padding: 1rem .2rem 0;

            width: 7.5rem;
        }


        .wsc-p-tit-box {
            display: none;
        }
        .wap-wsc-p-tit-box {
            display: block;
            text-align: center;
            h1 {
                display: inline-block;
                position: relative;
                margin-bottom: .5rem;
                font-size: .42rem;
                color: #333;
                white-space: nowrap;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -0.3rem;
                    right: 0;
                    display: block;
                    width: 5rem;
                    height: 2px;
                    background: linear-gradient(to left, @bgBlue 35%, rgba(255,255,255,.1));
                }
            }
            p {
                width: 100%;
                font-size: .26rem;
                line-height: 2;
                text-align: left;
                color: #333;
            }
        }
        .wsc-p3 .right {
            margin-top: 1rem;
            width: 100%;
        }
        .wsc-p5 .right {
            margin-top: .5rem;
        }
        .wsc-p4 .left ,.wsc-p6 .left {
            padding-top: 6rem;
        }
        .wsc-p4 .right, .wsc-p6 .right {
            position: absolute;
            top: 1rem;
            left: .2rem;
        }

        /*wsc-p3*/
        .wsc-p3-inner .content-box .wsc-p-img,.wsc-p5-inner .content-box .wsc-p-img {
            padding-top: 1rem;
            width: 100%;
        }
        .wsc-p4-inner .content-box .wsc-p-img,   .wsc-p6-inner .content-box .wsc-p-img {
            width: 100%;
        }
        .wsc-p4-inner .content-box .wsc-p-img {
            padding-top: 0;
        }

        /*wsc-p6*/
        .wsc-p6-inner .content-box .wsc-p-img {
            padding-top: 0;
        }
        .wsc-p6 .left {
            padding-top: 6rem;
        }


        /*wsc-p7*/
        .wsc-p7 {
            width: 7.5rem;
            height: auto;
        }
        .wsc-p7-inner .wsc-p7-img  {
            margin-top: 1rem;
            img {
                width: 100%;
                padding: 0;
            }

        }
        /*wsc-p8*/
        .wsc-p8-inner {
            padding-bottom: 1rem;
        }
        .process-box {
            padding-top: 1rem;
        }
        .process-box ul {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
        }
        .process-box .wsc-p8-item {
            width: 100%;
            /*margin-bottom: 1.6rem;*/
            margin-bottom: .9rem;;
        }

        .process-box .wsc-p8-item .wsc-p8-icons {
            flex-direction: row;
            &>div {
                width: 3.46rem;
                p {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
        .process-box .wsc-p8-item .wsc-p8-icons i {
            width: 1rem;
            height: 1rem;
            margin-right: .3rem;
            span {
                font-size: .35rem;
            }
        }
        .process-box .wsc-p8-item .wsc-p8-icons i:after {
            width: 4px;
            height: .5rem;
            left: 50%;
            top: 1.48rem;
            transform: translateX(-50%);
        }
        .process-box .wsc-p8-item .wsc-p8-icons h1 {
            margin: .3rem 0 .2rem;
        }
        .process-box .wsc-p8-item .wsc-p8-icons p {
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: .26rem;
        }

        .wsc-p8-inner .free-try-btn {
             margin: .5rem auto 0;
            font-size: .3rem;
            display: block;
        }
        .wscSystem .little-words {
            font-size: .25rem;
            display: flex;
            flex-direction: column;
            &>div {
                margin: 0 auto .2rem;
                width:2.4rem;
            }
        }
        .wsc-p9-inner {
            padding-bottom: 1rem;
            .process-box ul{
                flex-direction: row !important;
            }
        }
        .wsc-p9-item{
            margin: 0.3rem 0.4rem !important;
            width: 2.4rem !important;
            .wsc-p9-item-imgbox{
                width: 2.4rem  !important;
                height: 2.4rem  !important;
            }
            
        }


    } // media end

</style>
const wxApi = {
  wxRegister (option) {
     // 环境判断
    let baseUrl = ''
    let outkey = ''

    if(process.env.NODE_ENV == "development"){
        baseUrl = '/api'
        outkey = 'wxbddc4b5cda3b1e61' // 演示服
    }else{
        baseUrl = ''
        if(window.location.href.indexOf('gw.dev.maytek')!=-1){
            outkey = 'wxbddc4b5cda3b1e61' // 演示服
        }else if(window.location.href.indexOf('maytek.cn')!=-1){
            outkey = 'maytek2013' // 正式服
        } 
    }
    // let localUrl =  location.href.split('#')[0]
    let localUrl =  location.origin
    $.ajax({
        url: baseUrl+'/ApplyRecordWS/registerWxJs.gw',
        data: {
            outkey: outkey,
            url: localUrl
        },
        type:'post',
        success: function (res) {
            if (res.status) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.content["appid"], // 必填，公众号的唯一标识
                    timestamp: res.content["timestamp"], // 必填，生成签名的时间戳
                    nonceStr: res.content["noncestr"], // 必填，生成签名的随机串
                    signature: res.content["signature"], // 必填，签名
                    jsApiList: ['checkJsApi','updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage','onMenuShareQQ','onMenuShareQZone']
                });
                var shareData = {
                    "title": option.title,
                    "desc": option.description,
                    "link": location.href.split('#')[0],
                    "imgUrl":location.href.split('#')[0]+"dist/img/logo1.jpg"
                }
                wx.ready(() => {
                    wx.checkJsApi({
                        jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage','onMenuShareQQ','onMenuShareQZone'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                        success: function(res) {
                            if(res.checkResult.updateAppMessageShareData){
                                wx.updateAppMessageShareData({
                                    title: shareData.title,
                                    link: shareData.link,
                                    desc: shareData.desc,
                                    imgUrl: shareData.imgUrl,
                                    success: function () {
                                    },
                                });
                            } 
                            if(res.checkResult.updateTimelineShareData){
                                wx.updateTimelineShareData({
                                    title: shareData.title,
                                    link: shareData.link,
                                    imgUrl: shareData.imgUrl,
                                    success: function () {
                                    },
                                });
                            }
                            if(res.checkResult.onMenuShareTimeline){
                                wx.onMenuShareTimeline({
                                    title: shareData.title,
                                    link: shareData.link,
                                    imgUrl: shareData.imgUrl,
                                    success: function () {
                                    }
                                })
                            }

                            if(res.checkResult.onMenuShareAppMessage){
                                wx.onMenuShareAppMessage({
                                    title: shareData.title, // 分享标题
                                    desc: shareData.desc, // 分享描述
                                    link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                    imgUrl: shareData.imgUrl, // 分享图标
                                    success: function () {
                                    }
                                })
                            }
                            if(res.checkResult.onMenuShareQQ){
                                wx.onMenuShareQQ({
                                    title: shareData.title, // 分享标题
                                    desc: shareData.desc, // 分享描述
                                    link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                    imgUrl: shareData.imgUrl, // 分享图标
                                    success: function () {
                                    }
                                })
                            }
                        }
                      });
                })
                wx.error((res) => {
                    let b=JSON.stringify(res)
                    // alert(b) 
                })
            } else {
                // alert('提交失败');
            }
        }
    });
  }
}

export default wxApi


<template>
    <div class="free-try">
        <div class="free-p1">
            <div class="free-p1-box">
                <h1>{{msg}}</h1>
                <h2>全国统一服务热线：400-060-7500</h2>
                <router-link to="/register" class="b3" target="_blank">{{btn}}</router-link>
                <div class="free-p2">
                    <div class="ipt-box">
                        <input type="text" placeholder="姓名" v-model="registerInfo.username" @change="checkInput('username')">
                    </div>
                    <div class="ipt-box">
                        <input type="text" placeholder="公司名称" v-model="registerInfo.companyname" @change="checkInput('companyname')">
                    </div>
                    <div class="ipt-box">
                        <input type="text" placeholder="手机号码" v-model="registerInfo.telphone" @change="checkInput('telphone')">
                    </div>

                    <div class="b4" @click="register">提交试用申请</div>
                </div>
            </div>
        </div>
        <div class="wap-free-p2">
            <div class="ipt-box">
                <input type="text" placeholder="姓名" v-model="registerInfo.username"  @change="checkInput('username')">
            </div>
            <div class="ipt-box">
                <input type="text" placeholder="公司名称" v-model="registerInfo.companyname"  @change="checkInput('companyname')">
            </div>
            <div class="ipt-box">
                <input type="text" placeholder="手机号码" v-model="registerInfo.telphone"  @change="checkInput('telphone')">
            </div>

            <div class="b4" @click="register">提交试用申请</div>
        </div>
    </div>
</template>

<script>
    let vm;
    export default {
        name: "FreeTry",
        props: ["msg","btn"],
        data(){
            return {
                registerInfo: {
                    username: '', //姓名
                    companyname: '', //公司名
                    telphone: '',
                },
                BaseUrl:'',

            }
        },
        methods: {


            _moveFreeBtn() {

                if($('.free-try').length > 0) {
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离

                    if (($winHeight + $winScrollHeight) >= $('.free-try').offset().top ) {
                        $('.free-p2').animate({
                            left: '0'
                        }, 1200)
                    }
                }
            },

            checkInput(type) {
                if(type === 'telphone'){
                    let phoneval = mxApi.decidePhone(vm.registerInfo.telphone);
                    phoneval ? alert('手机格式不正确！') : '';
                    return false;
                }
            },
            register() {
                for (let i in vm.registerInfo) {
                    let r = vm.registerInfo[i];
                    if (!r) {
                        alert('请完善注册信息！');
                        return false;
                    }
                    if(i === 'telphone'){
                        if (mxApi.decidePhone(r)) {
                            alert('手机格式不正确！');
                            return false
                        }
                    }
                }

                $.ajax({
                    url: this.BaseUrl + '/ApplyRecordWS/addApplyRecord.gw',
                    data: {
                        email:'',
                        content:'',
                        title:'',
                        phone: vm.registerInfo.telphone,
                        name: vm.registerInfo.username,
                        company:vm.registerInfo.companyname,
                    },
                    dataType: "json",
                    success: function (data) {
                        if (data.status === 1) {
                            vm.registerInfo.telphone = ''
                            vm.registerInfo.username = ''
                            vm.registerInfo.companyname = ''
                            alert('提交成功！')
                        } else {
                            alert('提交失败');
                        }
                    }
                });
            },
            getBaseUrl(){
                // 环境判断
                if(process.env.NODE_ENV == "development"){
                    return '/api'
                }else{
                    return ''
                }
            },

        },
        mounted(){
            this.BaseUrl = this.getBaseUrl()

            vm  = this;
            let canRun = true;
            let timer;
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._moveFreeBtn()
                    canRun = true
                }, 300)

            })

            vm = this;


            window.mxApi = {



                /**
                 * 手机验证
                 * @param value 护照格式字符串
                 * @return boolean
                 * */
                decidePhone: function (value) {
                    /* let reg = /^0?1[345789]\d{9}$/;*/
                    let reg = /^([0-9]{3,4}-[0-9]{8})|1[0-9]{10}$/;
                    return !reg.test(value);
                },
                /**
                 * 邮箱验证
                 * @param value 护照格式字符串
                 * @return boolean
                 * */
                decideEmail(value) {
                    let reg = /^(\w)+@(\w)+(\.[A-Za-z]{2,3}){1,3}$/;
                    return !reg.test(value);
                },


            };




        },
    }
</script>

<style lang="less" >
    @bgBlue: #3b7ffc;

    .free-try {
        .free-p1-box {
            h1,h2 {
                color: #fff;
            }

        }
        .free-p1-box, free-p2-box {
            width: 1200px;
            margin: 0 auto;
        }
        .free-p1 {
            position: relative;
            padding-top: 50px;
            height: 380px;
            background: url("../../public/img/index/freetry-bg.png") no-repeat center/cover;
            text-align: center;
            color: #fff;
            h1 {
                font-size: 32px;
                margin-bottom: 35px;
            }
            h2 {
                font-size: 20px;
                margin-bottom: 35px;
            }
            .b3 {
                display: inline-block;
                width: 160px;
                height: 46px;
                line-height: 46px;
                font-size: 18px;
                text-align: center;
                border-radius: 23px;
                color: @bgBlue;
                background-color: #fff;
                cursor: pointer;
            }
            .free-p2 {
                width: 100%;
                position: absolute;
                left: -100%;
                bottom: 0;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                overflow: hidden;
                .ipt-box {
                    position: relative;
                    margin-right: 24px;
                    input {
                        border: 0;
                        padding: 0 2px 10px;
                        border-bottom: 1px solid #ccc;
                        color: #333;
                    }
                }
                .ipt-box:last-child {
                    margin-right: 0;
                }
                .getYzm {
                    position: absolute;
                    top: 0;
                    right: 0;
                    vertical-align: middle;
                    color: @bgBlue;
                    font-size: 14px;
                    cursor: pointer;
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 15px;
                        background-color: #ccc;
                        margin-right: 15px;
                        vertical-align: middle;
                    }
                }
                .b4 {
                    width: 120px;
                    height: 42px;
                    line-height: 42px;
                    margin-left: 40px;
                    text-align: center;
                    color: #fff;
                    border-radius: 30px;
                    background-color: @bgBlue;
                    font-size: 14px;
                    transform: translateY(-5px);
                    cursor: pointer;
                }
            }
        }
        .wap-free-p2 {
            display: none;
        }
    }


    @media all and (max-width: 750px) {
        .free-try{
            display: block;
        }
        .free-try .free-p1 .free-p2 {
            display: none;
        }
        .free-try .wap-free-p2 {
            display: block;
        }





        free-try {
            width: 7.5rem;
        }
        .free-try .free-p1 {
            padding-top: 1rem;
            padding-bottom: 1rem;
            height: auto;
            h1 {
                font-size: .45rem;
                margin-bottom: .3rem;
            }
            h2 {
                font-size: .35rem;
                margin-bottom: .5rem;
            }
            .b3 {
                width: 2.8rem;
                height: .85rem;
                line-height: .85rem;
                font-size: .3rem;
                border-radius: .6rem;
            }
        }
        .free-try .free-p1-box, .free-try free-p2-box {
            width: 7.5rem;
        }


        .free-try .wap-free-p2 {

            width: 7.5rem;
            flex-wrap: wrap;
            height: auto;
            padding: .5rem .2rem;




            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            overflow: hidden;
            .ipt-box {
                position: relative;


                width: 80%;
                margin-right: 0;
                margin-bottom: .8rem;
                input {
                    width: 100%;
                    border: 0;
                    padding: 0 2px 10px;
                    border-bottom: 1px solid #ccc;
                    color: #333;
                }
            }
            .ipt-box:last-child {
                margin-right: 0;
            }
            .getYzm {
                position: absolute;
                top: 0;
                right: 0;
                vertical-align: middle;
                color: @bgBlue;
                font-size: 14px;
                cursor: pointer;
                &:before {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 15px;
                    background-color: #ccc;
                    margin-right: 15px;
                    vertical-align: middle;
                }
            }
            .b4 {
                width: 2.8rem;
                height: 0.85rem;
                line-height: 0.85rem;
                margin-left: 0;
                font-size: .3rem;
                text-align: center;
                color: #fff;
                border-radius: .6rem;
                background-color: @bgBlue;

                transform: translateY(-5px);
                cursor: pointer;
            }
        }


    }
</style>
<template>
    <div class="knowMore" style="transform: none;">
        <div class="container">
            <div class="contentBox">
                <div class="header">
                    <span>{{moreData.type}}</span> / <span>{{moreData.title}}</span>
                </div>
                <div class="title">
                    <h1>{{moreData.title}}</h1>
                    <div class="write">
                        <span class="write-source" v-if="moreData.source">{{moreData.source}}</span><span class="write-date">{{moreData.releaseDate}}</span>
                    </div>
                    <div class="tag" v-if="moreData.tag">
                        <span v-for="(item,index) in moreData.tag" :key="index">{{item}}</span>
                    </div>
                </div>
                <div class="content">
                        <span id="conSpan" v-html="moreData.content"></span>
                </div>
                <div class="upAndDown">
                    <span class="upArticle">
                        <!-- 上一篇：<a :href="upContent.href" :title="upContent.title">{{upContent.title}}</a> -->
                        上一篇：<router-link :to="{path:upContent.href}" :title="upContent.title">{{upContent.title}}</router-link>
                    </span>
                    <span class="downArticle">
                        <!-- 下一篇：<a :href="downContent.href" :title="downContent.title">{{downContent.title}}</a> -->
                        下一篇：<router-link :to="{path:downContent.href}" :title="downContent.title">{{downContent.title}}</router-link>
                    </span>
                </div>
            </div>
        </div>
        <foot-box></foot-box>
    </div>
</template>

<script>
    let vm;
    import FootBox from '@/components/Footer.vue'
    export default {
        name: "KnowMore",
        components: {
            FootBox
        },
        metaInfo(){
            return {
                title:this.getMeta().title,
                meta:this.getMeta().meta
            }
        },
        data(){
            return {
                BaseUrl:'',
        


                newsList: [],
                newsType: '公司动态',
                numberCode:'',
                moreData:'',
                upContent:'',
                downContent:'',
                pagenum:'',
                maxPage:'',
                code:'',
                metaObj:'',
            }
        },
        watch: {
            $route(to, from) {
            // 对路由变化作出响应...
               this.$nextTick(()=>{
                    if (this.$route.query.code) {
                        this.numberCode = this.$route.query.code;
                        this.pagenum = this.$route.query.pagenum;
                        this.newsType = this.$route.query.newsType;
                        this.newsListGetData();
                    }
               })
            },  
        },
        methods: {
            getBaseUrl(){
                // 环境判断
                if(process.env.NODE_ENV == "development"){
                    return '/api'
                }else{
                    return ''
                }
            },
         

            htmlDecode(html){
                return html.replace(/(\&|\&)gt;/g, ">")
                    .replace(/(\&|\&)lt;/g, "<")
                    .replace(/(\&|\&)quot;/g, "\"");
            },

            //  获取新闻
            newsListGetData() {
                vm.newsType =  vm.newsType == '0'?'公司动态':'行业资讯';
                $.ajax({
                    url: this.BaseUrl+"/MaytekNewsWS/queryMaytekNewsList.gw",
                    data: {
                        q: JSON.stringify({type:vm.newsType}),
                        page: vm.pagenum,
                        rows: 10,
                    },
                    async: true,
                    success: function (data) {
                        if (data.status === 1) {
                            if(data.content.total%10 == 0){
                                vm.maxPage = data.content.total/10;
                            } else{
                                vm.maxPage = parseInt(data.content.total/10) + 1;
                            }
                            vm.newsList = [];
                            data.content.rows.forEach(function (d) { 
                                if(d.numberCode == vm.numberCode){

                                    vm.moreData = d;
                                    vm.moreData.releaseDate = vm.moreData.releaseDate.slice(0,10).split("-").join("/");
                                    vm.moreData.tag = vm.moreData.tag ? vm.moreData.tag.split(',') : '';
                                    vm.moreData.content = vm.htmlDecode(vm.moreData.content);
                                    vm.metaObj = vm.moreData.description ? eval('('+vm.moreData.description+')') : '';
                                    // console.log(vm.metaObj)
                                }
                                vm.newsList.push(d);
                            });
                            vm.getUpAndDown()
                        }
                    }
                })
            },
            getMeta(){
                let metaTitle,metaList = [];
                for(let i=0;i<Object.keys(this.metaObj).length;i++){
                    if(Object.keys(this.metaObj)[i] == "title"){
                        metaTitle = Object.values(this.metaObj)[i]
                    }else{
                        metaList.push({name:Object.keys(this.metaObj)[i],content:Object.values(this.metaObj)[i]});
                    }
                     
                }
                let setMeta = {
                    title:metaTitle,
                    meta:metaList
                };
                return setMeta;
            },
            getUpAndDown(){
                vm.newsList.forEach((item,index) =>{
                        if(item.numberCode == vm.moreData.numberCode){
                            if(index == 0 && index == (vm.newsList.length -1)){
                                vm.upContent = {
                                    title: '没有上一篇了',
                                };
                                $('.upArticle').css('pointerEvents','none');   
                                vm.downContent = {
                                    title: '没有下一篇了',
                                };
                                $('.upArticle').css('pointerEvents','none');
                                $('.downArticle').css('pointerEvents','none');    
                            } else if(index == 0){
                                let type = vm.newsType == '公司动态'?'0':'1';
                                if(vm.pagenum == 1){
                                    vm.upContent = {
                                        title: '没有上一篇了',
                                    };
                                    $('.upArticle').css('pointerEvents','none');   
                                }else{
                                    let n = Number(vm.pagenum)-1;
                                    $.ajax({
                                        url: this.BaseUrl+"/MaytekNewsWS/queryMaytekNewsList.gw",
                                        data: {
                                            q: JSON.stringify({type:vm.newsType}),
                                            page: n,
                                            rows: 10,
                                        },
                                        async: true,
                                        success: function (data) {
                                            if (data.status === 1) {
                                                vm.upContent = data.content.rows[9];
                                                vm.upContent.href = "/knowMore?code=" + data.content.rows[9].numberCode + "&pagenum=" + n + "&newsType=" + type;
                                            }
                                        }
                                    })
                                }
                                vm.downContent = vm.newsList[index+1];
                                vm.downContent.href = "/knowMore?code=" + vm.newsList[index+1].numberCode + "&pagenum=" + vm.pagenum + "&newsType=" + type;
                                $('.downArticle').css('pointerEvents','unset');   
                            } else if( index == (vm.newsList.length -1) ){ 
                                let type = vm.newsType == '公司动态'?'0':'1';
                                vm.upContent = vm.newsList[index-1];
                                vm.upContent.href = "/knowMore?code=" + vm.newsList[index-1].numberCode + "&pagenum=" + vm.pagenum + "&newsType=" + type;   
                                $('.upArticle').css('pointerEvents','unset');   
                                if(vm.pagenum != vm.maxPage){
                                    let n = Number(vm.pagenum)+1;
                                    $.ajax({
                                        url: this.BaseUrl+"/MaytekNewsWS/queryMaytekNewsList.gw",
                                        data: {
                                            q: JSON.stringify({type:vm.newsType}),
                                            page: n,
                                            rows: 10,
                                        },
                                        async: true,
                                        success: function (data) {
                                            if (data.status === 1) {
                                                vm.downContent = data.content.rows[0];
                                                vm.downContent.href = "/knowMore?code=" + data.content.rows[0].numberCode + "&pagenum=" + n + "&newsType=" + type;
                                            }
                                        }
                                    })
                                }else{
                                    vm.downContent = {
                                        title: '没有下一篇了',
                                    };
                                    $('.downArticle').css('pointerEvents','none'); 
                                }
                            } else{
                                let type = vm.newsType == '公司动态'?'0':'1';
                                vm.upContent = vm.newsList[index-1];
                                vm.upContent.href = "/knowMore?code=" + vm.newsList[index-1].numberCode + "&pagenum=" + vm.pagenum + "&newsType=" + type;
                                vm.downContent = vm.newsList[index+1];
                                vm.downContent.href = "/knowMore?code=" + vm.newsList[index+1].numberCode + "&pagenum=" + vm.pagenum + "&newsType=" + type;
                                $('.upArticle').css('pointerEvents','unset');  
                                $('.downArticle').css('pointerEvents','unset'); 
                            }
                        }
                    }); 
            },
          
        },
        created(){
            this.BaseUrl = this.getBaseUrl()
            
            vm = this;
            this.getMeta()
           
            // 初始化新闻数据
            // if(this.$route.query.code && this.$route.query.pagenum){
            //     vm.code = this.$route.query.code;
            //     vm.numberCode = this.$route.query.code;
            //     vm.pagenum = this.$route.query.pagenum;
            //     vm.newsType = this.$route.query.newsType;
            //     vm.newsListGetData();
            // }

            // 静态化后因路径原因这样获取参数
            var params = window.location.href.split('?')[1];
            var paramsList = params.split('&');
            if(paramsList[0].split('=')[1] && paramsList[1].split('=')[1] && paramsList[2].split('=')[1]){
                vm.code = paramsList[0].split('=')[1];
                vm.numberCode = paramsList[0].split('=')[1];
                vm.pagenum = paramsList[1].split('=')[1];
                vm.newsType = paramsList[2].split('=')[1];
                vm.newsListGetData();
            }
        },
        mounted(){
            // this.BaseUrl = this.getBaseUrl()
            
            // vm = this;
            // this.getMeta()
            $(function () {
                $('.header-nav').addClass('float');
                $('#nav').addClass('header-nav-bg');
                $('.link-item').addClass('nav-word');
            })
            $('.header-nav').hover(function () {
                $('.header-nav').addClass('float');
            })
           
            // 初始化新闻数据
            // if(this.$route.query.code && this.$route.query.pagenum){
            //     vm.code = this.$route.query.code;
            //     vm.numberCode = this.$route.query.code;
            //     vm.pagenum = this.$route.query.pagenum;
            //     vm.newsType = this.$route.query.newsType;
            //     vm.newsListGetData();
            // }

            // 静态化后因路径原因这样获取参数
            // var params = window.location.href.split('?')[1];
            // var paramsList = params.split('&');
            // if(paramsList[0].split('=')[1] && paramsList[1].split('=')[1] && paramsList[2].split('=')[1]){
            //     vm.code = paramsList[0].split('=')[1];
            //     vm.numberCode = paramsList[0].split('=')[1];
            //     vm.pagenum = paramsList[1].split('=')[1];
            //     vm.newsType = paramsList[2].split('=')[1];
            //     vm.newsListGetData();
            // }
        }
    }
</script>

<style lang="less">
    @bgBlue: #3b7ffc;
    .header-nav-bg{
        background-color: #fff !important;
        box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%) !important;
    }
    .nav-word{
        color: #000 !important;
    }
   

    .knowMore{
        .container{
            padding-top:90px;
            background-color:#eee;
            padding-bottom:10px;
        }
        .header{
           padding-bottom:10px;
           border-bottom:1px solid #eee;
           color:#999;
        }
        .title{
            margin-top:20px;
            h1{
                margin-bottom:10px;
                font-size:22px;
                font-weight:400;
            }
            .write{
                margin-bottom:20px;
                .write-source{
                    padding:1px 2px;
                    background-color:#eee;
                    color:#999;
                    margin-right:5px
                }
                .write-date{
                    color:#999;
                }
            }
            .tag{
                padding:10px 10px 0;
                background-color:#eee;
                border-radius:5px;
                color:#576b95;
                span{
                    display: inline-block;
                    margin:0 10px 10px 0;
                }
            }
        }
        .content{
            margin:10px 0;
            min-height:calc(100vh - 660px);
        }
        .upAndDown{
            display:flex;
            justify-content: space-between;
            padding-top:20px;
            border-top:1px solid #eee;
            user-select: none;
            span{
                width: 210px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .downArticle{
                text-align: right;
            }
        }
        #conSpan img{
            width: 100% !important;
        }
    }

    .contentBox{
        width: 1000px;
        background-color:#fff;
        padding:40px 50px 50px 50px;
        margin:auto;
    }
   


    @media all and (max-width: 750px){
        // .news {
        //     min-width: auto;
        // }
        .wap-menu .wap-nav-btn .line {
            background-color: #3b7ffc;
        }
        .contentBox{
            width: 98%;
            padding: 0.4rem;
        }
        .knowMore{
            .container{
                padding-top:1rem;
            }
            .title{
                .tag{
                    padding:10px 10px 0;
                    background-color:#eee;
                    border-radius:5px;
                    color:#576b95;
                    span{
                        display: inline-block;
                        margin: 0 0.1rem 0.2rem;
                    }
                }
            }
            .upAndDown{
                display:flex;
                justify-content: space-between;
                padding-top:20px;
                border-top:1px solid #eee;
                span{
                    width: 2.3rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
       

    } // media end

</style>
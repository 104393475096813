<template>
    <div class="news">
            <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat new-banner">
                <div class="banner-words-box">
                    <h1>美匣云推出码商系统解决方案</h1>
                    <h4 class="banner-words-box-h4">可以为飞猪、携程、美团等平台的门票商家提供码商系统服务</h4>
                    <h4 class="banner-words-box-move">可以为飞猪、携程、美团等<br/>平台的门票商家提供码商系统服务</h4>
                    <h5>按量计费，比其他同类码商系统的性价比更高</h5>
                    <router-link to="/register" class="free-try-btn" target="_blank">立即试用体验</router-link>
                </div>
            </div>
        </div>
        
            <div class="news-nav">
                <ul>
                    <li class="news-nav-item active">公司动态</li>
                    <li class="news-nav-item">行业资讯</li>
                    <li class="news-nav-item">升级日志</li>
                </ul>
            </div>
            <div class="center-box">
                
                <img src="../../public/img/news/loading.gif" alt="" v-if="loading" style="display:block;margin:auto;">
                <ul class="change-container">
                    <li class="change-item active">
                        <ul class="news-list">
                            <li class="news-item" v-for="(item,index) in newsList1" :key="index">
                                <div class="news-img">
                                    <img :src="item.images" alt="" draggable="false">
                                </div>
                                <div class="news-words-box">
                                    <h1>{{item.title}}</h1>
                                    <i>{{item.releaseDate}}</i><i class="news-source" v-if="item.source">来源：{{item.source}}</i>
                                    <p class="news-tag" v-if="item.tag">{{item.tag}}</p>
                                    <p class="news-des">{{item.remark}}</p>
                                    <a :href='openUrl(item)' target="_blank" class="know-more">了解详情</a>
                                </div>
                            </li>
                        </ul>
                        <div class="pagination-box">
                            <div class="pagination">
                                <!--<span class="page current" >1</span>-->
                                <span :class="['page',{'current':pagenum == index+1}] " v-for="(item,index) in maxNum" :key="index" @click="pageClick(index+1)">{{index + 1}}</span>
                            </div>
                        </div>

                    </li>
                    <li class="change-item ">
                        <ul class="news-list">
                            <li class="news-item" v-for="(item,index) in newsList2" :key="index">
                                <div class="news-img">
                                    <img :src="item.images" alt="" draggable="false">
                                </div>
                                <div class="news-words-box">
                                    <h1>{{item.title}}</h1>
                                    <i>{{item.releaseDate}}</i><i class="news-source" v-if="item.source">来源：{{item.source}}</i>
                                    <p class="news-tag" v-if="item.tag">{{item.tag}}</p>
                                    <p class="news-des">{{item.remark}}</p>
                                    <a :href='openUrl(item)' target="_blank" class="know-more">了解详情</a>
                                </div>
                            </li>
                        </ul>
                        <div class="pagination-box">
                            <div class="pagination">
                                <span :class="['page',{'current':pagenum == index+1}] " v-for="(item,index) in maxNum" :key="index" @click="pageClick(index+1)">{{index + 1}}</span>
                            </div>
                        </div>

                    </li>
                    <li class="change-item ">
                        <div class="update-log-container">
                            <ul>
                                <li class="log-item" v-for="(item, index) in newsList3" :key="index">
                                    <h1>{{item.title}}</h1>
                                    <div class="log-cont-box">
                                        <p class="log-content" v-html="item.content"></p>
                                    </div>
                                </li>
                            </ul>
                            <div class="pagination-box">
                                <div class="pagination">
                                    <span :class="['page',{'current':pagenum == index+1}] " v-for="(item,index) in maxNum" :key="index" @click="pageClick(index+1)">{{index + 1}}</span>
                                </div>
                            </div>
                        </div>

                    </li>
                </ul>

            </div>

        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>

<script>
    let vm;
    import FootBox from '@/components/Footer.vue'
    import PopWindow from '@/components/PopWindow.vue'
    export default {
        name: "News",
        components: {
            FootBox,
            PopWindow,
        },
        data(){
            return {
                BaseUrl:'',
                newsArr: [
                    {
                        title: '专栏：涛涛不绝第5期\t这组关于00后的数据里，藏着旅游业的未来趋势（之二）',
                        time: '2020/05/20',
                        des: '作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。早一步赢得他们的偏爱与信任，开启未来的商机呢？早一步赢得他们的偏爱与信任，开启未来的商机呢？',
                        content: '',
                        img: '',
                    },
                    {
                        title: '专栏：涛涛不绝第5期\t这组关于00后的数据里，藏着旅游业的未来趋势（之二）',
                        time: '2020/05/20',
                        des: '作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。',
                        content: '',
                        img: '',
                    },
                    {
                        title: '专栏：涛涛不绝第5期\t这组关于00后的数据里，藏着旅游业的未来趋势（之二）',
                        time: '2020/05/20',
                        des: '作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。早一步赢得他们的偏爱与信任，开启未来的商机呢？早一步赢得他们的偏爱与信任，开启未来的商机呢？',
                        content: '',
                        img: '',
                    },
                    {
                        title: '专栏：涛涛不绝第5期\t这组关于00后的数据里，藏着旅游业的未来趋势（之二）',
                        time: '2020/05/20',
                        des: '作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。早一步赢得他们的偏爱与信任，开启未来的商机呢？早一步赢得他们的偏爱与信任，开启未来的商机呢？',
                        content: '',
                        img: '',
                    },
                ],
                pageTotal: 1 - 1, // 总页数 - 1

                // 日志数据
                logArr: [
                    {
                        title: '2020年05月21日（周四）',
                        content: [
                            {
                                contData: '1. 作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。'
                            },
                            {
                                contData: '2. 作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。'
                            },
                            {
                                contData: '3. 作为旅游企业，如何抓住90/00后的心智 '
                            },
                        ]
                    },
                    {
                        title: '2020年05月21日（周四）',
                        content: [
                            {
                                contData: '1. 作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。'
                            },
                        ]
                    },
                    {
                        title: '2020年05月21日（周四）',
                        content: [
                            {
                                contData: '1. 作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。'
                            },
                            {
                                contData: '2. 作为旅游企业，如何抓住90/00后的心智，在他们即将入场的行业提前布局。'
                            },
                        ]
                    },
                ],


                newsList: [],
                newsList1: [], // 公司动态
                newsList2: [], // 行业资讯
                newsList3: [], // 升级日志
                pagenum: 1, // 页数
                maxNum: 0, // 最大页码
                newsListLength:0,
                newsTypeArr: ['公司动态','行业资讯','升级日志'],
                newsType: '公司动态',
                loading:true
            }
        },
        methods: {
            getBaseUrl(){
                // 环境判断
                if(process.env.NODE_ENV == "development"){
                    return '/api'
                }else{
                    return ''
                }
            },
            // 导航选中加class
            navAddClass() {
                
                $('.news-nav .news-nav-item').click(function () {
                    if(vm.loading == false){
                        vm.loading = true;
                        $('.news-nav .news-nav-item').removeClass('active')
                        $(this).addClass('active')
                        let index = $(this).index()
                        $('.change-container .change-item').removeClass('active')
                        $('.change-container .change-item').eq(index).addClass('active')
                        vm.newsType = vm.newsTypeArr[index]
                        vm.pagenum = 1;
                        vm.newsListGetData();
                    }
                })
                

            },
            //    分页器点击加class
            clickAddClass() {
                $('.pagination .page').click(function () {
                    $('.pagination .page').removeClass('current')
                    $(this).addClass('current')
                })
               
            },

            htmlDecode(html){
                return html.replace(/(\&|\&)gt;/g, ">")
                    .replace(/(\&|\&)lt;/g, "<")
                    .replace(/(\&|\&)quot;/g, "\"");
            },

            //  获取新闻
            newsListGetData() {
                $.ajax({
                    url: this.BaseUrl + "/MaytekNewsWS/queryMaytekNewsList.gw",
                    data: {
                        q: JSON.stringify({type:vm.newsType}),
                        page: vm.pagenum,
                        rows:  10,
                    },
                    dataType: "json",
                    async: true,
                    success: function (data) {
                        if (data.status === 1) {
                            let itemArray = [];
                            vm.maxNum = Math.floor( data.content.total / 10) + (data.content.total % 10 != 0 ? 1 : 0)
                            data.content.rows.forEach(function (d) { 
                                let row = {
                                    title: '',
                                    content: '',
                                    url: '',
                                    images: '',
                                    releaseDate: '',
                                    numberCode: '',
                                    source: '',
                                    tag: '',
                                    remark: ''
                                };
                                row.title = d.title;
                                row.content = vm.htmlDecode(d.content);
                                if(vm.newsType != '升级日志'){
                                    row.content = row.content.replace(/<.*?>/ig,"");
                                }
                                row.url = d.url;
                                row.images = d.images;
                                row.releaseDate = d.releaseDate.slice(0,10).split("-").join("/");
                                row.numberCode = d.numberCode;
                                row.type = d.type;
                                row.source = d.source;
                                row.tag = d.tag;
                                row.remark = d.remark;
                                itemArray.push(row);
                            });
                            vm.newsList1 = [];
                            vm.newsList2 = [];
                            vm.newsList3 = [];
                            if(vm.newsType == '公司动态'){
                                vm.newsList1 = itemArray;
                            } else if(vm.newsType == '行业资讯') {
                                vm.newsList2 = itemArray;
                            } else if(vm.newsType == '升级日志') {
                                vm.newsList3 = itemArray;
                            }

                            vm.loading = false;
                            vm.$forceUpdate();
                            // vm.newsList = itemArray;
                            // vm.newsListLength = vm.newsList.length;
                            // vm.maxNum =  Math.round(vm.newsListLength / 1);
                            // if(vm.pagenum > 1){
                            //     // vm.page();
                            // }
                        }
                    }
                })
            },

            // 获取每个类型最大新闻数量
            // getNewsMaxNum(){
            //     $.ajax({
            //         url: this.BaseUrl+"/MaytekNewsWS/queryMaytekNewsList.xyz",
            //         data: {
            //             q: JSON.stringify({type:vm.newsType}),
            //             page: vm.pagenum,

            //         },
            //         async: true,
            //         success: function (data) {
            //             if (data.status === 1) {

            //                 vm.newsList = data.content.rows;
            //                 vm.newsListLength = vm.newsList.length;
            //                 vm.maxNum = Math.round( data.content.total / 10)
            //             }
            //         }
            //     })
            // },
            // 点击页码 跳转
            pageClick(num){
                vm.pagenum = num;
                vm.newsListGetData();
            },
            openUrl(item){
                let type = item.type == '公司动态'?'0':'1'
                return "/knowMore?code=" + item.numberCode + "&pagenum=" + vm.pagenum + "&newsType=" + type;
            }
        },
        mounted(){
            this.BaseUrl = this.getBaseUrl()

            vm = this;
            // this.clickAddClass()
            this.navAddClass()

            // vm.getNewsMaxNum() // 根据该类型的总数据获取页码总数
            
            //帮助中心跳官网
            if(this.$route.query.up){
              $('.news-nav .news-nav-item').removeClass('active')
              $('.news-nav .news-nav-item').eq(2).addClass('active')
              $('.change-container .change-item').removeClass('active')
              $('.change-container .change-item').eq(2).addClass('active')
              vm.newsType = vm.newsTypeArr[2]
            }
            // 初始化新闻数据
            vm.newsListGetData();
        }
    }
</script>

<style lang="less">
    @bgBlue: #3b7ffc;
    .change-container {
        padding-bottom: 50px;
        min-height: 610px;
        .change-item {
            display: none;
            min-height: 560px;
        }
        .active {
            display: block;
        }
    }

    .news {
        min-width: 1200px;
        width: 100%;
        .banner-box {
            width: 100%;
            height: 600px;
            background-image: url("../../public/img/news/news_banner_bg.png");
            background-size: cover;
            .banner-inner {
                display: flex;
                justify-content: center;
            }
        }
        .banner-words-box {
            margin-top: 170px;
            text-align: center;

            h1 {
                font-size: 50px;
                margin-bottom: 20px;
                font-weight: normal;
            }
            h4 {
                font-size: 24px;
                margin-bottom: 26px;
                color: #fff;
            }
            .banner-words-box-move{
                display: none;
            }
            h5 {
                font-size: 18px;
                color: #fff;
            }
            .free-try-btn {
                margin-left: auto;
                margin-right: auto;
                border: solid 1px #ffffff;
                background-color: transparent;

            }
        }
    }
    
    .news-nav {
        background-color: #f2f4f7;
        &>ul {
            display: flex;
            justify-content: center;
            width: 1200px;
            margin: 0 auto 20px;
           
        }

        .news-nav-item {
            position: relative;
            width: 200px;
            height: 75px;
            line-height: 75px;
            margin-right: 175px;
            color: #333;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
            &:after {
                content: '';
                position: absolute;
                bottom: 2px;
                left: 0;
                display: block;
                width: 100%;
                height: 2px;
                background-color: transparent;
                transition: all .3s linear;
            }
        }
        .news-nav-item:hover {
            color: @bgBlue;
            &:after {
                background-color: @bgBlue;

            }
        }
        .news-nav-item:last-child {
            margin-right: 0;
        }
        .active {
            color: @bgBlue;
            &:after {
                background-color: @bgBlue;

            }
        }
    }


    /*新闻列表*/
    .news-list {
        .news-item {
            display: flex;
            justify-content: space-between;
            padding: 40px 0;
            border-bottom: 1px solid #e6e6e6;

            .news-img {
                width: 370px;
                height: 180px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }

            }
            .news-words-box {
                position: relative;
                width: 788px;
                overflow: hidden;
                h1 {
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 20px;
                    color: #333;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                i {
                    display: inline-block;
                    font-size: 14px;
                    color: #999;
                    line-height: 24px;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 14px;
                    color: #333;
                    line-height: 24px;
                    overflow : hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .know-more {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin-top: 34px;
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    background-color: @bgBlue;
                    cursor: pointer;
                }
                .news-source{
                    margin-left:10px;
                }
                .news-tag{
                    margin-bottom:10px;
                }
            }
        }
    }
    /*分页器*/
    .pagination-box {
        text-align: right;
    }
    .pagination {
        display: inline-flex;
        flex-wrap: nowrap;
        margin-top: 10px;
        text-align: right;

        .page {
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #999;
            font-size: 14px;
            background-color: #fff;
            border: solid 1px #cccccc;
            margin-right: 10px;
            cursor: pointer;
        }
        .page:last-child {
            margin-right: 0;
        }
        .current {
            background-color: #f5f8ff;
            border: 1px solid @bgBlue;
            color:  @bgBlue;
        }
    }

    /*更新日志*/
    .update-log-container {
        padding: 40px 0 50px;

        .log-item {
            width: 950px;
            margin: 0 auto;
            h1 {
                padding-bottom: 25px;
                margin-bottom: 25px;
                font-size: 18px;
                color: #333;
                font-weight: bold;
                border-bottom: 1px solid #e6e6e6;
            }
            .log-cont-box {
                padding-bottom: 80px;
            }
            .log-content {
                font-size: 14px;
                color: #666;
                line-height: 2;
            }
        }

    }


    @media all and (max-width: 750px){
        .news {
            min-width: auto;
            .banner-inner {
                width: 7.5rem;
            }
            .free-try-btn {
                font-size: .25rem;
            }
        }
        .news .banner-box {
            /*height: 10rem;*/
            height: 100vh;
        }
        .news .banner-words-box {
            padding: 0 .3rem;
            margin-top: 3.4rem;
            h1 {
                font-size: .4rem;
                margin-bottom: .6rem;
            }
            h4 {
                font-size: .3rem;
                margin-bottom: .5rem;
            }
            .banner-words-box-h4{
                display: none;
            }
            .banner-words-box-move{
                display: block;
                line-height: 24px;
            }
            h5 {
                font-size: .25rem;
            }
        }

        .news-nav > ul {
            width: 7.5rem;
            margin: 0 auto;

        }
        .news-nav .news-nav-item {
            width: 2.5rem;
            height: 1rem;
            line-height: 1rem;
            margin-right: .2rem;
            font-size: .25rem;
        }
        .news-list .news-item {
            flex-direction: column;
            padding: .8rem 0;
        }
        .news-list .news-item .news-img {
            width: 100%;
            height: 3.6rem;
            margin-bottom: .5rem;
        }
        .news-list .news-item .news-words-box {
            width: 100%;
            margin-bottom: 1rem;
            overflow: visible;
            h1 {
                margin-bottom: .2rem;
                font-size: .3rem;
                line-height: 1.5;
            }
            p {
                font-size: .26rem;
                line-height: 1.5;
            }
            .know-more {
                left: auto;
                right: 0;
                bottom: -1rem;
                width: 2rem;
                height: .7rem;
                line-height: .7rem;
            }
        }
        .news-list .news-item .news-img img {
            object-fit: contain;
        }
        .change-container {
            padding-bottom: 0;
            min-height: auto;

        }
        .change-container .change-item {
            min-height: auto;
        }
        /*日志*/
        .update-log-container .log-item {
            width: 100%;
            h1 {
                padding-bottom: .3rem;
                margin-bottom: .3rem;
                font-size: .35rem;
            }
        }
        .update-log-container {
            padding: .8rem 0 0;
        }
        .update-log-container .log-item .log-cont-box {
            padding-bottom: 1rem;
        }
        .update-log-container .log-item:last-child .log-cont-box {
            padding-bottom: 0;
        }
        .new-banner .banner-words-box .free-try-btn {
            margin-top: 1.5rem;
        }


    } // media end

</style>
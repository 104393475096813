<template>
    <div class="popWindow">
        <!-- <div class="wordI">
            <img src="../../public/img/index/greenFire.png" alt="">
            <span>终身免费</span>
        </div> -->
        <div class="wordII"> 
            美匣云旅游<span>ERP</span>
        </div>
        <div class="wordIII">
            中小企业版
        </div>
        <div class="btn">
            <router-link to="/mayTekErp" target="_blank">点击了解更多详情</router-link>
        </div>
        <p class="wordIV">
            扫微信立即沟通
        </p>
        <img src="../../public/img/index/qrcode.png" alt="" class="imgCode">
        <!-- <p class="wordV">咨询热线</p>
        <p class="phone">191-2232-2677</p> -->
    </div>
</template>
<script>
export default {
    name:'PopWindow',
    mounted(){

        // window.addEventListener('scroll',function(){
        //     if(parent.scrollY>720){
        //         $('.popWindow').css({
        //             'position':'fixed',
        //             'top':'230px'
        //         })
        //     }else if(parent.scrollY<700){
        //          $('.popWindow').css({
        //             'position':'absolute',
        //             'top':'720px'
        //         })
        //     }
        // })
        
    }
}
</script>

<style lang="less" scoped>
.popWindow{
    width: 236px;
	height: 440px;
	border-radius: 20px;
    background: url('../../public/img/index/popwindow.png') no-repeat;
    background-size: cover;
    box-shadow: 0px 2px 8px 0px rgba(240, 60, 25, 0.2);
    position: fixed;
    top: 120px;
    right: 10px;
    padding-top: 36px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    transition: all .5s ease;
}
.wordI{
    font-size: 22px;
	font-weight: bold;
	line-height: 22px;
	letter-spacing: 1px;
    margin-bottom: 15px;
    background-image: linear-gradient(0deg, #fff2b5 0%, #1cff91 100%);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    display: flex;
    align-items: center;
    img{
        margin-right: 5px;
    }
}
.wordII{
    font-size: 23px;
	font-weight: bold;
	line-height: 23px;
	letter-spacing: 1px;
    background-image: linear-gradient(0deg, #fff2b5 0%, #ffffff 100%);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    margin-bottom: 19px;
    span{
        font-size: 26px;
        line-height: 23px;
        display: block;
        float: right;
        vertical-align: bottom;
    }
}
.wordIII{
    font-size: 16px;
	line-height: 16px;
	color: #ffffff;
    position: relative;
    margin-bottom: 42px;
    &::before{
        display: block;
        content: '';
        width: 36px;
        height: 1px;
        background-color: rgba(250, 250, 250, 0.5);
        position: absolute;
        left: -46px;
        top: 50%;
    }
    &::after{
        display: block;
        content: '';
        width: 36px;
        height: 1px;
        background-color: rgba(250, 250, 250, 0.5);
        position: absolute;
        right: -46px;
        top: 50%;
    }
}
.btn{
    width: 158px;
	height: 36px;
	background-color: #f0e319;
	border-radius: 18px;
    font-size: 14px;
	line-height: 36px;
	color: #010101;
    text-align: center;
    margin-bottom: 29px;
}
.wordIV{
    font-size: 14px;
	line-height: 14px;
	color: #ffffff;
    margin-bottom: 10px;
}
.imgCode{
    width: 158px;
    display: block;
    margin-bottom: 19px;
}
.wordV{
    font-size: 16px;
	line-height: 16px;
	color: #ffffff;
    margin-bottom: 9px;
}
.phone{
    font-size: 20px;
	font-weight: bold;
	line-height: 20px;
	color: #ffffff;
}
@media all and (max-width: 750px) {
    .popWindow{
        display: none;
    }
}
</style>
<template>
    <div class="register">
        <div id="loginWrap"  class="wrapper" v-cloak>
            <!--<div class="header-box">-->
                <!--<header>-->
                    <!--<div class="head-inner clearFloat">-->
                        <!--<a href="../page/home.html" title="专注旅游管理系统|开发专业旅行社ERP|美匣科技">-->
                            <!--<img class="logo logo1" src="../../public/img/register/logo.png" alt="专注旅游管理系统|开发专业旅行社ERP|美匣科技"-->
                                 <!--title="专注旅游管理系统|开发专业旅行社ERP|美匣科技">-->
                            <!--<img class="logo logo2" src="../../public/img/register/logo2.png" alt="专注旅游管理系统|开发专业旅行社ERP|美匣科技"-->
                                 <!--title="专注旅游管理系统|开发专业旅行社ERP|美匣科技">-->
                        <!--</a>-->
                    <!--</div>-->
                <!--</header>-->
            <!--</div>-->
            <div class="content-wrap">
                <div class="register-wrap">
                    <h1 style="text-align: center;">申请试用体验</h1>
                    <form method="get">
                        <div class="input-outline-x">
                            <i class="iconfont icon-yonghu1"></i>
                            <input class="input-outline" type="text" placeholder="姓名"
                                   v-model="registerInfo.username"
                                   @change="checkInput('username')">
                        </div>
                        <div class="input-outline-x">
                            <i class="iconfont icon-yonghu1"></i>
                            <input class="input-outline" type="text" placeholder="公司名称"
                                   v-model="registerInfo.companyname"
                                   @change="checkInput('companyname')">
                        </div>
                        <div class="input-outline-x telphone clearFloat">
                            <div class="area-code">
                                <span>+86</span>
                                <i class="iconfont icon-arrow-down"></i>
                            </div>
                            <input class="input-outline" type="tel" placeholder="手机号"
                                   v-model="registerInfo.telphone"
                                   @change="checkInput('telphone')">
                        </div>

                    </form>
                    <button class="register-btn" @click="register">提交申请</button>
                </div>
                <div class="register-success">
                    <i class="iconfont icon-tianjiachenggong"></i>
                    <h1>试用申请成功</h1>
                    <h2>感谢您对美匣的信任</h2>
                    <h2>我们将在最快的时间与您取得联系</h2>
                    <div class="erweima-box clearFloat">
                        <i class="angle top-left"></i>
                        <i class="angle top-right"></i>
                        <i class="angle bottom-left"></i>
                        <i class="angle bottom-right"></i>
                        <img src="../../public/img/register/erma.jpg" alt="">
                        <div>
                            <p>微信扫一扫</p>
                            <p>获取更多旅业智能产品信息</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 服务条款 -->
            <div class="service-terms" :style="isShow?'display:block':'display:none'">
                <div class="title-box" ref="termsTitle">
                    <span class="activeLine">服务条款</span>
                    <span>隐私声明</span>
                    <i id="line"></i>
                    <i class="iconfont icon-guanbi" @click="isShow=false"></i>
                </div>
                <!-- 服务条款 -->
                <ul v-if="termsType===1">
                    <li v-for="(item,index) in serviceTerms" :key="index">
                        <h4>{{item.title}}</h4>
                        <p>{{item.content}}</p>
                    </li>
                </ul>
                <!-- 隐私声明 -->
                <ul v-if="termsType===2">
                    <li v-for="(item,index) in pravteTermsPage" :key="index">
                        <h4>{{item.title}}</h4>
                        <p>{{item.content}}</p>
                    </li>
                </ul>
                <div class="btn-box" ref="btnBox">
                    <button @click="isChecked = true;isShow = false">同意</button>
                    <button @click="isChecked = false;isShow = false">不同意</button>
                </div>
            </div>
            <footer>
                <p class="copyRight"><a target="_blank" href="http://www.beian.miit.gov.cn" >沪ICP备16025219号-1</a> 上海美匣网络科技有限公司</p>
            </footer>
        </div>
    </div>
</template>

<script>
    let vm;
    export default {
        name: "Register",
        data(){
            return {
                BaseUrl:'',
                termsType: 1,
                serviceTerms: [
                    {
                        title: '',
                        content: '上海美匣网络科技有限公司，提供www.maytek.cn（“maytek.cn”）的网站，此域名下的所有内容和它为您提供的服务 受制于以下条款和条件。使用maytek.cn，' +
                        '也就说明您同意最新修订版本的协议和上海美匣网络科技有限公司的隐私和安全策略（请参阅下面的“条款修订”2020年1月1日）',
                    },
                    {
                        title: '第一条：接受这些条件',
                        content: '通过使用maytek.cn的信息，工具，特征和功能，借助任何上海美匣网络科技有限公司的API，或者经由maytek.cn接口的任何软件、其它网站，' +
                        '或者它的API(统称“服务”)，都表示您同意遵守本协议，无论您是一个“访客”（表示您只是浏览maytek.cn网站），或者您是一个“成员”（意思是您注册了上海' +
                        '美匣网络科技有限公司）。 术语“您”或“用户”指的是访客或者成员。如果您想成为成员并使用该服务，您必须阅读本协议，表明您在注册过程中接受了此协议。' +
                        '如果您接受此协议，则表示 您有能力受其约束，或者，如果您代表了一个公司或实体，您有权使该实体受其约束。',
                    },
                    {
                        title: '第二条：版权',
                        content: 'maytek.cn包含的所有内容，比如文本、图形、徽标、按钮图标、图像、音频剪辑、数码下载、数据编译、软件以及内容汇编成一体的，连贯的网站都属于' +
                        '上海美匣网络科技有限公司，并受中国和国际版权法的保护。严禁未经上海美匣网络科技有限公司书面允许即对其内容进行复制。'

                    },
                    {
                        title: '第三条：商标和服务标记',
                        content: '上海美匣网络科技有限公司标志和其他上海美匣网络科技有限公司图形、标识、网页标题、按钮图标、脚本和服务名称均为上海美匣网络科技有限公司或' +
                        '其子公司的商标、证明商标、服务标记或其它商业外观。上海美匣网络科技有限公司的商标、证明商标、服务商标和商业外观由于它们的限制使用而具有固有的意义' +
                        '和重大的价值。它们不会用于未经上海美匣网络科技有限公司许可的任何有关产品或服务。所有在 maytek.cn出现的不属于上海美匣网络科技有限公司或其子公司的' +
                        '商标均为其各自所有者所拥有，可能或可能不是由上海美匣网络科技有限公司或它子公司所隶属、相关联或赞助的。'
                    },
                    {
                        title: '第四条：访问maytek.cn和服务的许可证',
                        content: '考虑到访问maytek.cn和其服务，上海美匣网络科技有限公司授予您一个访问网站和用于个人使用的有限许可。本许可协议禁止您下载（除了页面缓存），' +
                        '或修改它的任何部分， 除非得到上海美匣网络科技有限公司明确的书面同意。该协议不允许转售上海美匣网络科技有限公司的服务。在未经maytek.cn明确书面同意的' +
                        '情况下，您不得使用框架技术框架或附上任何商标、标识 或其它专有(包括图像、文本、网页布局或表单)的信息。您在没有经过上海美匣网络科技有限公司明确书面同' +
                        '意的情况下不可以使用任何元数据标签和利用上海美匣网络科技有限公司名称或商标的任何其它 “隐藏文本”。任何未经授权的使用自动终止了上海美匣网络科技有限公' +
                        '司授予的允许或许可，并可能产生各种损害赔偿的法律责任。您被授予有限的，可撤销的，非独有性的权利来创建至任何非密码保护的目录的超链接，只要这个链接不以' +
                        '虚假、误导、诽谤或其它冒犯性的内容来描绘上海美匣网络科技有限公司、其所属网站或它的服务。您在未经书面许可的情况下不能使用任何上海美匣网络科技有限公司' +
                        '专有的图形或商标作为链接的一部分。'
                    },
                    {
                        title: '第五条：账户和密码',
                        content: '如果您发布一个账户，您需自行负责维护您的账户和密码的机密性，并且您同意接受对在您的账户或密码下发生的所有活动负责。上海美匣网络科技有限公司' +
                        '保留以下权利：自行裁定权，拒绝服务，暂停或终止账户，或以其他方式限制访问maytek.cn和上海美匣网络科技有限公司的服务'
                    },
                    {
                        title: '第六条：您的内容',
                        content: '您保留所接受的上海美匣网络科技有限公司服务的任何材料的全部版权。'
                    },
                    {
                        title: '第七条：访问者使用和滥用maytek.cn的记录',
                        content: '作为maytek.cn的访问者和上海美匣网络科技有限公司服务的使用者，您同意为防止滥用，您的互联网协议地址被记录并且您的活动被监控。滥用情况包括但' +
                        '不限于：试图通过密码挖掘或任何其它方式来获取服务、其它账户、计算机系统或服务的网络链接的越权存取。传输任何含有病毒、特洛伊木马、蠕虫、定时炸弹' +
                        '或其它任何有害或有毒的程序。干扰或破坏连接到服务的网络或违反法规、政策或此类网络的规程。创建虚假身份或另有企图误导他人利用maytek.cn用户的身份。有' +
                        '意或无意地违反任何适用的当地，国家或国际法律。'
                    },
                    {
                        title: '第八条：禁止使用',
                        content: '您不得通过上海美匣网络科技有限公司的服务张贴、传达、传播、缓存或存储材料，上海美匣网络科技有限公司自行判断以下情况:(a)违反了任何本地、国家' +
                        '或外国法律或法规；(b)具有威胁性、淫秽、 不雅、诽谤、或可能对任何个人，团体，或实体（统称为"人"）产生负面影响；(c)违反任何人的权利，包括著作权，商业' +
                        '秘密保护的权利，专利或其他知识产 权或类似的法律或法规，包括但不限于安装未经许可的“盗版”或其他不恰当的软件。您同意您在不知情的情况下使用这项服务，' +
                        '此外： 上传，张贴，传送或以其他方式提供违法、有害、威胁、辱骂、骚扰、侵权、诽谤、粗俗、淫秽、侵犯他人隐私的内容，仇恨或种族，民族歧视的内容，或其他' +
                        '不良的内容； 以任何方式伤害未成年人； 冒充任何人或实体，包括但不限于上海美匣网络科技有限公司官方和论坛领袖，引导或主持，或以虚假陈述或谎称与任何人或' +
                        '实体的从属关系； 为了伪装通过服务传输内容的来源而伪造标题或其他操纵标识符； 上传、张贴、传输或以其它方式散布依据法律、合同或代理关系无权散布的内容' +
                        '（如内部消息，通过雇用关系得知的或属于所签署保密协议的专属及机密资料）； 上传，张贴，传送或以其他方式提供任何侵犯专利，商标，商业秘密，版权或其他' +
                        '任何一方的专有权利； 上传，张贴，传送或以其他方式提供任何未经请求或授权的广告，宣传材料，“垃圾邮件”，“垃圾信息”，“连锁信”，“传销”或类似的信息； ' +
                        '上传，张贴，传送或以其他方式提供任何包含软件病毒或其他计算机代码，文件或设计来中断，破坏或限制任何计算机软件，硬件或通讯设备功能； 干扰或破坏本服务' +
                        '或服务器或连接到服务的网络，或违反连接到服务的网络的任何规定，规程，政策或法规； 有意或无意地违反，企图侵犯，或规避任何适用ICANN的规定或政策； 有意' +
                        '或无意地违反任何适用的当地、国家或国际法律，包括但不限于由中国证券和证券交易委员会公布的任何规则，任何国家或者其它证券交易所的任何规则（包括但不限于' +
                        '中国证券交易所，纳斯达克）和任何具有法律效力的法规； 向任何恐怖组织或个人提供资金、物资和筹集资金、物资或者主动提供恐怖组织活动的地方； “跟踪”或以' +
                        '其他方式骚扰他人； 宣传或提供有关非法活动的指导信息，协助针对任何团体或个人的人身伤害或损害，或推广任何虐待动物的行为。 您同意保护上海美匣网络科技' +
                        '有限公司，并且使其不受损害您或者其它团体的使用而造成的任何索赔。上海美匣网络科技有限公司有权调查您，您的业务，和/或您的业主，管理人员，董事，经理及' +
                        '其他负责 人，您的网站，以及在任何时间地点组成的材料。这些调查的进行纯粹为上海美匣网络科技有限公司的利益，而不是为了您或任何第三方的利益。如果调查结' +
                        '果显示任何信息，行为或疏 忽，被上海美匣网络科技有限公司的单方面认为违背了任何地方，国家或外国法律或法规，或违反本协议，上海美匣网络科技有限公司可能' +
                        '会立即关闭该服务。您同意放弃任何因反对上海美匣网络科技有限公司的这种措施而以此为诉因或者要求索赔。'
                    },
                    {
                        title: '第九条：赔偿',
                        content: '您同意赔偿并且保护上海美匣网络科技有限公司和它的子公司，支部，职员，代理人，联合品牌或其他合作伙伴、员工，因您使用本服务或连接到服务而违' +
                        '反了服务条款，或对其他人的任何权利的侵犯而被提出的索赔，包括合理的律师费用。'
                    },
                    {
                        title: '第十条：免责声明及责任限制',
                        content: 'maytek.cn是由上海美匣网络科技有限公司基于“原样”及“现有”为基础提供。上海美匣网络科技有限公司关于maytek.cn的运作，其服务的有效性，或信息，' +
                        '内容，材料或包含在 maytek.cn的产品，不做任何声明或提供任何形式的明示或暗示的保证。您明确同意，您对maytek.cn的使用是自担风险的。为最大程度地适用于' +
                        '法律允许的情况，上海美匣网络科技有限公司否认所有包括明示或暗示的担保，但不限于隐含的商业授权和为一个特殊目的的适用性。上海美匣网络科技有限公司会尽' +
                        '一切努力，但并不能保证 maytek.cn，其服务器，或电子邮件从它的任何域名中传送病毒或其他有害成分。上海美匣网络科技有限公司对由maytek.cn的使用，包括但' +
                        '不限于直接的、间接的、附带的、惩罚性的和间接损害而产生的任何类型的损害赔偿是无责任的。某些国家不允许限制隐含的保证或排除或限制某些损害赔偿。如果这' +
                        '些法律的部分或上述免责声明适用于您，排除或限制可能对您不适用的，您也可能有额外的权利。'
                    },
                    {
                        title: '第十一条：终止',
                        content: '上海美匣网络科技有限公司的政策是调查违反这些条款和终止重复侵权者。您同意上海美匣网络科技有限公司可在某些情况下，在不事先通知的情况下，立' +
                        '即终止您的上海美匣网络科技有限公司账户、任何关联的电子邮件地址和 maytek.cn和相关的服务的访问。终止的原因包括但不限于：(a)对于我们的服务条款或其他' +
                        '合并协议或指导方针的违反或违背；(b)执法部门或其他 政府机构的要求；(c)您的请求(自主账户删除)；(d)对服务（或其中任何部分）的终止或重大修改；(e)突发' +
                        '技术或安全问题；(f)长期限制；(g) 您有从事或有合理理由被怀疑从事欺诈或非法的活动;(h)为您的账户提供了部分虚假资料；(i)未保持您账户的完整性，真实性，' +
                        '准确性；(j)任何触犯上述上海美匣网络科技有限公司禁止条列的行为；(k)您有与maytek.cn和相关服务有关的任何费用拖欠。此外，上海美匣网络科技有限公司只负' +
                        '责上海美匣网络科技有限公司保护之下的终端，对于用户自己或第三方的账户、服务以及已中断了上海美匣网络科技有限公司保护的终端，上海美匣网络科技有限公司' +
                        '概不负责。您明确同意终止原因的情况下，您进一步承认和同意，即使有任何终止，您对于上海美匣网络科技有限公司提出的第 9,10,11,和12条规定的义务终止后仍' +
                        '然有效。'
                    },
                    {
                        title: '第十二条：修订',
                        content: '上海美匣网络科技有限公司可以不时地修订本协议。任何以及所有本协议的更改都将张贴在maytek.cn上面。此外，该协议将一直显示它上次修改的日期。' +
                        '您将被视为接受并同意在那些更改被贴出后，您在使用服务时受任何修改的协议所约束。',
                    },
                    {
                        title: '第十三条：链接',
                        content: '该服务会提供，或第三方可能提供其他万维网网站或资源的链接。由于上海美匣网络科技有限公司不会对此类网站和资源的控制，您承认并同意上海美匣网' +
                        '络科技有限公司不对此类外部网站或资源的可用性负责，不认可，也不对任何内容，广告，产品，材料或源于此类网站或资源负责任。您进一步承认和同意，上海美匣' +
                        '网络科技有限公司对使用、依赖任何此类内容而造成的损失，直接或间接的任何损害，概不负责或承担赔偿责任。',
                    },
                    {
                        title: '第十四条：无第三方受益者',
                        content: '您同意，除非另有明文服务条款的规定，否则，对于此协议不得有第三方受益者。',
                    },
                    {
                        title: '第十五条：弃权',
                        content: '对上海美匣网络科技有限公司未行使或执行任何权利或本协议的规定，不构成对前述权利或规定的放弃。如果任何服务条款的规定经有管辖权的法院认定为' +
                        '无效，当事人依然同意法院应努力使反映在规定中的意图生效，而本条款的其他规定服务仍然具有十足效力及作用。',
                    },
                    {
                        title: '第十六条：可分割性条款',
                        content: '如果任何服务条款的规定经有管辖权的法院认定为无效，当事人依然同意法院应努力使反映在规定中的意图生效，而本条款的其他规定服务仍然具有十足效' +
                        '力及作用。',
                    },
                    {
                        title: '第十七条：不可转让账户',
                        content: '您同意您的上海美匣网络科技有限公司账户非经上海美匣网络科技有限公司的书面同意不得转让。',
                    },
                    {
                        title: '第十八条：索赔时限',
                        content: '您同意无论任何法规和法律相反，任何索赔或因本协议而引起的或与maytek.cn使用有关的诉讼必须在索赔或诉讼等原因发生的一年内提出，否则被永远禁止。',
                    },
                    {
                        title: '第十九条：适用法律',
                        content: '通过访问maytek.cn，您同意中国的法律，不考虑法律冲突原则，将控制这些使用条件和上海美匣网络科技有限公司可能出现和您之间或其关联公司的任何种' +
                        '类的任何争议。任何争议或索赔引起的或与本协议有关的判定应在中国上海。',
                    },
                    {
                        title: '第二十条：标题',
                        content: '标题和服务条款之标题仅为方便起见，并没有法律或合同的效力。',
                    },
                    {
                        title: '第二十一条：屏蔽',
                        content: '如果政府或监管机构要求，上海美匣网络科技有限公司可以暂时屏蔽页面甚至整个网站，但上海美匣网络科技有限公司会同时通知用户。',
                    },

                ],
                pravteTermsPage: [
                    {
                        title: '作为公共指定信息',
                        content: '作为我们服务的一部分，我们将尽量保障您提供给我们的任何将被公开的信息的明确性。即使您给了我们公开某些信息的权限，我们仍将从被披露的信息中保' +
                        '护那些被指定为私人的信息（如您的 姓名、电子邮件地址和其它特别标记为您的身份的信息）。',
                    },
                    {
                        title: '信息收集',
                        content: '上海美匣网络科技有限公司是本网站和通过任何上海美匣网络科技有限公司服务收集到的信息的唯一拥有者。',
                    },
                    {
                        title: '更正个人信息',
                        content: '这个网站可以很容易地于任何时间更正您个人信息的任何错误。只要有可能，我们会给您机会来审查并更改您给我们提供的信息。如果您注意到您提供给我们' +
                        '的信息中有错误，您可以随时修改。这可以在线完成或者直接与我们联系。',
                    },
                    {
                        title: 'COOKIES',
                        content: '为了给我们的用户提供更好的服务，我们的网站使用“cookies”。Cookies是由您的浏览器在您计算机硬盘上放置的您的计算机的唯一标识。上海美匣网络科' +
                        '技有限公司不会储存那些被我们在cookie中认为是私人的或者私有的信息。这消除了其他网站通过阅读我们的cookie并了解您个人或私人信息的机会。相反，我们使用' +
                        'cookie来帮助指导您到我们网站的适当的部分。比如，cookie可能表明您是我们网站的重复访问者。如果您不想让上海美匣网络科技有限公司或者其他的网站使用cookies，' +
                        '您可以在您的浏览器中禁用它们。上海美匣网络科技有限公司网站的某些部分在没有cookies的情况下仍任能够起作用。如果您特别想要删除被放置在您浏览器中的所有cookies，' +
                        '请意识到这将重置您的会话，禁用自动登录，并且可能给在上海美匣网络科技有限公司网站的其他功能造成不利影响。您可以选择允许上海美匣网络科技有限公司放置cookies在' +
                        '您受上海美匣网络科技有限公司保护站点上面的浏览器中。',
                    },
                    {
                        title: '推广伙伴',
                        content: '有时，上海美匣网络科技有限公司可能会通知您我们网站上的上海美匣网络科技有限公司推广伙伴的邀约。虽然我们可能为这些邀约找到特定类型的使用者，' +
                        '不过我们将会在我们的系统内完整地查找。我们的商业伙伴将不会拥有任何访问目标的信息，包括对特殊产品或服务感兴趣的人的姓名。直到您积极地响应这些邀约，' +
                        '否则我们将不会对任何伙伴透漏关于您的任何关于身份识别的信息。',
                    },
                    {
                        title: '第三方网站',
                        content: '上海美匣网络科技有限公司网站和其他网站包含第三方网站的链接。请注意，我们不对这些外部网站的隐私行为负责。我们鼓励用户注意了解他们什么时候' +
                        '离开我们的网站，并阅读其他网站的隐私声明。我们不能保证这些第三方遵守此处列出的隐私原则。',
                    },
                    {
                        title: '法院命令',
                        content: '上海美匣网络科技有限公司可能被法庭要求提供包含在我们网站的信息。上海美匣网络科技有限公司可能还需要提供依据法律、适用的法规、传票或其他法律' +
                        '程序的信息。在这些条件下，我们将尽可能告知所有用户我们必须披露的信息。',
                    },
                    {
                        title: '更改通知',
                        content: '我们将始终坚持这一原则：我们不会出售、出租或泄露任何未经您同意与我们共享的个人信息。我们认为我们日后将不会改变这一原则，但是，如果我们确实' +
                        '需要改变政策的任何其他方面，我们将在网站上公布这些变化。',
                    },

                ],
                isChecked: false, //是否同意《服务条款》
                isShow: false,
                isend: false,
                username: '',
                password: '',
                num: 90, //验证码倒计时
                registerInfo: {
                    username: '', //姓名
                    companyname: '', //公司名
                    telphone: '',
                    /*     captcha: '', //验证码
                         password: '',
                         repwd: '',*/
                },
                imgcode: '', //图形验证码
                isShowPwd: false,
                isShowRepwd: false,
                codeStr: '', //图形验证码
                imgCodeShow: false
            }
        },
        methods: {
            getBaseUrl(){
                // 环境判断
                if(process.env.NODE_ENV == "development"){
                    return '/api'
                }else{
                    return ''
                }
            },
            //服务条款切换
            switchTab() {
                let timer = setTimeout(function () {
                    let $termsTitle = vm.$refs.termsTitle;
                    let $span = $termsTitle.querySelectorAll('span');
                    let $line = document.getElementById('line');
                    $span.forEach(function (s, index) {
                        s.onmouseover = function (e) {
                            $line.style.left = this.offsetLeft + 'px';
                        };
                        s.onmouseout = function (e) {
                            let $activeLine = $termsTitle.querySelectorAll('.activeLine');
                            $line.style.left = $activeLine[0].offsetLeft + 'px';
                        };
                        s.onclick = function (e) {
                            $span.forEach(function (n) {
                                n.className = '';
                            });
                            this.className = 'activeLine';
                            $line.style.left = this.offsetLeft + 'px';
                            vm.termsType = index + 1;
                        }
                    });

                    clearTimeout(timer);
                }, 100)
            },
            checkInput(type) {
                if(type === 'telphone'){
                    let phoneval = mxApi.decidePhone(vm.registerInfo.telphone);
                    phoneval ? alert('手机格式不正确！') : '';
                    return false;
                }
            },
            register() {
                for (let i in vm.registerInfo) {
                    let r = vm.registerInfo[i];
                    if (!r) {
                        alert('请完善注册信息！');
                        return false;
                    }
                    if(i === 'telphone'){
                        if (mxApi.decidePhone(r)) {
                            alert('手机格式不正确！');
                            return false
                        }
                    }
                }

                $.ajax({
                    url: this.BaseUrl + '/ApplyRecordWS/addApplyRecord.gw',
                    data: {
                        email:'',
                        content:'',
                        title:'',
                        phone: vm.registerInfo.telphone,
                        name: vm.registerInfo.username,
                        company:vm.registerInfo.companyname,
                    },
                    dataType: "json",
                    success: function (data) {
                        if (data.status === 1) {
                            $('.register-success').show();
                            $('.register-wrap').hide();
                        } else {
                            alert('提交错误');
                        }
                    }
                });
            },
        },
        mounted(){
            this.BaseUrl = this.getBaseUrl()
            
            vm = this;

            $(function () {
                $('.header-nav').addClass('float');
            })
            $('.header-nav').hover(function () {
                $('.header-nav').addClass('float');
            })
            window.mxApi = {
                /*
                 **获取URl参数
                 */

                getUrlArgument: function () {
                    let url = location.search; //获取url中"?"符后的字串
                    let theRequest = {};
                    if (url.indexOf("?") !== -1) {
                        let str = url.substr(url.indexOf("?") + 1);
                        let strs = str.split("&");
                        for (let i = 0; i < strs.length; i++) {
                            theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
                        }
                    }
                    return theRequest;
                },
                /**
                 * 判断是pc还是移动
                 * true:pc  false:移动端
                 * */
                isPc: function () {
                    let userAgentInfo = navigator.userAgent;
                    let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];  //判断用户代理头信息
                    let flag = true;
                    for (let v = 0; v < Agents.length; v++) {
                        if (userAgentInfo.indexOf(Agents[v]) !== -1) {
                            flag = false;
                            break;
                        }
                    }
                    return flag;   //true为pc端，false为非pc端
                },
                initRem: function () {
                    let maxWidth = 768;
                    let minWidth = 320;
                    let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
                    if (width > maxWidth) {
                        width = maxWidth;
                    } else if (width < minWidth) {
                        width = minWidth
                    }
                    let fontSize = (width / maxWidth) * 100;
                    document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px';
                    document.getElementsByTagName('body')[0].style.fontSize = '0.28rem';
                    window.addEventListener('orientationchange' in window ? 'orientationchange' : 'resize', this.initRem, false);
                    document.addEventListener('DOMContentLoaded', this.initRem, false);
                },
                /**
                 * html转义
                 * */
                htmlDecode: function (html) {
                    return html.replace(/(\&|\&)gt;/g, ">")
                        .replace(/(\&|\&)lt;/g, "<")
                        .replace(/(\&|\&)quot;/g, "\"");
                },
                getTargetAttr: function () {
                    let list = document.body.querySelectorAll('a[target="_blank"]');
                    if (!mxApi.isPc()) {
                        list.forEach(function (i) {
                            i.setAttribute('target', '_self');
                            i.onclick = function (e) {

                                window.event ? window.event.cancelBubble = true : e.stopPropagation();
                            }
                        })
                    } else {
                        list.forEach(function (i) {
                            i.setAttribute('target', '_blank');
                            i.onclick = function (e) {
                                window.event ? window.event.cancelBubble = true : e.stopPropagation();
                            }
                        })
                    }
                },
                /**
                 * 手机验证
                 * @param value 护照格式字符串
                 * @return boolean
                 * */
                decidePhone: function (value) {
                    /* let reg = /^0?1[345789]\d{9}$/;*/
                    let reg = /^([0-9]{3,4}-[0-9]{8})|1[0-9]{10}$/;
                    return !reg.test(value);
                },
                /**
                 * 邮箱验证
                 * @param value 护照格式字符串
                 * @return boolean
                 * */
                decideEmail(value) {
                    let reg = /^(\w)+@(\w)+(\.[A-Za-z]{2,3}){1,3}$/;
                    return !reg.test(value);
                },
                /**
                 * 返回顶部
                 * */
                backtop(){
                    let backtop = document.createElement('div');
                    backtop.id = 'backTop';
                    let img = document.createElement('img');
                    img.src = '../images/home/backTop.png';
                    document.body.appendChild(backtop);
                    backtop.appendChild(img);
                    let timer = null;
                    if (mxApi.isPc()){
                        window.onscroll = function () {
                            let dtop = document.documentElement.scrollTop;
                            event.preventDefault();
                            if (dtop >= 300) {
                                backtop.style.display = "block";
                            } else {
                                backtop.style.display = "none";
                            }
                        };
                        backtop.onclick = function () {
                            timer = setInterval(function () {
                                let dtop = document.documentElement.scrollTop;
                                let speed = 200;
                                document.documentElement.scrollTop = dtop - speed;
                                if (dtop <=0) {
                                    clearInterval(timer);
                                }
                            }, 50)
                        };
                    }else {
                        window.onscroll = function () {
                            let dtop = document.documentElement.scrollTop;
                            if(dtop <= 0){
                                backtop.style.display = "none";
                            }else{
                                backtop.style.display = "block";
                            }
                        };
                        backtop.onclick = function (){
                            document.documentElement.scrollTop = 0;
                        }
                    }

                }
            };

            class messageDialog {
                constructor() {
                    this.dialog = '';
                }

                show(type, text) {
                    let $this = this;
                    $this.dialog && $this.dialog.remove();
                    let $parent = document.getElementsByClassName('wrapper')[0];
                    $this.dialog = document.createElement('p');
                    $this.dialog.className = 'message-dialog';
                    $parent.appendChild($this.dialog);

                    let $icon = document.createElement('i');
                    if (type === 'success') {
                        $this.dialog.style.backgroundColor = 'rgba(82, 196, 26, 0.1)';
                        $icon.className = 'iconfont icon-Group-';
                    } else {
                        $icon.className = 'iconfont icon-cuowu';
                    }
                    $this.dialog.appendChild($icon);

                    let $span = document.createElement('span');
                    $this.dialog.appendChild($span);
                    $span.innerText = text;

                    if(mxApi.isPc()){
                        let timer = setTimeout(function () {
                            $this.dialog.style.top = '7px';
                            clearTimeout(timer);
                        }, 100);
                    }else{
                        let timer = setTimeout(function () {
                            $this.dialog.style.opacity = '1';
                            let timer2 = setTimeout(function () {
                                $this.dialog.style.display = 'block';
                                clearTimeout(timer);
                                clearTimeout(timer2);
                            }, 200);
                        }, 100);
                    }



                    let timer3 = setTimeout(function () {
                        message.hide();
                        clearTimeout(timer3);
                    }, 2000)
                };

                hide() {
                    let $this = this;
                    if (!$this.dialog) {
                        return
                    }

                    if(mxApi.isPc()){
                        $this.dialog.style.top = '-45px';
                    }else{
                        $this.dialog.style.opacity = '0';
                    }
                    let removeTimer = setTimeout(function () {
                        try {
                            $this.dialog.remove();
                        } catch (e) {

                        }
                        $this.dialog.style.display = 'none';
                        $this.dialog = '';
                        clearTimeout(removeTimer);
                        removeTimer = null;
                    }, 300)
                };
            }

            window.message = new messageDialog();
            if (!mxApi.isPc()) {
                mxApi.initRem();
            }
        }

    }
</script>

<style lang="less">
    @import "../assets/css/font.css";
    .register {
        width: 100%;
        height: 100%;
        position: fixed;
        background-image: url("../../public/img/register/registerBg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    html .header-box header, body .header-box header {
        width: 100%;
        height: 55px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        z-index: 2;
    }
    html .header-box header .head-inner, body .header-box header .head-inner {
        width: 1300px;
        height: 100%;
        margin: auto;
    }
    html .header-box header .head-inner .logo1, body .header-box header .head-inner .logo1 {
        display: inline-block;
        margin-top: 15px;
    }

    .logo2 {
        display: none;
    }
    #loginWrap {
        width: 100%;
        min-width: 1300px;
        height: 100%;
        // background-color: #f3f5f7;
    }
    #loginWrap .header-box {
        height: auto!important;
        border-bottom: 1px solid #dddfee;
        background-color: #f3f5f7;
    }
    #loginWrap .content-wrap {
        width: 500px;
        height: 468px;
        margin: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    #loginWrap .content-wrap .register-wrap {
        width: 500px;
        height: auto;
        box-shadow: 0 2px 7px rgba(13, 106, 235, 0.2);
        background-color: #ffffff;
        margin: 10px auto;
        padding: 47px 100px;
    }
    #loginWrap .content-wrap .register-wrap h1 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    #loginWrap .content-wrap .register-wrap h1 a {
        color: #333333;
    }
    #loginWrap .content-wrap .register-wrap h1 .iconfont {
        color: #999;
        font-weight: normal;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-wrap p {
        font-size: 12px;
        margin: 31px 0 15px;
    }
    #loginWrap .content-wrap .register-wrap p a {
        color: #086bff;
        text-decoration: underline;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x {
        width: 300px;
        height: 40px;
        margin-bottom: 20px;
        position: relative;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .iconfont {
        position: absolute;
        top: 12px;
        left: 15px;
        color: #acacac;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .icon-suo1, #loginWrap .content-wrap .register-wrap form .input-outline-x .icon-emailicon {
        font-size: 18px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .icon-caozuo-xianshihaoma {
        right: 12px;
        left: auto;
        font-size: 21px;
        top: 10px;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .input-outline {
        width: 100%;
        height: 100%;
        padding-left: 48px;
        border: 1px solid #cccccc;
        border-radius: 5px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .input-outline:focus {
        border-color: #086bff;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .input-outline::placeholder {
        font-size: 12px;
        color: #ccc;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .area-code {
        width: 80px;
        height: 38px;
        border-right: 1px solid #cccccc;
        position: absolute;
        background: #fafafa;
        left: 1px;
        top: 1px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .area-code span {
        font-size: 14px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x .area-code .icon-arrow-down {
        position: static;
        float: right;
        margin-right: 8px;
        font-size: 19px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.telphone .input-outline {
        padding-left: 95px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.verification-code .input-outline {
        padding-left: 15px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.verification-code .input-outline::placeholder {
        color: #ccc;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.verification-code .captcha-btn {
        width: 110px;
        height: 38px;
        background: #086bff;
        color: #fff;
        text-align: center;
        border: 0;
        position: absolute;
        right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        top: 1px;
        cursor: pointer;
        line-height: 38px;
        font-size: 14px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification input {
        width: 120px;
        text-align: left;
        padding-left: 15px;
        float: left;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification #verificationCode {
        float: left;
        width: 100px;
        height: 40px;
        margin-left: 12px;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification #verificationCode canvas {
        width: 100%;
        height: 100%;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification #verificationCode #code_img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        vertical-align: top;
    }
    #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification span {
        display: inline-block;
        font-size: 14px;
        color: #066bff;
        position: relative;
        top: 19px;
        left: 14px;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-wrap .agree-terms {
        font-size: 12px;
        margin: 20px 0 16px;
    }
    #loginWrap .content-wrap .register-wrap .agree-terms .iconfont {
        font-size: 20px;
        display: inline-block;
        position: relative;
        top: 3px;
        color: #cccccc;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-wrap .agree-terms .icon-fuxuankuangxuanzhong {
        color: #086bff;
    }
    #loginWrap .content-wrap .register-wrap .agree-terms em {
        color: #086bff;
        font-style: normal;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-wrap .register-btn {
        width: 300px;
        height: 48px;
        border-radius: 5px;
        color: #ffffff;
        background-image: linear-gradient(#086bff, #2520e1);
        border: 0;
        font-size: 18px;
        cursor: pointer;
    }
    #loginWrap .content-wrap .register-success {
        width: 500px;
        height: auto;
        box-shadow: 0 2px 7px rgba(13, 106, 235, 0.2);
        background-color: #ffffff;
        margin: 10px auto;
        padding: 47px 0;
        display: none;
    }
    #loginWrap .content-wrap .register-success .icon-tianjiachenggong {
        color: #00cc99;
        font-size: 64px;
        display: block;
        text-align: center;
    }
    #loginWrap .content-wrap .register-success h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
    }
    #loginWrap .content-wrap .register-success h2 {
        font-size: 16px;
        text-align: center;
        margin-bottom: 7px;
    }
    #loginWrap .content-wrap .register-success .erweima-box {
        width: 316px;
        height: 104px;
        background-color: #f2f7ff;
        position: relative;
        margin: 40px auto;
        padding: 12px 0 12px 20px;
    }
    #loginWrap .content-wrap .register-success .erweima-box .angle {
        position: absolute;
        width: 11px;
        height: 11px;
        border: 2px solid transparent;
    }
    #loginWrap .content-wrap .register-success .erweima-box .top-left {
        border-top-color: #086bff;
        border-left-color: #086bff;
        top: 0;
        left: 0;
    }
    #loginWrap .content-wrap .register-success .erweima-box .top-right {
        border-top-color: #086bff;
        border-right-color: #086bff;
        top: 0;
        right: 0;
    }
    #loginWrap .content-wrap .register-success .erweima-box .bottom-left {
        border-bottom-color: #086bff;
        border-left-color: #086bff;
        bottom: 0;
        left: 0;
    }
    #loginWrap .content-wrap .register-success .erweima-box .bottom-right {
        border-bottom-color: #086bff;
        border-right-color: #086bff;
        bottom: 0;
        right: 0;
    }
    #loginWrap .content-wrap .register-success .erweima-box img {
        width: 80px;
        float: left;
    }
    #loginWrap .content-wrap .register-success .erweima-box > div {
        float: left;
        padding: 17px 0 0 17px;
    }
    #loginWrap .content-wrap .register-success .erweima-box > div p {
        margin-bottom: 12px;
        font-size: 14px;
    }
    #loginWrap .content-wrap .copyRight {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 20px;
        font-size: 12px;
        color: #999;
    }
    #loginWrap .content-wrap .copyRight a {
        color: #999;
        text-decoration: none;
    }
    #loginWrap .service-terms {
        width: 1080px;
        height: 744px;
        box-shadow: 0 2px 7px rgba(13, 106, 235, 0.2);
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
    }
    #loginWrap .service-terms .title-box {
        width: 100%;
        border-bottom: 1px solid #f3f5f7;
        position: relative;
    }
    #loginWrap .service-terms .title-box span {
        display: inline-block;
        width: 125px;
        text-align: center;
        padding-bottom: 15px;
        font-size: 16px;
    }
    #loginWrap .service-terms .title-box span:hover {
        font-weight: bold;
        cursor: pointer;
    }
    #loginWrap .service-terms .title-box .activeLine {
        font-weight: bold;
        cursor: pointer;
    }
    #loginWrap .service-terms .title-box #line {
        width: 125px;
        height: 3px;
        background-color: #086bff;
        position: absolute;
        left: 0;
        bottom: -2px;
        transition: all 0.3s linear;
    }
    #loginWrap .service-terms .title-box .iconfont {
        float: right;
        font-size: 14px;
        cursor: pointer;
    }
    #loginWrap .service-terms ul {
        height: 590px;
        overflow-y: auto;
        padding-bottom: 10px;
    }
    #loginWrap .service-terms ul li {
        padding-top: 30px;
    }
    #loginWrap .service-terms ul li h4 {
        padding-bottom: 15px;
        font-size: 14px;
        font-weight: bold;
    }
    #loginWrap .service-terms ul li p {
        font-size: 12px;
        line-height: 1.6;
    }
    #loginWrap .service-terms ul::-webkit-scrollbar {
        width: 5px;
    }
    #loginWrap .service-terms ul::-webkit-scrollbar-thumb {
        background-color: #086bff;
        border-radius: 5px;
    }
    #loginWrap .service-terms .btn-box {
        width: 100%;
        text-align: center;
        padding-top: 17px;
    }
    #loginWrap .service-terms .btn-box button {
        width: 150px;
        height: 48px;
        border-radius: 5px;
        border: 0;
        font-size: 16px;
        margin-right: 60px;
        background-image: linear-gradient(#086bff, #2520e1);
        color: #fff;
        cursor: pointer;
    }
    #loginWrap .service-terms .btn-box button:nth-child(2) {
        margin-right: 0;
        background-color: #e6e7e7;
        background-image: none;
        color: #999;
    }
    #loginWrap footer {
        width: 100%;
        position: fixed;
        z-index: 1;
        bottom: -11px;
    }
    #loginWrap footer .copyRight {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #999;
        background-color: transparent;
        line-height: 60px;
    }
    #loginWrap footer .copyRight a {
        color: #999;
        text-decoration: none;
    }
   @media all and (max-width: 600px){
       .header-box header .head-inner p {
           padding: 0 0.2rem;
       }
       #loginWrap .header-box {
           height: 0.88rem;
           background-color: transparent;
       }
       #loginWrap .header-box header {
           position: inherit;
       }
       html .header-box header .head-inner .logo1, body .header-box header .head-inner .logo1 {
           display: none;
       }
       .logo2 {
           display: block;
       }
       .header-box header .logo2 {
           display: inline-block;
           position: relative;
           margin-left: .5rem;
           margin-top: .4rem;
           width: 2.5rem !important;
       }

       #loginWrap {
           width: 100%;
           height: 100%;
        //    background-color: #f3f5f7;
           font-size: 0.28rem;
       }
       #loginWrap input, #loginWrap select, #loginWrap button {
           outline: none;
           border: none;
           -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
           -webkit-appearance: none;
       }
       #loginWrap .header-box {
           height: 0.88rem;
           background-color: transparent;
       }
       #loginWrap .header-box header {
           position: inherit;
       }
       #loginWrap .header-box .back {
           display: inline-block !important;
       }
       #loginWrap .header-box .back .icon-fanhui {
           color: #999;
           width: 0.5rem;
           display: inline-block;
       }
       #loginWrap .content-wrap {
           width: 100%;
           height: auto;
       }
       #loginWrap .content-wrap .register-wrap {
           width: 7rem;
           height: auto;
           margin: auto;
           box-shadow: 0 2px 7px rgba(13, 106, 235, 0.2);
           background-color: #ffffff;
           padding: 0.7rem;
           position: absolute;
           z-index: 9;
           left: 50%;
           top: 50%;
           transform: translate(-50%, -50%);
       }
       #loginWrap .content-wrap .register-wrap h1 {
           font-size: 24px;
           font-weight: bold;
       }
       #loginWrap .content-wrap .register-wrap p {
           font-size: 12px;
           margin: 31px 0 15px;
       }
       #loginWrap .content-wrap .register-wrap p a {
           color: #086bff;
           text-decoration: underline;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x {
           width: 100%;
           height: 40px;
           margin-bottom: 20px;
           position: relative;
           /* 绾挎鏍峰紡label瀹氫綅 */
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .iconfont {
           position: absolute;
           top: 10px;
           left: 15px;
           color: #acacac;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .icon-suo1, #loginWrap .content-wrap .register-wrap form .input-outline-x .icon-emailicon {
           font-size: 18px;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .icon-caozuo-xianshihaoma {
           right: 0.2rem;
           left: auto;
           font-size: 0.4rem;
           top: 0.2rem;
           cursor: pointer;
           -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .input-outline {
           width: 100%;
           height: 100%;
           padding-left: 48px;
           border: 1px solid #cccccc;
           border-radius: 5px;
           font-size: 0.28rem;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .input-outline:focus {
           border-color: #086bff;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .input-outline::placeholder {
           font-size: 12px;
           color: #ccc;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .area-code {
           width: 80px;
           height: 38px;
           border-right: 1px solid #cccccc;
           position: absolute;
           background: #fafafa;
           left: 1px;
           top: 1px;
           border-top-left-radius: 5px;
           border-bottom-left-radius: 5px;
           text-align: center;
           line-height: 38px;
           cursor: pointer;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .area-code span {
           font-size: 14px;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x .area-code .icon-arrow-down {
           position: initial;
           float: right;
           margin-right: 8px;
           font-size: 19px;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.telphone .input-outline {
           padding-left: 95px;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.verification-code .input-outline {
           padding-left: 15px;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.verification-code .input-outline::placeholder {
           color: #ccc;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.verification-code .captcha-btn {
           width: 110px;
           height: 38px;
           background: #086bff;
           color: #fff;
           text-align: center;
           border: 0;
           position: absolute;
           right: 0;
           border-top-right-radius: 5px;
           border-bottom-right-radius: 5px;
           top: 1px;
           cursor: pointer;
           line-height: 38px;
           font-size: 0.24rem;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification input {
           width: 2.2rem;
           text-align: center;
           padding-left: 0;
           float: left;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification #verificationCode {
           float: left;
           width: 2rem;
           height: 40px;
           margin-left: 12px;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification #verificationCode canvas {
           width: 100%;
           height: 100%;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification #verificationCode #code_img {
           width: 100%;
           height: 100%;
           cursor: pointer;
           vertical-align: top;
       }
       #loginWrap .content-wrap .register-wrap form .input-outline-x.img-verification span {
           display: inline-block;
           font-size: 14px;
           color: #066bff;
           position: relative;
           top: 19px;
           left: 14px;
           cursor: pointer;
       }
       #loginWrap .content-wrap .register-wrap .agree-terms {
           font-size: 12px;
           margin: 20px 0 16px;
       }
       #loginWrap .content-wrap .register-wrap .agree-terms .iconfont {
           font-size: 20px;
           display: inline-block;
           position: relative;
           top: 3px;
           color: #cccccc;
           cursor: pointer;
       }
       #loginWrap .content-wrap .register-wrap .agree-terms .icon-fuxuankuangxuanzhong {
           color: #086bff;
       }
       #loginWrap .content-wrap .register-wrap .agree-terms em {
           color: #086bff;
           font-style: normal;
           cursor: pointer;
       }
       #loginWrap .content-wrap .register-wrap .register-btn {
           width: 100%;
           height: 48px;
           border-radius: 5px;
           color: #ffffff;
           background-image: linear-gradient(#086bff, #2520e1);
           border: 0;
           font-size: 18px;
           cursor: pointer;
       }
       #loginWrap .content-wrap .register-success {
           width: 7rem;
           height: auto;
           box-shadow: 0 2px 7px rgba(13, 106, 235, 0.2);
           background-color: #ffffff;
           margin: 10px auto;
           padding: 0.4rem 0;
           position: absolute;
           transform: translate(-50%, -50%);
           top: 50%;
           left: 50%;
           display: none;
       }
       #loginWrap .content-wrap .register-success .icon-tianjiachenggong {
           color: #00cc99;
           font-size: 1rem;
           display: block;
           text-align: center;
       }
       #loginWrap .content-wrap .register-success h1 {
           font-size: 0.4rem;
           font-weight: bold;
           margin: 20px 0;
           text-align: center;
       }
       #loginWrap .content-wrap .register-success h2 {
           font-size: 0.3rem;
           text-align: center;
           margin-bottom: 0.2rem;
       }
       #loginWrap .content-wrap .register-success .erweima-box {
           width: 92%;
           background-color: #f2f7ff;
           position: relative;
           margin: 0.5rem auto;
           padding: 12px 0 12px 0.2rem;
       }
       #loginWrap .content-wrap .register-success .erweima-box .angle {
           position: absolute;
           width: 11px;
           height: 11px;
           border: 2px solid transparent;
       }
       #loginWrap .content-wrap .register-success .erweima-box .top-left {
           border-top-color: #086bff;
           border-left-color: #086bff;
           top: 0;
           left: 0;
       }
       #loginWrap .content-wrap .register-success .erweima-box .top-right {
           border-top-color: #086bff;
           border-right-color: #086bff;
           top: 0;
           right: 0;
       }
       #loginWrap .content-wrap .register-success .erweima-box .bottom-left {
           border-bottom-color: #086bff;
           border-left-color: #086bff;
           bottom: 0;
           left: 0;
       }
       #loginWrap .content-wrap .register-success .erweima-box .bottom-right {
           border-bottom-color: #086bff;
           border-right-color: #086bff;
           bottom: 0;
           right: 0;
       }
       #loginWrap .content-wrap .register-success .erweima-box img {
           width: 2rem;
           display: block;
           margin: auto;
       }
       #loginWrap .content-wrap .register-success .erweima-box > div {
           padding: 0.3rem 0 0 0.1rem;
       }
       #loginWrap .content-wrap .register-success .erweima-box > div p {
           margin-bottom: 0.1rem;
           font-size: 0.28rem;
           text-align: center;
       }
       #loginWrap .content-wrap .vertical-align {
           top: 50%;
           transform: translate(-50%, -50%);
           position: absolute;
           left: 50%;
       }
       #loginWrap .service-terms {
           width: 100%;
           height: 100%;
           box-shadow: 0 2px 7px rgba(13, 106, 235, 0.2);
           background-color: #ffffff;
           position: fixed;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
           padding: 0.5rem;
           z-index: 9;
       }
       #loginWrap .service-terms .title-box {
           width: 100%;
           border-bottom: 1px solid #f3f5f7;
           position: relative;
       }
       #loginWrap .service-terms .title-box span {
           display: inline-block;
           width: 125px;
           text-align: center;
           padding-bottom: 15px;
           font-size: 16px;
       }
       #loginWrap .service-terms .title-box span:hover {
           font-weight: bold;
           cursor: pointer;
       }
       #loginWrap .service-terms .title-box .activeLine {
           font-weight: bold;
           cursor: pointer;
       }
       #loginWrap .service-terms .title-box #line {
           width: 125px;
           height: 3px;
           background-color: #086bff;
           position: absolute;
           left: 0;
           bottom: -2px;
           transition: all 0.3s linear;
       }
       #loginWrap .service-terms .title-box .iconfont {
           float: right;
           font-size: 14px;
           cursor: pointer;
       }
       #loginWrap .service-terms ul {
           height: 83%;
           overflow-y: auto;
           padding-bottom: 10px;
       }
       #loginWrap .service-terms ul li {
           padding-top: 30px;
       }
       #loginWrap .service-terms ul li h4 {
           padding-bottom: 15px;
           font-size: 14px;
           font-weight: bold;
       }
       #loginWrap .service-terms ul li p {
           font-size: 12px;
           line-height: 1.6;
       }
       #loginWrap .service-terms ul::-webkit-scrollbar {
           width: 5px;
       }
       #loginWrap .service-terms ul::-webkit-scrollbar-thumb {
           background-color: #086bff;
           border-radius: 5px;
       }
       #loginWrap .service-terms .btn-box {
           width: 100%;
           text-align: center;
           padding-top: 17px;
       }
       #loginWrap .service-terms .btn-box button {
           width: 3rem;
           height: 48px;
           border-radius: 5px;
           border: 0;
           font-size: 16px;
           margin-right: 0.2rem;
           background-image: linear-gradient(#086bff, #2520e1);
           color: #fff;
           cursor: pointer;
       }
       #loginWrap .service-terms .btn-box button:nth-child(2) {
           margin-right: 0;
           background-color: #e6e7e7;
           background-image: none;
           color: #999;
       }
       #loginWrap footer {
           width: 100%;
           position: fixed;
           bottom: 0;
           z-index: 1;
       }
       #loginWrap footer .copyRight {
           width: 100%;
           text-align: center;
           font-size: 0.2rem;
           color: #999;
           background-color: transparent;
           height: 0.7rem;
           line-height: 0.7rem;
       }
       #loginWrap footer .copyRight a {
           color: #999;
           text-decoration: none;
       }

   }

</style>
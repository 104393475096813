<!--首页-->
<template>
  <div class="home" >
    <div id="banner" class="banner-box">
      <div class="content-box swiper-container swiper-box">
       <ul class="swiper-wrapper">
          <li class="spa-content-item swiper-slide" >
            <div class="swiper-bgI">
              <div class="banner-inner clearFloat">
              <div class="banner-words-box swiper1">

                <h1>美匣云旅游商家营销管理系统 </h1>
                <h2>营销前端+管理后端完美结合，更完善的系统构架</h2>
                <div class="little-words">
                  <div class="">旅行社</div>
                  <div class="">酒店民宿</div>
                  <div class="">景区景点</div>
                </div>
                <router-link to="/register" class="free-try-btn" target="_blank">申请试用</router-link>


              </div>
              <div class="banner-imgs">
                <img src="../../public/img/index/index_banner_img1.png" alt="" class="lazyload" draggable="false">
              </div>
              </div>
            </div>
          </li>
          <li class="spa-content-item swiper-slide">
            <div class="swiper-bgII">
              <div class="banner-inner clearFloat">
              <div class="banner-words-box swiper2">

                <div class="big-title">
                  <h1>美匣云旅游</h1><h1 class="erpWord">ERP</h1>
                  <!-- <div style="position:relative;">
                    <div class="cloud">
                      <img src="../../public/img/index/greenFire.png" alt="">
                      <div class="word">终身免费</div>
                    </div>
                    <div class="triangle"></div>
                  </div> -->
                </div>
                <h2>中小企业版</h2>
                <div class="little-wordsII">
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>地接社</span></div>
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>旅行社</span></div>
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>组团社</span></div>
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>批发商</span></div>
                </div>
                <router-link to="/register" class="free-try-btn" target="_blank">申请试用</router-link>
              </div>
              <div class="banner-qrcode">
                <p class="title">立即扫码添加微信</p>
                <p class="subtitle">申请体验</p>
                <img src="../../public/img/index/qrcode.png" alt="" class="imgCode">
                <div class="program">
                  <div>
                    <img src="../../public/img/index/erp.png" alt="">
                    <p>ERP系统</p>
                  </div>
                  <div>
                    <img src="../../public/img/index/guide.png" alt="">
                    <p>司导小程序</p>
                  </div>
                  <div>
                    <img src="../../public/img/index/provide.png" alt="">
                    <p>供应商小程序</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </li>
      </ul>
      <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="index-content index-p0">
      <div class="center-box index-p0-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">旅游商家管理系统<div class="divider"></div></h2>
          <p>三大解决方案，完美解决旅行社/景区景点/酒店民宿/营销管理难题</p>
        </div>
        <div class="content-box clearFloat">
           <ul>
              <li class="item">
                  <div>
                      <img src="../../public/img/wscSystem/A.png" style="width:360px;height:94px"/>
                      <h1>旅行社解决方案</h1>
                  </div>
                  <ul>
                      <li><span></span>收客系统，C端直客预约下单</li>
                      <li><span></span>同业分销，B端同业分销下单</li>
                      <li><span></span>订单管理，线上OTA+线下订单处理</li>
                      <li><span></span>计调管理，系统自动排团+OP匹配资源</li>
                      <li><span></span>资源管理，管理供应商资源库存</li>
                      <li><span></span>财务统计，统计业务财务销售数据</li>
                      <li><span></span>司导管理，分配并管理司机导游</li>
                  </ul>
                  
              </li>

              <li class="item">
                  <div>
                      <img src="../../public/img/wscSystem/B.png" style="width:360px;height:94px"/>
                      <h1>景区票务解决方案</h1>
                  </div>
                  <ul>
                      <li><span></span>一站式综合电商，游/吃/住/行/购皆可售</li>
                      <li><span></span>SCRM客户管理系统，游客画像精准营销</li>
                      <li><span></span>售票，多平台线上+线下预约购票下单</li>
                      <li><span></span>取票，窗口取票/自助售取票机取票</li>
                      <li><span></span>检票，纸质票+电子票用手持机/闸机验票</li>
                      <li><span></span>销售，通过OTA/电商平台/小程序等售卖</li>
                      <li><span></span>管控，实名制分时预约控制人流量</li>
                  </ul>
              </li>
              <li class="item">
                  <div>
                      <img src="../../public/img/wscSystem/C.png" style="width:360px;height:94px"/>
                      <h1>酒店民宿解决方案</h1>
                  </div>
                  <ul>
                      <li><span></span>更适合旅游商家，支持日历房+酒店套餐</li>
                      <li><span></span>C端个人直售，官方直营自助选购预订下单</li>
                      <li><span></span>B端同业分销，同行在线查位下单自动对账</li>
                      <li><span></span>公域流量引流，OTA+种草平台+视频平台引流</li>
                      <li><span></span>私域流量分销，微信群+朋友圈+公众号分销</li>
                      <li><span></span>会员权益模式，支持会员折扣/免单等模式</li>
                      <li><span></span>接入第三方系统，可接入第三方PMS系统</li>
                  </ul>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="index-content index-p1">
      <div class="center-box index-p1-inner">
            <i class="circle1"></i>
            <i class="circle2"></i>
            <i class="cloud"></i>
            <i class="arrow"></i>

          <h2>更全面的旅游管理软件系统</h2>
          <h3>首家打通「上下游」业务的旅游营销管理系统</h3>
          <p>为旅行社/景区景点/酒店民宿等商家提供营销管理软件系统，包括：抖音/微信小程序微商城+旅行社ERP管理系统+景区票务系统+酒店民宿会员制商城</p>
      </div>
    </div>
    <div class="index-content index-p2">
      <div class="center-box index-p2-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">抖音微信小程序微商城<div class="divider"></div></h2>
          <p>旅行社·景区景点·酒店民宿，综合电商平台</p>
          <p class="p-two">*赠送erp内部管理系统，买一得二！</p>
        </div>
        <div class="content-box clearFloat">
          <div class="left">
            <ul class="list">
              <li>
                <div class="icon-wrap">
                   <div class="infront-box">1</div>
                </div>
                <div class="words">
                  <h2>引流获客</h2>
                  <p>多种公域流量和私域流量引流方式，不仅可以从OTA平台、种草平台、搜索平台等引流，也能通过微信、朋友圈、公众号、短信等方式引流获客</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">2</div>
                </div>
                <div class="words">
                  <h2>运营留存</h2>
                  <p>系统不仅支持日历房价+分时预约+酒店套餐等多种形式，还支持旅游路线+景区票务+酒店住宿+特产实物等上架销售，形成一站式综合电商平台</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">3</div>
                </div>
                <div class="words">
                  <h2>分销裂变</h2>
                  <p>直客、同行、OTA商家不仅能在线查位下单支付，还能一键分享分销，自动生成营销图文，分享到微信群、朋友圈等。二级分销返佣模式，自动对账</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">4</div>
                </div>
                <div class="words">
                  <h2>营销复购</h2>
                  <p>赠送erp管理系统，搭配专业SCRM客户管理系统。不仅能同步处理OTA平台订单，匹配相关资源供应商，避免人工出错；SCRM还能对用户画像，精准营销</p>
                </div>
              </li>
            </ul>
            <div class="btn-box">
              <router-link to="register" class="btn b1" target="_blank">申请试用</router-link>
              <router-link to="/wscSystem" class="btn b2" >查看详情</router-link>
            </div>
          </div>
          <div class="right">
            <div class="index-p-img">
              <img src="../../public/img/index/index_p2_img1.png" alt="美匣云小程序商城">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-content index-zyMarket">
      <div class="center-box index-zyMarket-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">旅行社ERP管理系统<div class="divider"></div></h2>
          <h3>颠覆传统ERP管理系统固有模型，更适宜未来旅游业的综合型SaaS系统</h3>
        </div>
        <div class="content-box clearFloat">
          <div class="left">
            <div class="index-p-img">
              <img src="../../public/img/index/index_p3_img1.png" alt="美匣云ERP管理系统">
            </div>
          </div>
          <div class="right">
            
            <ul class="list">
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">1</div>
                </div>
                <div class="words">
                  <h2>适用范围广</h2>
                  <p>适用于OTA商家+旅行社+地接社+组团式+综合社+批发代理商+票务分销等旅游商家，降低商家成本，提高办公效率</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">2</div>
                </div>
                <div class="words">
                  <h2>覆盖旅行社全岗位工作</h2>
                  <p>覆盖：产品设计+前端销售+计调OP+司机导游+财务出纳+老板等全岗位工作，高度智能化，实现高效办公和信息化管理</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">3</div>
                </div>
                <div class="words">
                  <h2>高度智能化系统</h2>
                  <p>PC+WAP+APP+小程序，操作方便快捷，并且系统已无缝对接主流OTA平台、多家旅游保险、12301电子合同、第三方票务系统</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">4</div>
                </div>
                <div class="words">
                  <h2>超强连接性</h2>
                  <p>已无缝对接飞猪、马蜂窝、携程、美团等主流OTA平台，并已接入多家旅游保险、12301电子合同、以及第三方票务系统</p>
                </div>
              </li>
            </ul>
            <div class="btn-box">
              <router-link to="/register" class="btn b1" target="_blank">立即试用体验</router-link>
              <router-link to="/mayTekErp" class="btn b2" >查看详情</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-content index-p3">
      <div class="center-box index-p3-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">景区景点票务系统<div class="divider"></div></h2>
          <h3>不仅是景区票务系统，更是一站式综合电商平台</h3>
        </div>
        <div class="content-box clearFloat">
          <div class="left">
            <ul class="list">
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">1</div>
                </div>
                <div class="words">
                  <h2>多形式售票</h2>
                  <p>支持线上：OTA平台+公众号/小程序/官网+旅行社/实体分销商等方式售票，也支持线下：窗口+自助售取票机等多种售票方式，简单方便快捷</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">2</div>
                </div>
                <div class="words">
                  <h2>取票检票</h2>
                  <p>游客可以凭二维码/身份证/凭证码/手机号等方式在窗口或自助售取票机换票，也可以不换票，凭借这些信息或纸质门票直接手持机或者闸机验票</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">3</div>
                </div>
                <div class="words">
                  <h2>票务管理</h2>
                  <p>系统可以管理票务库存、控制入园人数和时间，也能统计游客信息/财务数据、跟踪游客轨迹等。通过各项数据对游客多维度多层级画像，精准营销</p>
                </div>
              </li>
              <li>
                <div class="icon-wrap">
                  <div class="infront-box">4</div>
                </div>
                <div class="words">
                  <h2>综合电商平台</h2>
                  <p>系统不仅支持景区票务的销售，也支持：吃、住、行、游、购、娱等商品销售。景区景点可以整合园区内和园区周边的商家，形成一站式服务，扩宽业务方向</p>
                </div>
              </li>
            </ul>
            <div class="btn-box">
              <router-link to="/register" class="btn b1" target="_blank">立即试用体验</router-link>
              <router-link to="/scenicTicket" class="btn b2" >查看详情</router-link>
            </div>
          </div>
          <div class="right">
            <div class="index-p-img">
              <img src="../../public/img/index/index_zyMarket_img1.png" alt="美匣云票务系统">
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="index-content index-p4">
      <div class="center-box index-p4-inner">
        <div class="p-tit-box">
          <div class="p-tit">专业的事交给专业的企业去做<div class="divider"></div></div>
          <p>坚持只做平台工具，不做业务经营的底线</p>
        </div>
        <div class="content-box clearFloat">
            <div class="index-p4-content">
              <div class="num-box num4-box">
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>6</span>年</h1>
                    <p>旅游行业<br/>
                    软件系统经验</p>
                  </div>
                </div>
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>10</span><i>+</i>家</h1>
                    <p>OTA平台<br/>
                    官方服务商</p>
                  </div>

                </div>
                <div class="dbx-bg">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>70</span><i>+</i>人</h1>
                    <p>资深专业<br/>
                    技术工程师</p>
                  </div>

                </div>
                <div class="dbx-bg">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>2000</span><i>+</i>家</h1>
                    <p>大中型企业<br/>
                    接入美匣云系统</p>
                  </div>

                </div>
              </div>
              <div class="num-box num3-box">
                <div class="dbx-bg">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>99.9%</span></h1>
                    <p>的客户好评度</p>
                  </div>

                </div>
                <div class="dbx-bg hovered">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>首家</span></h1>
                    <p>打通旅游上下游<br/>
                      业务的企业</p>
                  </div>

                </div>
                <div class="dbx-bg">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>500</span>强</h1>
                    <p>企业合作对象</p>
                  </div>

                </div>
              </div>
              <div class="num-box num2-box">
                <div class="dbx-bg">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>20000</span><i>+</i>单</h1>
                    <p>日订单处理量</p>
                  </div>

                </div>
                <div class="dbx-bg">
                   <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>200</span>万</h1>
                    <p>用户会员数量</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="index-p4-content-wap">
              <div class="num-box num1-box">
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>6</span>年</h1>
                    <p>旅游行业软件经验</p>
                  </div>

                </div>
                <div class="dbx-bg ">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>8</span>项</h1>
                    <p>旅游软件著作权</p>
                  </div>

                </div>
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>10</span><i>+</i>个</h1>
                    <p>OTA平台对接</p>
                  </div>

                </div>
              </div>
              <div class="num-box num3-box">
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>50</span><i>+</i>人</h1>
                    <p>专业客户服务团队</p>
                  </div>

                </div>
                
                <div class="dbx-bg  hovered">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>1V1</span></h1>
                    <p>资源及商家<br/>
                      对接服务</p>
                  </div>

                </div>
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>1000</span><i>+</i>家</h1>
                    <p>同行分销商家</p>
                  </div>

                </div>
              </div>
              <div class="num-box num2-box">
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>2000</span><i>+</i>个</h1>
                    <p>旅游产品资源</p>
                  </div>

                </div>
                <div class="dbx-bg ">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>2000</span>万</h1>
                    <p>美匣云SaaS系统<br/>
                      每年处理订单数</p>
                  </div>

                </div>
                <div class="dbx-bg">
                  <div class="hover-shadow"></div>
                  <div class="dbx-words">
                    <h1><span>2</span><i>万+</i>次</h1>
                    <p>每日自动同步<br/>
                      OTA平台订单数</p>
                  </div>

                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="index-content index-p5">
      <div class="index-p5-inner">
        <div class="p-tit-box">
          <div class="p-tit">部分真实案例<div class="divider"></div></div>
          <p>为超2000+家企业提供服务</p>
        </div>
        <div class="content-box clearFloat">
           <ul class="case-box">
             <li class="case-item">
               <img src="../../public/img/index/case1.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case2.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case3.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case4.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case5.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case6.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case7.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case8.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case9.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case10.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case11.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case12.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case13.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case14.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case15.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case16.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case17.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case18.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case19.png" alt="">
             </li>
             <li class="case-item">
               <img src="../../public/img/index/case20.png" alt="">
             </li>
           </ul>
        </div>
        
      </div>
    </div>

    <free-try :msg="msg" :btn="btn"></free-try>
    <foot-box></foot-box>
    <pop-window></pop-window>
  </div>
</template>

<script>
import FreeTry from '@/components/FreeTry.vue'
import FootBox from '@/components/Footer.vue'
import PopWindow from '@/components/PopWindow.vue'

let vm;
export default {

  name: 'Home',
    data(){
      return {
          msg: '立即试用体验美匣云系列产品及服务',
          btn: '立即试用体验'
      }
    },
  components: {
      FreeTry,
      FootBox,
      PopWindow,
  },
    methods: {
       
        // 动态效果
        _move() {
        
           if($('.home').length > 0) {
               let $winHeight = $(window).height(); // 窗口可视区域的高度
               let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离




               // 大标题

               let $pTit2 = $('.index-p2-inner')
               let $pTit3 = $('.index-p3-inner')
               let $pTit4 = $('.index-p4-inner')
               let $pTit5 = $('.index-p5-inner')
               let $pTitZyMarket = $('.index-zyMarket-inner')
               let moveBoxArr = [$pTit2,$pTitZyMarket,$pTit3,$pTit4,$pTit5]
               moveBoxArr.forEach( (item,index) => {
                   if (($winHeight + $winScrollHeight) >= item.offset().top) {

                       $(item).find('.p-tit-box').addClass('animated fadeInUp')
                   }
               })

               //index-p1 动效
               if (($winHeight + $winScrollHeight) >= $('.index-p1-inner').offset().top + 400) {
                   // index_p1
                   $('.index-p1-inner .arrow').addClass('animated fadeInUp')
                   $('.index-p1-inner .circle1').addClass('c1')
                   $('.index-p1-inner .circle2').addClass('c2')

                   $('.index-p1-inner h1').addClass('animated fadeInUp')
                   setTimeout(function () {
                       $('.index-p1-inner h2').addClass('animated fadeInUp')
                   }, 300)
                   setTimeout(function () {
                       $('.index-p1-inner h3').addClass('animated fadeInUp')
                   }, 500)



               }

               //index-p2 动效
               if (($winHeight + $winScrollHeight) >= $('.index-p2-inner').offset().top + 400) {
                   $('.index-p2-inner .list li').each(function (index, item) {
                       $(item).addClass('animated fadeInLeft')
                       $(item).css({
                           "animation-delay": 0.3 * index + "s"
                       })
                   })
                   $('.index-p2-inner .index-p-img').addClass('animated fadeInRight')
                   $('.index-p2-inner .index-p-img').css({
                       "animation-delay": 0.3  + "s"
                   })
               }
               //index-p3 动效
               if (($winHeight + $winScrollHeight) >= $('.index-p3-inner').offset().top + 400) {
                   $('.index-p3-inner .list li').each(function (index, item) {
                       $(item).addClass('animated fadeInRight')
                       $(item).css({
                           "animation-delay": 0.3 * index + "s"
                       })
                   })
                   $('.index-p3-inner .index-p-img').addClass('animated fadeInLeft')
                   $('.index-p3-inner .index-p-img').css({
                       "animation-delay": 0.3 + "s"
                   })
               }

               //index-p3 动效
               if (($winHeight + $winScrollHeight) >= $('.index-zyMarket-inner').offset().top + 400) {
                   $('.index-zyMarket-inner .list li').each(function (index, item) {
                       $(item).addClass('animated fadeInRight')
                       $(item).css({
                           "animation-delay": 0.3 * index + "s"
                       })
                   })
                   $('.index-zyMarket-inner .index-p-img').addClass('animated fadeInLeft')
                   $('.index-zyMarket-inner .index-p-img').css({
                       "animation-delay": 0.3 + "s"
                   })
               }


           }
        },

        // 团队实力
        _p4Hover() {
            $('.index-p4-inner .dbx-bg').hover(function (index, item) {
                $('.index-p4-inner .dbx-bg').removeClass('hovered')
                $(this).addClass('hovered')
            },
            function (index, item) {
                $('.index-p4-inner .dbx-bg').removeClass('hovered')
                $('.index-p4-inner .num3-box .dbx-bg:nth-child(2)').addClass('hovered')
            }
            )
        },

    },
  mounted(){
    vm = this
    //  p1
      let canRun = true;
      let timer;
      vm._move()
      $(window).scroll(function () {

          if (!canRun) {

              return
          }
          canRun = false
          timer = setTimeout(function () {

              vm._move()
              canRun = true
          }, 300)

      })

    //    p4
      vm._p4Hover()

      if(document.querySelector('.swiper-box')) {
          let mySwiper = new Swiper('.swiper-container',{
              speed: 800,
              autoplay: {
                  stopOnLastSlide: false,
                  disableOnInteraction: true,
              },//可选选项，自动滑动
              watchOverflow: true, // 当没有足够的slide切换时，例如只有1个slide，swiper会失效且隐藏导航等
              loop:true,
              pagination: {
                  el: '.swiper-pagination',
                  clickable :true,
              },
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
          })
      }
  },

}
</script>
<style lang="less" >

  @bgBlue: #3b7ffc;
  .home {
    width: 100%;
    min-width: 1200px;
    .big-title{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      h1{
        font-size: 48px;
        font-weight: bold;
        line-height: 49px;
        letter-spacing: 2px;
        margin-bottom: 0;
      }
      .erpWord{
        margin-right: 22px;
        font-size: 53px;
        font-weight: bold;
        line-height: 53px;
        letter-spacing: 2px;
      }
      .cloud{
        position: relative;
        z-index: 2;
        width: 168px;
        height: 52px;
        background-image: linear-gradient(0deg, #fa8013 0%, #f03a19 100%), linear-gradient(#ec3636, #ec3636);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin-right: 6px;
        }
        .word{
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 24px;
          background-image: linear-gradient(0deg, #fff2b5 0%, #1cff91 100%);
          -webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;
        }
      }
      .triangle{
          display: block;
          width: 20px;
          height: 20px;
          background-image: linear-gradient(0deg, #fa8013 0%, #f03a19 100%), linear-gradient(#ec3636, #ec3636);
          box-shadow: 0px 2px 8px 0px rgb(240 60 25 / 20%);
          border-radius: 5px;
          position: absolute;
          left: -9px;
          top: 50%;
          margin-top: -10px;
          transform: rotate(45deg);
          z-index: 1;
        }
    }
    .little-words {
      font-size: 16px;
      div {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        padding-left: 25px;
        color: #fff;
        i {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #5ddfff;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 8px;
          height: 8px;
          border: 3px solid rgba(79,134,242);
          border-radius: 50%;
          background-color: #5ddfff;
        }
      }
    }
    .little-wordsII{
      font-size: 16px;
      div {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        color: #fff;
        line-height: 18px;
        img{
          margin-right: 7px;
          vertical-align: middle;
        }
        span{
          vertical-align: middle;
        }
      }
    }
    .banner-imgs {
      img {
        width: 578px;
        height: 583px;
      }
    }
    .banner-imgs {
      top: 82px;
    }
    .banner-words-box {
      margin-top: 213px;
    }
    .p-tit-box  {
      // opacity: 0;
    }

  }
  .index-p-img {
    opacity: 0;
  }

  .banner-inner {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 1200px;
    height: 100%;
    margin: auto;
    .free-try-btn {
      margin-top: 58px;
      font-size: 18px;
    }
  }
  .banner-box {
    width: 100%;
    height: 700px;
    overflow: hidden;
    user-select: none;
    .swiper-bgI{
      width:100%;
      height: 100%;
      background-image: url("../../public/img/index/index_banner.png");
      background-size: cover;
    }
    .swiper-bgII{
      width:100%;
      height: 100%;
      background-image: url("../../public/img/index/index_banner.jpg");
      background-size: cover;
    }
  }
  .swiper-box{
    height: 100%;
  }
  .banner-words-box {

    h1, h2, h3 {
      color: #fff;
    }
    h3 {
      margin-bottom: 55px;
        font-size: 20px;
    }
    .shebei-box {
      &>div {
        display: inline-block;
        margin-right: 40px;
        background-image: url('../../public/img/index/index_icons1.png');
        background-repeat: no-repeat;
        background-size: 197px 18px;
        padding-left: 25px;
        color: #5ddfff;
      }
      .app-icons-app {
        background-position: 0 2px;
      }
      .app-icons-pc {
        background-position: -93px 2px;
      }
      .app-icons-xcx {
        background-position: -176px 2px;
      }
    }

  }
  .swiper1{
        h1 {
          font-weight: bold;
          margin-bottom: 20px;
          font-size: 40px;
        }
        h2{
            margin-bottom: 55px;
            font-size: 20px;
        }
    }
  .swiper2{
    h2 {
      margin: 26px 0 49px 0 !important;
      font-size: 20px;
      line-height: 20px;
      &::before{
        display: block;
        content: '';
        width: 30px;
        height: 2px;
        background-color: #fff;
        margin-bottom: 9px;
      }
    }
  }
  .banner-qrcode{
    padding: 46px 12px 0 27px;
    width: 320px;
    height: 420px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px 
      rgba(62, 87, 250, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    margin: 166px 0 0 247px;
    .title{
      font-size: 24px;
      line-height: 24px;
      color: #010101;
      text-align: center;
      margin-bottom: 17px;
    }
    .subtitle{
      font-size: 18px;
      font-weight: bold;
      line-height: 18px;
      color: #010101;
      margin-bottom: 27px;
      text-align: center;
      span{
        color: #19bb5a;
      }
    }
    .imgCode{
      margin: auto;
      margin-bottom: 38px;
      display: block;
    }
    .program{
      display: flex;
      justify-content: space-between;
      div{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        img{
          width: 22px;
          margin-bottom: 10px;
        }
        p{
          font-size: 14px;
          line-height: 14px;
          color: #777777;
        }
      }
    }
  }

  /*公共*/
  .banner-imgs {
    position: absolute;
    right: 0;
      transition: all .3s linear;
      &:hover {
          transform: scale(1.05);
      }
  }
  .free-try-btn {
    display: inline-block;
    width: 198px;
    height: 54px;
    line-height: 54px;
    border-radius: 27px;
    color: #fff;
  }

  .index-content {
    width: 100%;
    overflow: hidden;
  }
  .center-box {
    width: 1200px;
    margin: 0 auto;
  }

  .index-p2,.index-p3, .index-zyMarket{
    /*height: 850px;*/
    height: 900px;
      .list li {
          opacity: 0;
      }
  }


  .index-p0 {
        overflow: visible!important;
  }
  .index-p0-inner {
        position: relative;
        height: auto;
        padding-bottom: 70px;
        .p-tit-box {
          opacity: 1;
        }
        .content-box{
            margin-top: 90px;
            text-align: center;
            &>ul {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            .item {
                width: 360px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                transition: all .2s linear;
                &:hover {
                    transform: translateY(-12px);
                }
                .index-p0-icon {
                    width: 40px;
                    height: 37px;
                    background-image: url("../../public/img/wscSystem/wsc_p1_icons.png");
                    background-repeat: no-repeat;
                    background-size: cover;

                }
                .icon1 {

                    background-position: 0 0;
                }
                .icon2 {
                    background-position: -169px 0;
                }
                .icon3 {
                    background-position: -339px 0;
                }
                .icon4 {
                    background-position: -511px 0;
                }
                .icon5 {
                    background-position: -684px 0;
                }
                .icon6 {
                    background-position: -855px 0;
                }
                div:first-child{
                    position: relative;
                    h1{
                        position: absolute;
                        top: 50%;
                        left: 45px;
                        color: #fff;
                        font-size: 24px;
                        margin-top: -15px;
                    }

                }
                ul{
                    padding:20px 36px 56px;
                    li{
                        line-height:48px;
                        font-size: 14px;
                        text-align: left;
                        color: #333;
                        white-space: nowrap;
                        span{
                            display:inline-block;
                            width:6px;
                            height:6px;
                            border-radius: 50%;
                            background:#e49435;
                            margin-right: 10px;
                            vertical-align:middle
                        }
                    }
                }
            }
            .item:nth-child(1){
                ul{
                    li{
                        span{
                            background:#45a4ee;
                        }
                    }
                }
            }
            .item:nth-child(2){
                ul{
                    li{
                        span{
                            background:#e49435;
                        }
                    }
                }
            }
            .item:nth-child(3){
                ul{
                    li{
                        span{
                            background:#47b94c;
                        }
                    }
                }
            }
        }
    }



  /*p1*/
  .index-p1 {
    background-color: #f8f8f8;
  }
  .index-p1-inner {
    position: relative;
    height: 528px;
    padding-top: 320px;
    background: url(../../public/img/index/index_p1_img1.png) no-repeat center top/574px 476px;
    .circle1,.circle2,.cloud {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transform-origin: 50% 50%;
    }
    .cloud {
      transition: all 1.5s ease-in-out;
    }
    .circle1,.circle2 {
      transition: all 1s ease-in-out;
    }

    .circle1 {
      top: 151px;
      width: 158px;
      height: 158px;
      background: url("../../public/img/index/index_p1_circle1.png") no-repeat center/contain;
    }
    .c1 {
      transform: translateX(-50%) rotate(365deg);
    }
    .circle2 {
      top: 165px;
      width: 134px;
      height: 135px;
      background: url("../../public/img/index/index_p1_circle2.png") no-repeat center/contain;
    }
    .c2 {
      transform: translateX(-50%) rotate(365deg);
    }
    .cloud {
      top: 185px;
      width: 145px;
      height: 90px;
       background: url("../../public/img/index/index_p1_cloud.png");
    }

    .arrow {
      display: block;
      position: absolute;
      left: 48.3%;
      opacity: 0;
      top: 215px;
      width: 44px;
      height: 80px;
       background: url("../../public/img/index/index_p1_arrow.png");
      transition: all 1s ease-in-out;
    }

    h2 {
      opacity: 0;
      text-align: center;
      font-weight: bold;
      font-size: 32px;
      color: @bgBlue;
    }
    h3 {
      opacity: 0;
      text-align: center;
      margin-top: 30px;
      font-size: 18px;
      color: #333;
    }
    p {
      // opacity: 0;
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      color: #333;
    }
  }
  /*公共标题*/
  .p-tit-box {
    text-align: center;
    padding-top: 70px;
    .p-tit {
      position: relative;
      display: inline-block;
      font-size: 36px;
      color: #1a1a1a;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -100px;
        width: 79px;
        height: 6px;
        background: url("../../public/img/index/tit_left.png") no-repeat center/contain;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -100px;
        width: 79px;
        height: 6px;
        background: url("../../public/img/index/tit_right.png") no-repeat center/contain;
      }
      .divider {
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 65px;
          width: 232px;
          height: 3px;
          background: url('../../public/img/index/tit-line.png') no-repeat center/contain;

      }
    }
    p {
      display: block;
      position: relative;
      margin-top: 17px;
      line-height: 20px;
      color: #333;
      font-size: 16px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
        width: 232px;
        height: 3px;
        background: url('../../public/img/index/tit-line.png') no-repeat center/contain;
      }
    }
  }
.index-p2-inner,.index-p3-inner,.index-zyMarket-inner {
  img {
    transition: all 1s  linear;
    &:hover {
      transform: scale(1.02);
    }
  }
}

  .index-p2-inner {
    .p-two{
      margin-top: 10px;
    }
    img {
      width: 545px;
    }
  }
  .index-zyMarket{
    background-color: #f8f8f8;
  }
  .index-zyMarket-inner {
    img {
      width: 507px;
    }
    h3 {
      display: block;
      position: relative;
      margin-top: 26px;
      line-height: 20px;
      color: #333;
      font-size: 16px;
    }
  }
  .index-p3-inner {
    img {
      width: 505px;
    }
  }
  /*按钮公共*/
  .btn-box {
    margin-top: 75px;
    .b1 ,.b2{
      display: inline-block;
      width: 160px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border-radius: 23px;
      cursor: pointer;
      margin-right: 40px;
    }
  }

  .index-p2-inner,.index-p3-inner,.index-zyMarket-inner{
    // .p-tit-box p {
    //   margin-top: 40px;
    // }
      .content-box {
        position: relative;

        .left {
          float: left;
        }

      }
      .icon-wrap, .words {
        display: inline-block;
      }
      .icon-wrap {
        position: relative;
        width: 52px;
        height: 52px;
        margin-right: 15px;

        .infront-box {
          position: absolute;
          top: 0;
          right: 10px;
          width: 52px;
          height: 52px;
          line-height: 52px;
          text-align: center;
          color: #fff;

        }
      }
      .list {
        padding-top: 25px;
      }
      .list >li {
        margin-bottom: 35px;
      }
      .list >li:last-child {
        margin-bottom: 0;
      }
      .words {
        h2 {
          font-size: 20px;
          font-weight: bold;
          color: #333;
          margin-bottom: 5px;
        }
        p {
          width: 490px;
          font-size: 14px;
          line-height: 24px;
          color: #808080;
        }
      }
    .b1 {
      color: #fff;
        transition: all .5s ease;
    }
    .b2 {
      background-color: #edeff2;
      color: #999;
        transition: all .5s ease ;
    }
      .b1:hover, .b2:hover {
          transform: translateY(-2px);
      }
  }

  .index-p2-inner {
    .right {
      position: absolute;
      right: 0;
    }
  }
  .index-p3-inner {
    .right {
      position: absolute;
      right: 0;
    }
    h3{
      display: block;
      position: relative;
      margin-top: 26px;
      line-height: 20px;
      color: #333;
      font-size: 16px;
    }
  }
  .index-zyMarket-inner {
    .right {
      position: absolute;
      right: 0;
    }
  }


  /*p2*/
  // .index-p2 {
  //   background-color: #f8f8f8;
  // }
  .index-p2-inner {
    .content-box {
      margin-top: 65px;
    }
    .icon-wrap {
      background: url("../../public/img/index/index_p2_icons_bg.png") no-repeat center/cover;
      .infront-box {
        background: url("../../public/img/index/index_p2_icons.png") no-repeat center/cover;
      }
    }


    .b1 {
      background-color: @bgBlue;
    }
  }

  /*资源市场.index-zyMarket*/

  .index-zyMarket-inner {
    .content-box {
      margin-top: 77px;
    }
    .icon-wrap {
      background: url("../../public/img/index/index_p3_icons_bg.png") no-repeat center/cover;
      .infront-box {
        background: url("../../public/img/index/index_p3_icons.png") no-repeat center/cover;
      }
    }
    .b1 {
      background-color: #8664ff;
    }
  }

  /*p3*/
  // .index-p3 {
  //   background-color: #f8f8f8;
  // }
  .index-p3-inner {
    .content-box {
      margin-top: 77px;
    }
    .icon-wrap {
      background: url("../../public/img/index/index_p2_icons_bg.png") no-repeat center/cover;
      .infront-box {
        background: url("../../public/img/index/index_p2_icons.png") no-repeat center/cover;
      }
    }
    .b1 {
      /*background-color: #8664ff;*/
      background-color: @bgBlue;
    }
  }
  /*p4*/

  .index-p4 {
    height: 840px;
    background: url("../../public/img/index/index_p4_bg.jpg") no-repeat center/cover;
    .p-tit {
      &:before {
        display: none;

      }
      &:after {
        display: none;

      }
      span {
        display: inline-block;
        margin-right: 20px;
      }
      span:last-child {
        margin-right: 0;
      }
    }
    .p-tit-box {
      .p-tit {
        color: #fff;
      }
      p {
        font-size: 18px;
        color: #fff;
      }
    }
  }
  .index-p4-content {
    padding-top: 75px;
    .num-box {
      display: flex;
      justify-content: center;
      .dbx-bg {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 208px;
        margin-right: 20px;
        background: url("../../public/img/index/dbx_white.png") no-repeat center/cover;
        user-select: none;
        .dbx-words {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 2;
          p {
            text-align: center;
          }
        }
        .hover-shadow {
          width: 200px;
          height: 232px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background: url("../../public/img/index/dbx_blue.png") no-repeat center/cover;
          opacity: 0;
          transition: all .5s ease;
          z-index: 1;
        }
        h1,p,span,i {
          transition: all .3s ease;
        }
        h1 {
          margin-bottom: 5px;
          text-align: center;
          font-size: 14px;
          span {
            color: @bgBlue;
            font-size: 36px;
          }
          i {
            color: @bgBlue;
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
          color: #333;
          white-space: nowrap;
        }
        &:hover {
          .hover-shadow {

            opacity: 1;
          }

          h1, p,span,i {
            color: #fff;
          }
        }
      }
        .hovered {
            .hover-shadow {
                opacity: 1;
            }
            h1, p,span,i {
                color: #fff!important;
            }
        }
    }
    .num4-box,.num3-box,.num2-box {
      .dbx-bg:last-child {
        margin-right: 0;
      }
    }
    .num3-box,.num2-box {
      margin-top: -35px;
    }
  }
  .index-p4-content-wap {
    display: none;
  }
  /*p5 成功案例*/
 .index-p5 {
   height:640px;
 }
  .index-p5-inner {
    width: 1225px;
    margin: 0 auto;
    .case-box {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      .case-item {
        position: relative;
        display: inline-block;
        width: 245px;
        height: 98px;
        border-right: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        text-align: center;
        cursor: pointer;
        /*overflow: hidden;*/
        transition: all .2s linear;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .case-item:hover {
        transform: translateY(-1px);
        box-shadow: 0px 0px 10px rgba(0,0,0,.12);
      }
      .case-item:nth-child(5n) {
        border-right: 0;
      }
      .case-item:nth-last-child(1),.case-item:nth-last-child(2),.case-item:nth-last-child(3),.case-item:nth-last-child(4),.case-item:nth-last-child(5) {
        // border-bottom: 0;
      }

      .case-item:nth-child(1),.case-item:nth-child(6),.case-item:nth-child(11),.case-item:nth-child(16) {
        &:before {
          content:'';
          position: absolute;
          left: -60px;
          bottom: 0;
          display: block;
          width: 60px;
          height: 1px;
          background-color: #f2f2f2;

        }
      }
      .case-item:nth-child(5),.case-item:nth-child(10),.case-item:nth-child(15),.case-item:nth-child(20) {
        &:before {
          content:'';
          position: absolute;
          right: -60px;
          bottom: 0;
          display: block;
          width: 60px;
          height: 1px;
          background-color: #f2f2f2;

        }
      }
      .case-item:nth-last-child(2),.case-item:nth-last-child(3),.case-item:nth-last-child(4),.case-item:nth-last-child(5) {
        &:after {
          content:'';
          position: absolute;
          right: -1px;
          bottom: -60px;
          display: block;
          width: 1px;
          height: 60px;
          background-color: #f2f2f2;

        }
      }
    }
  }

  @import "../assets/css/wap.css";

  @media all and (max-width:750px) {
    
    .index-p5-inner .case-box .case-item:nth-last-child(2){
      border-bottom: 1px solid #f2f2f2;
    }
    .home {
      min-width: auto;
      .little-words {
        font-size: .25rem;
        display: flex;
        flex-direction: column;
        &>div {
          margin: 0 auto .2rem;
          width:2.6rem;
        }
      }
    }

    .free-try-btn {
      width: 2.4rem;
      height: .68rem;
      line-height: .68rem;
      margin: auto;
    }
    .banner-box {
      height:100vh;
    }

    .home .banner-inner {
      width: 7.5rem;
      flex-direction: column;
      .free-try-btn {
        margin-top: .3rem;
        font-size: .25rem;
      }
    }
    .banner-imgs {
      right: 50%;
      transform: translateX(50%);
      &:hover {
        transform: scale(1) translateX(50%);
      }
    }
    .home {

      .banner-imgs {
        top: 2.5rem;
        img {
          width: 4.7rem;
          height: auto;
        }
      }
      .banner-words-box {
        margin-top: 7.4rem;
      }
      .big-title{
        justify-content: center;
        align-items: center;
        h1{
          font-size: .34rem;
          line-height: .34rem;
          white-space: nowrap;
        }
        .erpWord{
          font-size: .38rem;
          line-height: .4rem;
        }
      }
      .swiper2{
        width: 100%;
        margin-top: 2rem;
        h2::before{
          display: none;
        }
      }
      .banner-qrcode{
        margin: auto;
        width: 6.2rem;
        height: auto;
        padding: 0.2rem;
        margin-top: 0.8rem;
        .title{
          margin-bottom: 0.1rem;
          font-size: .3rem;
        }
        .subtitle{
          font-size: .24rem;
          margin-bottom: 0.27rem;
        }
        .imgCode{
          width: 2rem;
          margin-bottom: 0.2rem;
        }
      }
      .little-wordsII {
        font-size: .16rem;
        div{
          margin-right: .3rem;
        }
      }
    }

    .banner-words-box {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .swiper1 h2 {
      font-size: .34rem !important;
    }
    .swiper1 h1 {
      font-size: .4rem !important;
      margin-bottom: .2rem !important;
    }
    .swiper1 h3 {
      font-size: .25rem !important;
      margin-bottom: .5rem !important;
    }
    .banner-words-box .shebei-box > div {
      margin-right: .8rem;
      background-size: cover;
      padding-left: .4rem;
      font-size: .26rem;
    }
    .banner-words-box .shebei-box {
      &>div {
        background-image: none;
        position: relative;
        line-height: 1.3;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          background-image: url(../../public/img/index/index_icons1.png);
          background-repeat: no-repeat;
          background-size: 2.955rem 0.27rem;
        }
      }
      .app-icons-app {
        left: .1rem;
        &:after {
          width: .18rem;
          height: .27rem;
          background-position: 0 0;
        }

      }
      .app-icons-pc {
        margin-right: .6rem;
        &:after {
          width: .24rem;
          height: .225rem;
          background-position: -1.42rem 0;
        }

      }
      .app-icons-xcx {
        &:after {
          width: .3rem;
          height: .24rem;
          background-position: -2.65rem -.02rem;
        }

        margin-right: 0;
      }

    }





    .index-p2, .index-p3, .index-zyMarket {
      height: auto;
    }


  .index-p0-inner{
    .content-box{
      margin-top: .5rem;
      >ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        >li:not(:last-child){
          margin-bottom: 1rem;
        }
      }
      .item{
        width: 90%;
        img{
          width: 100% !important;
        }
        ul li{
          font-size: .2rem;
        }
      }
    }
  }


    /* index-p1 */
    .index-p1-inner {
      width: 7.5rem;
      height: 8rem;
      padding-top: 4.1rem!important;
      background: url(../../public/img/index/index_p1_img1.png) no-repeat center top/contain;
      .circle1 {
        width: 3.16rem;
        height: 3.16rem;
        top: 1.4rem;
      }
      .circle2 {
        width: 2.68rem;
        height: 2.7rem;
        top: 1.7rem;
      }
      .cloud {
        width: 1.7rem;
        height: 1.8rem;
        top: 2.1rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .arrow {
        top: 2.7rem;
        left: 44.8%;
        width: .8rem;
        height: 1.2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      h1 {
        font-size: .45rem;
      }
      h2,h3 {
        font-size: .3rem;
        margin-top: .3rem;
      }
      h3 {
        font-size: .26rem;
        padding: 0 .25rem;
      }
    }

    /*  index-p2*/
    .center-box {
      width: 7.5rem;
      padding-bottom: 1rem;
    }

    /*大标题*/
    .p-tit-box {
      padding-top: .9rem;
      .p-tit {
        font-size: .4rem;
        .divider {
          top: .7rem;
          width: 2.5rem;
        }
      }
      p {
        font-size: .26rem;
        padding: 0 .2rem;
      }
    }
    .p-tit-box .p-tit:before {
      left: -1.2rem;
      width: 1rem;
      height: .12rem;
    }
    .p-tit-box .p-tit:after {
      right: -1.2rem;
      width: 1rem;
      height: .12rem;
    }

    .index-p2-inner .p-tit-box p, .index-p3-inner .p-tit-box p, .index-zyMarket-inner .p-tit-box p {
      margin-top: .4rem;
      line-height: 2;
    }
    .index-p2-inner .content-box .right,.index-p3-inner .content-box .right {
      right: 50%;
      transform: translateX(50%);
      top: .5rem;
    }
    .index-p2-inner .content-box,.index-p3-inner .content-box {
      margin-top: .5rem;
    }
    .index-p-img {
      img {
        width: 6rem;
      }
    }
    .index-p2-inner .list {
      width: 7.5rem;
      padding: 6.5rem 0.6rem 0;
    }
    .index-p3-inner .list  {
      width: 7.5rem;
      padding: 7rem 0.6rem 0;
    }
    .index-p2-inner .list > li, .index-p3-inner .list > li, .index-zyMarket-inner .list > li {
      white-space: nowrap;
    }
    .index-p2-inner .icon-wrap, .index-p3-inner .icon-wrap, .index-zyMarket-inner .icon-wrap {
      width: .5rem;
      height: .5rem;
      margin-right: .2rem;
    }
    .index-p2-inner .icon-wrap .infront-box, .index-p3-inner .icon-wrap .infront-box, .index-zyMarket-inner .icon-wrap .infront-box {
      width: .5rem;
      height: .5rem;
      line-height: .5rem;
      right: .1rem;
    }
    .index-p2-inner .words, .index-p3-inner .words, .index-zyMarket-inner .words {
      width: 85%;
    }
    .index-p2-inner .icon-wrap, .index-p3-inner .icon-wrap, .index-zyMarket-inner .icon-wrap, .index-p2-inner .words, .index-p3-inner .words, .index-zyMarket-inner .words {
      vertical-align: middle;
    }
    .index-p2-inner .words  ,.index-p3-inner .words , .index-zyMarket-inner .words {
      h2 {
        font-size: .3rem;
      }
      p {
        width: 100%;
        font-size: .26rem;
        white-space: normal;
        text-align: justify;
        color: #333;
      }
    }
    .btn-box {
      display: flex;
      justify-content: center;
      margin: 1rem auto 0;
    }
    .btn-box .b1, .btn-box .b2 {
      width: 2.2rem;
      height: .6rem;
      line-height: .6rem;
      font-size: .26rem;
    }
    .btn-box .b2 {
      margin-right: 0;
    }

  /*  资源市场*/
    .index-p2-inner .content-box .left, .index-p3-inner .content-box .left, .index-zyMarket-inner .content-box .left {
      float: none;
    }
    .index-zyMarket-inner .content-box {
      margin-top: 1rem;
    }

    .index-zyMarket {
      .index-p-img {
        text-align: center;
      }
    }
    .index-zyMarket-inner .list {
      width: 7.5rem;
      padding: .5rem 0.6rem 0;
    }
    .index-zyMarket-inner .right {
      position: relative;
      right: auto;
    }
  /*.index-p4*/
    .index-p4 {
      height: auto;
    }
    .index-p4 .p-tit-box p {
      font-size: .26rem;
    }

    .index-p4-content-wap {
      display: block;
      padding-top: .5rem;
      .num-box {
        display: flex;
        justify-content: center;
        .dbx-bg {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2.8rem;
          height: 2.9rem;
          margin-right: .2rem;
          background: url("../../public/img/index/dbx_white.png") no-repeat center/contain;
          user-select: none;
          .dbx-words {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            z-index: 2;
            p {
              text-align: center;
            }
          }
          .hover-shadow {
            width: 2.5rem;
            height: 2.86rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background: url("../../public/img/index/dbx_blue.png") no-repeat center/contain;
            opacity: 0;
            transition: all .5s ease;
            z-index: 1;
          }
          h1,p,span,i {
            transition: all .3s ease;
          }
          h1 {
            margin-bottom: 5px;
            text-align: center;
            font-size: 14px;
            span {
              color: @bgBlue;
              font-size: .5rem;
            }
            i {
              color: @bgBlue;
              font-size: 12px;
            }
          }
          p {
            font-size:12px;
            color: #333;
            white-space: normal;
          }
          &:hover {
            .hover-shadow {

              opacity: 1;
            }

            h1, p,span,i {
              color: #fff;
            }
          }
        }
        .dbx-bg:nth-child(3n) {
          margin-right: 0;
        }
        .hovered {
          .hover-shadow {
            opacity: 1;
          }
          h1, p,span,i {
            color: #fff!important;
          }
        }
      }
    }

    .index-p4-content {
      display: none;

    }


  // .case8

  /*.index-p5*/
    .index-p5 {
      height: auto;
    }
    .index-p5-inner{
      width: 7.5rem;
    }
    .index-p5-inner .case-box {
      padding: 1rem 0;
      // padding-left: .1rem;
    }
    .index-p5-inner .case-box .case-item {
      /*width: 2.3rem;*/
      width: 33.29%;
      height: .97rem;
    }

    .index-p5-inner .case-box .case-item:nth-child(5n) {
      border-right: 1px solid #f2f2f2;
    }
    .index-p5-inner .case-box .case-item:nth-child(3n) {
      border-right: 0;
    }
    .index-p5-inner .case-box .case-item:nth-last-child(3), .index-p5-inner .case-box .case-item:nth-last-child(4), .index-p5-inner .case-box .case-item:nth-last-child(5) {
      border-bottom: 1px solid #f2f2f2;
    }
    .index-p5-inner .case-box .case-item:nth-child(1):before, .index-p5-inner .case-box .case-item:nth-child(6):before, .index-p5-inner .case-box .case-item:nth-child(11):before {

        display: none;


    }
    .index-p5-inner .case-box .case-item:nth-child(5):before, .index-p5-inner .case-box .case-item:nth-child(10):before, .index-p5-inner .case-box .case-item:nth-child(15):before {

        display: none;


    }
    .index-p5-inner .case-box .case-item:nth-last-child(2):after, .index-p5-inner .case-box .case-item:nth-last-child(3):after, .index-p5-inner .case-box .case-item:nth-last-child(4):after, .index-p5-inner .case-box .case-item:nth-last-child(5):after {

        display: none;

    }
    .mobileNoShow{
      display: none !important;
    }
  } // media end

  @media all and (max-width:376px) {
    .index-p1-inner .circle1 {
      top: 1.4rem;
    }
    .index-p1-inner .circle2 {
      top: 1.7rem;
    }
  }


</style>

<template>
    <div class="maytek-erp">
        <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat">
              <div class="banner-words-box swiper2">

                <div class="big-title">
                  <h1>美匣云旅游</h1><h1 class="erpWord">ERP</h1>
                  <!-- <div style="position:relative;">
                    <div class="cloud">
                      <img src="../../public/img/index/greenFire.png" alt="">
                      <div class="word">终身免费</div>
                    </div>
                    <div class="triangle"></div>
                  </div> -->
                </div>
                <h2>中小企业版</h2>
                <div class="little-wordsII">
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>地接社</span></div>
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>旅行社</span></div>
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>组团社</span></div>
                  <div class=""><img src="../../public/img/index/check.png" alt=""><span>批发商</span></div>
                </div>
                <router-link to="/register" class="free-try-btn" target="_blank">申请试用</router-link>
              </div>
              <div class="banner-qrcode">
                <p class="title">立即扫码添加微信</p>
                <p class="subtitle">申请体验</p>
                <img src="../../public/img/index/qrcode.png" alt="" class="imgCode">
                <div class="program">
                  <div>
                    <img src="../../public/img/index/erp.png" alt="">
                    <p>ERP系统</p>
                  </div>
                  <div>
                    <img src="../../public/img/index/guide.png" alt="">
                    <p>司导小程序</p>
                  </div>
                  <div>
                    <img src="../../public/img/index/provide.png" alt="">
                    <p>供应商小程序</p>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="index-content erp-p5">
            <div class="center-box erp-p5-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">旅行社内部管理ERP软件系统<div class="divider"></div></h2>
                    <h3>旅行社业务管理系统，帮助旅游行业数字化转型</h3>
                </div>
                <div class="content-box ">
                    <ul>
                        <li class="erp-p5-item">
                            <span>
                                <i></i>
                                <em></em>
                            </span>
                            <h1>OTA商家</h1>
                            <p>API对接各大主流OTA+新媒体等平台<br/>
                                同步更新线路/酒店/门票/签证等数据<br/>
                                飞猪/票付通等授权码商自动发码核销</p>
                            <p class="wap-p">API对接各大主流OTA+新媒体等平台同步更新线路/酒店/门票/签证等数据飞猪/票付通等授权码商自动发码核销</p>
                        </li>
                        <li class="erp-p5-item">

                             <span>
                                <i></i>
                                <em></em>
                            </span>
                            <h1>旅行综合社</h1>
                            <p>门店/组团社/地接社/专线批发都适用<br/>
                                订单数据同步各大平台，避免人工出错<br/>
                                产品/销售/计调/财务/司导/老板都能用</p>
                            <p class="wap-p">门店/组团社/地接社/专线批发都适用订单数据同步各大平台，避免人工出错产品/销售/计调/财务/司导/老板都能用</p>
                        </li>
                        <li class="erp-p5-item">
                             <span>
                                <i></i>
                                <em></em>
                            </span>
                            <h1>批发分销商</h1>
                            <p>B2B/B2C分销收客，直客/同行不同价格<br/>
                                PC+WAP+小程序+APP，查看操作更方便<br/>
                                配备资源市场，资源采购分销都能实现</p>
                            <p class="wap-p">B2B/B2C分销收客，直客/同行不同价格PC+WAP+小程序+APP，查看操作更方便配备资源市场，资源采购分销都能实现</p>
                        </li>
                        <li class="erp-p5-item">
                             <span>
                                <i></i>
                                <em></em>
                            </span>
                            <h1>集团客户</h1>
                            <p>为集团用户提供多部门、多层级架构、<br/>
                               多层面业务、多层次管理的综合解决方案，<br/>
                                功能可个性化定制，系统可独立部署。</p>
                            <p class="wap-p">为集团用户提供多部门、多层级架构、多层面业务、多层次管理的综合解决方案，功能可个性化定制，系统可独立部署。</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="index-content erp-p1">
            <div class="center-box erp-p1-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">旅游SaaS云平台<div class="divider"></div></h2>
                    <h3>70+资深专业技术人员，系统功能不断更新迭代</h3>
                </div>
                <div class="content-box ">
                    <div class="center-img">
                        <img src="../../public/img/erp/erp_p1_img.png" alt="" draggable="false">
                        <div class="words-box words-box1">
                            <i></i>
                            <h1>业务智能管理</h1>
                            <p>对接主流平台<br />订单自动同步更新，不用人工搬运</p>
                            <p class="wap-p">对接主流平台,订单自动同步更新，不用人工搬运</p>
                        </div>
                        <div class="words-box words-box2">
                               <i></i>
                            <h1>计调OP管理</h1>
                            <p>自动排团，自动匹配司导/交通/酒店等资源<br />API对接合同/保险/票务/签证等第三方系统</p>
                            <p class="wap-p">自动排团，自动匹配司导/交通/酒店等资源,API对接合同/保险/票务/签证等第三方系统</p>
                        </div>
                        <div class="words-box words-box3">
                               <i></i>
                            <h1>协同办公</h1>
                            <p>PC+WAP+APP+小程序，随时随地编辑查看<br />老板-销售-计调-司导-财务，数据实时共享</p>
                            <p class="wap-p">PC+WAP+APP+小程序，随时随地编辑查看,老板-销售-计调-司导-财务，数据实时共享</p>
                        </div>
                        <div class="words-box words-box4">
                               <i></i>
                            <h1>财务管理</h1>
                            <p>一站式解决对账/审核/收付款/发票/采购等问题<br />日常业务销售--成本-利润等账务数据一目了然</p>
                            <p class="wap-p">一站式解决对账/审核/收付款/发票/采购等问题,日常业务销售--成本-利润等账务数据一目了然</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="index-content erp-p2">
            <div class="center-box erp-p2-inner">
                <div class="p-tit-box">
                    <div class="p-tit">满足企业多场景需求 <div class="divider"></div></div>
                    <h3>PC+WAP+小程序+APP，操作查看更方便</h3>
                </div>
                <div class="content-box ">
                    <div class="p2-img-box">
                        <img src="../../public/img/erp/erp_p2_img.png" alt="PC+WAP+小程序+APP端ERP系统" draggable="false">
                    </div>
                </div>
            </div>
        </div>

        <div class="index-content erp-p3">
            <div class="center-box erp-p3-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">更完善的旅行社管理后台系统构架<div class="divider"></div></h2>
                    <p>系统运行流程全景</p>
                </div>
                <div class="content-box ">
                    <div class="process-box1">
                        <div class="process-tit">销售渠道</div>
                        <ul>
                            <li class="process-box-item">
                                <h1>直客销售渠道</h1>
                                <div>自营微商城、各类线下渠道
                                    <i></i>
                                </div>
                            </li>
                            <li class="process-box-item">
                                  <h1>同业分销渠道</h1>
                                <div>美匣云资源市场、同业合作渠道 <i></i></div>
                            </li>
                            <li class="process-box-item">
                                <h1>各大OTA平台</h1>
                                <div>飞猪，马蜂窝，携程，途牛，去哪儿网，... <i></i></div>
                            </li>
                        </ul>
                    </div>
                    <div class="process-box2">
                        <div class="process-tit">美匣云ERP</div>
                        <ul class="model-list">
                            <li class="model-item">
                                <div class="m-item-tit">订单模型</div>
                                <div class="m-box m-box1">
                                    <div class="m-box-item">确认外部平台的订单</div>
                                    <div class="m-box-item">手动录单，确认订单</div>
                                </div>
                                <div class="m-box m-box2">
                                    <div class="m-box-item">录入成本</div>
                                    <div class="m-box-item">采购下单</div>
                                    <div class="m-box-item">对账请款</div>
                                </div>
                                <div class="m-box m-box3">
                                    <div class="m-box-item">收款管理</div>
                                    <div class="m-box-item">请款管理</div>
                                    <div class="m-box-item">付款管理</div>
                                </div>
                            </li>





                            <li class="model-item">
                                <div class="m-item-tit">散拼模型</div>
                                <div class="m-box m-box1">
                                    <div class="m-box-item">确认外部平台的订单</div>
                                    <div class="m-box-item">手动录单，确认订单</div>
                                </div>
                                <div class="m-box m-box2">
                                    <div class="m-box-item">订单参团</div>
                                    <div class="m-box-item">确认成团</div>
                                    <div class="m-box-item">安排司导</div>
                                    <div class="m-box-item">录入成本</div>
                                    <div class="m-box-item">对账请款</div>
                                </div>
                                <div class="m-box m-box3">
                                    <div class="m-box-item">收款管理</div>
                                    <div class="m-box-item">请款管理</div>
                                    <div class="m-box-item">付款管理</div>
                                    <div class="m-box-item">导游报账</div>
                                </div>
                            </li>
                            <li class="model-item">
                                <div class="m-item-tit">团期模型</div>
                                <div class="m-box m-box1">
                                    <div class="m-box-item">确认外部平台的订单</div>
                                    <div class="m-box-item">手动录单，确认订单</div>
                                </div>
                                <div class="m-box m-box2">
                                    <div class="m-box-item">封团确认</div>
                                    <div class="m-box-item">安排司导</div>
                                    <div class="m-box-item">录入成本</div>
                                    <div class="m-box-item">对账请款</div>
                                </div>
                                <div class="m-box m-box3">
                                    <div class="m-box-item">收款管理</div>
                                    <div class="m-box-item">请款管理</div>
                                    <div class="m-box-item">付款管理</div>
                                    <div class="m-box-item">导游报账</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="process-box3">
                        <div class="process-tit">供应商</div>
                        <ul>
                            <li class="process-box-item pro3">
                                <h1>供应商</h1>
                                <div>地接，门票，酒店，车船，餐饮，...</div>
                                <i></i>
                            </li>
                            <li class="process-box-item pro3">
                                <h1>保险</h1>
                                <div>各类保险平台</div>
                                <i></i>
                            </li>
                            <li class="process-box-item pro3">
                                <h1>电子合同</h1>
                                <div>12301，其他</div>
                                <i></i>
                            </li>
                            <li class="process-box-item pro3">
                                <h1>导游小程序</h1>
                                <h2 class="container">
                                    <div>带团报账</div>
                                    <div>查看收入</div>
                                </h2>
                                <i></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="index-content erp-p4">
            <div class="center-box erp-p4-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">丰富的功能模块<div class="divider"></div></h2>
                    <h3>满足各类型旅行社的业务需求，全面提升各个岗位的工作效率</h3>
                </div>
                <div class="content-box ">
                    <ul class="icons-container">
                        <li class="icons-item i1">
                            <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>产品管理</h1>
                            <p class="des-words">
                                线上线下、各平台产品<br/>库存和价格集中管理
                            </p>
                        </li>
                        <li class="icons-item i2">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>团期管理</h1>
                               <p class="des-words">
                                   单个建团、批量开团<br/>团期计划维护和管理
                            </p>
                        </li>
                        <li class="icons-item i3">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>订单管理</h1>
                               <p class="des-words">
                                   全渠道订单汇聚到系统<br/>进行统一管理和处理
                            </p>
                        </li>
                        <li class="icons-item i4">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>散客拼团</h1>
                               <p class="des-words">
                                   灵活的散拼排团模式<br/>大大提高计调排团效率
                            </p>
                        </li>
                        <li class="icons-item i5">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>客户管理</h1>
                               <p class="des-words">
                                   crm客户关系管理<br/>会员营销和客户数据分析
                            </p>
                        </li>
                        <li class="icons-item i6">
                         <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>财务管理</h1>
                            <p class="des-words">
                                收款、请款付款、应收应付<br/>预收预付一应俱全
                            </p>
                        </li>
                        <li class="icons-item i7">
                         <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>自动智能</h1>
                               <p class="des-words">
                                   自动确认订单、自动处理订单<br/>自动出票发货、自动排团拼团
                            </p>
                        </li>
                        <li class="icons-item i8">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>OTA直连</h1>
                               <p class="des-words">
                                   无缝对接飞猪、马蜂窝、携程<br/>途牛、去哪儿网等主流OTA平台
                            </p>
                        </li>
                        <li class="icons-item i9">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>旅游保险</h1>
                               <p class="des-words">
                                   已接入各类旅游保险平台<br/>通过美匣云系统在线投保
                            </p>
                        </li>
                        <li class="icons-item i10">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>电子合同</h1>
                               <p class="des-words">
                                   12301以及其他电子合同<br/>平台的对接，在线签合同
                            </p>
                        </li>
                        <li class="icons-item i11">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>司导端</h1>
                               <p class="des-words">
                                   司机/导游登录微信小程序<br/>完成带团和报账，查看收入
                            </p>
                        </li>
                        <li class="icons-item i12">
                           <div class="shadow">
                                <span class="shadow-img"></span>
                                <div class="shadow-hover"></div>
                                <i></i>
                            </div>
                            <h1>供应商端</h1>
                               <p class="des-words">
                                   供应商登录系统自主<br/>上传产品、查看订单、确认订单
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--特色功能-->
        <div class="index-content erp-spaFunc">
            <div class="center-box erp-spaFunc-inner">
                <div class="p-tit-box">
                    <div class="p-tit">特色功能</div>
                </div>
                <div class="content-box swiper-container swiper-box">
                    <ul class="swiper-wrapper">
                        <li class="spa-content-item spa-p1 swiper-slide"  id="anchor1">
                            <div class="center-box spa-p1-inner">
                                <div class="content-box">
                                    <div class="left">
                                        <div class="wsc-p-img">
                                            <img src="../../public/img/sapFunc/func_p1_img.png" alt="">
                                        </div>

                                    </div>
                                    <div class="right">
                                        <div class="wsc-p-tit-box">
                                            <h2>OTA平台无缝对接</h2>
                                            <p>飞猪、马蜂窝、携程、途牛、美团、去哪儿网等OTA平台无缝对接。订单信息自动同步进入系统
                                                ，客服再也不用手工搬单；订单到账及结算记录自动同步，各类佣金、手续费、退款金额、补款
                                                金额、订单真实到账金额一目了然。同步速度快，同步信息全，美匣云ERP系统是OTA商家的不
                                                二之选。</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="spa-content-item spa-p2 swiper-slide" id="anchor2">
                            <div class="center-box spa-p2-inner">
                                <div class="content-box">
                                    <div class="left">
                                        <div class="wsc-p-tit-box">
                                            <h2>强大的API对接能力</h2>
                                            <p>与各类保险平台、各类电子合同平台、 12301电子合同、旅游资源分销平台、酒店供应商系统、
                                                门票供应商系统、其他供应商系统、金蝶和用友财务系统等进行API接口对接，提供美匣云ERP
                                                系统API文档。</p>
                                        </div>

                                    </div>
                                    <div class="right">
                                        <div class="wsc-p-img">
                                            <img src="../../public/img/sapFunc/func_p2_img.png" alt="" draggable="false">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="spa-content-item spa-p3 swiper-slide" id="anchor3">
                            <div class="center-box spa-p3-inner">
                                <div class="content-box">
                                    <div class="left">
                                        <div class="wsc-p-img">
                                            <img src="../../public/img/sapFunc/func_p3_img.png" alt="" draggable="false">
                                        </div>

                                    </div>
                                    <div class="right">
                                        <div class="wsc-p-tit-box">
                                            <h2>OCR证件识别系统</h2>
                                            <p>系统支持身份证、护照、港澳通行证等证件识别，拍照上传证件照片完成信息识别，快速完成客人信息补充，告别手动录入客人信息的繁琐工作，您可以拥有！</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="spa-content-item spa-p4 swiper-slide" id="anchor4">
                            <div class="center-box spa-p4-inner">
                                <div class="content-box">
                                    <div class="left">
                                        <div class="wsc-p-tit-box">
                                            <h2>资源库存管理系统</h2>
                                            <p>机票、大巴车位、门票等各类资源的库存管控，既可以多团销售共享资源，又可严防资源超卖，非常有利于资源库存的集中化管控。</p>
                                        </div>

                                    </div>
                                    <div class="right">
                                        <div class="wsc-p-img">
                                            <img src="../../public/img/sapFunc/func_p4_img.png" alt="" draggable="false">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="spa-content-item spa-p5 swiper-slide" id="anchor5">
                            <div class="center-box spa-p5-inner">
                                <div class="content-box">
                                    <div class="left">
                                        <div class="wsc-p-img">
                                            <img src="../../public/img/sapFunc/func_p5_img.png" alt="" draggable="false">
                                        </div>

                                    </div>
                                    <div class="right">
                                        <div class="wsc-p-tit-box">
                                            <h2>CRM客户管理系统</h2>
                                            <p>将公司客户档案、会员信息进行集中化管理；会员生日提醒，节日关怀；出行爱好、订单数据分析；各种会员营销工具，助力提升客户的复购率。</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="spa-content-item spa-p6 swiper-slide" id="anchor6">
                            <div class="center-box spa-p6-inner">
                                <div class="content-box">
                                    <div class="left">
                                        <div class="wsc-p-tit-box">
                                            <h2>商业数据大盘</h2>
                                            <p>订单、收客、盈利、销售排行、产品排行、计调排行、资金流水、出团概况的即时掌握。为销售目标的决定，以及发展方向的研判做极好的数据支撑</p>
                                        </div>

                                    </div>
                                    <div class="right">
                                        <div class="wsc-p-img">
                                            <img src="../../public/img/sapFunc/func_p6_img.jpg" alt=""  draggable="false">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>


        <!--服务优势-->
        <div class="index-content service-goodness">
            <div class="center-box service-goodness-inner">
                <div class="p-tit-box">
                    <h2 class="p-tit">四大措施，保障信息数据安全<div class="divider"></div></h2>
                    <h3>70+人资深专业技术人员，2000+家大中型企业使用</h3>
                </div>
                <div class="content-box">
                    <ul>
                        <li class="ser-good-item">
                            <div class="icon-box"><i></i></div>
                            <div class="des-box">
                                <h1>信息数据安全管理</h1>
                                <p>前后端数据分离，保障信息在处理、传输、上传和储存过程中的数据安全</p>
                            </div>
                        </li>
                        <li class="ser-good-item">
                            <div class="icon-box"><i></i></div>
                            <div class="des-box">
                                <h1>权限分离操作日志</h1>
                                <p>设置多部门、多层级操作查看权限完全分离，操作日志保障全部行为可查询</p>
                            </div>
                        </li>
                        <li class="ser-good-item">
                            <div class="icon-box"><i></i></div>
                            <div class="des-box">
                                <h1>系统漏洞防护预警</h1>
                                <p>实时监控可疑用户设备和用户风险性行为，随时查漏补缺程序bug和系统漏洞</p>
                            </div>
                        </li>
                        <li class="ser-good-item">
                            <div class="icon-box"><i></i></div>
                            <div class="des-box">
                                <h1>企业级云盾</h1>
                                <p>SAAS云系统构架，部署于阿里云上，由企业级云盾提供7x24小时全方位防护</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="index-content erp-p6">
            <div class="center-box erp-p6-inner">
                 <h1 class="erp_p6_tit">只需要3步，即可拥有专属的旅行社系统</h1>
                <div class="content-box ">
                    <ul>
                        <li class="erp-p6-item">
                            <span></span>
                            <p>申请试用体验</p>
                        </li>
                        <li class="erp-p6-item">
                              <span></span>
                            <p>签署合作协议</p>
                        </li>
                        <li class="erp-p6-item">
                              <span></span>
                            <p>开通正式账户</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <free-try :msg="msg" :btn="btn"></free-try>
        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>

<script>
    import FreeTry from '@/components/FreeTry.vue'
    import FootBox from '@/components/Footer.vue'
    import PopWindow from '@/components/PopWindow.vue'
    let vm;
    export default {
        name: "MayTekErp",
        components: {
            FreeTry,
            FootBox,
            PopWindow,
        },
        data(){
            return {
                msg: '立即试用体验美匣云旅行社ERP系统',
                btn: '立即试用体验'
            }
        },
        methods: {

            // 动态效果
            _move() {

                if($('.maytek-erp').length > 0){
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离

                    // 大标题
                    let $pTit1 = $('.erp-p1-inner')
                    let $pTit2 = $('.erp-p2-inner')
                    let $pTit3 = $('.erp-p3-inner')
                    let $pTit4 = $('.erp-p4-inner')
                    let $pTitSpaFunc = $('.erp-spaFunc-inner')
                    let $pTit5 = $('.erp-p5-inner')
                    let $pTitGoodness = $('.service-goodness-inner')
                    let moveBoxArr = [$pTit1,$pTit2,$pTit3,$pTit4,$pTitSpaFunc,$pTit5,$pTitGoodness]
                    moveBoxArr.forEach( (item,index) => {
                        if (($winHeight + $winScrollHeight) >= item.offset().top) {
                            $(item).find('.p-tit-box').addClass('animated fadeInUp')



                        }
                    })


                    if (($winHeight + $winScrollHeight) >= $('.erp-p1-inner .content-box').offset().top + 300) {
                        // p1 左右滑入

                        $('.erp-p1-inner .words-box1').addClass('animated fadeInLeft')
                        $('.erp-p1-inner .words-box3').addClass('animated fadeInLeft')
                        $('.erp-p1-inner .words-box2').addClass('animated fadeInRight')
                        $('.erp-p1-inner .words-box4').addClass('animated fadeInRight')
                    }

                    //p2


                    if (($winHeight + $winScrollHeight) >= $('.erp-p2-inner').offset().top) {
                        $('.erp-p2-inner .p2-img-box').addClass('animated zoomIn')
                        $('.erp-p2-inner .p2-img-box').css({
                            "animation-delay": .3  + "s"
                        })
                    }

                    //    p3 动效
                    let $p3Box1 =  $('.erp-p3-inner .process-box1')
                    let $p3Box2 =  $('.erp-p3-inner .process-box2')
                    let $p3Box3 =  $('.erp-p3-inner .process-box3')
                    let p3BoxArr = [$p3Box1,$p3Box2,$p3Box3]
                    p3BoxArr.forEach((item,index) => {
                        if (($winHeight + $winScrollHeight) >= $('.erp-p3-inner').offset().top) {
                            let $itemSonArr1 = $(item).find('.process-box-item')
                            let $itemSonArr2 = $(item).find('.model-item')
                            let $itemSonArr3 = $(item).find('.pro3')
                            $(item).addClass('animated fadeInDown')
                            $(item).css({
                                "animation-delay": 1 * index + "s"
                            })
                            $itemSonArr1.each(function (index, el) {
                                $(el).addClass('animated fadeInLeft')
                                $(el).css({
                                    "animation-delay": 0.5 + (0.1*index) + "s"
                                })
                            })
                            $itemSonArr2.each(function (index, el) {
                                $(el).addClass('animated fadeInLeft')
                                $(el).css({
                                    "animation-delay": 1 + (0.15*index) + "s"
                                })
                            })
                            $itemSonArr3.each(function (index, el) {
                                $(el).addClass('animated fadeInLeft')
                                $(el).css({
                                    "animation-delay": 1.5 + (0.2*index) + "s"
                                })
                            })
                        }
                    })


                    //    p4 动效
                    $('.erp-p4-inner .icons-item').each(function (index,item) {
                        if (($winHeight + $winScrollHeight) >= $('.erp-p4-inner').offset().top) {
                            $(item).addClass('animated rollIn')
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        }
                    })
                }

            },


        },
        mounted(){
            vm = this
            // 滚动屏幕触发
            let canRun = true;
            let timer;
            vm._move()
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._move()
                    canRun = true
                }, 300)

            });

           if(document.querySelector('.swiper-box')) {
               let mySwiper = new Swiper('.swiper-container',{
                   speed: 800,
                   autoplay: {
                       stopOnLastSlide: false,
                       disableOnInteraction: true,
                   },//可选选项，自动滑动
                   watchOverflow: true, // 当没有足够的slide切换时，例如只有1个slide，swiper会失效且隐藏导航等
                    loop:true,
                   pagination: {
                       el: '.swiper-pagination',
                       clickable :true,
                   },
                   observer: true,
                   observeParents: true,
                   observeSlideChildren: true,
               })
           }

        },
    }
</script>

<style lang="less">
    @bgBlue: #3b7ffc;
    .maytek-erp {
        min-width: 1200px;
        width: 100%;
        .big-title{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            h1{
                font-size: 48px;
                font-weight: bold;
                line-height: 49px;
                letter-spacing: 2px;
                margin-bottom: 0;
            }
            .erpWord{
                margin-right: 22px;
                font-size: 53px;
                font-weight: bold;
                line-height: 53px;
                letter-spacing: 2px;
            }
            .cloud{
                position: relative;
                z-index: 2;
                width: 168px;
                height: 52px;
                background-image: linear-gradient(0deg, #fa8013 0%, #f03a19 100%), linear-gradient(#ec3636, #ec3636);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                margin-right: 6px;
                }
                .word{
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 24px;
                background-image: linear-gradient(0deg, #fff2b5 0%, #1cff91 100%);
                -webkit-background-clip: text; 
                -webkit-text-fill-color: transparent;
                }
            }
            .triangle{
                display: block;
                width: 20px;
                height: 20px;
                background-image: linear-gradient(0deg, #fa8013 0%, #f03a19 100%), linear-gradient(#ec3636, #ec3636);
                box-shadow: 0px 2px 8px 0px rgb(240 60 25 / 20%);
                border-radius: 5px;
                position: absolute;
                left: -9px;
                top: 50%;
                margin-top: -10px;
                transform: rotate(45deg);
                z-index: 1;
            }
        }
        .little-words {
            font-size: 16px;
            div {
                position: relative;
                display: inline-block;
                margin-right: 30px;
                padding-left: 25px;
                color: #fff;
                i {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #5ddfff;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 8px;
                    height: 8px;
                    border: 3px solid rgba(79,134,242);
                    border-radius: 50%;
                    background-color: #5ddfff;
                }
            }
        }
        .little-wordsII{
            font-size: 16px;
            div {
                position: relative;
                display: inline-block;
                margin-right: 30px;
                color: #fff;
                line-height: 18px;
                img{
                margin-right: 7px;
                vertical-align: middle;
                }
                span{
                vertical-align: middle;
                }
            }
        }
        .p-tit-box,  .erp-p1-inner .words-box{
            opacity: 0;
        }
        .banner-box {
            width: 100%;
            height: 700px;
            background-image: url("../../public/img/index/index_banner.png");
            background-size: cover;
        }
        .banner-imgs {
            top: 75px;
        }
        .banner-words-box {
            margin-top: 190px;
            span {
                color: #ffffff;
            }
        }
    }
    
    
    /*p1*/ 
    .erp-p1{
        background-color: #f8f8f8;
    }
    .erp-p1-inner {
        height: 726px;
        .content-box {

            padding-top: 45px;
            text-align: center;
            .center-img {
                position: relative;
                display: inline-block;
                padding-top: 70px;
                .words-box {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    i {
                        display: block;
                        width: 46px;
                        height: 46px;
                        background: url('../../public/img/erp/erp_p1_icons.png') no-repeat;
                        background-size: cover;
                    }
                    h1 {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333;
                        margin: 15px 0 10px;
                    }
                    p {
                        width: 240px;
                        font-size: 14px;
                        color: #666;
                        line-height: 24px;
                        white-space: nowrap;
                    }
                    .wap-p {
                        display: none;
                    }
                }
                .words-box1 {
                    top: 0;
                    left: -250px;
                    i {
                        background-position:  0 0;
                    }

                }
                .words-box2 {
                    top: 0;
                    right: -230px;
                    i {
                        background-position:  -75px 0;
                    }
                }
                .words-box3 {
                    bottom: -53px;
                    left: -240px;
                    i {
                        background-position:  -155px 0;
                    }
                }
                .words-box4 {
                    bottom: -53px;
                    right: -250px;
                    i {
                        background-position:  -236px 0;
                    }
                }
            }
        }
    }

    /*p2*/
    .erp-p2 {
        height: 760px;
        // background-color: #f8f8f8;
        .content-box {
            text-align: center;
            img {
                padding-top: 75px;
                transition: all .8s linear;

            }
        }
    }
    /*p3*/
    .erp-p3 {
        background-color: #f8f8f8;
    }
    .erp-p3-inner {
        .content-box {
            padding-top: 60px;
            .process-box1,.process-box2,.process-box3 {
                position: relative;
                border-radius: 50px;
                background-color: rgba(141, 179, 244, .1);
                opacity: 0;
            }
            .process-tit {
                position: absolute;
                left: 0;
                top: 0;
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
                color: #fff;
                font-size: 16px;
                border-radius: 50%;
                background-image: linear-gradient(0deg,
                #49a1de 0%,
                #57b2ef 0%,
                #65c3ff 0%,
                #7290fd 67%,
                #7f5cfa 100%),
                linear-gradient(
                        #8db3f4,
                        #8db3f4);
                box-shadow: 0px 0px 8px rgba(19, 115, 246, 0.52);
            }
            .process-box-item {
                position: relative;
                text-align: center;
                h1{
                    font-size: 16px;
                    color: #333;
                    margin: 10px 0 12px;
                }
                div {
                    position: relative;
                    padding: 0 10px;
                    height: 41px;
                    line-height: 41px;
                    color: #fff;
                    font-size: 14px;

                    border-radius: 21px;
                    
                }
            }
            .process-box1, .process-box2, .process-box3 {
                width: 1200px;
            }
            .process-box1 {

                height: 100px;
                margin-bottom: 66px;
                ul {
                    display: flex;
                    flex-wrap: nowrap;
                }
                .process-box-item:first-child {
                    margin-left: 182px;
                }
                .process-box-item {
                    margin-right: 111px;
                    div {
                        min-width: 200px;
                        background-color: @bgBlue;
                        i {
                            position: absolute;
                            left: 50%;
                            bottom: -73px;
                            display: block;
                            width: 44px;
                            height: 48px;
                            background: url('../../public/img/erp/erp_arrow1.png') no-repeat center/cover;

                        }
                    }
                }
                .process-box-item:last-child {
                    margin-right: 0;
                }
            }
            .process-box2 {
                display: flex;
                align-items: center;
                height: 380px;
                margin-bottom: 66px;
                .process-tit {
                    top: 50%;
                    transform: translateY(-50%);
                }
                .model-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-left: 155px;
                    .model-item {
                        display: flex;
                        align-items: center;
                        /*height: 120px;*/
                        .m-item-tit, .m-box {
                            box-shadow: 0px 0px 8px rgba(17, 21, 47, 0.1);
                            border-radius: 4px;
                            background-color: #fff;
                        }
                        .m-box1, .m-box2{
                            &:after {
                                content: '';
                                display: block;
                                width: 51px;
                                height: 20px;
                                position: absolute;
                                top: 50%;
                                right: -68px;
                                transform: translateY(-50%);
                                background: url("../../public/img/erp/erp_arrow_right.png") no-repeat center/cover;
                            }
                        }
                        .m-box {
                            position: relative;
                            margin-left: 140px;
                            padding: 9px;
                            font-size: 14px;
                            color: #666;
                            text-align: center;

                            .m-box-item {
                                width: 140px;
                                margin-bottom: 4px;
                                background-color: #f3f6fa;
                            }
                            .m-box-item:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .m-item-tit {
                            position: relative;
                            width: 100px;
                            height: 60px;
                            line-height: 60px;
                            text-align: center;
                            color: #333;
                            font-size: 14px;
                            &:after {
                                content: '';
                                display: block;
                                width: 51px;
                                height: 20px;
                                position: absolute;
                                top: 50%;
                                right: -68px;
                                transform: translateY(-50%);
                                background: url("../../public/img/erp/erp_arrow_right.png") no-repeat center/cover;
                            }
                        }
                        .m-box1 {
                            .m-box-item {
                                line-height: 36px;
                                font-size: 12px;
                            }

                        }
                        .m-box2 {
                            .m-box-item {
                                line-height: 20px;
                                font-size: 12px;
                            }

                        }
                        .m-box3 {
                            .m-box-item {
                                line-height: 24px;
                                font-size: 12px;
                            }
                        }
                    }
                    .model-item:nth-child(1) {
                        .m-box2 {
                            margin-bottom: 1px;
                        }
                        .m-box2,.m-box3 {
                            .m-box-item {
                                line-height: 30px;
                            }
                        }

                    }
                    .model-item:nth-child(2) {
                        .m-box1,.m-box2,.m-box3 {
                            &:before {

                                display: inline-block;
                                writing-mode: vertical-lr;
                                position: absolute;
                                top: 50%;
                                left: -35px;
                                transform: translateY(-50%);
                                font-size: 15px;
                                color: #333;
                                font-weight: bold;
                            }
                        }
                        .m-box1 {
                            &:before {
                                content: '销售岗';
                            }

                         }
                        .m-box2 {
                            margin-bottom: 1px;
                            .m-box-item {
                                margin-bottom: 1px;
                            }
                            &:before {
                                content: '计调岗';
                            }

                        }
                        .m-box3 {
                            .m-box-item {
                                margin-bottom: 1.5px;
                            }
                            &:before {
                                content: '财务岗位';
                            }

                        }
                    }
                    .model-item:nth-child(3) {
                        .m-box2 {
                            .m-box-item {
                                line-height: 24px;
                            }
                        }

                    }
                }

            }
            .process-box3 {
                height: 100px;
                margin-bottom: 60px;
                ul {
                    display: flex;
                    flex-wrap: nowrap;
                }
                .process-box-item:first-child {
                    margin-left: 182px;
                }
                .process-box-item {

                    margin-right: 94px;
                    .container {
                        display: flex;
                        flex-wrap: nowrap;
                        div:nth-child(2) {
                            margin-left: 10px;
                        }
                    }
                    div {
                        min-width: 95px;
                        background-color: #7b6cfb;
                    }
                    i {
                        position: absolute;
                        left: 50%;
                        top: -58px;
                        display: block;
                        width: 8px;
                        height: 48px;
                        background: url('../../public/img/erp/erp_arrow2.png') no-repeat center/cover;

                    }
                }
                .process-box-item:last-child {
                    margin-right: 0;
                }
            }
        }

    }

    /*p4*/
    .erp-p4 {
        height: 900px;
        // background-color: #f8f8f8;
    }
    .erp-p4-inner {
        .content-box {
            padding-top: 75px;
            .icons-container {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                width: 1008px;
                height: 570px;
                margin: 0 auto;
                .icons-item {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-right: 227px;
                    .des-words {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        width: 198px;
                        position: absolute;
                        bottom: -54px;
                        left: 50%;
                        font-size: 14px;
                        color: #333;
                        text-align: center;
                        transform: translateX(-50%);
                        // white-space: nowrap;
                        opacity: 0;
                        transition: all 0.2s linear;

                    }
                    .shadow {
                        position: relative;
                        width: 80px;
                        height: 80px;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        i {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            display: block;
                            background-size: cover;
                            background-position: 0;
                            background-repeat: no-repeat;
                            z-index: 3;
                        }
                        span {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 125px;
                            height: 116px;
                            background: url('../../public/img/erp/shadow.png') no-repeat center/cover;
                            opacity: 0;
                            transition: all .5s ease;
                            z-index: 2;
                        }
                        .shadow-hover {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 80px;
                            height: 80px;
                            opacity: 0;
                            background: url("../../public/img/erp/erp_p4_circle_bg.png") no-repeat center/cover;
                            transition: all .5s ease;
                            z-index: 3;
                        }


                    }
                    h1 {
                        position: relative;
                        margin-top: 26px;
                        font-size: 18px;
                        color: #333;
                        padding-bottom: 14px;
                        &:after {
                            content: '';
                            position: absolute;
                            display: block;
                            width: 48px;
                            height: 2px;
                            background-color: transparent;
                            /*box-shadow: 0px 0px 8px 0px rgba(17, 21, 47, 0.1);*/
                            border-radius: 1px;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: all .3s ease;
                        }
                    }

                    &:hover {
                        .shadow {
                            box-shadow: 0px 0px 8px rgba(17, 21, 47, 0.1);
                        }
                        .shadow-hover,span {
                            opacity: 1;
                        }
                        .des-words {
                            opacity: 1;
                        }
                        h1 {
                            &:after {
                                background-color: @bgBlue;
                            }
                        }

                    }


                }

                .icons-item:nth-child(4n) {
                    margin-right: 0;
                }
                .i1 {
                    i {
                        width: 27px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/1.png");
                    }

                }
                .i2 {
                    i {
                        width: 26px;
                        height: 27px;
                        background-image: url("../../public/img/erp/icon/2.png");
                    }
                }
                .i3 {
                    i {
                        width: 25px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/3.png");
                    }
                }
                .i4 {
                    i {
                        width: 28px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/4.png");
                    }
                }
                .i5 {
                    i {
                        width: 24px;
                        height: 24px;
                        background-image: url("../../public/img/erp/icon/5.png");
                    }
                }
                .i6 {
                    i {
                        width: 27px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/6.png");
                    }
                }
                .i7 {
                    i {
                        width: 32px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/7.png");
                    }
                }
                .i8 {
                    i {
                        width: 26px;
                        height: 27px;
                        background-image: url("../../public/img/erp/icon/8.png");
                    }
                }
                .i9 {
                    i {
                        width: 28px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/9.png");
                    }
                }
                .i10 {
                    i {
                        width: 22px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/10.png");
                    }
                }
                .i11 {
                    i {
                        width: 22px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/11.png");
                    }
                }
                .i12 {
                    i {
                        width: 26px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/12.png");
                    }
                }

                .i1:hover {
                    i {
                        width: 27px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/white/1.png");
                    }

                }
                .i2:hover {
                    i {
                        width: 26px;
                        height: 27px;
                        background-image: url("../../public/img/erp/icon/white/2.png");
                    }
                }
                .i3:hover {
                    i {
                        width: 25px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/white/3.png");
                    }
                }
                .i4:hover {
                    i {
                        width: 28px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/white/4.png");
                    }
                }
                .i5:hover {
                    i {
                        width: 24px;
                        height: 24px;
                        background-image: url("../../public/img/erp/icon/white/5.png");
                    }
                }
                .i6:hover {
                    i {
                        width: 27px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/white/6.png");
                    }
                }
                .i7:hover {
                    i {
                        width: 32px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/white/7.png");
                    }
                }
                .i8:hover {
                    i {
                        width: 26px;
                        height: 27px;
                        background-image: url("../../public/img/erp/icon/white/8.png");
                    }
                }
                .i9:hover {
                    i {
                        width: 28px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/white/9.png");
                    }
                }
                .i10:hover {
                    i {
                        width: 22px;
                        height: 26px;
                        background-image: url("../../public/img/erp/icon/white/10.png");
                    }
                }
                .i11:hover {
                    i {
                        width: 22px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/white/11.png");
                    }
                }
                .i12:hover {
                    i {
                        width: 26px;
                        height: 28px;
                        background-image: url("../../public/img/erp/icon/white/12.png");
                    }
                }
            }
        }
    }


    /*特色功能*/
    .erp-spaFunc {
        height: 775px;
        background-color: #f8f8f8;
    }
    .erp-spaFunc-inner {
        .content-box {
            .spa-content-item {
                .content-box {
                    display: flex;
                    justify-content: space-between;
                    .wsc-p-tit-box {
                        p {
                            width: 600px;
                        }
                        h2 {
                            display: inline-block;
                            position: relative;
                            margin-bottom: 45px;
                            font-size: 36px;
                            color: #333;
                            white-space: nowrap;
                            &:before {
                                content: '';
                                position: absolute;
                                top: -15px;
                                left: -25px;
                                display: block;
                                width: 73px;
                                height: 57px;

                            }
                            &:after {
                                content: '';
                                position: absolute;
                                bottom: -23px;
                                left: 0;
                                display: block;
                                width: 245px;
                                height: 2px;
                                background: linear-gradient(to right, @bgBlue 35%, rgba(255,255,255,.1));
                            }
                        }
                        // h2:after {
                        //     right: auto;
                        //     left: 0;
                        //     background: linear-gradient(to right, #3b7ffc 35%, rgba(255, 255, 255, 0.1));
                        // }
                    }

                }
                .wsc-p-img {
                    overflow: hidden;
                    margin-top: 150px;
                    border-radius: 10px;
                    font-size: 0;
                    img {
                        width: 520px;
                        transition: all .3s linear;
                        &:hover {
                            /*transform: scale(1.02);*/
                        }
                    }
                }
                .wsc-p-tit-box {
                    width: 600px;
                }

            }
            .spa-p1 {
                .wsc-p-img {
                    margin-top: 150px;
                }
            }
            .spa-p2, .spa-p3,.spa-p4,.spa-p5,.spa-p6 {
                height: 500px;
                .wsc-p-tit-box {
                    padding-top: 200px;
                }
                .spa-p-img {
                    padding-top: 150px;
                }
            }
        }
        .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
            bottom: 0;
        }
    }

    /*p5*/
    .erp-p5 {
        height: 636px;
        // background-color: #f8f8f8;
    }
    .erp-p5-inner {
        // width: 1340px !important;
        .content-box {
            padding-top: 80px;
            ul {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            .erp-p5-item {
                // margin-right: 119px;
                transition: all .2s ease;
                span {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    margin: 0 auto;
                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        display: block;
                        width: 40px;
                        height: 40px;
                        background-image: url("../../public/img/erp/erp_p5_icons.png");
                        background-repeat: no-repeat;
                        background-size: cover;
                        z-index: 2;
                    }
                    em {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        transform-origin: 50% 50%;
                        transition: all .3s linear;
                        z-index: 1;
                    }
                }
                h1 {
                    margin-top: 35px;
                    margin-bottom: 25px;
                    font-size: 20px;
                    color: #333;
                    text-align: center;
                    font-weight: 700;
                }
                p {
                    font-size: 14px;
                    color: #666;
                    line-height: 24px;
                    text-align: center;
                    white-space: nowrap;
                }
                .wap-p {
                    display: none;
                }
                &:hover {

                    transform: translateY(-3px);

                }
            }
            .erp-p5-item:nth-child(1) {
                span {
                    i {
                        background-position: 0 0;
                    }

                }

            }
            .erp-p5-item:nth-child(2) {
                span {
                    i {
                        background-position: -60px 0;
                    }
                }
            }
            .erp-p5-item:nth-child(3) {
                span {
                    i {
                        background-position:  -123px 0;
                    }
                }
            }
            .erp-p5-item:nth-child(4) {
                span {
                    i {
                        background-position: -183px 0;
                    }
                }
            }

            .erp-p5-item:nth-child(n) {
                span {
                    em {
                        background: url("../../public/img/erp/erp_p5_circle1.png");
                    }

                }
                &:hover {
                    em {
                        transform: rotate(90deg);
                    }
                }
            }
            .erp-p5-item:nth-child(2n) {

                span {
                    em {
                        background: url("../../public/img/erp/erp_p5_circle2.png");
                    }

                }
                &:hover {
                    em {
                        transform: rotate(-90deg);
                    }
                }
            }
            .erp-p5-item:last-child {
                margin-right: 0;
            }
        }
    }

    /*服务优势*/
    .service-goodness {
        height: 632px;
    }
    .service-goodness-inner {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 90px;
             .ser-good-item {
                 position: relative;
                 width: 580px;
                 height: 120px;
                 background-color: #f2f5fa;
                 border-radius: 6px;
                 padding-left: 99px;
                 padding-right: 19px;
                 margin-right: 40px;
                 margin-bottom: 43px;
                 user-select: none;

                 .icon-box {
                     position: absolute;
                     left: 13px;
                     top: -4px;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     width: 70px;
                     height: 70px;
                     background-color: #fff;
                     border-radius: 6px;
                     box-shadow: 0 0 8px 0 rgba(17, 21, 47, 0.1);
                     transition: all .3s ease;
                     i {
                         display: block;
                         width: 28px;
                         height: 28px;
                         background-image: url(../../public/img/erp/service-goodness-icons.png);
                         background-repeat: no-repeat;
                         background-size: 133px 28px;
                         background-position: 0 0;
                     }

                 }
                 h1,p {
                     width: 462px;
                 }
                 h1 {
                     font-size: 20px;
                     color: #333;
                     margin-bottom: 15px;
                     margin-top: 16px;
                     font-weight: 700;
                 }
                 
                 p {
                     font-size: 14px;
                     color: #808080;
                     line-height: 20px;
                     white-space: nowrap;
                 }
                 &:hover {
                     .icon-box {
                         transform: scale(1.05);
                     }
                 }
             }
            .ser-good-item:nth-child(2n) {
                margin-right: 0;
            }
            .ser-good-item:nth-child(3),.ser-good-item:nth-child(4) {
                margin-bottom: 0;
            }
            .ser-good-item:nth-child(2){
                i {
                    height: 24px;
                    background-position: -35px 0;
                }
            }
            .ser-good-item:nth-child(3){
                i {

                    background-position: -70px 0;
                }
            }
            .ser-good-item:nth-child(4){
                i {

                    background-position: -105px 0;
                }
            }
        }
    }


    /*p6*/
    .erp-p6 {
        height: 460px;
        background: url('../../public/img/erp/erp_p6_bg.png') no-repeat center/cover;
    }
    .erp-p6-inner {
        .erp_p6_tit {
            padding-top: 70px;
            padding-bottom: 90px;
            font-size: 24px;
            color: #fff;
            text-align: center;
        }
        .content-box {
            text-align: center;
            ul {
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
            }
             .erp-p6-item {
                 position: relative;
                 display: flex;
                 flex-direction: column;
                 justify-content: center;
                align-items: center;
                 margin-right: 220px;
                 &:after {
                     position: absolute;
                     top: 17%;
                     right: -106px;
                     content: '';
                     display: block;
                     width: 17px;
                     height: 40px;

                     background: url('../../public/img/erp/erp_p6_arrow.png');
                 }
                 span {
                     display: block;
                     width: 99px;
                     height: 101px;

                 }
                 p {
                     margin-top: 20px;
                     font-size: 16px;
                     color: #fff;
                 }
             }
            .erp-p6-item:nth-child(1) {
                span {background: url('../../public/img/erp/erp_p6_num1.png');}

            }
            .erp-p6-item:nth-child(2) {
                span {background: url('../../public/img/erp/erp_p6_num2.png');}
            }
            .erp-p6-item:nth-child(3) {
                span {background: url('../../public/img/erp/erp_p6_num3.png');}
            }
            .erp-p6-item:last-child {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }

        }
    }


    @media all and (max-width: 750px) {
        .maytek-erp .banner-box {
            height: 100vh;
        }
        .maytek-erp {
            width: 7.5rem;
            min-width: auto;
            .banner-inner {
                width: 7.5rem;
                flex-direction: column;
            }
            .free-try-btn {
                width: 2.4rem;
            }
             .big-title{
                justify-content: center;
                align-items: center;
                h1{
                    font-size: .34rem;
                    line-height: .34rem;
                    white-space: nowrap;
                }
                .erpWord{
                    font-size: .38rem;
                    line-height: .4rem;
                }
            }
            .swiper2{
                width: 100%;
                margin-top: 2rem !important;
                h2::before{
                    display: none;
                }
            }
            .banner-qrcode{
                margin: auto;
                width: 6.2rem;
                height: auto;
                padding: 0.2rem;
                margin-top: 0.8rem;
                .title{
                    margin-bottom: 0.1rem;
                    font-size: .3rem;
                }
                .subtitle{
                    font-size: .24rem;
                    margin-bottom: 0.27rem;
                }
                .imgCode{
                    width: 2rem;
                    margin-bottom: 0.2rem;
                }
            }
            .little-wordsII {
                font-size: .16rem;
                div{
                    margin-right: .3rem;
                }
            }
        }
        .maytek-erp .banner-imgs {
            top: 1.5rem;
            img {
                width: 4.2rem;
            }
        }
        .maytek-erp .banner-words-box {
            margin-top: 6.5rem;
        }

        /*.erp-p1*/
        .erp-p1-inner {
            height: auto;
        }
        .erp-p1-inner .content-box {
            padding-top: 1rem;
        }
        .erp-p1-inner .content-box .center-img {
            padding-top: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            img {
                display: none;
            }

            .words-box {
                position: relative;
                width: 48%;
                margin-bottom: 1rem;
                overflow: hidden;
                justify-content: normal;
                h1 {
                    font-size: .32rem;
                }

                i {
                    width: .46rem;
                    height: .46rem;

                }
                p {
                    display: none;
                }
                .wap-p {
                    display: block;
                    width: 3rem;
                    line-height: 2;
                    font-size: .25rem;
                    text-align: justify;
                    white-space: unset;
                }

            }

            .words-box3,.words-box4 {
                margin-bottom: 0;
            }
            .words-box1 {
                left: 0;
                top: 0;
            }
            .words-box2 {
                left: 0;
                top: 0;
                i {
                    background-position: -.76rem 0;
                }
            }
            .words-box3 {
                left: 0;
                top: 0;
                i {
                    background-position: -1.55rem 0;
                }
            }
            .words-box4 {
                left: 0;
                top: 0;
                i {
                    background-position: -2.35rem 0;
                }
            }

        }






        /*.erp-p2 */
        .erp-p2 {
            height: auto;
        }
        .erp-p2 .content-box img {
            width: 100%;
        }

        /*.erp-p3*/
        .erp-p3-inner .content-box {
            overflow: auto;
        }
        .erp-p3-inner .content-box {
            .process-box1,.process-box2,.process-box3 {
                width: 12rem;
            }
            .process-box1,.process-box3 {
                height: 1rem;
                margin-bottom: .6rem;
            }



            .process-tit {
                width: 1rem;
                height: 1rem;
                line-height: 1rem;
                overflow: hidden;
                font-size: 12px;
            }

            .process-box1 {
                height: 1rem;
                margin-bottom: .66rem;
                .process-box-item:first-child {
                    margin-left: 1.82rem;
                }
                .process-box-item {
                    margin-right: 1.11rem;
                    div {
                        min-width: 2rem;
                        i {
                            bottom: -.73rem;
                            width: .44rem;
                            height: .48rem;
                        }
                    }
                }

            }


            .process-box2 {
                height: 5rem;
                margin-bottom: .66rem;

                .model-list {

                    margin-left: 1.55rem;
                    .model-item {


                        .m-box1, .m-box2{
                            &:after {

                                width: .51rem;
                                height: .2rem;
                                top: 50%;
                                right: -.68rem;

                            }
                        }
                        .m-box {

                            margin-left: 1.4rem;
                            padding: .09rem;
                            font-size: .14rem;
                            .m-box-item {
                                width: 1.4rem;
                                margin-bottom: .04rem;
                            }

                        }
                        .m-item-tit {

                            width: 1rem;
                            height: .6rem;
                            line-height: .6rem;

                            font-size: 12px;
                            &:after {

                                width: .51rem;
                                height: .2rem;

                                top: 50%;
                                right: -.68rem;

                            }
                        }
                        .m-box1 {
                            .m-box-item {
                                line-height: .36rem;
                                font-size: .12rem;
                            }

                        }
                        .m-box2 {
                            .m-box-item {
                                line-height: .2rem;
                                font-size: .12rem;
                            }

                        }
                        .m-box3 {
                            .m-box-item {
                                line-height: .24rem;
                                font-size: .12rem;
                            }
                        }
                    }
                    .model-item:nth-child(1) {
                        .m-box2 {
                            margin-bottom: 1px;
                        }
                        .m-box2,.m-box3 {
                            .m-box-item {
                                line-height: .3rem;
                            }
                        }

                    }
                    .model-item:nth-child(2) {
                        .m-box1,.m-box2,.m-box3 {
                            &:before {
                                top: 50%;
                                left: -.35rem;
                                font-size: .12rem;
                                white-space: nowrap;
                            }
                        }
                        .m-box1 {
                            &:before {
                                content: '销售岗';
                            }

                        }
                        .m-box2 {
                            margin-bottom: 1px;
                            .m-box-item {
                                margin-bottom: 1px;
                            }
                            &:before {
                                content: '计调岗';
                            }

                        }
                        .m-box3 {
                            .m-box-item {
                                margin-bottom: .015rem;
                            }
                            &:before {
                                content: '财务岗位';
                            }

                        }
                    }
                    .model-item:nth-child(3) {
                        .m-box2 {
                            .m-box-item {
                                line-height: .24rem;
                            }
                        }

                    }
                }

            }

            .process-box3 {
                height: 1rem;
                margin-bottom: .6rem;
                .process-box-item:first-child {
                    margin-left: 1.82rem;
                }
                .process-box-item {
                    margin-right: .94rem;
                    .container {
                        div:nth-child(2) {
                            margin-left: .1rem;
                        }
                    }
                    div {
                        min-width: .95rem;
                    }
                    i {
                        top: -.58rem;
                        width: .08rem;
                        height: .48rem;
                    }
                }

            }

            .process-box-item  {
                h1 {
                    font-size: 12px;
                    margin: .1rem 0 .12px;
                }
                div {
                    padding: 0 .1rem;
                    height: .41rem;
                    line-height: .41rem;
                    font-size: 12px;
                }
            }

        }









        /*.erp-p4*/
        .erp-p4 {
            height: auto;
        }
        .erp-p4-inner .content-box {
            padding-top: 1rem;
        }
        .erp-p4-inner .content-box .icons-container {
            width: 100%;
            height: auto;
        }
        .erp-p4-inner .content-box .icons-container .icons-item {
            width: 45%;
            margin-right: 10%;
            margin-bottom: 1.5rem;
        }
        .erp-p4-inner .content-box .icons-container .icons-item:nth-child(4n) {
            margin-right: 1rem;
        }
        .erp-p4-inner .content-box .icons-container .icons-item:nth-child(2n) {
            margin-right: 0;
        }
        .erp-p4-inner .content-box .icons-container .icons-item h1 {
            margin-top: .25rem;
            font-size: .35rem;
            padding-bottom: .2rem;
        }
        .erp-p4-inner .content-box .icons-container .icons-item .des-words {
            opacity: 1;
            width: 3.5rem;
            bottom: -.8rem;
            font-size: .26rem;
            line-height: 1.5;
            color: #666;
        }

        /*spaFunc*/
        .erp-spaFunc {
            height: auto;
            .swiper-container {
                padding-bottom: .8rem;
            }
        }
        .swiper-box {
            .center-box {
                width: 100%;
                padding: 0;
            }
        }
        .erp-spaFunc-inner .content-box .spa-content-item .wsc-p-img {
            margin-top: 1rem;
        }
        .erp-spaFunc-inner .content-box .spa-content-item .wsc-p-img img {
            width: 100%;
        }
        .erp-spaFunc-inner .content-box .spa-content-item .content-box {
            flex-direction: column;
            justify-content: center;
        }
        .erp-spaFunc-inner .content-box .spa-p1 .wsc-p-tit-box,.erp-spaFunc-inner .content-box .spa-p2 .wsc-p-tit-box, .erp-spaFunc-inner .content-box .spa-p3 .wsc-p-tit-box, .erp-spaFunc-inner .content-box .spa-p4 .wsc-p-tit-box, .erp-spaFunc-inner .content-box .spa-p5 .wsc-p-tit-box, .erp-spaFunc-inner .content-box .spa-p6 .wsc-p-tit-box {
            padding-top: 1rem;
        }


        .erp-spaFunc-inner .content-box .spa-p1,.erp-spaFunc-inner .content-box .spa-p2, .erp-spaFunc-inner .content-box .spa-p3, .erp-spaFunc-inner .content-box .spa-p4, .erp-spaFunc-inner .content-box .spa-p5, .erp-spaFunc-inner .content-box .spa-p6 {
            height: auto;
        }

        .spa-p1,.spa-p2, .spa-p3,.spa-p4,.spa-p5,.spa-p6 {
            .wsc-p-tit-box {
                display: block;
                h2 {
                    font-size: .4rem;
                }
                p {
                    width: 100%;
                    font-size: .3rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;


                }
            }
        }
        .erp-spaFunc-inner .content-box .spa-content-item .content-box .wsc-p-tit-box p {
            width: 100%;
        }
        .erp-spaFunc-inner .content-box .spa-content-item .wsc-p-tit-box {
            width: 100%;

        }
        .wsc-p-tit-box p {
            width: 100%;
            text-align: justify;
        }



        /*.erp-p5*/
        .erp-p5 {
            height: auto;
        }
        .erp-p5-inner .content-box {
            padding-top: 1rem;
        }
        .erp-p5-inner .content-box ul {
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .erp-p5-inner .content-box .erp-p5-item {
            width: 45%;
            margin-right: 0;
            margin-bottom: .8rem;
        }
        .erp-p5-inner .content-box .erp-p5-item:nth-last-child(1),.erp-p5-inner .content-box .erp-p5-item:nth-last-child(2) {
            margin-bottom: 0;
        }
        .erp-p5-inner .content-box .erp-p5-item p {
            display: none;
        }
        .erp-p5-inner .content-box .erp-p5-item .wap-p {
            display: block;
            text-align: justify;
            white-space: unset;
        }
        .erp-p5-inner .content-box .erp-p5-item:last-child {
            margin-bottom: 0;
        }


        /*service-goodness*/
        .service-goodness {
            height: auto;
            .p-tit-box h3{
                font-size: .25rem;
            }
        }
        .service-goodness-inner ul {
            margin-top: 1rem;
        }
        .service-goodness-inner ul .ser-good-item {
            width: 100%;
            height: auto;
            padding-left: 2rem;
            padding-right: .15rem;
            padding-bottom: .3rem;
            margin-right: 0;
            margin-bottom: .5rem;

            h1 {
                width: 100%;
                font-size: .35rem;
                margin-bottom: .3rem;
                margin-top: .3rem;
            }
            p {
                width: 100%;
                font-size: .25rem;
                line-height: 1.5;
                text-align: justify;
                white-space: normal;
            }
        }
        .service-goodness-inner ul .ser-good-item:nth-child(3)  {
            margin-bottom: .5rem;
        }
        .service-goodness-inner ul .ser-good-item .icon-box {
            left: .26rem;
            top: -.2rem;
            width: 1.4rem;
            height: 1.4rem;
        }
        /*.erp-p6-inner*/
        .erp-p6 {
            height: auto;
        }
        .erp-p6-inner .erp_p6_tit {
            padding-top: .9rem;
            padding-bottom: 1rem;
            font-size: .35rem;
        }
        .erp-p6-inner .content-box ul {
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
        }
        .erp-p6-inner .content-box .erp-p6-item {
            margin-right: 0;
            margin-bottom: 2rem;
        }
        .erp-p6-inner .content-box .erp-p6-item:last-child {
            margin-bottom: 0;
        }
        .erp-p6-inner .content-box .erp-p6-item:after {
            top: 3.7rem;
            right: 50%;
            transform: translateX(50%);
            width: .8rem;
            height: .34rem;
            background: url("../../public/img/erp/erp_p6_arrow_wap.png") no-repeat center / cover;
        }
         .maytek-erp .little-words {
            font-size: .25rem;
            display: flex;
            flex-direction: column;
            &>div {
                margin: 0 auto .2rem;
                width:2.3rem;
            }
        }
    } // media end

</style>
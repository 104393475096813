<!--景区票务-->
<template>
  <div class="douyin" >
    <div id="banner" class="banner-box">
      <div class="banner-inner clearFloat">
        <div class="banner-words-box">

          <h1>美匣云抖音小程序</h1>
          <h2>助力旅游商家运营变现抖音流量</h2>
          <p style="color:#67efff;">适用场景：景区景点、酒店民宿</p>
          <!-- <div class="little-words">
            <div class="">景区景点</div>
            <div class="">酒店民宿</div>
          </div> -->
          <router-link to="/register" class="free-try-btn" target="_blank">试用</router-link>


        </div>
        <div class="banner-imgs">
          <img src="../../public/img/douyin/index_banner_img1.png" alt="美匣云抖音小程序" class="lazyload" draggable="false">
        </div>
      </div>
    </div>
    <div class="index-content dy-p0">
      <div class="center-box dy-p0-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">抖音用户数据报告</h2>
          <h3>庞大流量用户数据     极高变现价值</h3>
        </div>
         <div class="process-box">
            <ul>
                <li class="dy-p0-item">
                    <img class="dy-p0-item-img" src="../../public/img/douyin/p0/dy_p0_img_1.png" alt="">
                </li>
                <li class="dy-p0-item">
                    <img class="dy-p0-item-img" src="../../public/img/douyin/p0/dy_p0_img_2.png" alt="">
                </li>    
                <li class="dy-p0-item">
                    <img class="dy-p0-item-img" src="../../public/img/douyin/p0/dy_p0_img_3.png" alt="">
                </li>
                <li class="dy-p0-item">
                    <img class="dy-p0-item-img" src="../../public/img/douyin/p0/dy_p0_img_4.png" alt="">
                </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="index-content dy-p1">
      <div class="center-box dy-p1-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">美匣云抖音平台解决方案<div class="divider"></div></h2>
          <h3>助力旅游商家抓住抖音平台流量红利</h3>
        </div>
        <div class="process-box">
            <ul>
                <li class="dy-p1-item">
                    <img class="dy-p1-item-img" src="../../public/img/douyin/p1/dy_p1_img_1.png" alt="">
                    <div>                       
                        <p>抖音团购解决方案</p>
                        <span>帮助商家认领POI点位<br/>【优惠团购】、蓝 V认证。提供抖音<br/>团购日常运营服务、多场景变现工具、<br/>CPS分销服务</span>
                    </div>
                </li>
                <li class="dy-p1-item">
                    <img class="dy-p1-item-img" src="../../public/img/douyin/p1/dy_p1_img_2.png" alt="">
                    <div>                       
                        <p>抖音小店解决方案</p>
                        <span>协助商家走专属渠道快速开店<br/>美匣云打通抖音小店，提供订单管理<br/>服务、预约日期服务、可直连出票、<br/>统计数据</span>
                    </div>
                </li>    
                <li class="dy-p1-item">
                    <img class="dy-p1-item-img" src="../../public/img/douyin/p1/dy_p1_img_3.png" alt="">
                    <div>                       
                        <p>抖音平台技术工具</p>
                        <span>提供预约系统、小程序、订单管理核<br/>销系统、直连票务系统。协助商家利用<br/>团购、小店、直播、短视频等变现</span>
                    </div>
                </li>
                 <li class="dy-p1-item">
                    <img class="dy-p1-item-img" src="../../public/img/douyin/p1/dy_p1_img_4.png" alt="">
                    <div>                       
                        <p>抖音运营服务方案</p>
                        <span>为商家提供抖音日常运营服务<br/>包括：商品设计包装、上架更新维护、粉<br/>丝管理回复、订单处理、数据复盘等</span>
                    </div>
                </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="index-content dy-p2">
      <div class="center-box dy-p2-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">抖音团购综合解决方案<div class="divider"></div></h2>
          <h3>商家开通团购POI,人人可分销带货</h3>
        </div>
        <div class="content-box clearFloat">
          <div class="process-box">
                <ul>
                    <li class="dy-p2-item">
                        <div class="dy-p2-icons">
                            <div class="circle circle1" >
                                <div class="circle-inner circle1-inner">
                                    开通POI
                                </div>
                            </div>
                            <div class="word word1"> 
                              <p>为商家快速开通抖音POI【优惠团购】/认领IPO点位。发布视频可直接定位商家地址，呈现出定位图标</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p2-item">
                        <div class="dy-p2-icons">
                            <div class="circle circle2" >
                                <div class="circle-inner circle2-inner">
                                    团购小程序
                                </div>
                            </div>
                            <div class="word word2">
                                <p>团购小程序支持抖音多场景挂载。可挂载到短视频、达人推荐、直播间等场景，客户可直接点击下单</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p2-item">
                        <div class="dy-p2-icons">
                           <div class="circle circle3" >
                                <div class="circle-inner circle3-inner">
                                    CPS分销
                                </div>
                            </div>
                            <div class="word word3">
                                <p>任何抖音账号申请了POI产品权益后，拍摄视频时添加POI地址，观看的人点击下单消费之后可获得CPS收益</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p2-item">
                        <div class="dy-p2-icons">
                           <div class="circle circle4" >
                                <div class="circle-inner circle4-inner">
                                    在线预约
                                </div>
                            </div>
                            <div class="word word4">
                                <p>抖音下单之后无法直接预约美匣云提供自助预约具体出行日期系统。预约成功后系统将自动完成出票发码</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p2-item">
                        <div class="dy-p2-icons">
                           <div class="circle circle5" >
                                <div class="circle-inner circle5-inner">
                                    直连出票
                                </div>
                            </div>
                            <div class="word word5">
                                <p>支持API直连景区票务系统。客户预约之后由景区票务系统发码和出票，支持由景区核销设备直接核销</p>
                            </div>
                        </div>
                    </li>
                    <li style="clear:both;"></li>
                </ul>
                <div class="imgs-p2">
                  <h3>抖音小程序丰富的应用场景  多流量入口和多渠道变现</h3>
                  <div>
                    <div>
                      <img src="../../public/img/douyin/p2/dy_p2_img_1.png" alt="抖音团购流量入口">
                      <p>开通POI</p>
                    </div>
                    <div>
                      <img src="../../public/img/douyin/p2/dy_p2_img_2.png" alt="抖音团购流量入口">
                      <p>团购小程序</p>
                    </div>
                    <div>
                      <img src="../../public/img/douyin/p2/dy_p2_img_3.png" alt="抖音团购流量入口">
                      <p>CPS分销</p>
                    </div>
                    <div>
                      <img src="../../public/img/douyin/p2/dy_p2_img_4.png" alt="抖音团购流量入口">
                      <p>在线预约</p>
                    </div>
                    <div>
                      <img src="../../public/img/douyin/p2/dy_p2_img_5.png" alt="抖音团购流量入口">
                      <p>直连出票</p>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
     <div class="index-content dy-p3">
      <div class="center-box dy-p3-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">抖音小店整套解决方案<div class="divider"></div></h2>
          <h3>预约系统打通抖音店铺与旅游商家  减少人工介入</h3>
        </div>
        <div class="content-box clearFloat">
           <div class="process-box">
              <ul>
                  <li class="dy-p3-item"> 
                    <!-- <div class="scheme_img"> -->
                      <img src="../../public/img/douyin/p3/dy_p3_img_1.png" alt="">
                    <!-- </div> -->
                    <div class="word">
                      <span>开通</span>
                      <p>帮助商家提交开店资料，走专属通道开通小店功能、协助小店装修、风格定位等</p>
                    </div>   
                  </li>
                  <li class="dy-p3-item"> 
                    <div class="scheme_img">
                      <img src="../../public/img/douyin/p3/dy_p3_img_2.png" alt="">
                    </div>
                    <div class="word">
                      <span>运营</span>
                      <p>提供抖店日常运营服务。包括：抖店商品设计包装、上下架、维护、数据统计等</p>
                    </div>   
                  </li>
                  <li class="dy-p3-item"> 
                    <div class="scheme_img">
                      <img src="../../public/img/douyin/p3/dy_p3_img_3.png" alt="">
                    </div>
                    <div class="word">
                      <span>订单管理</span>
                      <p>美匣云打通抖音小店，API自动同步小店订单，获取订单信息，自动处理订单详情</p>
                    </div>   
                  </li>
                  <li class="dy-p3-item"> 
                      <div class="scheme_img">
                        <img src="../../public/img/douyin/p3/dy_p3_img_4.png" alt="">
                      </div>
                      <div class="word">
                        <span>在线预约</span>
                        <p>客人下单以后，支持自助预约出行日期，预约成功后系统将自动完成出票发码</p>
                      </div>   
                  </li>
                  <li class="dy-p3-item"> 
                    <div class="scheme_img">
                      <img src="../../public/img/douyin/p3/dy_p3_img_5.png" alt="">
                    </div>
                    <div class="word">
                      <span>直连出票</span>
                      <p>可直连景区票务系统，由景区票务系统发码和出票，由景区核销设备完成核销</p>
                    </div>   
                  </li>
                  <li style="clear:both;"></li>
              </ul>
              <div class="dy-p3-box">
                <h3>帮助抖音商家数字化转型    减少人工消耗</h3>
                <div>
                  <ul>
                    <li>人工预约，客户通过抖音小店下单后，需人工客服确定出行日期，核销抖音码</li>
                    <li>刷码入内，客户凭借人工客服发送的核销码入内，或者换票入内</li>
                    <li>票务系统，人工录入景区票务系统，再由票务系统发出核销码，客户核销入内</li>
                  </ul>
                  <div>
                    <div class="linear"></div>
                    <span>vs</span>
                    <div class="linear"></div>
                  </div>
                  <ul>
                    <li>预约系统，客户下单之后，自主填写信息，预约出行时间，自动核销抖音码</li>
                    <li>无需换票，客户可以凭借自动预约之后的二维码直接刷码入内</li>
                    <li>直连景区票务系统，由景区票务系统 发码和出票，由景区核销设备完成核销</li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="index-content dy-p4">
      <div class="center-box dy-p4-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">配套的技术工具<div class="divider"></div></h2>
          <h3>为抖音各场景提供变现工具 </h3>
        </div>
        <div class="content-box clearFloat">
             <div class="process-box">
                <ul>
                    <li class="dy-p4-item">
                        <div class="dy-p4-icons">
                            <div class="circle circle1" >
                                <div class="circle-inner circle1-inner">
                                    抖音预约系统
                                </div>
                            </div>
                            <div class="word word1"> 
                              <p>客人抖音下单之后，支持自助预约出行日期预约成功之后，预约系统将自动完成出票发码</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p4-item">
                        <div class="dy-p4-icons">
                            <div class="circle circle2" >
                                <div class="circle-inner circle2-inner">
                                    抖音小程序
                                </div>
                            </div>
                            <div class="word word2">
                                <p>小程序适用抖音多应用场景可挂载到短视频、直播间、团购POI商品</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p4-item">
                        <div class="dy-p4-icons">
                           <div class="circle circle3" >
                                <div class="circle-inner circle3-inner">
                                    订单系统
                                </div>
                            </div>
                            <div class="word word3">
                                <p>自动同步抖店和团购订单用户下单后，可由订单系统自动发送核销码或凭证码</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p4-item">
                        <div class="dy-p4-icons">
                           <div class="circle circle4" >
                                <div class="circle-inner circle4-inner">
                                    核销工具
                                </div>
                            </div>
                            <div class="word word4">
                                <p>订单系统自动发码给客户,景区核销工具直接核销团购/抖店/小程序订单皆支持</p>
                            </div>
                        </div>
                    </li>
                    <li class="dy-p4-item">
                        <div class="dy-p4-icons">
                           <div class="circle circle5" >
                                <div class="circle-inner circle5-inner">
                                    直连票务<br/>系统
                                </div>
                            </div>
                            <div class="word word5">
                                <p>支持直连景区票务系统,由景区票务系统发码出票，由景区核销设备完成核销</p>
                            </div>
                        </div>
                    </li>
                    <li style="clear:both;"></li>
                </ul>
                <div class="imgs-p2">
                  <h3>抖音预约系统，客人直接在线完成预约</h3>
                  <div>
                    <div>
                      <p>1</p>
                      <img src="../../public/img/douyin/p4/dy_p4_img_1.png" alt="抖音预约系统">
                    </div>
                    <div>
                      <p>2</p>
                      <img src="../../public/img/douyin/p4/dy_p4_img_2.png" alt="抖音预约系统">
                    </div>
                    <div>
                      <p>3</p>
                      <img src="../../public/img/douyin/p4/dy_p4_img_3.png" alt="抖音预约系统">
                    </div>
                    <div>
                      <p>4</p>
                      <img src="../../public/img/douyin/p4/dy_p4_img_4.png" alt="抖音预约系统">
                    </div>
                    <div>
                      <p>5</p>
                      <img src="../../public/img/douyin/p4/dy_p4_img_5.png" alt="抖音预约系统">
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-content dy-p5">
      <div class="center-box dy-p5-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">抖音运营服务方案<div class="divider"></div></h2>
          <h3>不仅提供工具   也提供一站式运营方案</h3>
        </div>
        <div class="content-box clearFloat">
          <div class="process-box">
            <ul>
              <li class="dy-p5-item">
                  <img class="dy-p5-item-img" src="../../public/img/douyin/p5/dy_p5_img_1.png" alt="">
                  <div>                       
                      <p>日常运营</p>
                      <span>提供商城日常运营服务。包括：商品设计包装、上下架、粉丝维护、评论/留言/私信维护管理</span>
                  </div>
              </li>
              <li class="dy-p5-item">
                  <img class="dy-p5-item-img" src="../../public/img/douyin/p5/dy_p5_img_2.png" alt="">
                  <div>                       
                      <p>团购运营</p>
                      <span>认领POI点位，开通团购服务，上架、更新、维护、 优化团购产品，挂载团购小程序，团购订单处理</span>
                  </div>
              </li>    
              <li class="dy-p5-item">
                  <img class="dy-p5-item-img" src="../../public/img/douyin/p5/dy_p5_img_3.png" alt="">
                  <div>                       
                      <p>抖店运营</p>
                      <span>提交开店资料，协助审核资料，开通官方抖店，上架发布抖店商品，商品设计包装运营、数据复盘</span>
                  </div>
              </li>
              <li class="dy-p5-item">
                  <img class="dy-p5-item-img" src="../../public/img/douyin/p5/dy_p5_img_4.png" alt="">
                  <div>                       
                      <p>直播运营</p>
                      <span>负责直播产品上下架、改价格、改库存、拉黑、禁言等工作，和直播挂载商品变现工具、直播复盘</span>
                  </div>
              </li>
              <li class="dy-p5-item">
                  <img class="dy-p5-item-img" src="../../public/img/douyin/p5/dy_p5_img_5.png" alt="">
                  <div>                       
                      <p>资源运营</p>
                      <span>帮助商家整合各类资源。例如：达人资源、网络媒体资源、达人直播带货、网红视频带货等资源</span>
                  </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    
    <div class="index-content dy-p6">
      <div class="center-box dy-p6-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">配置数据商业智能大屏<div class="divider"></div></h2>
          <h3>游客画像  销售分析  人流监控  掌控全局</h3>
        </div>
        <div class="content-box clearFloat">
          <div class="process-box">
            <div class="dy-p6-item">
              <img src="../../public/img/douyin/p6/dy_p6_img_1.png" alt="抖音数据商业智能大屏">
              <img src="../../public/img/douyin/p6/dy_p6_img_2.png" alt="抖音数据商业智能大屏">
            </div> 
          </div>
        </div>
      </div>
    </div>

   

    <free-try :msg="msg" :btn="btn"></free-try>
    <foot-box></foot-box>
  </div>
</template>

<script>

import FreeTry from '@/components/FreeTry.vue'
import FootBox from '@/components/Footer.vue'
let vm;
export default {

  name: 'scenicTicket',
    data(){
      return {
          contentP5: [
            {
              title:'电子票务系统',
              explain:'美匣云票务系统采用SAAS架构部署，全面解决景区景点售票、取票、检票、营销、防疫问题，平台简单易操作，功能强大，适用范围广。',
              word: {
                '售':['线上订购','线下直售'],
                '取':['纸质票','电子票'],
                '检':['闸机','手持机'],
                '销':['渠道引流','全民营销']
              }
            },
            {
              title:'票务分销系统',
              explain:'美匣云票务分销系统，不仅支持OTA商家和同行企业，在线查位，下单预订，自动分销；还支持个人客户分销返佣模式，形成营销裂变，全民营销。',
              word: {
                '个人':['分销返佣','一键分享'],
                'OTA':['订单同步','发码核销'],
                '同行':['查位下单','自动对账']
              }
            },
            {
              title:'会员管理系统',
              explain:'配置专业scrm客户管理系统，管理用户信息，对客户精准画像，生成各种维度的数据汇总，方便企业对客户定位，以短信、图文、在线沟通等方式吸引客户再次复购。',
              word: {
                '管理':['基础信息','客户画像'],
                '分析':['数据汇总','维度分析'],
                '运营':['人群分级','精准营销'],
                '触达':['在线沟通','消息推送']
              }
            },
            {
              title:'小程序微商城解决方案',
              explain:'小程序微商城，不仅能为景区景点提供门票解决方案，也能把景区景点打造成一站式综合电商平台。不仅限于门票的售卖，还能售卖包含衣、食、住、行、玩、购等方面的各类商品，整合景区景点商家，充分利用商家资源。',
              word: {
                '获客引流':['OTA平台','微信平台'],
                '客户留存':['数据信息','行为跟踪'],
                '分销裂变':['分销返佣','一键复购'],
                '运营转化':['精准营销','二次消费']
              }
            },
            {
              title:'营销复购解决方案',
              explain:'景区景点可以根据自身特点，设置多样的营销方案。例如：套票组合、年卡次卡、会员等级权益等方式，绑定用户；同时通过短信、公众号图文、视频、聊天沟通、优惠推送等吸引用户二次消费。',
              word: {
                '卡/票':['套票','次/月/年卡'],
                '会员权益':['会员折扣','会员积分'],
                '营销联动':['朋友圈公众号','短信小程序']
              }
            },
            {
              title:'智慧景区管理方案',
              explain:'景区景点不仅限于门票的售卖，更想要打造成智慧景区，实现一部手机游景区的概念。这里面不仅需要整合景区商家，包括：衣、食、住、行、玩、购等各种商家集合，也需要管理游客游前、游中、游后全过程，能实现分时预约、限制人数、智能导览、旅游攻略等功能。',
              word: {
                '游前':['购票购卡','旅游攻略'],
                '游中':['语音讲解','商家推送'],
                '游后':['点评分享','推荐复购'],
                '防疫':['分时预约','实名制登记']
              }
            },
            {
              title:'硬件设备支持方案',
              explain:'硬件设备的支持，是票务系统中很重要的一环，售票、取票、检票都会用到，美匣科技提供下列硬件设备：闸机、手持机、门票打印机、自助售取票机给景区景点参考。',
              word: ['景区闸机','手持机','门票打印机','自助售取票机']
            },
          ],
          btn: '立即试用体验',
          msg: '立即试用体验美匣云资源市场',
          data:'',
          objKeys:'',
          objValues:'',
          imgNo1:[
              require('../../public/img/scenicTicket/p5/sce_p5_img_1.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_2.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_3.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_4.png')
            ],
          imgNo2:[
              require('../../public/img/scenicTicket/p5/sce_p5_img_5.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_6.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_7.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_8.png')
            ],
          imgP5:'',
          trueP5:false,
      }
    },
  components: {
      FreeTry,
      FootBox
  },
  methods: {
      // if ($('.banner').length > 0 || $('.about-us').length > 0) {  }
      // 动态效果
      _move() {
          if($('.douyin').length > 0) {
              let $winHeight = $(window).height(); // 窗口可视区域的高度
              let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离





              // 大标题
              let $pTit0 = $('.dy-p0-inner')
              let $pTit1 = $('.dy-p1-inner')
              let $pTit2 = $('.dy-p2-inner')
              let $pTit3 = $('.dy-p3-inner')
              let $pTit4 = $('.dy-p4-inner')
              let $pTit5 = $('.dy-p5-inner')
              let $pTit6 = $('.dy-p6-inner')
              let moveBoxArr = [$pTit0,$pTit1,$pTit2,$pTit3,$pTit4,$pTit5,$pTit6]
              moveBoxArr.forEach( (item,index) => {
                  if (($winHeight + $winScrollHeight) >= item.offset().top) {

                      $(item).find('.p-tit-box').addClass('animated fadeInUp')

                  }
              })

            //dy-p0 动效
            if (($winHeight + $winScrollHeight) >= $('.dy-p0-inner').offset().top) {
              $('.dy-p0-inner .dy-p0-item').each(function (index,item) {
                  $(item).addClass('animated fadeInRight')
                  $(item).css({
                      "animation-delay": .05 * index + "s"
                  })
              })

            }

              //dy-p1 动效
              if (($winHeight + $winScrollHeight) >= $('.dy-p1-inner').offset().top + 300) {
              $('.dy-p1-inner .dy-p1-item').each(function (index,item) {
                  $(item).addClass('animated fadeInLeft')
                  $(item).css({
                      "animation-delay": .3 * index + "s"
                  })
              })

              }

              //dy-p2 动效
              if (($winHeight + $winScrollHeight) >= $('.dy-p2-inner').offset().top) {
            
                      $('.dy-p2-inner .dy-p2-item').each(function (index,item) {
                          $(item).find('.dy-p2-icons').addClass('animated fadeInLeft')
                          $(item).find('.dy-p2-icons').css({
                              "animation-delay": .4 * index + "s"
                          })
                      })
                      $(".dy-p2-inner .process-box .imgs-p2").addClass('animated fadeInUp')
                      $(".dy-p2-inner .process-box .imgs-p2").css({
                              "animation-delay": 1 + "s"
                      })

              }

            //dy-p3 动效
              if (($winHeight + $winScrollHeight) >= $('.dy-p3-inner').offset().top) {
                  $('.dy-p3-inner .dy-p3-item').each(function (index,item) {
                      $(item).addClass('animated fadeInLeft')
                      $(item).css({
                          "animation-delay": .4 * index + "s"
                      })
                  })

                  $(".dy-p3-inner .process-box .dy-p3-box").addClass('animated fadeInUp')
                  $(".dy-p3-inner .process-box .dy-p3-box").css({
                          "animation-delay": 1 + "s"
                  })

              }
              //dy-p4 动效
              if (($winHeight + $winScrollHeight) >= $('.dy-p4-inner').offset().top) {
                  $('.dy-p4-inner .dy-p4-item').each(function (index, item) {
                      $(item).addClass('animated fadeInLeft')
                      $(item).css({
                          "animation-delay": 0.15 * index + "s"
                      })
                  })
                  $(".dy-p4-inner .process-box .imgs-p2").addClass('animated fadeInUp')
                  $(".dy-p4-inner .process-box .imgs-p2").css({
                          "animation-delay": 1 + "s"
                  })
                
              }

              //dy-p5 动效
              if (($winHeight + $winScrollHeight) >= $('.dy-p5-inner').offset().top) {
                
                  $('.dy-p5-inner .dy-p5-item').each(function (index, item) {
                      $(item).addClass('animated fadeInLeft')
                      $(item).css({
                          "animation-delay": 0.15 * index + "s"
                      })
                  })
              }

              //dy-p6 动效
              if (($winHeight + $winScrollHeight) >= $('.dy-p6-inner').offset().top) {
                  $(".dy-p6-inner .dy-p6-item").find('img:nth-child(1)').addClass('animated fadeInLeft')
                  $(".dy-p6-inner .dy-p6-item").find('img:nth-child(2)').addClass('animated fadeInRight')
                  $(".dy-p6-inner .dy-p6-item img").css({
                          "animation-delay": 0.3 + "s"
                  })
              
              }
          }
        
      }

  },
  mounted(){
    vm = this
    //  p1
      let canRun = true;
      let timer;
      vm._move();
      $(window).scroll(function () {

          if (!canRun) {

              return
          }
          canRun = false
          timer = setTimeout(function () {

              vm._move()
              canRun = true
          }, 300)

      })
      
  },

}
</script>
<style lang="less" >

  @bgBlue: #3b7ffc;
  .douyin {
    width: 100%;
    min-width: 1200px;
    .little-words {
      font-size: 16px;
      margin-bottom: 38px;
      div {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        padding-left: 25px;
        color: #fff;
        i {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #5ddfff;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 8px;
          height: 8px;
          border: 3px solid rgba(79,134,242);
          border-radius: 50%;
          background-color: #5ddfff;
        }
      }
    }
    .banner-box {
        width: 100%;
        height: 600px;
        background-image: url("../../public/img/index/index_banner.png");
        background-size: cover;
        overflow: hidden;
        user-select: none;
    }
   
    .banner-words-box {
      margin-top: 200px;
    }
    .banner-words-box {

    h1, h2, h3 {
      color: #fff;
    }
    h1 {
        font-weight: bold;
        margin-bottom: 33px;
        font-size: 40px;
    }
    h2 {
        font-size: 31px;
        font-weight: 100;
    }
    h3 {
        margin-bottom: 55px;
        font-size: 20px;
    }
    
    .free-try-btn {
      margin-top: 31px;
      font-size: 18px;
    }

  }
    // .p-tit-box  {
    //   opacity: 0;
    // }

  }
 

  .banner-inner {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 1200px;
    height: 100%;
    margin: auto;
  }
  
  .douyin .banner-imgs{
    top: 120px;
  }

  /*公共*/
  .banner-imgs {
    position: absolute;
    right: 0;
      transition: all .3s linear;
      &:hover {
          transform: scale(1.05);
      }
  }
  .free-try-btn {
    display: inline-block;
    width: 180px;
    height: 54px;
    line-height: 54px;
    border-radius: 30px;
    color: #fff;
  }

  .index-content {
    width: 100%;
    overflow: hidden;
  }
  .center-box {
    width: 1200px;
    margin: 0 auto;
  }


/*p0*/
  .dy-p0-inner {
    position: relative;
    // height: 720px;
    .p-tit-box .p-tit:after{
      display: none;
    }
    .p-tit-box .p-tit:before{
      display: none;
    }
    .p-tit{
      font-size: 32px;
      font-weight: bold;
      color:#3b7ffc !important;
    }
    .process-box{
      padding-top: 39px;
      ul{
          flex-wrap: wrap;
      }
      .dy-p0-item{
          margin:0 10px 40px 0;
          // float: left;        
      }
    }
  }
  
  /*p1*/
  .dy-p1 {
    background-color: #f8f8f8;
  }
  .dy-p1-inner {
    position: relative;
    // height: 720px;
     .process-box{
         margin: 72px 0 94px;
         padding: 0;
            ul{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            .dy-p1-item{
                margin:0 20px 20px 0;
                float: left;
                flex: 1;
                >div{
                    text-align: center;
                    margin-top: 27px;
                    p{
                        padding: 25px 0 30px;
                        position: relative;
                        &:before{
                            content: '';
                            display: block;
                            width: 20px;
                            height: 2px;
                            background-color: #3b7ffc;
                            position: absolute;
                            top: -2px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                .dy-p1-item-img{
                    display: block;
                    margin: auto;

                }
                p{
                   
                }
                
            }
      }
  }
  /*公共标题*/
  .p-tit-box {
    text-align: center;
    padding-top: 70px;
    .p-tit {
      position: relative;
      display: inline-block;
      font-size: 36px;
      color: #1a1a1a;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -100px;
        width: 79px;
        height: 6px;
        background: url("../../public/img/index/tit_left.png") no-repeat center/contain;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -100px;
        width: 79px;
        height: 6px;
        background: url("../../public/img/index/tit_right.png") no-repeat center/contain;
      }
      .divider {
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 59px;
          width: 232px;
          height: 3px;
          background: url('../../public/img/index/tit-line.png') no-repeat center/contain;

      }
    }
    p {
      display: block;
      position: relative;
      margin-top: 26px;
      line-height: 20px;
      color: #333;
      font-size: 16px;
    }
  }

  .dy-p2-inner {
    .process-box {
        padding-top: 71px;
        ul {
            // display: flex;
            // flex-wrap: nowrap;
            // justify-content: space-between;
            display: block;
            height: 380px;
            position: relative;
            &::before{
              content: '';
              width: 1200px;
              height: 500px;
              display: block;
              position: absolute;
              top: -339px;
              border-radius: 0 0 50% 50%;
              border-bottom: 1px solid #ccc;
            }
        }
        .dy-p2-item {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: center;
            float: left;
            width: 20%;
            position: relative;
            .dy-p2-icons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .circle{
                    width:140px;
                    height:140px;
                    border-radius:50%;
                    padding:10px;
                    position: relative;
                    font-size: 20px;
                    line-height: 120px;
                    color: #ffffff;
                }
                .circle-inner{
                    border-radius: 50%;
                    width: 120px;
                    height: 120px;
                }
                .circle1{
                    background-color: rgba(59, 127, 252, .1);
                }
                .circle1-inner{
                    background-color: #3884c5;
                }
                .circle2{
                    background-color:rgba(85,85,233,.1);
                }
                .circle2-inner{
                    background-color: #5555e9;
                }
                .circle3{
                    background-color:rgba(209,94,162,.1);
                }
                .circle3-inner{
                    background-color: #d15ea2;
                }
                .circle4{
                    background-color:rgba(215,135,57,.1);
    
                }
                .circle4-inner{
                    background-color: #d78739;
                   
                }
                .circle5{
                    background-color:rgba(123,178,52, 0.1);
                     
                }
                .circle5-inner{
                    background-color: #7bb234;
                }
                .word{
                  width: 90%;
                  height: 248px;
                  border-radius: 80%;
                  position: absolute;
                  top: 40px;
                  p {
                      font-size: 14px;
                      line-height: 20px;
                      color: #333;
                      position: absolute;
                      top: 50%;
                      padding: 0 20px;
                  }
                }
                .word1{
                  background: linear-gradient(to bottom, transparent 40%, rgba(59,127,252,0.2));
                }
                .word2{
                  background: linear-gradient(to bottom, transparent 40%, rgba(229,229,252));
                }
                .word3{
                  background: linear-gradient(to bottom, transparent 40%, rgba(248,230,241));
                }
                .word4{
                  background: linear-gradient(to bottom, transparent 40%, rgba(249,237,225));
                }
                .word5{
                  background: linear-gradient(to bottom, transparent 40%, rgba(235,243,224));
                }
            }
        }
        .dy-p2-item:nth-child(even) {
            margin-top: 60px;
        }
        .dy-p2-item:nth-child(3) {
            margin-top: 85px;
        }
        .imgs-p2{
          text-align: center;
          margin: 60px 0;
          >div{
            position: relative;
            &::after{
              content: '';
              width: 102%;
              height: 1px;
              box-shadow: 0 0 25px 19px #394c6e;
              position: absolute;
              bottom: 120px;
              left: -10px;
              border-radius: 50%;
              z-index: 0;
              background-color: unset;
            }
            div {
              position: relative;
              z-index: 1;
              p{
                font-size: 20px;
                color: #333;
                margin-top: 20px;
                font-family: "MicrosoftYaHei";
                font-weight: bold;
              }
            }
            div:nth-child(2),div:nth-child(4){
              position: relative;
              top: 10px;
              img{
                box-shadow: 10px 0px 5px 0px rgba(59,127,252,.7), -10px 0px 5px 0px rgba(59,127,252,.7);
              }
            }
            div:nth-child(3){
              position: relative;
              top: 20px;
              z-index: 2;
              img{
                box-shadow: 10px 0px 5px 0px rgba(59,127,252,.7), -10px 0px 5px 0px rgba(59,127,252,.7);
              }
            }
          }
          h3{
            font-size: 28px;
            font-weight: bold;
            color: #3b7ffc;
            margin-bottom: 80px;
          }
          div{
            display: inline-block;
          }
        }
    }
  }
  .dy-p3 {
    background-color: #f8f8f8;
  }
  .dy-p3-inner {
    .process-box {
      padding: 39px 0 70px;
      >ul {
          display: block;
          position: relative;
          z-index: 0;
          &::before{
            content: '';
            width: 1200px;
            height: 480px;
            display: block;
            position: absolute;
            top: -339px;
            border-radius: 0 0 50% 50%;
            border-bottom: 1px solid #ccc;
            z-index: -1;
          }
      }
      .dy-p3-item {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          text-align: center;
          width: 20%;
          float: left;
          box-sizing: border-box;
          padding: 0 10px;
          .word{
            margin-top: 28px;
            span{
              font-family: "MicrosoftYaHei";
              font-weight: bold;
              font-size: 20px;
              line-height: 20px;
              color: #333333;
              margin-bottom: 17px;
              display: block;
            }
            p{
              font-family: "MicrosoftYaHei";
              font-size: 14px;
              line-height: 24px;
              color: #333333;
              padding: 0 20px;
            }
          }
      }
      .dy-p3-item:nth-child(even) {
        margin-top: 60px;
      }
      .dy-p3-item:nth-child(3) {
          margin-top: 85px;
      }
      .dy-p3-box{
        margin-top: 134px;
        >div{
          display: flex;
          justify-content: space-around;
          span{
            font-size: 36px;
            background-image:-webkit-linear-gradient(0, #49a1de 0%, #57b2ef 0%, #65c3ff 0%, #7f5cfa 100%); 
            -webkit-background-clip:text; 
            -webkit-text-fill-color:transparent; 
            display: block;
            line-height: 18px;
            height: 25px;
          }
          >div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .linear{   
              width: 1px;
              height: 150px;
              background: #e6e6e6;
            }
          }
        }
        h3{
          font-size: 28px;
          font-weight: bold;
          color: #3b7ffc;
          text-align: center;
          margin-bottom: 89px;
        }
        ul{
          display: block;
          li:not(:last-child){
            margin-bottom: 71px;
          }
        }
        ul:first-child{
          li{
            width: 501px;
            padding: 23px 0;
            text-align: center;
            border: solid 2px #c5cddd;
            position: relative;
            &::after{
              content: '';
              width: 12px;
              height: 12px;
              background-color: #c5cddd;
              position: absolute;
              top: calc(50% - 6px);
              right: -7px;
              transform: rotate(45deg);
            }
          }
        }
        ul:last-child{
          li{
            width: 501px;
            padding: 23px 0;
            text-align: center;
            border-style: solid;
	          border-width: 2px;
            border-image-slice: 1;
            border-image-source: linear-gradient(90deg, #49a1de 0%, #57b2ef 0%, #65c3ff 0%, #7f5cfa 100%);
            position: relative;
            &::before{
              content: '';
              width: 12px;
              height: 12px;
              background-color: #65c3ff;
              position: absolute;
              top: calc(50% - 6px);
              left: -7px;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
  .dy-p4-inner {
    .process-box {
        padding-top: 71px;
        ul {
            display: block;
            height: 380px;
            position: relative;
            &::before{
              content: '';
              width: 1200px;
              height: 500px;
              display: block;
              position: absolute;
              top: -339px;
              border-radius: 0 0 50% 50%;
              border-bottom: 1px solid #ccc;
            }
        }
        .dy-p4-item {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: center;
            float: left;
            width: 20%;
            position: relative;
            .dy-p4-icons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .circle{
                    width:140px;
                    height:140px;
                    border-radius:50%;
                    padding:10px;
                    position: relative;
                    font-size: 20px;
                    line-height: 120px;
                    color: #ffffff;
                }
                .circle-inner{
                    border-radius: 50%;
                    width: 120px;
                    height: 120px;
                }
                .circle1{
                    background-color: rgba(59, 127, 252, .1);
                }
                .circle1-inner{
                    background-color: #3884c5;
                }
                .circle2{
                    background-color:rgba(85,85,233,.1);
                }
                .circle2-inner{
                    background-color: #5555e9;
                }
                .circle3{
                    background-color:rgba(209,94,162,.1);
                }
                .circle3-inner{
                    background-color: #d15ea2;
                }
                .circle4{
                    background-color:rgba(215,135,57,.1);
    
                }
                .circle4-inner{
                    background-color: #d78739;
                   
                }
                .circle5{
                    background-color:rgba(123,178,52, 0.1);
                     
                }
                .circle5-inner{
                    background-color: #7bb234;
                }
                .word{
                  width: 90%;
                  height: 248px;
                  border-radius: 80%;
                  position: absolute;
                  top: 40px;
                  p {
                      font-size: 14px;
                      line-height: 20px;
                      color: #333;
                      position: absolute;
                      top: 50%;
                      padding: 0 20px;
                  }
                }
                .word1{
                  background: linear-gradient(to bottom, transparent 40%, rgba(59,127,252,0.2));
                }
                .word2{
                  background: linear-gradient(to bottom, transparent 40%, rgba(229,229,252));
                }
                .word3{
                  background: linear-gradient(to bottom, transparent 40%, rgba(248,230,241));
                }
                .word4{
                  background: linear-gradient(to bottom, transparent 40%, rgba(249,237,225));
                }
                .word5{
                  background: linear-gradient(to bottom, transparent 40%, rgba(235,243,224));
                }
            }
        }
        .dy-p4-item:nth-child(even) {
            margin-top: 60px;
        }
        .dy-p4-item:nth-child(3) {
            margin-top: 85px;
        }
        .imgs-p2{
          text-align: center;
          margin: 60px 0;
          >div{
            position: relative;
            z-index: 1;
            &::before{
              content: '';
              width: calc(100% - 238px);
              height: 1px;
              position: absolute;
              top: 13px;
              left: 116px;
              z-index: -1;
              background-color: #dfe7ff;
            }
            div {
              p{
                width: 28px;
                height: 28px;
                background-color: #dfe7ff;
                font-size: 18px;
                line-height: 28px;
                border-radius: 50%;
                color: #6582b8;
                font-weight: bold;
                display: block;
                margin: 0 auto 18px;
              }
            }
          }
          h3{
            font-size: 28px;
            font-weight: bold;
            color: #3b7ffc;
            margin-bottom: 80px;
          }
          div{
            display: inline-block;
          }
        }
    }
  }
  .dy-p5 {
    background-color: #f8f8f8;
  }
  .dy-p5-inner {
    .process-box{
      padding-top: 39px;
      ul{
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin: 63px 0 97px;
      }
      .dy-p5-item{
          padding: 0 30px;
          >div{
              text-align: center;
              margin-top: 27px;
              p{
                  padding: 24px 0 16px;
                  font-size: 20px;
                  font-weight: bold;
              }
              span{
                line-height: 24px;
              }
          }
          .dy-p5-item-img{
              display: block;
              margin: auto;
              box-shadow: 0 0 20px 1px;
              border-radius: 50%;
          } 
        }
        .dy-p5-item:nth-child(1){
          .dy-p5-item-img{
              box-shadow: 0 0 20px 1px #3b7ffc;
          }
          p{
            color:#3b7ffc
          }
        } 
        .dy-p5-item:nth-child(2){
          .dy-p5-item-img{
            box-shadow: 0 0 20px 1px #5555e9;
          }
          p{
            color:#5555e9
          }
        } 
        .dy-p5-item:nth-child(3){
          .dy-p5-item-img{
            box-shadow: 0 0 20px 1px #d15ea2;
          }
          p{
            color:#d15ea2
          }
        } 
        .dy-p5-item:nth-child(4){
          .dy-p5-item-img{
            box-shadow: 0 0 20px 1px #d78739;
          }
          p{
            color:#d78739
          }
        } 
        .dy-p5-item:nth-child(5){
          .dy-p5-item-img{
            box-shadow: 0 0 20px 1px #7bb234;
          }
          p{
            color:#7bb234
          }
        } 
    }
  }
   .dy-p6-inner {

    .process-box {
        padding: 74px 0;
        .dy-p6-item{
          display: flex;
          justify-content: space-between;
          img{
            box-shadow: 7px -7px 0 0 #afcbff;
          }
        } 
    }
  }
  
  /*按钮公共*/
  .btn-box {
    margin-top: 75px;
    .b1 ,.b2{
      display: inline-block;
      width: 160px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border-radius: 23px;
      cursor: pointer;
      margin-right: 40px;
    }
  }

  /*p3*/
  .dy-p5 {
    background-color: #f8f8f8;
  }
  /*p4*/

  // .dy-p6 {
  //   .p-tit {
  //     color: #fff;
  //     &:before {
  //       display: none;

  //     }
  //     &:after {
  //       display: none;

  //     }
  //     span {
  //       display: inline-block;
  //       margin-right: 20px;
  //     }
  //     span:last-child {
  //       margin-right: 0;
  //     }
  //   }
  //   .p-tit-box {
  //     p {
  //       font-size: 18px;
  //       color: #fff;
  //     }
  //   }
  // }
  .dy-p6-content {
    padding-top: 75px;
    .num-box {
      display: flex;
      justify-content: center;
      .dbx-bg {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 208px;
        margin-right: 20px;
        background: url("../../public/img/index/dbx_white.png") no-repeat center/cover;
        user-select: none;
        .dbx-words {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 2;
          p {
            text-align: center;
          }
        }
        .hover-shadow {
          width: 200px;
          height: 232px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background: url("../../public/img/index/dbx_blue.png") no-repeat center/cover;
          opacity: 0;
          transition: all .5s ease;
          z-index: 1;
        }
        h1,p,span,i {
          transition: all .3s ease;
        }
        h1 {
          margin-bottom: 5px;
          text-align: center;
          font-size: 14px;
          span {
            color: @bgBlue;
            font-size: 36px;
          }
          i {
            color: @bgBlue;
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
          color: #333;
          white-space: nowrap;
        }
        &:hover {
          .hover-shadow {

            opacity: 1;
          }

          h1, p,span,i {
            color: #fff;
          }
        }
      }
        .hovered {
            .hover-shadow {
                opacity: 1;
            }
            h1, p,span,i {
                color: #fff!important;
            }
        }
    }
    .num4-box,.num3-box,.num2-box {
      .dbx-bg:last-child {
        margin-right: 0;
      }
    }
    .num3-box,.num2-box {
      margin-top: -35px;
    }
  }
  .dy-p6-content-wap {
    display: none;
  }
//   /*p5 成功案例*/
//  .index-p5 {
//    height:720px;
//  }
//   .index-p5-inner {
//     width: 1225px;
//     .case-box {
//       display: flex;
//       flex-wrap: wrap;
//       padding-top: 30px;
//       .case-item {
//         position: relative;
//         display: inline-block;
//         width: 245px;
//         height: 98px;
//         border-right: 1px solid #f2f2f2;
//         border-bottom: 1px solid #f2f2f2;
//         text-align: center;
//         cursor: pointer;
//         /*overflow: hidden;*/
//         transition: all .2s linear;
//         img {
//           display: inline-block;
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
//       }
//       .case-item:hover {
//         transform: translateY(-1px);
//         box-shadow: 0px 0px 10px rgba(0,0,0,.12);
//       }
//       .case-item:nth-child(5n) {
//         border-right: 0;
//       }
//       .case-item:nth-last-child(1),.case-item:nth-last-child(2),.case-item:nth-last-child(3),.case-item:nth-last-child(4),.case-item:nth-last-child(5) {
//         border-bottom: 0;
//       }

//       .case-item:nth-child(1),.case-item:nth-child(6),.case-item:nth-child(11),.case-item:nth-child(16) {
//         &:before {
//           content:'';
//           position: absolute;
//           left: -60px;
//           bottom: 0;
//           display: block;
//           width: 60px;
//           height: 1px;
//           background-color: #f2f2f2;

//         }
//       }
//       .case-item:nth-child(5),.case-item:nth-child(10),.case-item:nth-child(15),.case-item:nth-child(20) {
//         &:before {
//           content:'';
//           position: absolute;
//           right: -60px;
//           bottom: 0;
//           display: block;
//           width: 60px;
//           height: 1px;
//           background-color: #f2f2f2;

//         }
//       }
//       .case-item:nth-last-child(2),.case-item:nth-last-child(3),.case-item:nth-last-child(4),.case-item:nth-last-child(5) {
//         &:after {
//           content:'';
//           position: absolute;
//           right: -1px;
//           bottom: -60px;
//           display: block;
//           width: 1px;
//           height: 60px;
//           background-color: #f2f2f2;

//         }
//       }
//     }
//   }

  @import "../assets/css/wap.css";

  @media all and (max-width:750px) {
    
    .index-p5-inner .case-box .case-item:nth-last-child(2){
      border-bottom: 1px solid #f2f2f2;
    }
    .douyin {
      min-width: auto;
      .little-words {
        font-size: .25rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        &>div {
          margin: 0 auto .2rem;
          width:1rem;
        }
      }
      .banner-inner {
        width: 7.5rem;
        .free-try-btn {
          margin-top: .3rem;
          font-size: .25rem;
        }
      }
    }

    .free-try-btn {
      width: 2.2rem;
      height: .68rem;
      line-height: .68rem;
      margin: auto;
    }
    .douyin .banner-box {
      height:100vh;
    }

    
    .banner-imgs {
      right: 50%;
      transform: translateX(50%);
      &:hover {
        transform: scale(1) translateX(50%);
      }
    }
    .douyin {

      .banner-imgs {
        top: 2.5rem;
        img {
          width: 4.7rem;
          height: auto;
        }
      }
      .banner-words-box {
        margin-top: 7.4rem;
      }
    }

    .douyin .banner-words-box {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      h2 {
        font-size: .35rem;
      }
      h1 {
        font-size: .4rem;
        margin-bottom: .2rem;
      }
      h3 {
        font-size: .25rem;
        margin-bottom: .5rem;
      }

    }
    .banner-words-box .shebei-box > div {
      margin-right: .8rem;
      background-size: cover;
      padding-left: .4rem;
      font-size: .26rem;
    }
    .banner-words-box .shebei-box {
      &>div {
        background-image: none;
        position: relative;
        line-height: 1.3;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          background-image: url(../../public/img/index/index_icons1.png);
          background-repeat: no-repeat;
          background-size: 2.955rem 0.27rem;
        }
      }
      .app-icons-app {
        left: .1rem;
        &:after {
          width: .18rem;
          height: .27rem;
          background-position: 0 0;
        }

      }
      .app-icons-pc {
        margin-right: .6rem;
        &:after {
          width: .24rem;
          height: .225rem;
          background-position: -1.42rem 0;
        }

      }
      .app-icons-xcx {
        &:after {
          width: .3rem;
          height: .24rem;
          background-position: -2.65rem -.02rem;
        }

        margin-right: 0;
      }

    }





    .dy-p2, .dy-p4, .index-zyMarket {
      height: auto;
    }



    /* sce-p1 */
    .dy-p1-inner {
      width: 7.5rem;
      height: auto;
      .process-box ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
      .process-box {
        margin-bottom: 0;
        .dy-p1-item {
          margin:0 0 1rem;
          p{
            font-size: 0.3rem;
            padding: 0.2rem 0 0.3rem !important;
          }
        }
      }
      
      h1 {
        font-size: .45rem;
      }
      h2,h3 {
        font-size: .3rem;
        margin-top: .3rem;
      }
      h3 {
        font-size: .26rem;
        padding: 0 .25rem;
      }
    }

    /*  dy-p2*/
    .dy-p2-inner{
      .process-box{
        padding-top: 1rem;
        ul{
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          height: auto;
          &::before{
            display: none;
          }
        }
        .dy-p2-item {
          display: block;
          width: 100%;
          height: 6rem;
          margin-top: 0 !important;
          .dy-p2-icons{
            display: block;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            position: relative;
            .circle{
              margin: auto;
            }
            .word{
              width: 4.5rem;
              height: 4.9rem;
              border-radius: 80%;
              position: absolute;
              top: 0.7rem;
              left: calc(50% - 2.25rem);
            }
            p{
              margin-top: 0;
              font-size: 0.26rem;
            }
          } 
        }
        .imgs-p2{
          h3{
            font-size: 0.29rem;
            margin-bottom: 0.5rem;
          }
          >div{
            &::after{
              display: none;
            }
            div {
              top: unset !important;
              img{
                box-shadow: none !important;
              }
              p{
                margin: 0.3rem 0 0.5rem !important;
              }
            }
          }
        }
      } 
    }
    
    
    /*  dy-p3*/
    .dy-p3{
      .center-box{
        padding-bottom: 0;
      }
      .dy-p3-inner .process-box {
        ul{
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          &::before{
            display: none;
          }
          .dy-p3-item{
            margin-bottom: 20px;
            width: 100%;
            margin-top: 0 !important;
            .dy-p3-icons .scheme{
              width: 7rem;
              img{
                width: 98%;
              }
            }

          }
        }
        .dy-p3-box{
          margin-top: 1rem;
          h3{
            font-size: 0.29rem;
            margin-bottom: 0.5rem;
          }
          >div{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            ul{
              li{
                width: 100% !important;
                padding-left: 0.2rem;
                padding-right: 0.2rem;
                margin-bottom: 1rem;
              }
            }
            ul:last-child{
              margin-top: 1rem;
            }
            >div{
              flex-direction: row;
              .linear{
                width: 2.8rem;
                height: 1px;
              }
            }
          }
        }
        
      }
    }
    /*  sce-p4*/
    .dy-p4{
      .dy-p4-inner .process-box{
        padding-top: .7rem;
        ul{
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          height: auto;
          &::before{
            display: none;
          }
        }
        .dy-p4-item {
          display: block;
          width: 100%;
          height: 6rem;
          margin-top: 0 !important;
          .dy-p4-icons{
            display: block;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            position: relative;
            .circle{
              margin: auto;
            }
            .word{
              width: 4.5rem;
              height: 4.9rem;
              border-radius: 80%;
              position: absolute;
              top: 0.7rem;
              left: calc(50% - 2.25rem);
            }
            p{
              margin-top: 0;
              font-size: 0.26rem;
            }
          } 
        }
        .imgs-p2{
          h3{
            font-size: 0.29rem;
            margin-bottom: 0.5rem;
          }
          >div{
            &::before{
              display: none;
            }
            div {
              top: unset !important;
              img{
                box-shadow: none !important;
              }
              p{
                display: block;
                margin: auto;
              }
            }
          }
        }
      }
      
    }

    .dy-p5{
      .dy-p5-inner .process-box{
        padding-top: .7rem;
        ul{
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          height: auto;
          margin-bottom: .5rem;
        }
        .dy-p5-item {
          margin-bottom: 1rem;
          >div{
            margin-top: 0.3rem;
          }
        }
      }
    }

    .dy-p6{
      .dy-p6-inner .process-box{
        .dy-p6-item {
          flex-direction: column;
          img{
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    /*  dy-p5*/
    .dy-p5-inner .process-box .left-nav ul{
      width: 160px;
    }

    /*  dy-p2*/
    .center-box {
      width: 7.5rem;
      padding-bottom: 1rem;
    }

    /*大标题*/
    .p-tit-box {
      padding-top: .9rem;
      .p-tit {
        font-size: .4rem;
        .divider {
          top: .7rem;
          width: 2.5rem;
        }
      }
      p {
        font-size: .26rem;
        padding: 0 .2rem;
      }
    }
    .p-tit-box .p-tit:before {
      left: -1.2rem;
      width: 1rem;
      height: .12rem;
    }
    .p-tit-box .p-tit:after {
      right: -1.2rem;
      width: 1rem;
      height: .12rem;
    }

    .dy-p2-inner .p-tit-box p, .dy-p4-inner .p-tit-box p, .index-zyMarket-inner .p-tit-box p {
      margin-top: .4rem;
      line-height: 2;
    }
    .dy-p2-inner .content-box .right,.dy-p4-inner .content-box .right {
      right: 50%;
      transform: translateX(50%);
      top: .5rem;
    }
    .dy-p4-inner .content-box {
      margin-top: .5rem;
    }
    .index-p-img {
      img {
        width: 6rem;
      }
    }
    .dy-p2-inner .list {
      width: 7.5rem;
      padding: 6.5rem 0.6rem 0;
    }
    .dy-p4-inner .list  {
      width: 7.5rem;
      padding: 7rem 0.6rem 0;
    }
    .dy-p2-inner .list > li, .dy-p4-inner .list > li, .index-zyMarket-inner .list > li {
      white-space: nowrap;
    }
    .dy-p2-inner .icon-wrap, .dy-p4-inner .icon-wrap, .index-zyMarket-inner .icon-wrap {
      width: .5rem;
      height: .5rem;
      margin-right: .2rem;
    }
    .dy-p2-inner .icon-wrap .infront-box, .dy-p4-inner .icon-wrap .infront-box, .index-zyMarket-inner .icon-wrap .infront-box {
      width: .5rem;
      height: .5rem;
      line-height: .5rem;
      right: .1rem;
    }
    .dy-p2-inner .words, .dy-p4-inner .words, .index-zyMarket-inner .words {
      width: 85%;
    }
    .dy-p2-inner .icon-wrap, .dy-p4-inner .icon-wrap, .index-zyMarket-inner .icon-wrap, .dy-p2-inner .words, .dy-p4-inner .words, .index-zyMarket-inner .words {
      vertical-align: middle;
    }
    .dy-p2-inner .words  ,.dy-p4-inner .words , .index-zyMarket-inner .words {
      h2 {
        font-size: .3rem;
      }
      p {
        width: 100%;
        font-size: .26rem;
        white-space: normal;
        text-align: justify;
        color: #333;
      }
    }
    .btn-box {
      display: flex;
      justify-content: center;
      margin: 1rem auto 0;
    }
    .btn-box .b1, .btn-box .b2 {
      width: 2.2rem;
      height: .6rem;
      line-height: .6rem;
      font-size: .26rem;
    }
    .btn-box .b2 {
      margin-right: 0;
    }

  /*  资源市场*/
    .dy-p2-inner .content-box .left, .dy-p4-inner .content-box .left, .index-zyMarket-inner .content-box .left {
      float: none;
    }
    .index-zyMarket-inner .content-box {
      margin-top: 1rem;
    }

    .index-zyMarket {
      .index-p-img {
        text-align: center;
      }
    }
    .index-zyMarket-inner .list {
      width: 7.5rem;
      padding: .5rem 0.6rem 0;
    }
    .index-zyMarket-inner .right {
      position: relative;
      right: auto;
    }
  /*.dy-p6*/
    .dy-p6 {
      height: auto;
    }
    .dy-p6 .p-tit-box p {
      font-size: .26rem;
    }

    .dy-p6-content-wap {
      display: block;
      padding-top: .5rem;
      .num-box {
        display: flex;
        justify-content: center;
        .dbx-bg {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2.8rem;
          height: 2.9rem;
          margin-right: .2rem;
          background: url("../../public/img/index/dbx_white.png") no-repeat center/contain;
          user-select: none;
          .dbx-words {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            z-index: 2;
            p {
              text-align: center;
            }
          }
          .hover-shadow {
            width: 2.5rem;
            height: 2.86rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background: url("../../public/img/index/dbx_blue.png") no-repeat center/contain;
            opacity: 0;
            transition: all .5s ease;
            z-index: 1;
          }
          h1,p,span,i {
            transition: all .3s ease;
          }
          h1 {
            margin-bottom: 5px;
            text-align: center;
            font-size: 14px;
            span {
              color: @bgBlue;
              font-size: .5rem;
            }
            i {
              color: @bgBlue;
              font-size: 12px;
            }
          }
          p {
            font-size:12px;
            color: #333;
            white-space: normal;
          }
          &:hover {
            .hover-shadow {

              opacity: 1;
            }

            h1, p,span,i {
              color: #fff;
            }
          }
        }
        .dbx-bg:nth-child(3n) {
          margin-right: 0;
        }
        .hovered {
          .hover-shadow {
            opacity: 1;
          }
          h1, p,span,i {
            color: #fff!important;
          }
        }
      }
    }

    .dy-p6-content {
      display: none;

    }


  // .case8

  /*.index-p5*/
    .index-p5 {
      height: auto;
    }
    .index-p5-inner .case-box {
      padding-top: 1rem;
      padding-left: .1rem;
    }
    .index-p5-inner .case-box .case-item {
      /*width: 2.3rem;*/
      width: 33.29%;
      height: .97rem;
    }

    .index-p5-inner .case-box .case-item:nth-child(5n) {
      border-right: 1px solid #f2f2f2;
    }
    .index-p5-inner .case-box .case-item:nth-child(3n) {
      border-right: 0;
    }
    .index-p5-inner .case-box .case-item:nth-last-child(3), .index-p5-inner .case-box .case-item:nth-last-child(4), .index-p5-inner .case-box .case-item:nth-last-child(5) {
      border-bottom: 1px solid #f2f2f2;
    }
    .index-p5-inner .case-box .case-item:nth-child(1):before, .index-p5-inner .case-box .case-item:nth-child(6):before, .index-p5-inner .case-box .case-item:nth-child(11):before {

        display: none;


    }
    .index-p5-inner .case-box .case-item:nth-child(5):before, .index-p5-inner .case-box .case-item:nth-child(10):before, .index-p5-inner .case-box .case-item:nth-child(15):before {

        display: none;


    }
    .index-p5-inner .case-box .case-item:nth-last-child(2):after, .index-p5-inner .case-box .case-item:nth-last-child(3):after, .index-p5-inner .case-box .case-item:nth-last-child(4):after, .index-p5-inner .case-box .case-item:nth-last-child(5):after {

        display: none;

    }
    .mobileNoShow{
      display: none !important;
    }
  } // media end

  @media all and (max-width:376px) {
    .index-p1-inner .circle1 {
      top: 1.4rem;
    }
    .index-p1-inner .circle2 {
      top: 1.7rem;
    }
  }


</style>

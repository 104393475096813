<template>
  <div class="about">
    <div id="banner" class="banner-box">
      <div class="banner-inner clearFloat">
         <div class="about-banner-box">
           <img src="../../public/img/about/about_banner_img1.png" alt="" class="about-banner-img a-img1 lazyload"  draggable="false">
           <img src="../../public/img/about/about_banner_img2.png" alt="" class="about-banner-img a-img2 lazyload" draggable="false" >
         </div>
        <div class="wap-about-banner-box">
          <img src="../../public/img/about/about_banner_img_wap.png" alt="" class="lazyload"  >
        </div>
      </div>
    </div>

     <div class="about-content">
       <div class="about-p1">
         <div class="center-box about-p1-inner">
            <div class="about-p1-bg"></div>
           <div class="about-p1-words">
               美匣科技专为旅游商家提供营销管理软件系统解决方案。为旅行社/景区景点/酒店民宿提供软件系统服务。公司自主研发的“美匣云”系统，集旅行社ERP管理系统、抖音/微信小程序商城、景区票务系统、酒店民宿会员制商城、SCRM客户管理系统、旅游资源分销市场于一体的综合性旅游SaaS系统平台。全方位解决了前端营销收客、后端业务管理和协同办公等问题。适用于各类型的旅游商家，并可为商家提供针对性的成套系统解决方案。
           </div>
         </div>
       </div>

       <!--企业文化-->
       <div class="about-p2">
         <div class="center-box about-p2-inner">
            <h1 class="about-p-tit">企业文化</h1>

             <div class="calture-item-box1">
               <h1>经营理念 ——— <span>坚持只做平台工具，不做业务经营的底线</span></h1>
             </div>
             <div class="calture-item-box2 clearFloat">
               <div class="calture-item-box2-words">
                 <h1>愿景<span></span></h1>
                 <span>一起发现工作的美好</span>
               </div>
               <div class="calture-item-box2-words">
                 <h1>使命<span></span></h1>
                 <span>永远提供适宜未来趋势的产品，助力旅行社持续变革，推动旅游行业持续升级</span>
               </div>
               <div class="calture-item-box2-words">
                 <h1>核心价值观<span></span></h1>
                 <span>立志、磨心、有所成</span>
               </div>
           </div>
         </div>



       </div>
       
       <!--发展历程-->

       <div class="about-p3">
         <div class="center-box about-p3-inner">
           <h1 class="about-p-tit">发展历程</h1>

           <ul class="year-list">
             <li class="year-item">
               <h1 class="year-tit">2021</h1>
               <ul class="events-list">
                 <li class="events-item events1">
                   <h2 class="month"><span>1</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣云推出成熟的会员制商城解决方案，适用于酒店，民宿，旅游等行业</p>
                   </div>
                 </li>
               </ul>
             </li>
             <li class="year-item">
               <h1 class="year-tit">2020</h1>
               <ul class="events-list">
                 <li class="events-item events11">
                   <h2 class="month"><span>11</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣云TPS5合作客户达到500家</p>
                   </div>
                 </li>

                 <li class="events-item events7">
                   <h2 class="month"><span>7</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣云微商城小程序正式上线，众多老客户和新客户陆续开通美匣云微商城小程序</p>
                   </div>
                 </li>

                 <li class="events-item events6">
                    <h2 class="month"><span>6</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>B2B同行分销平台——美匣云资源市场正式上线，并开始招募有优质资源的旅行社入驻</p>

                   </div>
                 </li>
                 <li class="events-item events5">
                    <h2 class="month"><span>5</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣云TPS5第一版正式上线，同步上线移动端“美匣云APP”，并推出ERP系统</p>

                   </div>
                 </li>
                 <li class="events-item events2">
                    <h2 class="month"><span>2</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>正式启动研发全新的以移动化办公为主的美匣云SaaS系统，内部代号：美匣云TPS5</p>

                   </div>
                 </li>
               </ul>
             </li>
             <li class="year-item">
               <h1 class="year-tit">2019</h1>
               <ul class="events-list">
                 <li class="events-item events10">
                   <h2 class="month"><span>10</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣科技完成A轮融资</p>

                   </div>
                 </li>
                 <li class="events-item events8">
                   <h2 class="month"><span>8</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣云正式客户过百家</p>
                   </div>
                 </li>
                 <li class="events-item events5">
                   <h2 class="month"><span>5</span>月</h2>
                   <i class="fangxing"></i>
                   <div class="events-words">
                     <p>美匣云全面升级，tps4.0上线</p>
                   </div>
                 </li>
                   <li class="events-item events4">
                       <h2 class="month"><span>4</span>月</h2>
                       <i class="fangxing"></i>
                       <div class="events-words">
                           <p>美匣云地接社解决方案发布</p>
                       </div>
                   </li>
                   <li class="events-item events3">
                       <h2 class="month"><span>3</span>月</h2>
                       <i class="fangxing"></i>
                       <div class="events-words">
                           <p>美匣科技完成天使轮融资</p>
                       </div>
                   </li>
               </ul>
             </li>
               <li class="year-item">
                   <h1 class="year-tit">2018</h1>
                   <ul class="events-list">
                       <li class="events-item events11">
                           <h2 class="month"><span>11</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p> 美匣云批发商解决方案发布</p>

                           </div>
                       </li>
                       <li class="events-item events9">
                           <h2 class="month"><span>9</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p> tps3.0上线，美匣云电商解决方案发布</p>
                           </div>
                       </li>
                       <li class="events-item events7">
                           <h2 class="month"><span>7</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p>美匣云入台证解决方案发布</p>
                           </div>
                       </li>
                       <li class="events-item events6">
                           <h2 class="month"><span>6</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p>美匣科技确定深耕度假领域，做一站式ERP，确定美匣云产品版图</p>
                           </div>
                       </li>
                   </ul>
               </li>
               <li class="year-item">
                   <h1 class="year-tit">2017</h1>
                   <ul class="events-list">
                       <li class="events-item events10">
                           <h2 class="month"><span>10</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p>SAAS架构建设完成</p>

                           </div>
                       </li>
                       <li class="events-item events3">
                           <h2 class="month"><span>3</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p> tps2.0发布，这是一个平台化国际化版本</p>
                           </div>
                       </li>


                   </ul>
               </li>
               <li class="year-item">
                   <h1 class="year-tit">2016</h1>
                   <ul class="events-list">
                       <li class="events-item events9">
                           <h2 class="month"><span>9</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p>tps1.0上线，深度对接飞猪</p>
                           </div>
                       </li>
                       <li class="events-item events8">
                           <h2 class="month"><span>5</span>月</h2>
                           <i class="fangxing"></i>
                           <div class="events-words">
                               <p>美匣科技成立，愿景：一起发现工作的美好</p>
                           </div>
                       </li>


                   </ul>
               </li>
           </ul>

         </div>



       </div>

       <!--资质证明-->
       <div class="about-p4">
         <div class="center-box about-p4-inner">
           <h1 class="about-p-tit">资质证明</h1>
            
           <div class="z4">
             <ul class="zz-list">
               <li class="zz-item">
                 <img src="../../public/img/about/zz1.png" alt="">
               </li>
               <li class="zz-item">
                 <img src="../../public/img/about/zz4.png" alt="">
               </li>
               <li class="zz-item">
                 <img src="../../public/img/about/zz2.png" alt="">
               </li>
               <li class="zz-item">
                 <img src="../../public/img/about/zz3.png" alt="">
               </li>
             </ul>
           </div>


         </div>



       </div>


       <!--联系我们-->
       <div class="about-p5">
         <div class="center-box about-p5-inner">
           <h1 class="about-p-tit">联系我们</h1>

          <div class="about-concat clearFloat">
            <ul class="concat-words">
              <li class="concat-item">
                <span class="icons ico-phone"></span>
                <div>
                  <h1>全国服务热线</h1>
                  <p>400-060-7500</p>
                </div>
              </li>
              <li class="concat-item">
                <span class="icons ico-address"></span>
                <div>
                  <h1>上海公司总部</h1>
                  <p>上海市松江区茸兴路407号6幢A202室</p>
                </div>
              </li>
              <li class="concat-item">
                <span class="icons ico-address"></span>
                <div>
                  <h1>重庆分公司</h1>
                  <p>重庆市渝北区食品城大道18号重庆广告产业园15幢3A楼</p>
                </div>
              </li>
              <li class="concat-item">
                <span class="icons ico-address"></span>
                <div>
                  <h1>湖南办事处</h1>
                  <p>湖南省长沙市雨花区人民东路38号东一时区商厦北栋12楼</p>
                </div>
              </li>
              <li class="concat-item">
                <span class="icons ico-address"></span>
                <div>
                  <h1>湖北办事处</h1>
                  <p>湖北省武汉市江汉区解放大道686号世贸大厦3303</p>
                </div>
              </li>
            </ul>
            <div class="map-box">
              <div class="amap-page-container">
                <el-amap vid="amapDemo"
                         :zoom="zoom"
                         :center="center"
                         :events="events"
                         class="amap-demo" >
                  <el-amap-marker v-for="(marker, index) in markers"
                                  :key="index"
                                  :position="marker.center"
                                  :visible="marker.visible"
                                  :draggable="marker.draggable"
                                  :vid="marker.index"
                                  :events="marker.events">

                  </el-amap-marker>
                  <el-amap-circle vid="circle"
                                  :center="center"
                                  :radius="radius"
                                  fill-opacity="0.2"
                                  stroke-color="#38f"
                                  stroke-opacity="0.8"
                                  stroke-weight="1"
                                  fill-color="#38f">
                  </el-amap-circle>
                </el-amap>
              </div>
            </div>
          </div>

         </div>



       </div>





     </div>

    <foot-box></foot-box>
    <pop-window></pop-window>
  </div>
</template>

<script>

    import FootBox from '@/components/Footer.vue';
    import PopWindow from '@/components/PopWindow.vue'

    let vm = null;
    export default {
        name: "About",
        components: {
            FootBox,
            PopWindow,
        },
        data(){
            return {
                current: 5,  //模块切换标识
                count: 1,
                zoom: 17, //地图缩放
                center: [106.60880953073502, 29.648039981554714],  //地图中心点
                events: {
                    click(e) {

                    }
                }, //地图点击事件
                markers: [
                    {
                        center: [106.60872370004654, 29.648273086833395],
                        visible: true,
                        draggable: false,
                        template: '<span></span>',
                        events: {
                            click(e) {

                            }
                        }
                    },
                ],  //地图点坐标
                radius: 190, //地图圆半径


            }
        },
        methods: {
            _move() {
                if($('.about').length > 0) {
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离

                    let $item = $('.events-list .events-item')
                    $item.each(function (index,item) {

                        if (($winHeight + $winScrollHeight) >= $(item).offset().top) {

                            $(item).addClass('animated fadeInUp')
                            $(item).css({
                                "animation-delay": .1 * index  + "s"
                            })


                        }
                    })

                }
            },


        },
        mounted() {
            vm = this


            //  p1
            let canRun = true;
            let timer;
            vm._move()
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._move()
                    canRun = true
                }, 300)

            });



        }
    }
</script>

<style lang="less">
  @bgBlue: #3b7ffc;
  /*公共s*/
  .about-p-tit {
    position: relative;
    font-size: 28px;
    color: #333;
    padding-top: 60px;
    padding-bottom: 15px;
    margin-bottom: 45px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 111px;
      height: 2px;
      background-image: linear-gradient(96deg,
      #49a1de 0%,
      #57b2ef 0%,
      #65c3ff 0%,
      #7290fd 67%,
      #7f5cfa 100%),
      linear-gradient(
              #3b7ffc,
              #3b7ffc);
    }
  }
  
  /*公共e*/

  /*地图*/
  .amap-page-container {
    width: 695px;
    height: 436px;
    position: relative;
  }

  .wap-about-banner-box {
    display: none;
  }
  .about-banner-box {
    display: block;
  }
  .about {
    min-width: 1200px;
      width: 100%;
    .banner-box {
      position: relative;
      width: 100%;
      height: 600px;
      background-image: url("../../public/img/about/about_banner_bg.png");
      background-size: cover;
      .about-banner-img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
      }
      .a-img1 {
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
      }
      .a-img2 {
        z-index: 1;
        transition: all 1s linear;
        &:hover {
          transform:  translate(-50%,0) scale(1.02);
        }
      }
    } 
  }
  /*p1*/
  .about-p1 {
    height: 400px;
  }
  .about-p1-inner {
    position: relative;
    .about-p1-bg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 400px;
      height: 241px;
      background: url("../../public/img/about/about_p1_bg.png") no-repeat center/contain;
    }
    .about-p1-words {
      padding-top: 200px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #333;
    }

  }
  
  /*p2 企业文化*/
  .about-p2 {
    background-color: #f8f8f8;
    height: 435px;
  }
  .about-p2-inner {
    .about-p-tit {
      &:after {
        display: none;
      }
    }
    .calture-item-box1 {
      position: relative;
      width: 600px;
      margin-top: 70px;
      margin-left: 26px;
      margin-bottom: 80px;
      line-height: 50px;
      text-align: center;
      border: solid 2px @bgBlue;
      h1 {
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }
      span {
        font-size: 14px;
        font-weight: normal;
      }
      &:after {
        content: '';
        position: absolute;
        top: -15px;
        left: -15px;
        display: block;
        width: 32px;
        height: 32px;
        background-color: @bgBlue;
      }
    }
    .calture-item-box2 {
      padding-left: 45px;
      .calture-item-box2-words {
        float: left;
        margin-right: 120px;
        h1 {
          position: relative;
          font-weight: bold;
          margin-bottom: 15px;
          font-size: 16px;
          span {
            position: absolute;
            top: 4px;
            left: -30px;
            display: block;
            width: 14px;
            height: 14px;
            background-color: rgba(141,185,250,.4);
            font-size: 14px;
            &:before {
              content: '';
              position: absolute;
              top: -3px;
              left: -3px;
              display: block;
              width: 14px;
              height: 14px;
              background-color: @bgBlue
            }
          }
        }
      }
      .calture-item-box2-words:last-child {
        margin-right: 0;
      }
    }
  }

  /*p3 发展历程*/
  .about-p3 {
    /*height: 1140px;*/
  }
  .about-p3-inner {
    overflow: hidden;
      .year-list {
        .year-item {
          .year-tit {
            display: inline-block;
            position: relative;
            font-size: 36px;
            color: transparent;
            background: linear-gradient(to right, #68bdff 50%, #7e60fa 100%);
            background-clip: text;
            -webkit-background-clip: text;
            user-select: none;
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 100px;
              display: block;
              width: 1500px;
              height: 1px;
              background-color: #ccc;
            }
          }
          .events-list {
            padding-top: 55px;
            padding-left: 131px;

            .events-item {
              opacity: 0;
              position: relative;
              width: 950px;
              margin-bottom: 80px;
              &:after {
                content: '';

                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                  font-size: 24px;
                  font-weight: bold;
                  color: #3b7ffc;
                  opacity: .1;
              }

              .month, .fangxing, .events-words {
                display: inline-block;
                vertical-align: middle;
              }

              .month {
                width: 53px;
                text-align: right;
                font-size: 16px;
                color: #333;
                margin-right: 46px;
                span {
                  display: inline-block;
                  margin-right: 5px;
                  font-size: 24px;
                  color: @bgBlue;
                }
              }

              .fangxing {
                position: relative;
                width: 14px;
                height: 14px;
                background: url("../../public/img/about/about_fangk.png") no-repeat center/cover;
                &:before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -40px;
                  display: block;
                  width: 40px;
                  height: 1px;
                  background-color: #c4d8fe;
                }
                &:after {
                  content: '';
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: -111px;
                  display: block;
                  width: 1px;
                  height: 112px;
                  background-color: #ccc;
                }
              }

              .events-words {
                position: relative;
                margin-left: 30px;


                p {

                  font-size: 14px;
                  color: #333;
                }
                p:not(:first-child) {
                  margin-top: 5px;
                }
              }
            }
            .events-item:last-child {
              .fangxing {
                &:after {
                  display: none;
                }
              }
            }
            .events12 {
                  &:after {
                      content: 'Dec.';
                  }

              }
            .events11 {
                &:after {
                    content: 'Nov';

              }
            }
            .events10 {
                  &:after {
                      content: 'Oct.';
                  }

              }
            .events9 {
                  &:after {
                      content: 'Sep.';
                  }

              }
            .events8 {
                  &:after {
                      content: 'Aug.';
                  }

              }
            .events8 {
                &:after {
                    content: 'Aug.';
                }

            }
            .events7 {
                  &:after {
                      content: 'Jul.';

                  }
              }
            .events6 {
                  &:after {
                      content: 'Jun.';

                  }
              }
            .events5 {
                  &:after {
                      content: 'May.';

                  }
              }
            .events4 {
                &:after {
                    content: 'Apr.';

                }
            }
            .events3 {

                &:after {
                    content: 'Mar.';

                }

            }
            .events2 {
                  &:after {
                      content: 'Jan.';

                  }

              }
            .events1 {
                &:after {
                    content: 'Mon.';

                }

            }
          }
        }
      }
  }


  /*p4 资质证明*/
  .about-p4 {

    background-color: #f8f8f8;
    .zz-list {
      display: flex;
      padding-bottom: 100px;
      .zz-item {
        // width: 360px;
        width: 290px;
        // height: 370px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
      }
      .zz-item:last-child {
        margin-right: 0;
      }
    }
  }

  /*p5 联系我们*/
  .about-p5-inner {
    padding-bottom: 72px;
    .about-concat {
      .concat-words, .map-box {
        float: left;
      }
      .concat-words {
        margin-right: 60px;
        .concat-item:first-child>div {
            margin-left: 20px;
        }
        .concat-item {
          width: 420px;
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #e6e6e6;
          overflow: hidden;
          .icons {
            display: inline-block;
            background-image: url("../../public/img/about/about_icons.png");
            background-repeat: no-repeat;
            background-size: 201px 36px;
          }
          .ico-address {
            width: 28px;
            height: 36px;
            background-position: 0 0;
          }
          .ico-phone {
             width: 35px;
            height: 36px;
            background-position: -51px 0;
          }
          .ico-email {
             width: 36px;
            height: 28px;
            background-position: -109px -4px;
          }
          .ico-qq {
             width: 33px;
            height: 36px;
            background-position: -167px 0;
          }
          &>div {
            display: inline-block;
            margin-left: 25px;
            h1 {
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              margin-bottom: 5px;
            }
            p {
              font-size: 14px;
              line-height: 24px;
              color: #333;
            }

          }
        }
          // .concat-item:nth-child(1),.concat-item:nth-child(2) {
          //    &>div {
          //        margin-left: 33px;
          //    }
          // }
        .concat-item:last-child {
          border-bottom: 0;
        }
      }
      .map-box {
        width: 695px;
        height: 436px;

      }
    }


  }

  @media all and (max-width: 1350px) {
      .header-center-box .head-logo {
          margin-left: 0;
          margin-right: 20px;
      }
      .header-center-box .head-nav-list {
          margin-right: 30px;
      }
  }
  @media all and (max-width: 750px) {
    .wap-about-banner-box {
      display: block;
      background-color: #061b41;
      img {
        margin-top: 1rem;
        width: 100%;
      }
    }
    .about-banner-box {
      display: none;
    }
    .about {
          min-width: auto;
          .banner-inner {
              width: 7.5rem;
          }
    }
    .about .banner-box {
      /*height: 10.5rem;*/
      height: 100vh;
    }
    .about .banner-box .a-img1 {
      width: 2rem;
    }
    .about .banner-box .a-img2 {
      width: 100%;
      margin-top: 2.7rem;
    }


    /*.about-p1*/
    .about-p1 ,.about-p2{
      height: auto;
    }
    .about-p1-inner .about-p1-bg {
      width: 100%;
      height: 2.82rem;
    }

    .about-p1-inner .about-p1-words {
      padding-top: 3rem;
      line-height: 2;
      font-size: .26rem;
    }

    /*about-p2*/
    .about-p-tit {
      font-size: .35rem;
      padding-top: 1rem;
      padding-bottom: .3rem;
      margin-bottom: 0;
    }
    .about-p2-inner .calture-item-box1 h1 {
      text-align: left;
      font-size: .3rem;
    }
    .about-p2-inner .calture-item-box1 {
      width: auto;
      margin-top: 1rem;
      margin-left: .2rem;
      margin-bottom: 1rem;
      padding-left: .5rem;
      line-height: 2.5;
    }
    .about-p2-inner .calture-item-box2 .calture-item-box2-words {
      margin-right: .24rem;
      margin-bottom: .5rem;
      line-height: 2;
    }
    .about-p2-inner .calture-item-box2 .calture-item-box2-words h1 span {
      top: 0.18rem;
      left: -.6rem;
      width: .28rem;
      height: .28rem;
      font-size: .26rem;
    }
    .about-p2-inner .calture-item-box2 .calture-item-box2-words h1 span:before {
      top: -.06rem;
      left: -.06rem;
      width: .28rem;
      height: .28rem;
    }
    .about-p2-inner .calture-item-box2 .calture-item-box2-words h1 {
      font-size: .3rem;
    }
    .about-p2-inner .calture-item-box2 .calture-item-box2-words>span {
        font-size: .26rem;
     }

    /*about-p3*/
    .about-p-tit:after {
      width: 1.5rem;
    }
    .about-p3 .year-list {
      margin-top: 1rem;
    }
    .about-p3-inner .year-list .year-item .year-tit:after {
      width: 5rem;
    }
    .about-p3-inner .year-list .year-item .events-list {
      padding-top: 1rem;
      padding-left: 0;
    }
    .about-p3-inner .year-list .year-item .events-list .events-item {
      width: 100%;height: .9rem;
      margin-bottom: 1.6rem;
    }
    .about-p3-inner .year-list .year-item .events-list .events-item .events-words {
      margin-left: .2rem;
    }

    .about-p3-inner .year-list .year-item:last-child .events-list .events-item:last-child {
      margin-bottom: 0;
    }
      .about-p3-inner .year-list .year-item .events-list .events-item .events-words p {
          width: 3rem;

      }
      .about-p3-inner .year-list .year-item .events-list .events-item:after {
          font-size: .5rem;
      }
      .about-p3-inner .year-list .year-item .events-list .events-item .month {
          width: 1.06rem;
          font-size: .28rem;
          margin-right: .95rem;
      }
      .about-p3-inner .year-list .year-item .events-list .events-item .month span {
          margin-right: .1rem;
          font-size: .45rem;
      }
    /*about-p4*/
    .z4 {
      margin-top: 1rem;
    }
    .about-p4 .zz-list{
      flex-wrap: wrap;
      padding-bottom: 0;
      .zz-item {
        width: 50%;
        height: auto;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: .5rem;
        img {
          height: auto;
        }
      }
      .zz-item:last-child {
        margin-bottom: 0;
        margin-right: auto;
      }
    }


    /*about-p5*/
    .about-p5 .about-concat {
      margin-top: 1rem;
    }
    .about-p5-inner .about-concat .concat-words {
      width: 100%;
      margin-right: 0;
    }
    .about-p5-inner .about-concat .concat-words .concat-item {
      width: 100%;
      padding-top: .3rem;
    }
    .about-p5-inner .about-concat .concat-words .concat-item > div {
      width: 5.45rem;
      margin-left: .5rem;
    }
    .about-p5-inner .about-concat .map-box {
      margin-top: 1rem;
      width: 100%;
      height: 8rem;
    }
    .amap-page-container {
      width: 100%;
      height: 9rem;
    }

  } // media end
</style>
<template>
    <div class="visaSystem-erp">
        <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat">
              <div class="banner-words-box">
                <h1>美匣云</h1>
                <h2>旅行社智能签证平台</h2>
                <h3>自动送签丨状态回传丨智能收客丨<span>家庭签</span></h3>
                <div class="little-words">新加坡、泰国、日本、马来西亚等国家更新中·····</div>
                <a href="http://c0k5mn9s9hp8xmzj.mikecrm.com/GnjbOhO" class="free-try-btn" target="_blank">申请试用</a>
              </div>
              <img src="../../public/img/visaSystem/p1/p1_img.png" class="banner-img">
            </div>
        </div>

        <div class="visaSystem-p1">
            <div class="center-box visaSystem-p1-inner">
                <div class="inner-title">
                    <h2>美匣云签证系统</h2>
                    <h3 class="subTitle-line">一站式智能化全自动化服务</h3>
                </div>
                <div class="content-box ">
                    <img src="../../public/img/visaSystem/p2/p2_img.png" class="visaSystem-p1-img">
                </div>
            </div>
        </div>

        <div class="visaSystem-p2">
            <div class="center-box visaSystem-p2-inner">
                <div class="inner-title">
                    <h2>收签社业务全覆盖</h2>
                    <h3 class="subTitle-line">多渠道收客  财务对账一目了然</h3>
                </div>
                <div class="content-box ">
                    <div class="visaSystem-p2-type">
                        <div class="visaSystem-p2-type-item">
                            <img src="../../public/img/visaSystem/p3/p3_img1.png" alt="">
                            <div class="item-content">
                                <p>OTA同步状态</p>
                                <p>可回传办签状态给OTA平台，同步更新</p>
                            </div>
                        </div>
                        <div class="visaSystem-p2-type-item">
                            <img src="../../public/img/visaSystem/p3/p3_img2.png" alt="">
                            <div class="item-content">
                                <p>前端财务对账</p>
                                <p>可统计各个订单财务明细，应有尽有</p>
                            </div>
                        </div>
                        <div class="visaSystem-p2-type-item mr0">
                            <img src="../../public/img/visaSystem/p3/p3_img3.png" alt="">
                            <div class="item-content">
                                <p>非签证订单</p>
                                <p>可处理综合订单（如：线路订单）里的签证</p>
                            </div>
                        </div>
                    </div>
                    <div class="visaSystem-p2-type">
                        <div class="visaSystem-p2-type-item">
                            <img src="../../public/img/visaSystem/p3/p3_img4.png" alt="">
                            <div class="item-content">
                                <p>PC代下单</p>
                                <p>门店/销售 可自行录入线下订单，方便快捷</p>
                            </div>
                        </div>
                        <div class="visaSystem-p2-type-item mr0">
                            <img src="../../public/img/visaSystem/p3/p3_img5.png" alt="">
                            <div class="item-content">
                                <p>商城小程序</p>
                                <p>企业专属小程序微商城，随时随地收客</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="visaSystem-p3">
            <div class="center-box visaSystem-p3-inner">
                <div class="inner-title">
                    <h2>送签社自动送签返回状态</h2>
                    <h3 class="subTitle-line">多账号同时登录操作  省时省力</h3>
                </div>
                <div class="content-box ">
                    <div class="visaSystem-p3-type-item">
                        <img src="../../public/img/visaSystem/p4/p4_img1.png" alt="">
                        <div class="item-content">
                            <p>中文界面</p>
                            <p>直连各国领馆系统后台，<br>中文填写界面，快速填写<br>资料送签</p>
                        </div>
                    </div>
                    <div class="visaSystem-p3-type-item">
                        <img src="../../public/img/visaSystem/p4/p4_img2.png" alt="">
                        <div class="item-content">
                            <p>智能识别</p>
                            <p>OCR识别信息扫描录入，<br>材料一键生成，减少人工<br>误差省时省力</p>
                        </div>
                    </div>
                    <div class="visaSystem-p3-type-item">
                        <img src="../../public/img/visaSystem/p4/p4_img3.png" alt="">
                        <div class="item-content">
                            <p>多账号登录</p>
                            <p>支持多个账号同时登录，<br>不同岗位不同人员同时在<br>线作业</p>
                        </div>
                    </div>
                    <div class="visaSystem-p3-type-item">
                        <img src="../../public/img/visaSystem/p4/p4_img4.png" alt="">
                        <div class="item-content">
                            <p>京东物流</p>
                            <p>无缝对接京东物流，随时<br>查看物流信息，企业更方<br>便更省钱</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="visaSystem-p4">
            <div class="center-box visaSystem-p4-inner">
                <div class="inner-title">
                    <h2>美匣云签证系统服务</h2>
                    <h3 class="subTitle-line">更专业的全自动送签智能化软件</h3>
                </div>
                <div class="content-box ">
                    <div class="visaSystem-p4-item">
                        <img src="../../public/img/visaSystem/p5/p5_img1.png" alt="">
                        <p class="title">资料快捷提交</p>
                        <div class="item-content">
                            <div><span class="short-line"></span><span>可批量发送短信给顾客，客户可通过H5自行上传资料，并随时查看办签状态</span></div>
                            <div><span class="short-line"></span><span>支持OCR扫描，精准识别</span></div>
                        </div>
                    </div>
                    <div class="visaSystem-p4-item">
                        <img src="../../public/img/visaSystem/p5/p5_img2.png" alt="">
                        <p class="title">OTA平台API对接</p>
                        <div class="item-content">
                            <div><span class="short-line"></span><span>OTA订单下行信息同步，不用手动搬单，避免出错</span></div>
                            <div><span class="short-line"></span><span>飞猪、马蜂窝平台订单可同步回传办签状态至平台</span></div>
                        </div>
                    </div>
                    <div class="visaSystem-p4-item">
                        <img src="../../public/img/visaSystem/p5/p5_img3.png" alt="">
                        <p class="title">强大的财务统计</p>
                        <div class="item-content">
                            <div><span class="short-line"></span><span>各大旅行社可自行配置各个收客渠道或者办签标价并统计核算</span></div>
                            <div><span class="short-line"></span><span>可统计各个订单的财务情况，形成系统报表，简单清晰</span></div>
                        </div>
                    </div>
                    <div class="visaSystem-p4-item">
                        <img src="../../public/img/visaSystem/p5/p5_img4.png" alt="">
                        <p class="title">非签证订单处理</p>
                        <div class="item-content">
                            <div><span class="short-line"></span><span>综合订单（如路线）可单独处理签证订单，不用手动分离</span></div>
                            <div><span class="short-line"></span><span>旅行社可自行设置订单是否需要审核，简化流程</span></div>
                        </div>
                    </div>
                    <div class="visaSystem-p4-item">
                        <img src="../../public/img/visaSystem/p5/p5_img5.png" alt="">
                        <p class="title">周边附赠服务</p>
                        <div class="item-content">
                            <div><span class="short-line"></span><span>京东物流：同步京东物流状态</span></div>
                            <div><span class="short-line"></span><span>批量操作：订单状态可批量一键更改</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="visaSystem-p5">
            <div class="visaSystem-p5-inner">
                <div class="inner-title">
                    <h2>关于我们</h2>
                    <h3 class="subTitle-line">ABOUT US</h3>
                </div>
                <div class="content-box">
                    <div class="wordI">
                        上海美匣网络科技有限公司（简称：美匣科技）成立于2016年，<br>
                        是一家专注于旅游行业的软件系统解决方案提供商和服务商。
                    </div>
                    <div class="wordII">公司总部位于上海，主要的技术团队和客户服务团队集中在在重庆办公，在武汉，长沙等城市设有分公司和办事处。公司目前拥有50多人的技术服务团队，已为全国上千家旅游商家提供系统化服务。“美匣云”是一套集旅行社ERP系统、旅游小程序商城、签证系统，于一体的旅游SaaS系统平台，全方位解决旅行社订单管理、产品资源、系统对账等难题。</div>
                    <router-link to="/about" class="btn" target="_blank">点击了解更多</router-link>
                </div>  
            </div>
            <div class="imageBox">
                <div class="imageBoxItemI">
                    <div class="tagItem">
                        <img src="../../public/img/visaSystem/p6/p6_img4.png" alt="">
                        <p>OTA</p>
                    </div>
                </div>
                <div class="imageBoxItemII">
                    <div class="tagItem">
                        <img src="../../public/img/visaSystem/p6/p6_img5.png" alt="">
                        <p>旅行社</p>
                    </div>
                </div>
                <div class="imageBoxItemIII">
                    <div class="tagItem">
                        <img src="../../public/img/visaSystem/p6/p6_img6.png" alt="">
                        <p>送签社</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="visaSystem-p6">
            <div class="visaSystem-p6-inner">
                <div class="content-box">
                    <img src="../../public/img/visaSystem/p7/p7_title.png" alt="">
                    <p class="visaSystem-p6-wordI">全自动化办签系统</p>
                    <p class="visaSystem-p6-wordII">全面提高旅行社送签效率</p>
                    <a href="http://c0k5mn9s9hp8xmzj.mikecrm.com/GnjbOhO" class="btn" target="_blank">欢迎点击试用</a>
                </div>
                <img src="../../public/img/visaSystem/p7/p7_backTop.png" class="backTop" @click="backTop">
            </div>
        </div>

        <!-- <free-try :msg="msg" :btn="btn"></free-try> -->
        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>

<script>
    import FreeTry from '@/components/FreeTry.vue'
    import FootBox from '@/components/Footer.vue'
    import PopWindow from '@/components/PopWindow.vue'
    let vm;
    export default {
        name: "VisaSystem",
        components: {
            FreeTry,
            FootBox,
            PopWindow,
        },
        data(){
            return {
                msg: '立即试用体验美匣云旅行社ERP系统',
                btn: '立即试用体验'
            }
        },
        methods: {

            // 动态效果
            _move() {

                if($('.visaSystem-erp').length > 0){
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离

                    // 大标题
                    let $pTit1 = $('.visaSystem-p1-inner')
                    let $pTit2 = $('.visaSystem-p2-inner')
                    let $pTit3 = $('.visaSystem-p3-inner')
                    let $pTit4 = $('.visaSystem-p4-inner')
                    let $pTit5 = $('.visaSystem-p5-inner')
                    let moveBoxArr = [$pTit1,$pTit2,$pTit3,$pTit4,$pTit5]
                    moveBoxArr.forEach( (item,index) => {
                        if (($winHeight + $winScrollHeight) >= item.offset().top) {
                            $(item).find('.inner-title').addClass('animated fadeInUp')
                        }
                    })

                    if (($winHeight + $winScrollHeight) >= $('.visaSystem-p1-inner .content-box').offset().top + 300) {
                    
                        $('.visaSystem-p1-inner .visaSystem-p1-img').addClass('animated swing')
                    }

                    if (($winHeight + $winScrollHeight) >= $('.visaSystem-p2-inner .content-box').offset().top) {
                        $('.visaSystem-p2-type .visaSystem-p2-type-item').each(function(index,item){
                            $(item).addClass('animated fadeInLeft')
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        })
                    }

                    if (($winHeight + $winScrollHeight) >= $('.visaSystem-p3-inner .content-box').offset().top) {
                        $('.visaSystem-p3-inner .visaSystem-p3-type-item').each(function(index,item){
                            $(item).addClass('animated fadeInRight')
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        })
                    }

                    if (($winHeight + $winScrollHeight) >= $('.visaSystem-p4-inner .content-box').offset().top) {
                        $('.visaSystem-p4-inner .visaSystem-p4-item').each(function(index,item){
                            $(item).addClass('animated fadeInLeft')
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        })
                    }

                    if (($winHeight + $winScrollHeight) >= $('.visaSystem-p5-inner .content-box').offset().top) {
                        $(".wordI").addClass('animated fadeInLeft')
                        $(".wordII").addClass('animated fadeInRight')
                    }

                    if (($winHeight + $winScrollHeight) >= $('.visaSystem-p6-inner .content-box').offset().top) {
                        $(".visaSystem-p6-wordI").addClass('animated fadeInLeft')
                        $(".visaSystem-p6-wordII").addClass('animated fadeInRight')
                    }
                    
                }

            },
            backTop(){
                $('html,body').animate({scrollTop:0},200);
            }


        },
        mounted(){
            vm = this
            // 滚动屏幕触发
            let canRun = true;
            let timer;
            vm._move()
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._move()
                    canRun = true
                }, 300)

            });

        },
    }
</script>

<style lang="less">
    @bgBlue: #3b7ffc;
    .visaSystem-erp {
        min-width: 1200px;
        width: 100%;
        .inner-title{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2{
                font-family: PingFang-SC-Bold;
                font-size: 40px;
                line-height: 40px;
                color: #2080ff;
                margin-bottom: 17px;
                display: inline-block;
            }
            h3{
                font-family: SourceHanSansCN-Normal;
                font-size: 28px;
                line-height: 28px;
                color: #999999;
                display: inline-block;
            }
            .subTitle-line{
                position: relative;
                &::before{
                    display: block;
                    content: "";
                    width: 94px;
                    height: 1px;
                    background-color: #b3b3b3;
                    position: absolute;
                    top: 50%;
                    left: -114px;
                    transform: translateY(-50%);
                }
                &::after{
                    display: block;
                    content: "";
                    width: 94px;
                    height: 1px;
                    background-color: #b3b3b3;
                    position: absolute;
                    top: 50%;
                    right: -114px;
                    transform: translateY(-50%);
                }
            }
        }
        .banner-box {
            width: 100%;
            height: 630px;
            background-image: url("../../public/img/visaSystem/p1/p1_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
        .banner-inner{
            padding-top: 132px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
        .banner-words-box {
            margin-top: 61px;
            color: #ffffff;
            h1{
                font-family: PingFang-SC-Heavy;
	            font-size: 70px;
                line-height: 70px;
                margin-bottom: 17px;
            }
            h2{
                font-family: PingFang-SC-Heavy;
	            font-size: 70px;
                line-height: 70px; 
                margin-bottom: 39px;
            }
            h3{
                font-family: PingFang-SC-Medium;
	            font-size: 30px;
                line-height: 30px;
                margin-bottom: 22px;
                span{
                    font-family: PingFang-SC-Heavy;
	                font-size: 30px;
                    line-height: 30px;
                    color: #ffe71e;
                }
            }
            .little-words{
                font-family: SourceHanSansCN-Light;
	            font-size: 18px;
                line-height: 18px;
            }
            .free-try-btn{
                width: 192px;
                height: 49px;
                margin-top: 36px;
                border-radius: 24px;
                border: solid 2px #ffffff;
                font-family: PingFang-SC-Heavy;
	            font-size: 26px;
                line-height: 45px;
                background-color: transparent;
            }
        }
        .banner-img{
            margin-left: 125px;
        }
        
    }

    /*p1*/ 
    .visaSystem-p1{
        background-color: #ffffff;
        padding: 99px 0 75px;
        box-shadow: 0px 5px 38px 0px rgba(7, 0, 2, 0.04);
    }
    .visaSystem-p1-inner {
        .content-box {
            img{
                margin: 60px auto 0;
                display: block;
            }
        }
    }

    /*p2*/
    .visaSystem-p2 {
        padding: 99px 0 100px;
        background-color: #f6f6f6;
        .content-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 111px;
            .visaSystem-p2-type{
                display: flex;
                justify-content: center;
                align-items: center;
                &:first-child{
                    margin-bottom: 49px;
                }
                &-item{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-right: 128px;
                    img{
                        display: block;
                        margin-bottom: 28px;
                    }
                    .item-content{
                        p:first-child{
                            font-family: PingFang-SC-Bold;;
                            font-size: 22px;
                            line-height: 22px;
                            color: #2080ff;
                            margin-bottom: 10px;
                        }
                        p:last-child{
                            font-family: SourceHanSansCN-Normal;
                            font-size: 16px;
                            line-height: 16px;
                            color: #666666;
                        }
                    }
                }
                .mr0{
                    margin-right: 0;
                }
            }
            .visaSystem-p2-img{
                margin-top: 78px;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
    /*p3*/
    .visaSystem-p3 {
        padding: 99px 0 103px;
        background-color: #ffffff;
        .content-box{
            margin-top: 59px;
            display: flex;
            justify-content: space-between;
        }
        &-type-item{
            width: 182px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
                display: block;
                margin-bottom: 38px;
            }
            .item-content{
                text-align: center;
                p:first-child{
                    font-family: PingFang-SC-Bold;
                    font-size: 22px;
                    line-height: 22px;
                    margin-bottom: 17px;
                    color: #2080ff;
                }
                p:last-child{
                    font-family: PingFang-SC-Regular;
                    font-size: 16px;
                    color: #666666;
                }
            }
        }
    }

    /*p4*/
    .visaSystem-p4 {
        padding: 99px 0 109px;
        background-color: #f6f6f6;
        .content-box{
            margin-top: 68px;
            display: flex;
            justify-content: center;
        }
        .visaSystem-p4-item{
            width: 217px;
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            align-items: center;
            margin-right: 68px;
            position: relative;
            &::after{
                content: "";
                display: block;
                width: 1px;
                height: 40px;
                background-color: #d4d4d4;
                position: absolute;
                top: 47px;
                left: 251px;
            }
            &:last-child{
                margin-right: 0;
                &::after{
                    display: none;
                }
            }
            img{
                margin-bottom: 32px;
            }
            .title{
                font-family: PingFang-SC-Bold;
                font-size: 22px;
                line-height: 22px;
                color: #2080ff;
                margin-bottom: 25px;
                text-align: center;
            }
            .item-content{
                div{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-family: PingFang-SC-Regular;
                    font-size: 16px;
                    color: #6d6e69;
                    text-align: justify;
                    &:first-child{
                        margin-bottom: 16px;
                    }
                }
                .short-line{
                    display: block;
                    flex-shrink: 0;
                    width: 10px;
                    height: 3px;
                    background-color: #3c9fff;
                    border-radius: 2px;
                    margin-right: 11px;
                    margin-top: 9px;
                }
            }
        }
    }
    
    .visaSystem-p5 {
        .visaSystem-p5-inner{
            padding: 99px 0 62px;
            background-color: #136fff;
        }
        .inner-title h2,.inner-title h3{
            color: #ffffff;
        }
        .content-box{
            margin: 48px auto 0;
            width: 1155px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .wordI{
                font-family: PingFang-SC-Heavy;
	            font-size: 20px;
                line-height: 34px;
                text-align: center;
                margin-bottom: 48px;
            }
            .wordII{
                font-family: PingFang-SC-Regular;
	            font-size: 18px;
                line-height: 30px;
                margin-bottom: 43px;
            }
            .btn{
                display: block;
                width: 232px;
                height: 49px;
                border-radius: 24px;
                border: solid 2px #ffffff;
                font-family: PingFang-SC-Heavy;
	            font-size: 26px;
                line-height: 45px;
                text-align: center;
                color: #ffffff;
                cursor: pointer;
            }
        }
        .imageBox{
            width: 100%;
            height: 240px;
            display: flex;
            justify-content: flex-start;
            .imageBoxItemI,.imageBoxItemIII{
                width: 36%;
                height: 100%;
            }
            .imageBoxItemII{
                width: 28%;
                height: 100%;
            }
            .tagItem{
                margin-top: 83px;
                display: inline-block;
                p{
                    text-align: center;
                    font-family: PingFang-SC-Bold;
                    font-size: 20px;
                    margin-top: 14px;
                    color: #ffffff;
                }
            }
            .imageBoxItemI{
                background-image: url("../../public/img/visaSystem/p6/p6_img1.png");
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
                justify-content: flex-end;
                .tagItem{
                    margin-right: 203px;
                }
            }
            .imageBoxItemII{
                background-image: url("../../public/img/visaSystem/p6/p6_img2.png");
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
                justify-content: center;
                position: relative;
                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 10px;
	                background-color: #136fff;
                    position: absolute;
                    bottom: -10px;
                    left: 0;
                }
            }
            .imageBoxItemIII{
                background-image: url("../../public/img/visaSystem/p6/p6_img3.png");
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
                justify-content: flex-start;
                .tagItem{
                    margin-left: 203px;
                }
            }
        }
    }
   
    /*p6*/
    .visaSystem-p6 {
        padding: 73px 0 100px;
        background-image: url("../../public/img/visaSystem/p7/p7_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        color: #ffffff;
        .content-box{
            text-align: center;
            img{
                margin-bottom: 36px;
            }
            .visaSystem-p6-wordI{
                font-family: PingFang-SC-Heavy;
	            font-size: 70px;
                line-height: 70px;
                margin-bottom: 18px;
            }
            .visaSystem-p6-wordII{
                font-family: PingFang-SC-Medium;
	            font-size: 50px;
                line-height: 50px;
                margin-bottom: 37px;
            }
        }
        .btn{
            display: block;
            width: 232px;
            height: 49px;
            background-color: #136fff;
            border-radius: 24px;
            margin: auto;
            font-family: PingFang-SC-Heavy;
	        font-size: 26px;
            line-height: 49px;
            color: #ffffff;
            text-align: center;
            cursor: pointer;
        }
        .backTop{
            display: block;
            float: right;
            margin-right: 15px;
            margin-top: 25px;
            cursor: pointer;
        }
    }
    


    @media all and (max-width: 750px) {
        .visaSystem-erp .banner-box {
            height: auto;
        }
        .visaSystem-erp {
            width: 7.5rem;
            min-width: auto;
            .banner-inner {
                width: 7.5rem;
                padding-top: 1.32rem;
                flex-direction: column;
                align-items: center;
            }
            .banner-words-box {
                margin-top: 0.61rem;
                h1{
                    font-size: 0.7rem;
                    line-height: 0.7rem;
                    margin-bottom: 0.17rem;
                }
                h2{
                    font-size: 0.7rem;
                    line-height: 0.7rem;
                    margin-bottom: 0.39rem;
                }
                h3{
                    font-size: 0.3rem;
                    line-height: 0.3rem;
                    margin-bottom: 0.22rem;
                    span{
                        font-size: 0.3rem;
                        line-height: 0.3rem;
                    }
                }
                .little-words {
                    font-size: 0.18rem;
                    line-height: 0.18rem;
                }
                .free-try-btn{
                    width: 1.92rem;
                    height: 0.49rem;
                    margin-top: 0.36rem;
                    border-radius: 0.24rem;
                    font-size: 0.26rem;
                    line-height: 0.45rem;
                }
            }
            .banner-img{
                margin-left: 0;
                margin-top: 0.6rem;
            }
            .inner-title{
                h2{
                    font-size: 0.4rem;
                    line-height: 0.4rem;
                    margin-bottom: 0.17rem;
                }
                h3{
                    font-size: 0.28rem;
                    line-height: 0.28rem;
                }
                .subTitle-line{
                    &::before {
                        width: 0.94rem;
                        height: 0.01rem;
                        left: -1.14rem;
                    }
                    &::after {
                        width: 0.94rem;
                        height: 0.01rem;
                        right: -1.14rem;
                    }
                }
            }
            .center-box{
                padding-bottom: 0;
            }
        }

        /*.visaSystem-p1*/
        .visaSystem-p1 {
            height: auto;
            padding: 0.99rem 0 0.75rem;
            .visaSystem-p1-inner .content-box img {
                display: block;
                margin: 0.6rem auto 0;
                width: 98%;
            }
        }
       
        /*.visaSystem-p2 */
        .visaSystem-p2 {
            height: auto;
            padding: 0.99rem 0 1rem;
            .content-box{
                margin-top: 1.11rem;
                .visaSystem-p2-type{
                    flex-direction: column;
                    &:first-child{
                        margin-bottom: 0.49rem;
                    }
                    &-item {
                        margin-right: 0;
                        margin-bottom: 0.6rem;
                        img{
                            margin-bottom: 0.28rem;
                        }
                        .item-content{
                            p:first-child{
                                font-size: 0.22rem;
                                line-height: 0.22rem;
                                margin-bottom: 0.1rem;
                            }
                            p:last-child{
                                font-size: 0.16rem;
                                line-height: 0.16rem;
                            }
                        }
                    }
                }
            }
        }


        /*.visaSystem-p3*/
        .visaSystem-p3{
            height: auto;
            padding: 0.99rem 0 1.03rem;
            .content-box{
                margin-top: 0.59rem;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
            &-type-item{
                width: 1.82rem;
                width: 50%;
                margin-bottom: 0.6rem;
                &:nth-child(3),&:nth-child(4){
                    margin-bottom: 0;
                }
                img{
                    width: 1.07rem;
                    margin-bottom: 0.38rem;
                }
                .item-content{
                    p:first-child{
                        font-size: 0.22rem;
                        line-height: 0.22rem;
                        margin-bottom: 0.17rem;
                    }
                    p:last-child{
                        font-size: 0.16rem;
                    }
                }
            }
        }
       


        /*.visaSystem-p4*/
        .visaSystem-p4 {
            height: auto;
            padding: 0.99rem 0 1.09rem;
            .content-box{
                margin-top: 0.68rem;
                flex-direction: column;
                align-items: center;
            }
            .visaSystem-p4-item{
                width: 50%;
                margin-right: 0;
                margin-bottom: 0.6rem;
                &:last-child{
                    margin-bottom: 0;
                }
                &::after{
                    display: none;
                }
                img{
                    margin-bottom: 0.32rem;
                    width: 0.67rem;
                }
                .title{
                    font-size: 0.22rem;
                    line-height: 0.22rem;
                    margin-bottom: 0.25rem;
                }
                .item-content{
                    div{
                        font-size: 0.16rem;
                        &:first-child{
                            margin-bottom: 0.16rem;
                        }
                    }
                    .short-line{
                        margin-right: 0.11rem;
                        margin-top: 0.16rem;
                    }
                }
            }
        }
       
        /*.visaSystem-p5*/
        .visaSystem-p5 {
            height: auto;
            .visaSystem-p5-inner{
                padding: 0.99rem 0 0.62rem;
            }
            .content-box{
                width: 100%;
                margin: 0.48rem auto 0;
                padding: 0 0.3rem;
                .wordI {
                    font-size: 0.2rem;
                    line-height: 0.34rem;
                    margin-bottom: 0.48rem;
                }
                .wordII {
                    font-size: 0.18rem;
                    line-height: 0.3rem;
                    margin-bottom: 0.43rem;
                }
                .btn{
                    width: 2.32rem;
                    height: 0.49rem;
                    border-radius: 0.24rem;
                    font-size: 0.26rem;
                    line-height: 0.45rem;
                }
            }
            .imageBox{
                height: 2.4rem;
                font-size: 26px;
                .imageBoxItemI{
                    .tagItem{
                        margin-right: 0.83rem;
                    }
                }
                .imageBoxItemII::after{
                    height: 0.1rem;
                    bottom: -0.1rem;
                }
                .imageBoxItemIII{
                    .tagItem{
                        margin-left: 0.83rem;
                    }
                }
                .tagItem{
                    margin-top: 0.83rem;
                    img{
                        width: 0.74rem;
                    }
                    p{
                        font-size: 0.2rem;
                        margin-top: 0.14rem;
                    }
                }
            }
        }

        /*.visaSystem-p6*/
        .visaSystem-p6 {
            height: auto;
            padding: 0.73rem 0 1rem;
            .content-box{
                img{
                    margin-bottom: 0.36rem;
                    width: 1.37rem;
                }
                .visaSystem-p6-wordI {
                    font-size: 0.7rem;
                    line-height: 0.7rem;
                    margin-bottom: 0.18rem;
                }
                .visaSystem-p6-wordII {
                    font-size: 0.5rem;
                    line-height: 0.5rem;
                    margin-bottom: 0.37rem;
                }
            }
            .btn{
                width: 2.32rem;
                height: 0.49rem;
                border-radius: 0.24rem;
                font-size: 0.26rem;
                line-height: 0.49rem;
            }
            .backTop{
                width: 0.7rem;
                margin-right: 0.15rem;
                margin-top: 0.25rem;
            }
        }
    } // media end

</style>
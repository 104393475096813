<template>
    <div class="distributor-box">
        <div class="distributor-banner">
            <img src="../assets/img/banner_top.png" alt="" class="about-banner-img a-img1 lazyload"  draggable="false">
            <div class="distributor-banner-text">
                <h1>美匣云代理商火热招募中</h1>
                <p>旅游行业互联网转型风口，携手美匣云掘金千亿市场</p>
                <button @click="ApplyRecordWS()">申请成为代理商</button>
            </div>
            <img src="../assets/img/banner_bottom.png" alt="" class="about-banner-img a-img1 lazyload"  draggable="false">
        </div>
        <div class="distributor-content">
            <div class="distributor-content-p1">
                <div class="distributor-content-center">
                    <div class="p-tit-box">
                        <div class="p-tit">我们的产品 <div class="divider"></div></div>
                        <p style="padding-top:20px;" class="pcDom">区别于传统旅游ERP系统服务商和小程序商城服务商的单一产品线模式，美匣云由小程序商城 + 旅游ERP系统 + 资源分销市场三大产品<br/>
                        组成强大的旅游生态系统平台通过外部营销系统 + 内部管理系统 + 供应链服务全方位多维度赋能旅游商家，助力商家实现数字化转型升级</p>
                        <p class="mobile">
                            区别于传统旅游ERP系统服务商和小程序商城服务商的单一产品线模式，美匣云由小程序商城 + 旅游ERP系统 + 资源分销市场三大产品组成强大的旅游生态系统平台通过外部营销系统 + 内部管理系统 + 供应链服务全方位多维度赋能旅游商家，助力商家实现数字化转型升级
                        </p>
                    </div>
                    <div class="distributor-content-p1-content">
                        <ul>
                            <li>
                                <router-link to="/wscSystem" class = 'link-item'>
                                    <div class="distributor-content-p1-content-title">微商城/小程序商城</div>
                                    <div class="distributor-content-p1-content-img"><img src="../assets/img/distributor-content-p1-content-img1.png" alt="" draggable="false" ></div>
                                    <div class="distributor-content-p1-content-tip">
                                        <p>微商城+小程序一体化解决方案</p>
                                        <p>助力商家转型线上，实现数字化营销</p>
                                    </div>
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link to="/mayTekErp" class = 'link-item'>
                                    <div class="distributor-content-p1-content-title">旅游ERP系统</div>
                                    <div class="distributor-content-p1-content-img"><img src="../assets/img/distributor-content-p1-content-img2.png" alt="" draggable="false" ></div>
                                    <div class="distributor-content-p1-content-tip">
                                        <p>旅游ERP系统，连通上下游</p>
                                        <p>PC+APP双端助力商家实现数字化经营</p>
                                    </div>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/zyMarket" class = 'link-item'>
                                    <div class="distributor-content-p1-content-title">资源分销市场</div>
                                    <div class="distributor-content-p1-content-img"><img src="../assets/img/distributor-content-p1-content-img3.png" alt="" draggable="false" ></div>
                                    <div class="distributor-content-p1-content-tip">
                                        <p>汇聚优质旅游资源，由商家直供</p>
                                        <p>解决商家产品直采找货难题，一键铺货</p>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div class="distributor-content-p2">
                <div class="distributor-content-center">
                    <div class="p-tit-box">
                        <div class="p-tit">我们的前景 </div>
                    </div>
                    <div class="distributor-content-p2-content">
                        <ul>
                            <li>
                                <div class="distributor-content-p2-content-title">
                                    <img src="../assets/img/distributor-content-p2-content-img1.png" alt="" draggable="false">
                                    <span></span>
                                    <p>市场大</p>
                                </div>
                                <div class="distributor-content-p2-content-tip">
                                    疫情过后，旅游行业面临巨大挑战，也充满新的机遇，行业已在重新洗牌，线上转型+数字化营销一定是必由之路，所有商家都需要更先进的软件系统来协助自己的业务发展
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p2-content-title">
                                    <img src="../assets/img/distributor-content-p2-content-img2.png" alt="" draggable="false">
                                    <span></span>
                                    <p>产品强</p>
                                </div>
                                <div class="distributor-content-p2-content-tip">
                                    颠覆传统软件固有模型，是一套面向未来旅游业、功能全面的综合型SaaS系统，既有对外营销系统，也有内部管理系统，还有强大的系统对接能力，适用于各类型旅游商家
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p2-content-title">
                                    <img src="../assets/img/distributor-content-p2-content-img3.png" alt="" draggable="false">
                                    <span></span>
                                    <p>竞品少</p>
                                </div>
                                <div class="distributor-content-p2-content-tip">
                                    市面上所有旅游相关的软件系统，有些只有内部管理的ERP系统，有些只有对外营销收客的小程序商城，很难满足旅游业务发展需求，更不能满足集团型客户的营销和管理需求
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p2-content-title">
                                    <img src="../assets/img/distributor-content-p2-content-img4.png" alt="" draggable="false">
                                    <span></span>
                                    <p>成长快</p>
                                </div>
                                <div class="distributor-content-p2-content-tip">
                                    美匣云产品成长快，市场发展更快，作为旅游软件系统行业的后起之秀，美匣云已经得到越来越多客户的认可、支持和好评，全国各地越来越多的客户选择我们美匣云
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div class="distributor-content-p3">
                <div class="distributor-content-center">
                    <div class="p-tit-box">
                        <div class="p-tit">我们的支持</div>
                    </div>
                    <div class="distributor-content-p3-content">
                        <ul>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img1.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>产品支持</p>
                                    <p>提供竞争力强、好评度高的旅游软件系统</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img2.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>销售支持</p>
                                    <p>协助伙伴洽谈客户，提高销售成功率</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img3.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>培训支持</p>
                                    <p>提供完善的产品培训、销售培训支持</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img4.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>技术支持</p>
                                    <p>强大的技术团队，及时响应伙伴和客户需求</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img5.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>售后支持</p>
                                    <p>提供长期全面的售前、售后服务和项目支持</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img6.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>销售物料</p>
                                    <p>完善销售工具（视频、PPT、解决方案文档）</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img7.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>市场支持</p>
                                    <p>线上线下多渠道宣传推广，提升品牌和产品知名度</p>
                                </div>
                            </li>
                            <li>
                                <div class="distributor-content-p3-content-left">
                                    <img src="../assets/img/distributor-content-p3-content-img8.png" alt="" draggable="false" >
                                </div>
                                <div class="distributor-content-p3-content-right">
                                    <p>客户资源</p>
                                    <p>提供当地意向客户线索，发挥本地服务优势</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div class="distributor-content-p4">
                <div class="distributor-content-center">
                    <div class="p-tit-box">
                        <div class="p-tit">渠道伙伴要求 </div>
                    </div>
                    <div class="distributor-content-p4-content">
                        <ul>
                            <li>
                                <span></span>
                                <div class="distributor-content-p4-content-title">
                                    经验丰富
                                </div>
                                <div class="distributor-content-p4-content-tip">
                                    有多年的旅游行业从业经验，组团社、专线批发商、综合批发商、地接社、以及景区运营商、酒店运营商等旅游业内人士，有旅游行业资源、景区资源、酒店资源
                                </div>
                            </li>
                            <li>
                                <span></span>
                                <div class="distributor-content-p4-content-title">
                                    专业团队
                                </div>
                                <div class="distributor-content-p4-content-tip">
                                    有成熟的销售团队，有专业的销售拓客能力，负责美匣云产品的销售和推广
                                </div>
                            </li>
                            <li>
                                <span></span>
                                <div class="distributor-content-p4-content-title">
                                    熟悉美匣
                                </div>
                                <div class="distributor-content-p4-content-tip">
                                    熟悉美匣云产品的相关情况，有一定的认知和了解，并认同美匣云的经营理念
                                </div>
                            </li>
                            <li>
                                <span></span>
                                <div class="distributor-content-p4-content-title">
                                    工匠精神
                                </div>
                                <div class="distributor-content-p4-content-tip">
                                    具有敬业精神和良好的服务意识，能为客户提供相应的服务和支持
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <!-- <div class="distributor-content-p5">
                <div class="distributor-content-center">
                    <div class="p-tit-box">
                        <div class="p-tit">合作流程 </div>
                    </div>
                    <div class="distributor-content-p1-content">
                        <ul>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="index-content zy-p3">
                <div class="center-box zy-p3-inner">
                    <div class="content-box ">
                        <div class="p-tit-box">
                            <div class="p-tit">合作流程</div>
                        </div>
                        <div class="process-box">
                            <ul>
                                <li class="wsc-p8-item">
                                    <div class="wsc-p8-icons">
                                        <i class="icon"><span>1</span></i>
                                        <h1>提交代理申请</h1>

                                    </div>
                                </li>
                                <li class="wsc-p8-item">
                                    <div class="wsc-p8-icons">
                                        <i class="icon"><span>2</span></i>
                                        <h1>代理商资质审核</h1>

                                    </div>
                                </li>
                                <li class="wsc-p8-item">
                                    <div class="wsc-p8-icons">
                                        <i class="icon"><span>3</span></i>
                                        <h1>签署代理协议</h1>

                                    </div>
                                </li>
                                <li class="wsc-p8-item">
                                    <div class="wsc-p8-icons">
                                        <i class="icon"><span>4</span></i>
                                        <h1>全面销售培训</h1>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="distributor-content-p5">
                <div  class="distributor-content-p5-center">
                    <div class="distributor-content-p5-title">在线申请</div>
                    <img class="distributor-content-p5-img" src="../assets/img/distributor-content-p5-content-img6.png"  alt="" draggable="false" >
                    <div class="distributor-content-p5-box">
                        <div class="ipt-box">
                            <img src="../assets/img/distributor-content-p5-content-img1.png" alt="" draggable="false">
                            <input type="text" placeholder="姓名" v-model="registerInfo.name">
                        </div>
                        <div class="ipt-box">
                            <img src="../assets/img/distributor-content-p5-content-img2.png" alt="" draggable="false">
                            <input type="text" placeholder="电话" v-model="registerInfo.phone">
                        </div>
                        <div class="ipt-box">
                            <img src="../assets/img/distributor-content-p5-content-img4.png" alt="" draggable="false">
                            <input type="text" placeholder="公司名" v-model="registerInfo.company">
                        </div>
                        <br/>
                        <div class="ipt-box">
                            <img src="../assets/img/distributor-content-p5-content-img3.png" alt="" draggable="false">
                            <input type="text" placeholder="所在城市" v-model="registerInfo.city">
                        </div>
                        <div class="ipt-box">
                            <img src="../assets/img/distributor-content-p5-content-img3.png" alt="" draggable="false">
                            <input type="text" placeholder="公司地址" v-model="registerInfo.address">
                        </div>
                        <div class="ipt-box last">
                            <img src="../assets/img/distributor-content-p5-content-img5.png" alt="" draggable="false">
                            <textarea type="text" placeholder="公司介绍" v-model="registerInfo.companyProfile"></textarea>
                        </div>
                        <div class="submit" @click="register">
                            提交
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>
<script>
import FootBox from '@/components/Footer.vue'
import PopWindow from '@/components/PopWindow.vue'
export default {
     components: {
        FootBox,
        PopWindow,
    },
    data(){
        return{
            registerInfo:{
                name:'',
                phone:'',
                city:'',
                company:'',
                address:'',
                companyProfile:''
            },
            BaseUrl:''
        }
    },
    methods: {
        ApplyRecordWS(){
            if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
                $('html,body').animate({scrollTop:$('.distributor-content-p5').offset().top + 60}, 800)
            }
            else {
                $('html,body').animate({scrollTop:$('.distributor-content-p5').offset().top - 60}, 800)
            }
        },
        // 动态效果
        move() {
            if($('.distributor-box').length > 0) {
                let $winHeight = $(window).height(); // 窗口可视区域的高度
                let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离
                // 大标题
                $('.p-tit-box').each((index,item)=>{
                    if (($winHeight + $winScrollHeight) >= $(item).offset().top) {
                        $(item).addClass('animated fadeInUp')
                    }
                })
                // 我们的产品
                $('.distributor-content>div').each((index,item)=>{
                    if (($winHeight + $winScrollHeight) >= $(item).offset().top ) {
                        $(item).find('li').each(function (index,item1) {
                            $(item1).addClass('animated fadeInLeft')
                            $(item1).css({
                                "animation-delay": .4 * index + "s"
                            })
                        })
                    }
                })
                
                // 在线申请
                if (($winHeight + $winScrollHeight) >= $('.distributor-content-p5').offset().top ) {
                    $('.distributor-content-p5-center').addClass('animated zoomIn')
                }
            }
        },
        register() {
            for (let i in this.registerInfo) {
                let r = this.registerInfo[i];
                if (!r) {
                    alert('请完善注册信息！');
                    return false;
                }
                if(i === 'phone'){
                    if (mxApi.decidePhone(r)) {
                        alert('手机格式不正确！');
                        return false
                    }
                }
            }
            let that = this
            $.ajax({
                url: this.BaseUrl+'/ApplyRecordWS/addApplyRecord.gw',
                data: {
                    name:this.registerInfo.name,
                    phone:this.registerInfo.phone,
                    source:'agent',
                    company:this.registerInfo.company,
                    city:this.registerInfo.city,
                    address:this.registerInfo.address,
                    companyProfile:this.registerInfo.companyProfile,
                },
                type:'get',
                dataType: "json",
                success: function (data) {
                    if (data.status === 1) {
                        that.registerInfo = {}
                        alert('提交成功！')
                    } else {
                        alert('提交失败');
                    }
                }
            });
        },
        getBaseUrl(){
            // 环境判断
            if(process.env.NODE_ENV == "development"){
                return '/api'
            }else{
                return ''
            }
        }
    },
    
    mounted(){
        this.BaseUrl = this.getBaseUrl()
        // 滚动屏幕触发
        let canRun = true;
        let timer;
        this.move()
        let that = this
        $(window).scroll(function () {
            if (!canRun) {
                return
            }
            canRun = false
            timer = setTimeout(function () {
                that.move()
                canRun = true
            }, 300)
        })
        window.mxApi = {
            /**
             * 手机验证
             * @param value 护照格式字符串
             * @return boolean
             * */
            decidePhone: function (value) {
                /* let reg = /^0?1[345789]\d{9}$/;*/
                let reg = /^([0-9]{3,4}-[0-9]{8})|1[0-9]{10}$/;
                return !reg.test(value);
            },
            /**
             * 邮箱验证
             * @param value 护照格式字符串
             * @return boolean
             * */
            decideEmail(value) {
                let reg = /^(\w)+@(\w)+(\.[A-Za-z]{2,3}){1,3}$/;
                return !reg.test(value);
            },


        };
    },
}
</script>
<style scoped lang="less">
.distributor-box{
    .mobile{
        display:none;
    }
    .pcDom{
        display: block;
    }
    .distributor-banner{
        width: 100%;
        height:600px;
        // background: url("../assets/img/Distributor-banner.png") no-repeat center;
        background: linear-gradient(to right, #8236e9, #3845e7);
        text-align: center;
        position:relative;
        img:nth-child(1){
            position:absolute;
            top: 0;
            left: 50%;
            transform: translateX(-60%);
        }
        img:last-child{
            position:absolute;
            bottom:0;
            left: 50%;
            transform: translateX(-50%);
        }
        .distributor-banner-text{
            text-align:center;
            position:absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);  
            h1{
                font-size:60px;
                color: #fff;
                font-weight:700;
                
            }
            p{
                font-size:24px;
                color: #84f2ff;
                margin: 45px 0;
            }
            button{
                width:176px;
                height:50px;
                text-align: center;
                line-height:50px;
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, #fabb3e, #fd961b);
                border-radius: 40px;
                color: #fff;
                border: 0;
                font-size:16px;
                cursor: pointer;
            }
        }
    }
    .distributor-content{
        min-width: 1200px;
        .distributor-content-center{
            width:1200px;
            margin: 0 auto;
        }
        .p-tit-box p{
            line-height: 30px;
        }
        .distributor-content-p1{
            &-content{
                margin: 70px 0;
                ul{
                    display: flex;
                    justify-content:space-between;
                    li{
                        text-align: center;
                        cursor: pointer;
                        .distributor-content-p1-content-title{
                             background-image:-webkit-linear-gradient(bottom,#8aceff,#7956ff);
                            -webkit-background-clip:text;
                            -webkit-text-fill-color:transparent;
                            font-size: 24px;
                            line-height: 48px;
                            font-weight: bold;
                        }
                        .distributor-content-p1-content-img{
                            margin: 15px 0;
                        }
                        .distributor-content-p1-content-tip{
                            p:nth-child(1){
                                color: #333;
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 600;
                            }
                            p:nth-child(2){
                                color: #666;
                                font-size: 14px;
                                line-height: 30px;
                            }
                            
                        }
                    }
                }
            }
        }
        .distributor-content-p2{
            background:#f8f8f8;
            &-content{
                margin-top: 70px;
                padding-bottom:70px;
                
                ul{
                    display: flex;
                    justify-content:space-between;
                    flex-wrap:wrap;
                    li{
                        padding:48px;
                        background:#fff;
                        width:580px;
                        box-sizing: border-box;
                        margin-bottom: 24px;
                        box-shadow: 0px 0px 10px 0px #cecece;
                        border-radius: 10px;
                        .distributor-content-p2-content-title{
                            span{
                                display:block;
                                width:80px;
                                height:3px;
                                margin-bottom: 24px;
                                margin-top: 10px;
                            }
                            p{
                                font-size: 24px;
                                font-weight: 600;
                                color: #333;
                                margin-bottom: 24px;
                            }
                            img{
                                position: relative;
                                left: -14px;
                            }
                        }
                        .distributor-content-p2-content-tip{
                            font-size: 14px;
                            color: #666;
                            line-height: 30px;
                        }
                        &:nth-child(1){
                            span{
                                background:#fc3b96;
                            }
                        }
                        &:nth-child(2){
                            span{
                                background:#fc963b;
                            }
                            img{
                                position: relative;
                                left: 0;
                            }
                        }
                        &:nth-child(3){
                            span{
                                background:#21cc45;
                            }
                            
                        }
                        &:nth-child(4){
                            span{
                                background:#6c63ff;
                            }
                        }
                    }
                }
            }
        }
        .distributor-content-p3{
            background:#fff;
            &-content{
                margin-top: 70px;
                padding-bottom:70px;
                
                ul{
                    display: flex;
                    justify-content:space-between;
                    flex-wrap:wrap;
                    li{
                        padding:18px 14px 0;
                        background:#f2f5fa;
                        width:580px;
                        box-sizing: border-box;
                        margin-bottom: 24px;
                        border-radius: 10px;
                        
                        .distributor-content-p3-content-left{
                           width:70px;
                           height:70px;
                           background:#fff;
                           border-radius:4px;
                           text-align: center;
                           line-height:70px;
                           display:inline-block;
                           transform: translateY(-22px);
                           display: flex;
                           justify-content: center;
                           align-items: center;
                           box-shadow: 0px 0px 4px 0px #d9d9d9;
                           float:left;

                        }
                        .distributor-content-p3-content-right{
                            display:inline-block;
                            width:calc(100% - 85px);
                            margin-left: 15px;
                            vertical-align: middle;
                            p:nth-child(1){
                                font-size: 20px;
                                color: #333;
                            }
                            p:nth-child(2){
                                font-size: 14px;
                                color: #666;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
        .distributor-content-p4{
            background:#f8f8f8;
            &-content{
                margin-top: 70px;
                padding-bottom:70px;
                ul{
                    display: flex;
                    justify-content:space-between;
                    flex-wrap:wrap;
                    li{
                        width:260px;
                        height:320px;
                        box-sizing: border-box;
                        padding:30px;
                        color:#fff;
                        span{
                            display:block;
                            width: 40px;
                            height:8px;
                            background:#3b7ffc;
                            transform: translate(-35px, -16px);
                        }
                        &:nth-child(1){
                            background: url("../assets/img/distributor-content-p4-content-img1.png") no-repeat center;
                            background-size:100% 100%;
                        }
                        &:nth-child(2){
                            background: url("../assets/img/distributor-content-p4-content-img2.png") no-repeat center;
                            background-size:100% 100%;
                        }
                        &:nth-child(3){
                            background: url("../assets/img/distributor-content-p4-content-img3.png") no-repeat center;
                            background-size:100% 100%;
                        }
                        &:nth-child(4){
                            background: url("../assets/img/distributor-content-p4-content-img4.png") no-repeat center;
                            background-size:100% 100%;
                        }
                        .distributor-content-p4-content-title{
                            text-align: center;
                            font-size: 24px;
                            margin-bottom: 36px;
                            margin-top: 10px;
                        }   
                        .distributor-content-p4-content-tip{
                            font-size: 14px;
                            line-height:24px;
                            text-align: justify;
                        }

                    }

                }
            }
        }
        .distributor-content-p5{
            background: url("../../public/img/index/freetry-bg.png") no-repeat center/cover;
            height:680px;
            position:relative;
            .distributor-content-p5-center{
                width:800px;
                height:600px;
                background:#fff;
                position:absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                padding:40px 62px;
                .distributor-content-p5-title{
                    text-align: center;
                    color: #333;
                    font-size: 28px;
                }
                .distributor-content-p5-img{
                    position: absolute;
                    right: -35px;
                    top: 30px;
                }
                .distributor-content-p5-box{
                    margin-top:40px;
                }
                .ipt-box{
                    display:inline-block;
                    width:320px;
                    height:44px;
                    margin-right: 10px;
                    margin-bottom: 20px;
                    border-radius:4px;
                   position: relative;
                   
                   img{
                       position: absolute;
                       top: 12px;
                       left: 15px;
                   }
                    input{
                        width:100%;
                        height:100%;
                        border: none;
                        background:#f7f7f7;
                        padding:5px;
                        padding-left:40px;
                        &:focus{
                            background:#fff;
                            box-shadow: 0px 0px 10px 0px #c3c3c3;
                        }
                    }
                    &.last{
                        width:100%;
                        height:129px;
                    }
                    textarea{
                        width:100%;
                        height:100%;
                        border: none;
                        background:#f7f7f7;
                        outline:none;
                        padding:5px;
                        padding-left:40px;
                        padding-top:15px;
                        resize: none;
                        &:focus{
                            background:#fff;
                            box-shadow: 0px 0px 10px 0px #c3c3c3;
                        }
                    }
                }
                .submit{
                    width: 180px;
                    height:42px;
                    line-height: 42px;
                    text-align: center;
                    margin: 0 auto;
                    background:#3b7ffc;
                    font-size: 14px;
                    color: #fff;
                    cursor: pointer;
                    border-radius: 20px;
                    margin-top: 30px;
                }
            }
        }

    }
}
@media all and (max-width: 750px) {
    .distributor-box{
        .pcDom{
            display:none;
        }
        .mobile{
            display: block;
        }
        .distributor-banner{
            width:7.5rem;
            height:100vh;
            img{
                width:100%;
            }
            .distributor-banner-text{
                width:60%;
                h1{
                    font-size: .4rem !important;
                }
                p{
                    font-size: .24rem;
                    margin:.3rem 0;
                }
                button{
                    height:.8rem;
                    line-height: .8rem;
                    font-size: .24rem;
                    margin-top: .6rem;
                }
            }
        }
        .distributor-content{
            min-width: 100%;
            .p-tit-box p{
                padding-top: .4rem !important;
                margin-top: 0;
            }
            .distributor-content-center{
                width: 90%;
                margin: 0 auto;
                ul{
                    flex-wrap: wrap;
                    li{
                        margin-bottom: .4rem;
                        width: 100%;
                    }
                }
            }
            .distributor-content-p1-content{
                margin: .7rem 0;
                ul{
                    li{
                        .distributor-content-p1-content-title{
                            font-size: .35rem;
                        }
                        .distributor-content-p1-content-tip{
                            p:nth-child(1){
                                font-size: 14px;
                            }
                            p:nth-child(2){
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .distributor-content-p2-content{
                margin-top: .7rem;
                padding-bottom: .7rem;
                ul{
                    li{
                        padding:.48rem;
                        .distributor-content-p2-content-title{
                            p{
                                margin-bottom: .24rem;
                                font-size:.35rem;
                            }
                            span{
                                margin-bottom: .24rem;
                            }
                            img{
                                position: relative;
                                left: -15px;
                            }
                        }
                        .distributor-content-p2-content-tip{
                            font-size: 12px;
                            line-height:20px;
                        }
                    }
                }
            }
            .distributor-content-p3-content{
                margin-top: .7rem;
                padding-bottom: .7rem;
                ul{
                    li{
                        padding: .18rem .14rem .05rem;
                        .distributor-content-p3-content-left{
                            width: 1rem;
                            height: 1rem;
                            transform: translateY(-.26rem);
                        }
                       .distributor-content-p3-content-right{
                           width: calc(100% - 1.6rem);
                           margin-left: .15rem;
                           p:nth-child(1){
                               font-size:14px;
                           }
                           p:nth-child(2){
                               font-size:12px;
                               margin-top: .1rem;
                           }
                           
                       } 
                    }
                }
            }
            .distributor-content-p4-content{
               margin-top: .7rem;
               padding-bottom: .7rem; 
            }
            .distributor-content-p5{
                height: 16.8rem;
                .distributor-content-p5-center{
                    padding:.4rem;
                    width:8rem;
                    height:auto;
                    .ipt-box{
                        width: 100%;
                    }
                    .distributor-content-p5-img{
                        display:none;
                    }
                }
            }
            .zy-p3 {
                height: auto;
                .process-box .wsc-p8-item {
                    margin-bottom: .9rem;
                    &:nth-child(2){
                        .wsc-p8-icons{
                            margin-left: 15px;
                        }
                    }
                }
                .process-box .wsc-p8-item:last-child {
                    margin-bottom: 0;
                }
                .process-box .wsc-p8-item .wsc-p8-icons i:after {
                    height: .5rem;
                    top: 1.2rem;
                }
            }

            
        }
    }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'


import Home from '../views/Home.vue'
import zyMarket from '../views/ZyMarket.vue'
import WscSystem from '../views/WscSystem.vue'
import MayTekErp from '../views/MayTekErp.vue'
import SpaFunc from '../views/SpaFunc.vue'
import ScenicTicket from '../views/ScenicTicket.vue'
import News from '../views/News.vue'
import About from '../views/About.vue'
import Distributor from '../views/Distributor.vue'
import Register from '../views/Register.vue'
import KnowMore from '../views/KnowMore.vue'
import douyin from '../views/douyin.vue'
import CommerceErp from '../views/CommerceErp.vue'
import VisaSystem from '../views/VisaSystem.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index.html',
        redirect: '/index'
    },
    {
        path: '/dist',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: Home,
        meta: {
            index: 1,

        }
        // component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue') // 路由懒加载
    },
    {
        path: '/wscSystem',
        name: 'wscSystem',
        component: WscSystem,
        meta: {
            index: 2,

        }

    },
    {
        path: '/zyMarket',
        name: 'zyMarket',
        component: zyMarket,
        meta: {
            index: 3,

        }
    },
    {
        path: '/mayTekErp',
        name: 'mayTekErp',
        component: MayTekErp,
        meta: {
            index: 4,

        }
    },
    {
        path: '/spaFunc',
        name: 'spaFunc',
        component: SpaFunc
    },
    {
        path: '/scenicTicket',
        name: 'scenicTicket',
        component: ScenicTicket,
        meta: {
            index: 5,

        }
    },
    {
        path: '/distributor',
        name: 'distributor',
        meta: {
            index: 6,

        },
        component: Distributor
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {
            index: 7,
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            index: 8,
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/knowMore',
        name: 'knowMore',
        component: KnowMore
    },
    {
        path: '/douyin',
        name: 'douyin',
        component: douyin,
        meta: {
            index: 9,
        }
    },
    {
        path: '/commerceErp',
        name: 'commerceErp',
        component: CommerceErp,
        meta: {
            index: 10,

        }
    },
    {
        path: '/visaSystem',
        name: 'visaSystem',
        component: VisaSystem,
        meta: {
            index: 11,

        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router

<template>
    <div class="zyMarket">
        <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat">
                <div class="banner-words-box">
                    <h1>美匣云旅游资源市场</h1>
                    <h3>一个很纯粹的旅游资源B2B分销市场</h3>
                    <div class="little-words">
                        <div class="">大量优质旅游资源</div>
                        <div class="">众多同业分销渠道</div>
                        <div class="">没有中间商赚差价</div>
                    </div>
                    <div class="free-try-btn">
                        <router-link to="/register" style="color:#fff;" target="_blank">立即试用体验</router-link>
                    </div>
                </div>
                <div class="banner-imgs">
                    <img src="../../public/img/zyMarket/zy_banner_img1.png" alt="" class="lazyload" draggable="false">
                </div>
            </div>
        </div>
        <div class="index-content zy-p1">
            <div class="center-box zy-p1-inner">

                <div class="content-box ">
                    <div class="gy-cg-container">
                        <div class="hover-box">
                            <div class="gy-cg-box gys">
                                <div class="gy-cg-bigTit">
                                    <span class="icon1"></span>
                                    <h1>供货商</h1>
                                </div>
                                <div class="howtodo-contianer">
                                    <div class="howtodo">
                                        发布产品至资源市场，上千家同行旅行社都可以帮您分销卖货
                                        <p>您只需要做好订单处理即可</p>
                                    </div>
                                </div>
                                <div class="sy-qun">
                                    <div class="sec-tit">
                                        <i class="lil-icon sy-icon"></i>
                                        <h2 class="lil-tit">适用客户</h2>
                                    </div>
                                    <div class="des">
                                        <p>
                                            <i class="fang-blue"></i>
                                            有优势产品和旅游资源的供货商、地接社和批发商；
                                        </p>
                                        <p>
                                            <i class="fang-blue"></i>
                                            以同业收客为主，打算在全国各地发展更多的同业销售渠道；
                                        </p>
                                        <p>
                                            <i class="fang-blue"></i>
                                            目前以收直客为主，没有同业收客渠道，打算发展同业销售渠道；
                                        </p>
                                    </div>
                                </div>
                                <div class="the-help">
                                    <div class="sec-tit">
                                        <i class="lil-icon help-icon"></i>
                                        <h2 class="lil-tit">有何帮助</h2>

                                    </div>
                                    <div class="des">
                                        <p>
                                            <i class="fang-blue"></i>
                                            无须在全国各地建立办事处和销售团队，就能增加了上千个全国各地的同业渠道帮您卖货的机会，大大降低了运营成本和人力成本；
                                        </p>
                                        <p>
                                            <i class="fang-blue"></i>
                                            直接和分销商签订供销协议，当分销商产生订单以后，直接通过美匣云系统自动将订单推送至供货商，当供货商将订单处理完毕，自动将订单的处理结果和状态反馈给分销商，让订单的沟通对接效率提升数倍
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hover-box">
                            <div class="gy-cg-box cgs">
                                <div class="gy-cg-bigTit">
                                    <span class="icon2"></span>
                                    <h1>分销商</h1>
                                </div>
                                <div class="howtodo-contianer">
                                    <div class="howtodo">
                                        一键上架销售，一键完成采购，订单的后续处理交给供货商来负责
                                        <p>您只需要做好前端销售即可</p>
                                    </div>
                                </div>

                                <div class="sy-qun">
                                    <div class="sec-tit">
                                        <i class="lil-icon sy-icon"></i>
                                        <h2 class="lil-tit">适用客户</h2>
                                    </div>
                                    <div class="des">
                                        <p>
                                            <i class="fang-pur"></i>
                                            在寻找全国各地优质产品及旅游资源的组团社、定制社、出境转境内的旅行社以及其他旅游组织
                                        </p>
                                    </div>
                                </div>
                                <div class="the-help">
                                    <div class="sec-tit">
                                        <i class="lil-icon help-icon"></i>
                                        <h2 class="lil-tit">有何帮助</h2>
                                    </div>
                                    <div class="des">
                                        <p>
                                            <i class="fang-pur"></i>
                                            大大缩短了供应链路，可直采全国各地源头产品及旅游资源，降低采购成本；一键上架至各个渠道即可销售，简单省事儿；
                                        </p>
                                        <p>
                                            <i class="fang-pur"></i>
                                            直接和供货商签订供销协议，当产生订单以后，美匣云系统自动将订单推送给供货商进行处理，并可实时查询供货商的订单处理状态和结果，无须操心订单的后续处理；
                                        </p>
                                        <p>
                                            <i class="fang-pur"></i>
                                            不仅仅只是旅游资源，实物产品也可以一件采购和上架销售，大大丰富了自己的产品线；
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="index-content zy-p2">
            <div class="center-box zy-p2-inner">
                <div class="p-tit-box">
                    <div class="p-tit">美匣云资源市场优势</div>

                </div>
                <div class="content-box ">
                    <ul class="zy-p2-list">
                        <li class="zy-p2-item">
                            <div class="icons ic1"></div>
                            <div class="words">
                                <h1>入驻</h1>
                                <p>面向全国招募供货商和分销商<br/>
                                    供货商将产品发布<br/>
                                    到美匣云资源市场<br/>
                                    分销商从资源市场寻找优质资源
                                </p>
                                <p class="wap-p">面向全国招募供货商和分销商，供货商将产品发布到美匣云资源市场，分销商从资源市场寻找优质资源
                                </p>
                            </div>
                        </li>
                        <li class="zy-p2-item">
                            <div class="icons ic2"></div>
                            <div class="words">
                                <h1>资源丰富</h1>
                                <p>美匣云资源市场汇聚全国各地<br/>
                                    旅游线路、酒店民宿、景区门票<br/>
                                    品牌实物、美食鲜果等<br/>
                                    各类优质旅游资源及实物产品
                                </p>
                                <p class="wap-p">美匣云资源市场汇聚全国各地，旅游线路、酒店民宿、景区门票、品牌实物、美食鲜果等各类优质旅游资源及实物产品
                                </p>
                            </div>
                        </li>
                        <li class="zy-p2-item">
                            <div class="icons ic3"></div>
                            <div class="words">
                                <h1>无中间商</h1>
                                <p>分销商与供货商直接签订采销合作协议<br/>
                                    直接通过美匣云资源市场<br/>
                                    完成下单和付款<br/>
                                    可一单一结，由供货商直接收款
                                </p>
                                <p class="wap-p">分销商与供货商直接签订采销合作协议，直接通过美匣云资源市场完成下单和付款，可一单一结，由供货商直接收款
                                </p>
                            </div>
                        </li>
                        <li class="zy-p2-item">
                            <div class="icons ic4"></div>
                            <div class="words">
                                <h1>简单纯粹</h1>
                                <p>美匣云资源市场是为旅游商家搭建的<br/>
                                    一个旅游资源B2B采销平台<br/>
                                    美匣云只为商家提供纯粹的软件服务<br/>
                                    不做旅游业务
                                </p>
                                <p class="wap-p">美匣云资源市场是为旅游商家搭建的一个旅游资源B2B采销平台，美匣云只为商家提供纯粹的软件服务，不做旅游业务
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="index-content zy-p3">
            <div class="center-box zy-p3-inner">
                <div class="content-box ">
                    <div class="p-tit-box">
                        <div class="p-tit">合作流程</div>
                    </div>
                    <div class="process-box">
                        <ul>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>1</span></i>
                                    <h1>签订合作协议</h1>

                                </div>
                            </li>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>2</span></i>
                                    <h1>开通专属美匣云账号</h1>

                                </div>
                            </li>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>3</span></i>
                                    <h1>发布产品和采购产品</h1>

                                </div>
                            </li>
                            <li class="wsc-p8-item">
                                <div class="wsc-p8-icons">
                                    <i class="icon"><span>4</span></i>
                                    <h1>推介资源和客户</h1>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <free-try :msg="msg" :btn="btn"></free-try>
        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>
<script>
    import FreeTry from '@/components/FreeTry.vue'
    import FootBox from '@/components/Footer.vue'
    import PopWindow from '@/components/PopWindow.vue'
    let vm;
    export default {
        name: "ZyMarket",
        data(){
            return {
                msg: '立即试用体验美匣云资源市场',
                btn: '立即试用体验'
            }
        },
        components: {
            FreeTry,
            FootBox,
            PopWindow,
        },
        methods: {

            // 动态效果
            _move() {

                if($('.zyMarket').length > 0) {
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离





                    // 大标题
                    let $pTit1 = $('.zy-p2-inner')
                    let $pTit2 = $('.zy-p3-inner')

                    let moveBoxArr = [$pTit1,$pTit2 ]
                    moveBoxArr.forEach( (item,index) => {
                        if (($winHeight + $winScrollHeight) >= item.offset().top) {
                            $(item).find('.p-tit-box').addClass('animated fadeInUp')
                        }
                    })


                    // 资源市场优势
                    if (($winHeight + $winScrollHeight) >= $('.zy-p2-inner .content-box ').offset().top ) {
                        $('.zy-p2-inner .zy-p2-item').each(function (index,item) {
                            $(item).addClass('animated fadeInLeft')
                            $(item).css({
                                "animation-delay": .4 * index + "s"
                            })
                        })

                    }

                    // 合作流程
                    if (($winHeight + $winScrollHeight) >= $('.zy-p3-inner .content-box ').offset().top ) {
                        $('.zy-p3-inner .wsc-p8-item').each(function (index,item) {
                            $(item).find('.wsc-p8-icons').addClass('animated fadeInLeft')
                            $(item).find('.wsc-p8-icons').css({
                                "animation-delay": .4 * index + "s"
                            })
                        })

                    }




                }
            },


        },
        mounted(){
            vm = this
            // 滚动屏幕触发
            let canRun = true;
            let timer;
            vm._move()
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._move()
                    canRun = true
                }, 300)

            })
        },

    }

</script>
<style lang="less">

    .zyMarket {
        min-width: 1200px;
        width: 100%;
        .p-tit-box {
            opacity: 0;
        }
        .banner-box {
            width: 100%;
            height: 600px;
            background-image: url("../../public/img/wscSystem/wsc_banner_bg.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
        .banner-words-box {
            padding-top: 180px;
            h3 {
                font-size: 20px;
            }
        }
        .banner-imgs {
            margin-top: 130px;
        }
        .little-words {
            font-size: 16px;
            div {
                position: relative;
                display: inline-block;
                margin-right: 30px;
                padding-left: 25px;
                color: #fff;
                i {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #5ddfff;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 8px;
                    height: 8px;
                    border: 3px solid rgba(79,134,242);
                    border-radius: 50%;
                    background-color: #5ddfff;
                }
            }
        }
    }


    /*zy-p1*/
    .zy-p1 {

        overflow: visible;
    }
    .gy-cg-container {
        display: flex;
        justify-content: center;
        /*padding-top: 40px;*/
        .hover-box {
            width: 50%;
            padding-top: 40px;
            padding-bottom: 50px;
            background-color: #fff;
            transition: all .2s linear;
            &:hover {
                transform: translate(-1.5px,-3px);
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                .gys {
                    border-right: 0;
                }
            }
        }
        .gy-cg-box {

            /*width: 540px;*/


            .gy-cg-bigTit {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 480px;
                margin-bottom: 28px;
                h1 {
                    display: inline-block;
                    font-size: 30px;
                    margin-left: 15px;
                }
            }

            .howtodo {
                width: 480px;
                height: 88px;
                padding: 10px 30px 0;
                margin-bottom: 40px;
                background-image: linear-gradient(to bottom, #71B2F8 0%, #3b7ffc 50%);
                background-blend-mode: normal, normal;
                border-radius: 10px;
                line-height: 28px;
                overflow: hidden;
                font-size: 14px;
                color: #fff;
                p {
                    // margin-top: 8px;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                }
            }
            .des {
                p {
                    position: relative;
                    padding-left: 28px;
                    font-size: 14px;
                    line-height: 30px;
                    color: #545454;
                    margin-bottom: 35px;
                    i {
                        position: absolute;
                        left: 0;
                        top: 10px;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
                p:last-child {
                    margin-bottom: 0;
                }

            }
            .sy-qun {
                margin-bottom: 35px;
                padding-bottom: 40px;
                border-bottom: 1px solid #e6e6e6;
                height: 250px;
            }

            .sec-tit {
                display: flex;
                align-items: center;
                margin-bottom: 28px;
                .sy-icon {
                    width: 20px;
                    height: 20px;
                    background: url("../../public/img/zyMarket/area-ico.png") no-repeat center/cover;
                }
                .help-icon {
                    width: 20px;
                    height: 20px;
                    background: url("../../public/img/zyMarket/help-ico.png") no-repeat center/cover;
                }
                .lil-tit {
                    display: inline-block;
                    margin-left: 10px;

                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                }
            }

        }
        .gys {
            border-right: 1px solid #e6e6e6;
            padding: 0 60px 0;
            .gy-cg-bigTit {

                .icon1 {
                    width: 41px;
                    height: 39px;
                    background: url("../../public/img/zyMarket/gh-ico.png") no-repeat center/cover;
                }
                h1 {
                    color: #3b7ffc;

                }
            }

            .howtodo {
                background-image: linear-gradient(to bottom, #71B2F8 0%, #3b7ffc 50%);
                background-blend-mode: normal, normal;
                border: 4px solid #bed5ff;

            }
            .fang-blue {
                background-image: url("../../public/img/zyMarket/fang-blue.png");
            }
        }
        .cgs {
            padding: 0 60px 0;
            .gy-cg-bigTit {
                .icon2 {
                    width: 37px;
                    height: 40px;
                    background: url("../../public/img/zyMarket/cg-ico.png")  no-repeat center/cover;
                }
                h1 {
                    color: #8664ff;
                }
            }
            .howtodo {
                background-image: linear-gradient(180deg, #B896F8 0%, #8664ff 50%);
                background-blend-mode: normal, normal;
                border: 4px solid #e1d8ff;
            }
            .fang-pur {
                background-image: url("../../public/img/zyMarket/fang-pur.png");
            }
        }
    }



    /*zy-p2*/
    .zy-p2 {
        width: 100%;
        height: 640px;
        background-color: #f8f8f8;;
        overflow: hidden;
        .zy-p2-list {
            margin-top: 125px;
            display: flex;
            justify-content: space-between;
            .zy-p2-item {
                width: 240px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                .icons {
                    position: relative;
                    width: 110px;
                    height: 110px;
                    background-image: url("../../public/img/zyMarket/zy-p2-icons.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -180px;
                        display: block;
                        width: 160px;
                        height: 1px;
                        background-color: #3b7ffc;
                        opacity: .2;
                    }
                }
                .ic1 {
                    background-position: 0 0;
                }
                .ic2 {
                    background-position: -160px 0;
                }
                .ic3 {
                    background-position: -320px 0;
                }
                .ic4 {
                    background-position: -480px 0;
                }
                h1 {
                    font-size: 20px;
                    color: #333;
                    margin: 40px auto 30px;
                    font-weight: 700;
                }
                p {
                    width: 100%;
                    font-size: 14px;
                    color: #666;
                    line-height: 24px;
                }
                .wap-p {
                    display: none;
                }
            }
            .zy-p2-item:last-child {
                .icons {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }


    /*zy-p3*/
    .zy-p3 {
        width: 100%;
        height: 550px;
        overflow: hidden;
    }

    @media all and (max-width: 750px){
        .zyMarket {
            min-width: auto;
            .banner-inner {
                width: 7.5rem;
            }
            .banner-imgs {
                margin-top: 2rem;
                img {
                    width: 100%;
                }
            }
            .free-try-btn {
                font-size: .25rem;
            }
        }
        .zyMarket .banner-box {
            height: 100vh;
            background-position: center;
        }
        .zyMarket .banner-words-box {
            padding-top:6rem;
        }
        .zyMarket .banner-words-box h3 {
            font-size: .3rem;
        }
        .zyMarket .little-words {
            font-size: .25rem;
            display: flex;
            flex-direction: column;
            &>div {
                margin: 0 auto .2rem;
                width:2.9rem;
            }
        }
        /*wap-zy-p1*/
        .zy-p1 {
            padding-bottom: 0;
        }
        .gy-cg-container {
            flex-direction: column;
            .hover-box {
                width: 100%;
                padding-top: .8rem;
                padding-bottom: 0;
                &:hover {
                    transform: translateY(0);
                    box-shadow: none;
                }
            }
            .gy-cg-box {
                width: 100%;

                .gy-cg-bigTit {
                    width: 100%;
                    h1 {
                        font-size: .5rem;
                    }
                }
                .howtodo {
                    width: 100%;
                    height: 2rem;
                    padding: .3rem .5rem 0;
                    margin-bottom: .8rem;
                    font-size: .26rem;
                    line-height: inherit;
                    p {
                        margin-top: .2rem;
                        font-size: .3rem;
                    }
                }
                 .sy-qun {
                    margin-bottom: .7rem;
                    padding-bottom: .8rem;
                    border-bottom: 1px solid #e6e6e6;
                    height: auto;
                }
                .des {
                    p {
                        padding-left: .5rem;
                        font-size: .26rem;
                        line-height: 2;
                        margin-bottom: .35rem;
                    }
                }
            }
            .gys {
                padding: .1rem 0 0;
                border-right: 0;
                border-bottom: 0;
                margin-bottom: 1rem;
                .gy-cg-bigTit .icon1 {
                    width: .6rem;
                    height: .56rem;
                }
            }
            .cgs {
                padding: .1rem 0 0;
                .gy-cg-bigTit .icon2 {
                    width: .54rem;
                    height: .6rem;
                }
            }
        }



        /*zy-p2*/
        .zy-p2{
            height: auto;
            .zy-p2-list {
                margin-top: 1rem;
                flex-direction: column;
                .zy-p2-item {
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-around;
                    .words {
                        width: 4rem;
                    }
                    p{
                        display: none;
                    }
                    .wap-p {
                        display: block;
                        font-size: .26rem;
                        text-align: justify;
                    }
                    .icons {
                        width: 1.1rem;
                        height: 1.1rem;
                    }
                    h1 {
                        text-align: left;
                    }
                    .ic2 {
                        background-position: -1.6rem 0;
                    }
                    .ic3 {
                        background-position: -3.2rem 0;
                    }
                    .ic4 {
                        background-position: -4.8rem 0 ;
                    }
                }
            }
        }
        .zy-p2 .zy-p2-list .zy-p2-item .icons:after {
            display: none;
        }



        /*.zy-p3*/
        .zy-p3 {
            height: auto;
            .process-box .wsc-p8-item {
                margin-bottom: .9rem;
            }
            .process-box .wsc-p8-item:last-child {
                margin-bottom: 0;
            }
            .process-box .wsc-p8-item .wsc-p8-icons i:after {
                height: .5rem;
                top: 1.2rem;
            }
        }

        .zyMarket .process-box .wsc-p8-item:first-child .wsc-p8-icons {
            margin-right: 46px;
        }
    } // media end
</style>
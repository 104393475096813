<template>
  <div id="main">

    <div id="nav" class="header-nav">
      <div class="header-center-box">
        <div class="head-logo">
          <img src="../public/img/logo_header.png" alt="" class="pc-logo">
          <img src="../public/img/wap_logo1.png" alt="" class="wap-logo">
        </div>
        <div class="head-nav-list">
          <router-link to="/index" class = 'link-item '>首页</router-link>
          <router-link to="/wscSystem" class = 'link-item'>微商城小程序</router-link>
          <!-- <router-link to="/mayTekErp" class = 'link-item'>旅行社ERP</router-link> -->
          <router-link to="/commerceErp" class = 'link-item'>电商组团ERP</router-link>
          <router-link to="/visaSystem" class = 'link-item'>签证系统</router-link>
          <!-- <router-link to="/scenicTicket" class = 'link-item'>景区票务</router-link>
          <router-link to="/zyMarket" class = 'link-item'>资源市场</router-link> -->
          <!-- <router-link to="/distributor" class = 'link-item'>代理商招募</router-link> -->
          <!-- <router-link to="/douyin" class = 'link-item'>抖音小程序</router-link> -->
          <router-link to="/news" class = 'link-item'>公司资讯</router-link>
          <router-link to="/about" class = 'link-item'>关于我们</router-link>
        </div>
        <div class="login-boxs">
          <!-- <a href="https://nft.maytek.cn/pinpainft" class="link-nft"  target="_blank" style="transform:none;">
            <span>
              <i>N</i>
              <i>F</i>
              <i>T</i>
              <i>数</i>
              <i>藏</i>
              <i>方</i>
              <i>案</i>
            </span>
          </a> -->
          <a class="free-try-btn" href="/register" target="_blank" style="transform:none;"> 
           <span>
              <i>试</i>
              <i>用</i>
              <i>体</i>
              <i>验</i>
            </span>
          </a>
          <!-- <a class="login" href="https://cloud.maytek.cn/" target="_blank">登录</a> -->
          <!-- <router-link to="" class="free-try-btn app-down" >
            <span>
              <i>A</i>
              <i>P</i>
              <i>P</i>
              <i>下</i>
              <i>载</i>
            </span>
            <span class="app-erweima">
              <img src="../public/img/erweima.png" alt="">
            </span>
          </router-link> APP下载-->
        </div>
        <div class="wap-menu">
          <div class="wap-nav-btn">
            <span class="line"></span>
            <span class="line line-center"></span>
            <span class="line"></span>
          </div>
          <div class="wap-nav-list">
            <div class="wap-nav-log">
              <img src="../public/img/wap_logo1.png" alt="">
            </div>
            <ul >
              <li class="wap-nav-item active">
                <router-link to="/index">首页</router-link>
              </li>

              <li class="wap-nav-item">
                <router-link to="/wscSystem" >微商城小程序</router-link>
              </li>
              <!-- <li class="wap-nav-item">
                <router-link to="/mayTekErp" class = ''>旅行社ERP</router-link>
              </li> -->
              <li class="wap-nav-item">
                <router-link to="/commerceErp" class = ''>电商组团ERP</router-link>
              </li>
               <li class="wap-nav-item">
                <router-link to="/visaSystem" class = ''>签证系统</router-link>
              </li>
              <!-- <li class="wap-nav-item">
                <router-link to="/scenicTicket" class = ''>景区票务</router-link>
              </li>
              <li class="wap-nav-item">
                <router-link to="/zyMarket" class = ''>资源市场</router-link>
              </li> -->
              <!-- <li class="wap-nav-item">
                <router-link to="/distributor" class = 'link-item'>代理商招募</router-link>
              </li> -->
              <!-- <li class="wap-nav-item">
                <router-link to="/douyin" class = 'link-item'>抖音小程序</router-link>
              </li> -->
              <li class="wap-nav-item">
                <router-link to="/news" class = ''>公司资讯</router-link>
              </li>
              <li class="wap-nav-item">
                <router-link to="/about" class = ''>关于我们</router-link>
              </li>
              <!-- <li class="wap-nav-item">
                <a href="https://nft.maytek.cn/pinpainft" class = '' target="_blank">NFT数藏方案</a>
              </li> -->
              
              
              <li class="wap-nav-item">
                <router-link to="/register" class = ''>试用体验</router-link>
              </li>
              <!-- <li class="wap-nav-item">
                <a href="https://cloud.maytek.cn/" class = '' >登录</a>
              </li>
              <li class="wap-nav-item">
                <router-link to="/register" class = ''>免费试用</router-link>
              </li> -->
            </ul>
          </div>

          <div class="transparent-mask"></div>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
        export default {
            watch: {
                '$route': function () {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;

                    $('.header-nav').removeClass('float');
                    $('.wap-nav-btn').removeClass('show')
                    $(".wap-nav-list").animate({
                        left: '-100%'
                    },"linear")

                    $('.transparent-mask').hide()
                }
            },
            mounted(){
              // 导航滚动变色
              window.onscroll = function () {
                  if(document.documentElement.scrollTop > 80) {
                      $('.header-nav').addClass('float')
                  }
                  if(document.documentElement.scrollTop < 80) {
                      $('.header-nav').removeClass('float')
                  }
              };

              $("#cnzz_stat_icon_1280297064").css('display','none');//cnzz统计代码

              (function initRem() {
                  let maxWidth = 750;
                  let minWidth = 320;
                  let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
                  if (width > maxWidth) {
                      width = maxWidth;
                  } else if (width < minWidth) {
                      width = minWidth
                  }
                  let fontSize = (width / maxWidth) * 100;
                  document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px';
                  document.getElementsByTagName('body')[0].style.fontSize = '0.14rem';
                  window.addEventListener('orientationchange' in window ? 'orientationchange' : 'resize', initRem, false);
                  document.addEventListener('DOMContentLoaded', initRem, false);
              })();

              //  导航条
              if ($('.wap-nav-btn').length > 0) {
                  $(".wap-nav-btn").click(function () {

                      $('.transparent-mask').show()
                      $('.wap-nav-btn').toggleClass('show')
                      $(".wap-nav-list").animate({
                          left: '0'
                      }, "linear");

                  });

                  $('.transparent-mask').click(function() {

                      $('.wap-nav-list').animate({
                          "left": '-100%',
                      }, 400)
                      $('.wap-nav-btn').removeClass('show')
                      $('.transparent-mask').hide()
                  })


                  $('.wap-nav-item').each(function (index,item) {
                      $(item).click(function () {
                          $('.wap-nav-item').removeClass('active')
                          $(this).addClass('active')
                      })
                  })

              }

              //全国悼念期给网页添加滤镜
              // var nowTime = new Date().getTime();
              // var deadLineTime = new Date(2022,11,12,0,0,0).getTime();
              // if(nowTime < deadLineTime){
              //     document.querySelector("html").classList.add("grayFilter");
              //     setTimeout(()=>{
              //       document.querySelector("html").classList.remove("grayFilter");
              //     },(deadLineTime - nowTime))
              // }


            }
        }
    // import HeaderNav from '@/components/HeaderNav.vue'
</script>
<style lang="less">
@import "assets/css/common.css";

@bgGreen: #19bb5a;
@bgBlue: #3b7ffc;
@bgOrange: #F34D17;
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb{background:#909090;border-radius:5px}
::-webkit-scrollbar-track-piece{background-color:#f1f1f1}
.free-try-btn {
  text-align: center;
  background-color: @bgGreen;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
}
.link-nft{
  text-align: center;
  background-color: @bgOrange;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  margin-right: 20px;
}

#main {
  width: 100%;

}

 .grayFilter{
    -webkit-filter: grayscale(95%);
    filter: grayscale(95%);
}


.app-down {
  position: relative;
  .app-erweima {
    visibility: hidden;
    position: absolute;
    top: 60px;
    left: 50%;
    width: 200px;
    height: 200px;
    padding: 0;
    background-color: #fff;
    transform: translate(-50%,5px);
    transition: all .3s ease;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
      z-index: 0;
    }
    img {
      width: 100%;
    }

  }
  &:hover {
    .app-erweima {
      visibility: visible;
      transform: translate(-50%, 0);
    }
  }
}


/* 顶部导航栏 */
#nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 999;
  transition: all .1s linear;

}
.header-nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: rgba(0,0,0,.1);

}
.header-center-box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  width: 1200px;
  height: 80px;
  .head-logo {
    margin-left: -110px;
    margin-right: 90px;
  }
  .head-nav-list {
    margin-right: 100px;
    white-space: nowrap;
  }
  .link-item {
    position: relative;
    margin-right: 40px;
    padding-bottom: 10px;
    color: #ccc;
    font-size: 16px;
    transition: all .3s linear;
    &:after {
      content: "";
      position: absolute;
      left: 50%;

      bottom: 0;
      width: 0;
      height: 2px;
      margin-left: 0;
      transition: all .3s ease;
    }
  }
  .link-item:last-child {
    margin-right: 0;
  }
  .link-item:hover {
    color: #fff;
    &:after {
      content: '';
      width: 40px;
      margin-left: -20px;
      background-color: #fff;
    }
  }

}
.login-boxs {
  white-space: nowrap;
  &>div {
    display: inline-block;
  }
  .login {
    color: #fff;
    margin-right: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  .free-try-btn {
    display: inline-block;
    width: 88px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    transition: all .3s ease;
    span {
      display: inline-flex;
      i {
        display: block;
        --move: 12px;
        --move-y: -8px;
        --blur: 4px;
        font-size: 14px;
        transition: all .3s ease;

      }
    }
    &:hover {
      transform: translateY(-3px);
      span {
        i {
          animation:  smoke .75s linear var(--d);
        }
        i:nth-child(1){
          --d: 0.05s;
        }
        i:nth-child(2){
          --d: 0.1s;
        }
        i:nth-child(3){
          --d: 0.15s;
        }
        i:nth-child(4){
          --d: 0.2s;
        }
      }
    }
  }
  .link-nft{
    display: inline-block;
    width: 114px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    transition: all .3s ease;
    span {
      display: inline-flex;
      i {
        display: block;
        --move: 12px;
        --move-y: -8px;
        --blur: 4px;
        font-size: 14px;
        transition: all .3s ease;

      }
    }
    &:hover {
      transform: translateY(-3px);
      span {
        i {
          animation:  smoke .75s linear var(--d);
        }
        i:nth-child(1){
          --d: 0.05s;
        }
        i:nth-child(2){
          --d: 0.1s;
        }
        i:nth-child(3){
          --d: 0.15s;
        }
        i:nth-child(4){
          --d: 0.2s;
        }
        i:nth-child(5){
          --d: 0.25s;
        }
        i:nth-child(6){
          --d: 0.3s;
        }
        i:nth-child(7){
          --d: 0.35s;
        }
        i:nth-child(8){
          --d: 0.4s;
        }
      }
    }
  }

}
@keyframes smoke {
  25% {
    transform: translateX(0);
  }
  45% {
    filter: blur(var(--blur));
  }
  50%, 50.1% {
    opacity: 0;
  }
  50% {
    -webkit-transform: translateX(var(--move)) translateY(var(--move-y));
    transform: translateX(var(--move)) translateY(var(--move-y));
  }
  50.1% {
    transform: translateX(calc(var(--move) * -1));
  }
  55% {
    filter: blur(var(--blur));
  }
  75% {
    transform: translateX(0);
  }
}
/* 滚动变色 */
.float {
    background-color: #fff!important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  .login-boxs .login {
    color: @bgBlue;
  }
  .link-item {
    color: #666;
  }
  .link-item:hover {
    color: #000;
    &:after {

      background-color: @bgBlue;
    }
  }
  .wap-menu .wap-nav-btn .line {
    background-color: @bgBlue;
  }
}

  /* 导航选中效果 */
.header-center-box .router-link-exact-active {
    color: #fff;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 40px;
      height: 2px;
      margin-left: -20px;
      background-color: #fff;
    }
  }
.header-center-box .free-try-btn:after {
  display: none!important;
}
.float .header-center-box .router-link-exact-active {
  color: #000;
  i {
    color: #fff;
  }
  &:after {
    background-color: @bgBlue;
  }
}



.pc-logo {
  display: block;
}
.wap-logo {
  display: none;
}
.wap-menu {
  display: none;
}


@media all and (max-width:1050px) {
    ::-webkit-scrollbar {
        height: 4px;
    }

    #nav {
        overflow: auto hidden;
    }
}

@media all and (max-width:750px) {
    .center-box {
        width: 7.5rem;
        padding: 0 .3rem 1rem;
    }
  #main {
    overflow: hidden;

  }
  ::-webkit-scrollbar,::-webkit-scrollbar-thumb,::-webkit-scrollbar-track {
    display: none;
  }

  .header-center-box .router-link-exact-active:after {
    display: none;
  }
  .pc-logo {
    display: none;
  }
  .wap-logo {
    display: block;
    width: 3rem;
  }
  .wap-nav-log {
    img {
      width: 3rem;
    }
  }
  .head-nav-list {
    display: none;

  }
  .login-boxs {
    display: none;
  }
  #nav {
    height: .88rem;
      overflow: visible;
  }
  .header-center-box {
    position: relative;
    width: 7.5rem;
    height: 100%;
    padding: 0 .2rem;
    justify-content: space-between;
    .head-logo {
      margin-left: 0px;
      margin-right: 0;
    }
  }

  .wap-nav-log {
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.18rem;
    padding: 0 .4rem;
    border-bottom: 0.01rem solid #e6e6e6;
  }
  .wap-menu {

    display: block;
    width: .5rem;
    line-height: .5rem;
    cursor: pointer;
  }
  .wap-menu .show .line:nth-child(1) {
    transform:translateY(0.2rem) rotate(45deg)
  }
  .wap-menu .show .line:nth-child(2) {
    transform:scaleX(0);
    transform-origin:right
  }
  .wap-menu .show .line:nth-child(3) {
    transform:translateY(-0.17rem) rotate(-45deg)
  }
  .wap-menu .wap-nav-btn {
    width:100%
  }
  .wap-menu .wap-nav-btn .line {
    position:relative;
    display:block;
    width:.5rem;
    height:.04rem;
    overflow:hidden;
    background-color:#fff;
    transition:.3s
  }
  .wap-menu .wap-nav-btn .line:nth-child(2) {
    margin:.15rem 0
  }
  .wap-menu .wap-nav-list {
    display:block;
    overflow:hidden;
    position:absolute;
    width: 70%;
    height: 100vh;
    top:0;
    left:-100%;
    background-color:#fff;
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    z-index:999;
  }
    .transparent-mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 998;
    }
  .wap-menu .wap-nav-list .wap-nav-item {
    position: relative;
    width:100%;
    line-height:1rem;
    font-size:.3rem;
    padding-left:.75rem;
    margin-bottom: .15rem;
  }
  .wap-menu .wap-nav-list .wap-nav-item a {
    display:block;
    color:#333;
  }
  .wap-menu .wap-nav-list .active {
    background-color: rgba(235,242,255);
    a {
      color: #3b7ffc;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: .04rem;
      height: 1rem;
      background-color: #3b7ffc;
    }
  }

  .wap-menu .wap-nav-list .wap-nav-item .sub-nav {
    display:none
  }
  .wap-menu .wap-nav-list .wap-nav-item .sub-nav li {
    font-size:.26rem;
    padding:0;
    border-top:0
  }
  .wap-menu .wap-nav-list .wap-nav-item:first-child {
    border-top:1px solid #eaeaea
  }

  .free-try-btn {
    width: 2.2rem;
    height: .68rem;
    line-height: .68rem;
    margin: auto;
  }
  .banner-inner {
    width: 7.5rem;
    .free-try-btn {
      margin-top: .7rem;
      font-size: .25rem;
    }
  }

}


</style>

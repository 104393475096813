<template>
    <div class="spa-func">
        <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat">
                <div class="banner-words-box">
                    <h1>美匣云EPR</h1>
                    <h4 class="func-banner-tit"><span>更高效 更好用 更智能 更强大 </span> 的旅行社系统</h4>
                </div>
                <div class="banner-imgs">
                    <img src="../../public/img/sapFunc/func_banner_img.png" alt="" class="lazyload">
                </div>
            </div>
        </div>

        <div class="spa-content">
            <div class="spa-nav">
                <ul ref="tabTitle">
                    <li class="spa-link active" name="anchor1">
                        <h1>OTA平台无缝对接</h1>
                    </li>
                    <li class="spa-link " name="anchor2">
                        <h1>强大的API对接能力</h1>
                    </li>
                    <li class="spa-link " name="anchor3">
                        <h1>OCR证件识别系统</h1>
                    </li>
                    <li class="spa-link " name="anchor4">
                        <h1>资源库存管理系统</h1>
                    </li>
                    <li class="spa-link " name="anchor5">
                        <h1>CRM客户管理系统</h1>
                    </li>
                    <li class="spa-link " name="anchor6">
                        <h1>商业数据大盘</h1>
                    </li>
                </ul>
            </div>
            <ul>
                <li class="spa-content-item spa-p1" id="anchor1">
                    <div class="center-box spa-p1-inner">
                        <div class="content-box">
                            <div class="left">
                                <div class="wsc-p-img">
                                    <img src="../../public/img/sapFunc/func_p1_img.png" alt="">
                                </div>

                            </div>
                            <div class="right">
                                <div class="wsc-p-tit-box">
                                    <h1><span>OTA平台无缝对接</span></h1>
                                    <p>飞猪、马蜂窝、携程、途牛、美团、去哪儿网等OTA平台无缝对接。订单信息自动同步进入系统
                                        ，客服再也不用手工搬单；订单到账及结算记录自动同步，各类佣金、手续费、退款金额、补款
                                        金额、订单真实到账金额一目了然。同步速度快，同步信息全，美匣云ERP系统是OTA商家的不
                                        二之选。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="spa-content-item spa-p2" id="anchor2">
                     <div class="center-box spa-p2-inner">
                        <div class="content-box">
                            <div class="left">
                                <div class="wsc-p-tit-box">
                                    <h1><span>强大的API对接能力</span></h1>
                                    <p>与各类保险平台、各类电子合同平台、 12301电子合同、旅游资源分销平台、酒店供应商系统、
                                        门票供应商系统、其他供应商系统、金蝶和用友财务系统等进行API接口对接，提供美匣云ERP
                                        系统API文档。</p>
                                </div>

                            </div>
                            <div class="right">
                                <div class="wsc-p-img">
                                    <img src="../../public/img/sapFunc/func_p2_img.png" alt="">
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li class="spa-content-item spa-p3" id="anchor3">
                     <div class="center-box spa-p3-inner">
                        <div class="content-box">
                            <div class="left">
                                <div class="wsc-p-img">
                                    <img src="../../public/img/sapFunc/func_p3_img.png" alt="" >
                                </div>

                            </div>
                            <div class="right">
                                <div class="wsc-p-tit-box">
                                    <h1><span>OCR证件识别系统</span></h1>
                                    <p>系统支持身份证、护照、港澳通行证等证件识别，拍照上传证件照片完成信息识别，快速完成客
                                        人信息补充，告别手动录入客人信息的繁琐工作，您可以拥有！</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="spa-content-item spa-p4" id="anchor4">
                     <div class="center-box spa-p4-inner">
                        <div class="content-box">
                            <div class="left">
                                <div class="wsc-p-tit-box">
                                    <h1><span>资源库存管理系统</span></h1>
                                    <p>机票、大巴车位、门票等各类资源的库存管控，既可以多团销售共享资源，又可严防资源超
                                        卖，非常有利于资源库存的集中化管控。</p>
                                </div>

                            </div>
                            <div class="right">
                                <div class="wsc-p-img">
                                    <img src="../../public/img/sapFunc/func_p4_img.png" alt="">
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li class="spa-content-item spa-p5" id="anchor5">
                     <div class="center-box spa-p5-inner">
                        <div class="content-box">
                            <div class="left">
                                <div class="wsc-p-img">
                                    <img src="../../public/img/sapFunc/func_p5_img.png" alt="">
                                </div>

                            </div>
                            <div class="right">
                                <div class="wsc-p-tit-box">
                                    <h1><span>CRM客户管理系统</span></h1>
                                    <p>将公司客户档案、会员信息进行集中化管理；会员生日提醒，节日关怀；出行爱好、订单数据分
                                        析；各种会员营销工具，助力提升客户的复购率。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="spa-content-item spa-p6" id="anchor6">
                     <div class="center-box spa-p6-inner">
                        <div class="content-box">
                            <div class="left">
                                <div class="wsc-p-tit-box">
                                    <h1><span>商业数据大盘</span></h1>
                                    <p>订单、收客、盈利、销售排行、产品排行、计调排行、资金流水、出团概况的即时掌握。为
                                        销售目标的决定，以及发展方向的研判做极好的数据支撑。</p>
                                </div>

                            </div>
                            <div class="right">
                                <div class="wsc-p-img">
                                    <img src="../../public/img/sapFunc/func_p6_img.jpg" alt=""  >
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <free-try></free-try>
        <foot-box></foot-box>
    </div>
</template>

<script>
    import FreeTry from '@/components/FreeTry.vue'
    import FootBox from '@/components/Footer.vue'
    let vm;
    export default {
        name: "SpaFunc", // 特色功能页面
        components: {
            FreeTry,
            FootBox
        },
        data(){
            return {
                anchor:1
            }
        },
        methods: {

            init() {
                console.log(vm.$refs.tabTitle);
                let timer = setTimeout(function () {
                    let tabtitle = vm.$refs.tabTitle.querySelectorAll('.spa-link');
                    tabtitle.forEach(function (e,idx) {

                        e.onclick = function () {

                            tabtitle.forEach(function (i) {
                                $(i).removeClass('active')
                            });

                            $(this).addClass('active')
                            let name = this.getAttribute('name');

                            $('html,body').animate({scrollTop: $("#" + name).offset().top - 80}, 1000);
                            vm.anchor = idx+1;
                            // window.history.replaceState({}, '', 'enhanceScheme.html');
                        }
                    });
                    clearTimeout(timer)
                }, 100)
            },

        },
        mounted() {
            vm.init();
        },
        created(){
            vm = this
        }
    }
</script>

<style lang="less">
   
    .spa-func{
        .banner-box {
            width: 100%;
            height: 600px;
            background-image: url("../../public/img/sapFunc/func_banner_bg.png");
            background-size: cover;
        }
        .banner-imgs {
            top: 0;
        }
        .banner-words-box {
            margin-top: 216px;
            h1 {
                font-size: 60px;
                margin-bottom: 40px;
                font-weight: normal;
            }
        }
        .func-banner-tit {
            font-size: 20px;
            color: #fff;

            span {
                font-size: 30px;
                color: #5ddfff;
            }
        }
        .spa-content {
            position: relative;
        }
        .spa-content-item {
            .content-box {
                display: flex;
                justify-content: space-between;
            }
            .wsc-p-img {
                overflow: hidden;
                margin-top: 80px;
                border-radius: 10px;
                font-size: 0;
                img {
                    transition: all .3s linear;
                    &:hover {
                        /*transform: scale(1.02);*/
                    }
                }
            }
            .wsc-p-tit-box {
                width: 600px;
            }

        }
        .spa-content-item:nth-child(2n) {
            background-color: #f8f8f8;
        }

        .spa-p1 {
            .wsc-p-img {
                margin-top: 150px;
            }
        }
        .spa-p2, .spa-p3,.spa-p4,.spa-p5,.spa-p6 {
            height: 500px;
            .wsc-p-tit-box {
                padding-top: 140px;
            }
            .spa-p-img {
                padding-top: 80px;
            }
        }

    }

    /*导航*/
    .spa-nav {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        
        ul {
            display: flex;
            flex-wrap: nowrap;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            background-color: #fff;
            .active {
                h1 {
                    color: #4486fc;
                }
            }
            .spa-link {
                position: relative;
                width: 200px;
                height: 100px;
                line-height: 100px;
                text-align: center;
                cursor: pointer;
                h1 {
                    color: #333;
                    font-size: 16px;
                    transition: all .3s linear;
                }
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: block;
                    width: 1px;
                    height: 60px;
                    background-color: #e6e6e6;
                    transform: translateY(-50%);
                    transition: all .3s linear;
                }
                &:hover {
                    h1 {
                        color: #4486fc;
                    }
                }
            }
            .spa-link:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }



    /*p1*/
    .spa-p1 {
        height: 575px;
        .wsc-p-tit-box {
            padding-top: 220px;
        }
        .spa-p-img {
            padding-top: 152px;
        }
    }


</style>
<template>
    <div class="commerce-erp">
        <div id="banner" class="banner-box">
            <div class="banner-inner clearFloat">
              <div class="banner-words-box">
                <h1>旅行社电商组团管理系统</h1>
                <h2>美匣云ERP</h2>
                <h3>适合组团社、OTA电商的自动智能化ERP</h3>
                <div class="little-words">
                    对接OTA丨财务自动对账丨操作简单丨功能齐全
                </div>
                <a href="http://c0k5mn9s9hp8xmzj.mikecrm.com/NAYYPGh" class="free-try-btn" target="_blank">申请使用</a>
              </div>
              <div class="banner-qrcode-box">
                <div class="title">扫码添加微信</div>
                <div class="content">
                    <p><span>免费</span>申请体验</p>
                    <img src="../../public/img/commerceErp/p1/qrcode.png" alt="" class="imgCode">
                    <div class="program">
                        <div>
                            <img src="../../public/img/commerceErp/p1/p1_img2.png" alt="" class="littleImgI">
                            <p>组团社</p>
                        </div>
                        <div>
                            <img src="../../public/img/commerceErp/p1/p1_img3.png" alt="" class="littleImgII">
                            <p>OTA电商</p>
                        </div>
                    </div>
                </div>
              </div>
              <img src="../../public/img/commerceErp/p1/p1_img1.png" class="banner-img">
            </div>
        </div>

        <div class="commerceErp-p1">
            <div class="center-box commerceErp-p1-inner">
                <div class="inner-title">
                    <h2>电商组团社业务全覆盖</h2>
                    <h3>OTA电商丨C端直客  一站式管理</h3>
                </div>
                <div class="content-box ">
                    <div class="image-box">
                        <img src="../../public/img/commerceErp/p2/p2_img1.png" >
                        <img src="../../public/img/commerceErp/p2/p2_img2.png" >
                        <img src="../../public/img/commerceErp/p2/p2_img3.png" >
                        <img src="../../public/img/commerceErp/p2/p2_img4.png" >
                        <img src="../../public/img/commerceErp/p2/p2_img5.png" >
                    </div>
                    <img src="../../public/img/commerceErp/p2/p2_img6.png" class="commerceErp-p1-img">
                </div>
            </div>
        </div>

        <div class="commerceErp-p2">
            <div class="center-box commerceErp-p2-inner">
                <div class="inner-title">
                    <h2>面向旅游组团社-OTA电商的解决方案</h2>
                    <h3>岗位操作全覆盖  全自动化</h3>
                </div>
                <div class="content-box ">
                    <div class="commerceErp-p2-type">
                        <div class="commerceErp-p2-type-item">
                            <div class="item-num">1</div>
                            <div class="item-content">
                                <p>产品</p>
                                <p>路线、门票、签证等产品上下架，各类型供应商资料录入</p>
                            </div>
                        </div>
                        <div class="commerceErp-p2-type-item">
                            <div class="item-num">2</div>
                            <div class="item-content">
                                <p>销售</p>
                                <p>处理订单的认领、录入、收付款，补录客户信息、处理合同、开票</p>
                            </div>
                        </div>
                        <div class="commerceErp-p2-type-item">
                            <div class="item-num">3</div>
                            <div class="item-content">
                                <p>计调</p>
                                <p>自动/手动排团、添加采购单，管理供应商资源、对账结算</p>
                            </div>
                        </div>
                        <div class="commerceErp-p2-type-item">
                            <div class="item-num">4</div>
                            <div class="item-content">
                                <p>财务</p>
                                <p>收付款计划管理、对账结算，流水管理、发票管理、审批操作</p>
                            </div>
                        </div>
                        <div class="commerceErp-p2-type-item">
                            <div class="item-num">5</div>
                            <div class="item-content">
                                <p>供应商</p>
                                <p>可通过手机端/邮件，一键查看并确认订单、订单对账</p>
                            </div>
                        </div>
                    </div>
                    <img src="../../public/img/commerceErp/p3/p3_img1.png" class="commerceErp-p2-img">
                </div>
            </div>
        </div>

        <div class="commerceErp-p3">
            <div class="center-box commerceErp-p3-inner">
                <div class="inner-title">
                    <h2>组团社各岗位全覆盖</h2>
                    <h3>各岗位操作简单   分工明确</h3>
                </div>
                <div class="content-box ">
                   <img src="../../public/img/commerceErp/p4/p4_img.png" class="commerceErp-p3-img">
                </div>
            </div>
        </div>

        <div class="commerceErp-p4">
            <div class="center-box commerceErp-p4-inner">
                <div class="inner-title">
                    <h2>美匣云旅行社ERP系统</h2>
                    <h3>多项功能   助力组团社-OTA电商实现数字化和自动化</h3>
                </div>
                <div class="content-box ">
                   <div class="commerceErp-p4-words">
                        <div class="commerceErp-p4-words-itemI">
                            <div class="moveItem">
                                <p class="item-title">超扩展性</p>
                                <p class="item-content commerceErp-p4-pc">自动同步飞猪，马蜂窝、携程<br>、美团、抖音小店等平台订单</p>
                                <p class="item-content commerceErp-p4-wap">自动同步飞猪，马蜂窝、携程、美团、抖音小店等平台订单</p>
                            </div>
                            <div class="moveItem">
                                <p class="item-title">高自动化</p>
                                <p class="item-content commerceErp-p4-pc">线路、酒店、门票、签证等各<br>类型的订单均可自动同步</p>
                                <p class="item-content commerceErp-p4-wap">线路、酒店、门票、签证等各类型的订单均可自动同步</p>
                            </div>
                        </div>
                        <div class="commerceErp-p4-words-itemII">
                            <div class="moveItem">
                                <p class="item-title">同步更新</p>
                                <p class="item-content commerceErp-p4-pc">可通过系统反向控制订单状态<br>，发货状态，订单备注等信息</p>
                                <p class="item-content commerceErp-p4-wap">可通过系统反向控制订单状态，发货状态，订单备注等信息</p>
                            </div>
                            <div class="moveItem">
                                <p class="item-title">数据统计</p>
                                <p class="item-content commerceErp-p4-pc">自动统计订单收入，成本支出<br>，各类手续费，以及订单利润</p>
                                <p class="item-content commerceErp-p4-wap">自动统计订单收入，成本支出，各类手续费，以及订单利润</p>
                            </div>
                        </div>
                        <div class="commerceErp-p4-words-itemIII">
                            <div class="moveItem">
                                <p class="item-title">飞猪码商</p>
                                <p class="item-content commerceErp-p4-pc">飞猪平台授权码商系统，对接<br>第三方票务系统，自动发码</p>
                                <p class="item-content commerceErp-p4-wap">飞猪平台授权码商系统，对接第三方票务系统，自动发码</p>
                            </div>
                            <div class="moveItem">
                                <p class="item-title">飞猪API</p>
                                <p class="item-content commerceErp-p4-pc">自动同步飞猪店铺订单流水，<br>自动完成订单的收款和对账</p>
                                <p class="item-content commerceErp-p4-wap">自动同步飞猪店铺订单流水，自动完成订单的收款和对账</p>
                            </div>
                        </div>
                   </div>
                   <img src="../../public/img/commerceErp/p5/p5_img.png" class="commerceErp-p4-wap">
                </div>
            </div>
        </div>

        <div class="commerceErp-p5">
            <div class="commerceErp-p5-inner">
                <div class="commerceErp-p5-inner-content">
                    <img src="../../public/img/commerceErp/p6/p6_img1.png" alt="" class="moveItem">
                    <p class="title moveItem">上海美匣网络科技有限公司成立于2016年</p>
                    <p class="subTitle moveItem">是一家专注于旅游行业的软件系统解决方案提供商和服务商</p>
                </div>
                <div class="content-box">
                    <img src="../../public/img/commerceErp/p6/p6_img3.png" alt="">
                    <div>
                        <p class="title">美匣云</p>
                        <p class="words">一套集旅行社ERP系统、旅游小程序商城、签证系统，于一体的<br>旅游SAAS系统平台，全方位解决旅行社订单管理、产品资源、<br>系统对账等难题</p>
                        <div class="tag">致力于为旅游电商、组团社等商家提供强大完善的信息化系统和数字化营销方案</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="commerceErp-p6">
            <div class="commerceErp-p6-inner">
                <div class="commerceErp-p6-inner-content">
                    <p class="title">美匣云ERP</p>
                    <p class="subTitle">为旅行社自动化服务</p>
                </div>
                <div class="content-box">
                    <div class="item-box">
                        <div class="commerceErp-p6-item">
                            <div class="commerceErp-p6-item-top">
                                <img src="../../public/img/commerceErp/p7/p7_img1.png" alt="">
                                <p>电商-组团解决方案</p>
                            </div>
                            <div class="commerceErp-p6-item-bottom">
                                <p>（自动化ERP管理系统）</p>
                                <p>自动化</p>
                                <p>简单易上手</p>
                            </div>
                        </div>
                        <div class="commerceErp-p6-item">
                            <div class="commerceErp-p6-item-top">
                                <img src="../../public/img/commerceErp/p7/p7_img2.png" alt="">
                                <p>小程序微商城</p>
                            </div>
                            <div class="commerceErp-p6-item-bottom">
                                <p>（微信小程序、拼团小程序）</p>
                                <p>产品展示</p>
                                <p>会员运营</p>
                            </div>
                        </div>
                        <div class="commerceErp-p6-item">
                            <div class="commerceErp-p6-item-top">
                                <img src="../../public/img/commerceErp/p7/p7_img3.png" alt="">
                                <p>美匣云-签证系统</p>
                            </div>
                            <div class="commerceErp-p6-item-bottom">
                                <p>（新加坡、泰国、日本等…）</p>
                                <p>自动送签</p>
                                <p>信息同步</p>
                            </div>
                        </div>
                    </div>
                    <a href="http://c0k5mn9s9hp8xmzj.mikecrm.com/NAYYPGh" class="btn" target="_blank">申请使用</a>
                </div>
            </div>
        </div>

        <!-- <free-try :msg="msg" :btn="btn"></free-try> -->
        <foot-box></foot-box>
        <pop-window></pop-window>
    </div>
</template>

<script>
    import FreeTry from '@/components/FreeTry.vue'
    import FootBox from '@/components/Footer.vue'
    import PopWindow from '@/components/PopWindow.vue'
    let vm;
    export default {
        name: "CommerceErp",
        components: {
            FreeTry,
            FootBox,
            PopWindow,
        },
        data(){
            return {
                msg: '立即试用体验美匣云旅行社ERP系统',
                btn: '立即试用体验'
            }
        },
        methods: {

            // 动态效果
            _move() {

                if($('.commerce-erp').length > 0){
                    let $winHeight = $(window).height(); // 窗口可视区域的高度
                    let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离

                    // 大标题
                    let $pTit1 = $('.commerceErp-p1-inner')
                    let $pTit2 = $('.commerceErp-p2-inner')
                    let $pTit3 = $('.commerceErp-p3-inner')
                    let $pTit4 = $('.commerceErp-p4-inner')
                    let moveBoxArr = [$pTit1,$pTit2,$pTit3,$pTit4]
                    moveBoxArr.forEach( (item,index) => {
                        if (($winHeight + $winScrollHeight) >= item.offset().top) {
                            $(item).find('.inner-title').addClass('animated fadeInUp')
                        }
                    })

                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p1-inner .content-box').offset().top + 300) {
                        // p1 左右滑入
                        $('.commerceErp-p1-inner .image-box').addClass('animated fadeInLeft')
                        $('.commerceErp-p1-inner .commerceErp-p1-img').addClass('animated fadeInRight')
                    }

                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p2-inner .content-box').offset().top) {
                        $('.commerceErp-p2-inner .commerceErp-p2-type').addClass('animated fadeInLeft')
                        $('.commerceErp-p2-inner .commerceErp-p2-img').addClass('animated fadeInRight')
                    }

                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p3-inner .content-box').offset().top) {
                        $('.commerceErp-p3-inner .commerceErp-p3-img').addClass('animated swing')
                    }

                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p4-inner .content-box').offset().top) {
                        $('.commerceErp-p4-inner .moveItem').each(function(index,item){
                            if(index%2!=0){
                                $(item).addClass('animated fadeInLeft')
                            }else{
                                $(item).addClass('animated fadeInRight')
                            }
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        })
                    }

                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p5-inner').offset().top) {
                        $('.commerceErp-p5-inner-content .moveItem').each(function(index,item){
                            if(index%2!=0){
                                $(item).addClass('animated fadeInLeft')
                            }else{
                                $(item).addClass('animated fadeInRight')
                            }
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        })
                    }

                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p6-inner').offset().top) {
                        $('.commerceErp-p6-inner .commerceErp-p6-inner-content').addClass('animated fadeInDown')
                    }
                    if (($winHeight + $winScrollHeight) >= $('.commerceErp-p6-inner .content-box').offset().top) {
                        $('.commerceErp-p6-item').each(function(index,item){
                            $(item).addClass('animated fadeInLeft')
                            $(item).css({
                                "animation-delay": .15 * index + "s"
                            })
                        })
                    }
                }

            },


        },
        mounted(){
            vm = this
            // 滚动屏幕触发
            let canRun = true;
            let timer;
            vm._move()
            $(window).scroll(function () {

                if (!canRun) {

                    return
                }
                canRun = false
                timer = setTimeout(function () {

                    vm._move()
                    canRun = true
                }, 300)

            });

        },
    }
</script>

<style lang="less">
    @bgBlue: #3b7ffc;
    .commerce-erp {
        min-width: 1200px;
        width: 100%;
        .inner-title{
            position: relative;
            text-align: center;
            h2{
                font-family: SourceHanSansCN-Bold;
                font-size: 60px;
                line-height: 60px;
                color: #19171a;
                margin-bottom: 24px;
            }
            h3{
                font-family: SourceHanSansCN-Normal;
                font-size: 36px;
                line-height: 36px;
                color: #555357;
            }
            &::after{
                display: block;
                content: "";
                width: 80px;
                height: 8px;
                background-image: linear-gradient(90deg, #4646fe 0%, #288aef 50%, #09d2da 100%);
                border-radius: 4px;
                position: absolute;
                top: 140px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .banner-box {
            width: 100%;
            height: 950px;
            background-image: url("../../public/img/commerceErp/p1/p1_banner.png");
            background-size: cover;
            background-repeat: no-repeat;
        }
        .banner-inner{
            width: 1221px;
        }
        .banner-words-box {
            margin-top: 212px;
            color: #ffffff;
            h1{
                font-family: SourceHanSansCN-Normal;
                font-size: 40px;
                line-height: 40px;
                margin-bottom: 23px;
            }
            h2{
                font-family: SourceHanSansCN-Bold;
                font-size: 90px;
                line-height: 90px;  
                margin-bottom: 23px;
            }
            h3{
                font-family: SourceHanSansCN-Bold;
                font-size: 36px;
                line-height: 36px;
                margin-bottom: 60px;
            }
            .little-words{
                font-family: SourceHanSansCN-Medium;
                font-size: 30px;
                line-height: 30px;
                color: #38f6ff;
                margin-bottom: 83px;
            }
            .free-try-btn{
                width: 340px;
                height: 74px;
                background-color: #3ecf2a;
                border-radius: 37px;
                font-family: SourceHanSansCN-Bold;
                font-size: 39px;
                line-height: 74px;
                color: #ffffff;
                text-align: center;
                margin: 0;
            }
        }
        .banner-img{
            position: absolute;
            top: 58px;
            right: 0;
            z-index: 1;
        }
        .banner-qrcode-box:hover+ .banner-img{
            transform: scale(1.1);
        }
        .banner-qrcode-box{
            position: absolute;
            top: 208px;
            right: 101px;
            z-index: 2;
            cursor: pointer;
            .title{
                width: 251px;
                height: 59px;
                background-image: linear-gradient(90deg, #4646fe 0%, #288aef 50%, #09d2da 100%), linear-gradient(#32ccff, #32ccff);
                box-shadow: 0px 0px 21px 0px rgba(2, 0, 234, 0.25);
                border-radius: 10px;
                color: #ffffff;
                font-family: SourceHanSansCN-Medium;
                font-size: 18px;
                line-height: 42px;
                text-align: center;
                margin: auto;
            }
            .content{
                width: 300px;
                height: 403px;
                background-color: #ffffff;
                border-radius: 10px;
                padding: 40px 64px 35px;
                box-sizing: border-box;
                position: relative;
                top: -17px;
                p{
                    font-family: SourceHanSansCN-Bold;
                    font-size: 28px;
                    line-height: 28px;
                    color: #555357;
                    span{
                        color: #3b60f9;
                    }
                }
                .imgCode{
                    width: 172px;
	                height: 171px;
                    margin-top: 31px;
                }
                .program{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 29px;
                    >div{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        p{
                            font-family: SourceHanSansCN-Medium;
                            font-size: 16px;
                            line-height: 16px;
                            color: #545454;
                            margin-top: 13px;
                        }
                    }
                    .littleImgI{
                        width: 36px;
	                    height: 42px;
                    }
                    .littleImgII{
                        width: 39px;
	                    height: 37px;
                    }
                }
            }
        }
    }
    
    /*p1*/ 
    .commerceErp-p1{
        background-color: #ffffff;
        padding-top: 57px;
    }
    .commerceErp-p1-inner {
        .content-box {
            padding-top: 65px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .image-box{
                img{
                    margin-right: 29px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            img{
                margin-top: 48px;
            }
        }
    }

    /*p2*/
    .commerceErp-p2 {
        padding: 108px 0 145px;
        background-image: linear-gradient(180deg, rgba(113,127,163,0.3) 0, #f5f5fa 100%);
        .content-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 111px;
            .commerceErp-p2-type{
                &-item{
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 42px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .item-num{
                        width: 42px;
                        height: 42px;
                        font-family: SourceHanSansCN-Bold;
                        font-size: 24px;
                        line-height: 42px;
                        color: #ffffff;
                        text-align: center;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                    &:nth-child(1) .item-num{
                        background-image: linear-gradient(135deg, #fd6e6a 0%, #ffc600 100%);
                    }
                    &:nth-child(2) .item-num{
                        background-image: linear-gradient(135deg, #3b2667 0%, #bc78ec 100%);
                    }
                    &:nth-child(3) .item-num{
                        background-image: linear-gradient(135deg, #ff96f9 0%, #c32bac 100%);
                    }
                    &:nth-child(4) .item-num{
                        background-image: linear-gradient(135deg, #72edf2 0%, #5151e5 100%);
                    }
                    &:nth-child(5) .item-num{
                        background-image: linear-gradient(135deg, #01e9bd 0%, #007cde 100%);
                    }
                    .item-content{
                        margin-top: 7px;
                        p:first-child{
                            font-family: SourceHanSansCN-Bold;
                            font-size: 24px;
                            line-height: 24px;
                            color: #333333;
                            margin-bottom: 18px;
                        }
                        p:last-child{
                            font-family: SourceHanSansCN-Regular;
                            font-size: 20px;
                            line-height: 20px;
                            color: #686868;
                        }
                    }
                }
            }
            .commerceErp-p2-img{
                margin-top: 78px;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
    /*p3*/
    .commerceErp-p3 {
        padding: 108px 0 97px;
        background-image: url("../../public/img/commerceErp/p4/p4_bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        .inner-title{
            h2,h3{
                color: #ffffff;
            }  
        }
        .content-box{
            margin-top: 78px;
        }
        &-img{
            margin: auto;
        }
    }

    /*p4*/
    .commerceErp-p4 {
        padding: 108px 0 140px;
        background-image: linear-gradient(180deg, rgba(113,127,163,0.3) 0, #f5f5fa 100%);
        .content-box{
            margin-top: 119px;
        }
        &-words{
            width: 528px;
            height: 516px;
            margin: auto;
            background-image: url("../../public/img/commerceErp/p5/p5_img.png");
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            &-itemI,&-itemII,&-itemIII{
                position: relative;
                .item-title{
                    font-family: SourceHanSansCN-Bold;
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 19px;
                    white-space: nowrap;
                }
                .item-content{
                    font-family: SourceHanSansCN-Regular;
                    font-size: 20px;
                    line-height: 28px;
                    color: #545454;
                    white-space: nowrap;
                }
            }
            &-itemI{
                position: absolute;
                top: 5px;
                >div:first-child{
                    position: absolute;
                    left: -215px;
                    .item-title{
                        text-align: right;
                        color: #564ceb;
                    }
                }
                >div:last-child{
                    position: absolute;
                    left: 496px;
                   .item-title{
                        color: #db780e;
                   }
                }
            }
             &-itemII{
                position: absolute;
                top: 203px;
                >div:first-child{
                    position: absolute;
                    left: -291px;
                    top: 0;
                   .item-title{
                    text-align: right;
                        color: #219e6c;
                   }
                }
                >div:last-child{
                    position: absolute;
                    left: 560px;
                    top: 0;
                   .item-title{
                        color: #d74370;
                   }
                }
            }
             &-itemIII{
                position: absolute;
                top: 413px;
                >div:first-child{
                    position: absolute;
                    left: -215px;
                   .item-title{
                        text-align: right;
                        color: #cba216;
                   }
                }
                >div:last-child{
                    position: absolute;
                    left: 496px;
                   .item-title{
                        color: #2b71c6;
                   }
                }
            }
        }
        &-pc{
            display: block;
        }
        &-wap{
            display: none;
        }
    }
    
    .commerceErp-p5 {
        padding-top: 126px;
        &-inner-content{
            img{
                margin: 0 auto 35px;
                display: block;
            }
            p{
                text-align: center;
            }
            .title{
                font-family: SourceHanSansCN-Medium;
                font-size: 36px;
                line-height: 36px;
                color: #333333;
                margin-bottom: 20px;
            }
            .subTitle{
                font-family: SourceHanSansCN-Normal;
                font-size: 28px;
                line-height: 28px;
                color: #545454;
            }
        }
        .content-box{
            margin-top: 138px;
            width: 100%;
            height: 397px;
            background-color: #f5f5fa;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            >div{
                margin-top: 30px;
                margin-left: 48px;
            }
            img{
                margin-top: -27px;
            }
            .title{
                font-family: MicrosoftYaHei;
                font-weight: bold;
                font-size: 28px;
                line-height: 28px;
                color: #2f2f2f;
                margin-bottom: 16px;
            }
            .words{
                font-family: MicrosoftYaHei;
                font-size: 20px;
                line-height: 30px;
                color: #2f2f2f;
	            opacity: 0.8;
                margin-bottom: 34px;
            }
            .tag{
                width: 565px;
	            height: 110px;
                padding: 23px 28px 44px 18px;
                background-image: url("../../public/img/commerceErp/p6/p6_img2.png");
                background-size: cover;
                background-repeat: no-repeat;
                font-family: SourceHanSansCN-Medium;
                font-size: 20px;
                line-height: 24px;
                color: #4581f9;
            }
        }
    }
   
    /*p6*/
    .commerceErp-p6 {
        padding: 108px 0 77px;
        background-image: url("../../public/img/commerceErp/p7/p7_bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        color: #ffffff;
        &-inner-content{
            margin-bottom: 86px;
            text-align: center;
            .title{
                font-family: SourceHanSansCN-Bold;
                font-size: 60px;
                line-height: 60px;
                margin-bottom: 33px;
            }
            .subTitle{
                font-family: SourceHanSansCN-Light;
                font-size: 36px;
                line-height: 36px;   
            }
        }
        .item-box{
            display: flex;
            justify-content: center;
            margin-bottom: 92px;
            .commerceErp-p6-item{
                width: 320px;
                height: 400px;
                &-top{
                    width: 100%;
                    height: 244px;
                    border-radius: 10px 10px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img{
                        margin-bottom: 33px;
                    }
                    p{
                        font-family: SourceHanSansCN-Bold;
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
                &-bottom{
                    width: 100%;
                    height: 156px;
                    border-radius: 0px 0px 10px 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: SourceHanSansCN-Medium;
                    font-size: 20px;
                    line-height: 30px;
                }
                &:nth-child(1){
                    margin-right: 60px;
                    .commerceErp-p6-item-top{
                        background-color: rgba(70,70,254, 0.5);
                    }
                    .commerceErp-p6-item-bottom{
                        background-color: #4646fe;
                    }
                }
                &:nth-child(2){
                    margin-right: 60px;
                    .commerceErp-p6-item-top{
                        background-color: rgba(40,138,239, 0.5);
                    }
                    .commerceErp-p6-item-bottom{
                        background-color: #288aef;
                    }
                }
                &:nth-child(3){
                    .commerceErp-p6-item-top{
                        background-color: rgba(9,176,218, 0.5);
                    }
                    .commerceErp-p6-item-bottom{
                        background-color: #09b0da;
                    }
                }
            }
        }
        .btn{
            display: block;
            width: 286px;
            height: 82px;
            margin: auto;
            font-family: SourceHanSansCN-Bold;
            font-size: 39px;
            line-height: 82px;
            text-align: center;
            color: #ffffff;
            background-image: linear-gradient(45deg, #4646fe 0%, #288aef 50%, #09d2da 100%);
	        box-shadow: 0px 0px 21px 0px rgba(2, 0, 234, 0.25);
	        border-radius: 41px;
        }
    }
    


    @media all and (max-width: 750px) {
        .commerce-erp .banner-box {
            height: auto;
        }
        .commerce-erp {
            width: 7.5rem;
            min-width: auto;
            .banner-inner {
                width: 7.5rem;
                padding-bottom: 2rem;
                flex-direction: column;
            }
            .banner-words-box {
                margin-top: 2.12rem;
                h1{
                    font-size: 0.4rem;
                    line-height: 0.4rem;
                    margin-bottom: 0.23rem;
                }
                h2{
                    font-size: 0.4rem;
                    line-height: 0.4rem;
                    margin-bottom: 0.23rem;
                }
                h3{
                    font-size: 0.36rem;
                    line-height: 0.36rem;
                    margin-bottom: 0.6rem;
                }
                .little-words {
                    font-size: 0.3rem;
                    line-height: 0.3rem;
                    margin-bottom: 0.83rem;
                }
                .free-try-btn {
                    width: 3.4rem;
                    height: 0.74rem;
                    font-size: 0.39rem;
                    line-height: 0.74rem;
                    border-radius: 0.37rem;
                }
            }
        
            .banner-qrcode-box{
                position: unset;
                margin: 1rem auto 0;
                .title{
                    width: 2.51rem;
                    height: 0.59rem;
                    font-size: 0.18rem;
                    line-height: 0.42rem;
                }
                .content{
                    width: 3rem;
                    height: 4.6rem;
                    padding: 0.4rem 0.4rem 0.35rem;
                    top: -0.17rem;
                    p{
                        font-size: 0.28rem;
                        line-height: 0.28rem;
                        text-align: center;
                    }
                    .imgCode{
                        width: 2.2rem;
                        height: 2.21rem;
                        margin-top: 0.31rem;
                    }
                    .program{
                        margin-top: 0.29rem;
                        .littleImgI {
                            width: 0.36rem;
                            height: 0.42rem;
                        }
                        .littleImgII {
                            width: 0.39rem;
                            height: 0.37rem;
                        }
                        >div{
                            p{
                                font-size: 0.16rem;
                                line-height: 0.16rem;
                                margin-top: 0.13rem;
                            }
                        }
                    }
                }
            }
            .banner-img{
                top: 6rem;
                right: 6%;
                width: 100%;
            }
            .inner-title{
                h2{
                    font-size: 0.6rem;
                    line-height: 0.6rem;
                    margin-bottom: 0.24rem;
                }
                h3{
                    font-size: 0.36rem;
                    line-height: 0.36rem;
                }
                &::after{
                    width: 0.8rem;
                    height: 0.08rem;
                    top: 1.4rem;
                }
            }
            .center-box{
                padding-bottom: 0;
            }
        }

        /*.commerceErp-p1*/
        .commerceErp-p1-inner {
            height: auto;
            .content-box{
                padding-top: 0.65rem;
                .image-box{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        margin: 0.48rem auto 0;
                    }
                }
                img{
                    margin-top: 0.48rem;
                    width: 100%;
                }
            }
        }

        /*.commerceErp-p2 */
        .commerceErp-p2 {
            height: auto;
            padding: 1.08rem 0 1.45rem;
            .inner-title{
                h2{
                    font-size: 0.4rem;
                    line-height: 0.4rem;
                }
            }
            .content-box{
                margin-top: 1.11rem;
                flex-direction: column;
                justify-content: flex-start;
                .commerceErp-p2-type-item {
                    margin-bottom: 0.42rem;
                    .item-num{
                        width: 0.42rem;
                        height: 0.42rem;
                        font-size: 0.24rem;
                        line-height: 0.42rem;
                        margin-right: 0.15rem;
                    }
                    .item-content {
                        margin-top: 0.07rem;
                        p:first-child {
                            font-size: 0.24rem;
                            line-height: 0.24rem;
                            margin-bottom: 0.18rem;
                        }
                        p:last-child {
                            font-size: 0.2rem;
                            line-height: unset;
                        }
                    }
                }
                .commerceErp-p2-img {
                    margin-top: 0.78rem;
                    width: 100%;
                }
            }
        }

        /*.erp-p3*/
        .commerceErp-p3 {
            padding: 1.08rem 0 0.97rem;
            .content-box{
                margin-top: 0.78rem;
            }
            .commerceErp-p3-img{
                width: 100%;
            }
        }

        /*.commerceErp-p4*/
        .commerceErp-p4 {
            height: auto;
            padding: 1.08rem 0 1.4rem;
            .inner-title{
                h3{
                    font-size: 0.3rem;
                    line-height: 0.3rem;
                }
            }
            .content-box{
                margin-top: 1.19rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .commerceErp-p4-words{
                    width: 100%;
                    height: auto;
                    background-image: none;
                    margin-bottom: 0.6rem;
                    .commerceErp-p4-words-itemI,
                    .commerceErp-p4-words-itemII,
                    .commerceErp-p4-words-itemIII{
                        position: unset;
                        margin-bottom: 0.5rem;
                        .item-title{
                            font-size: 0.24rem;
                            line-height: 0.24rem;
                            margin-bottom: 0.19rem
                        }
                        .item-content{
                            font-size: 0.20rem;
                            line-height: 0.28rem;
                        }
                        >div:first-child{
                            position: unset;
                            margin-bottom: 0.2rem;
                            .item-title{
                                text-align: left;
                            }
                        }
                        >div:last-child{
                            position: unset;
                        }
                    }
                }
                .commerceErp-p4-pc{
                    display: none;
                }
                .commerceErp-p4-wap{
                    display: block;
                }
            }
        }
        
        /*.erp-p5*/
        .commerceErp-p5 {
            height: auto;
            padding-top: 1.26rem;
            .commerceErp-p5-inner-content{
                img{
                    margin: 0 auto 0.35rem;
                    width: 5.64rem;
                }
                .title{
                    font-size: 0.36rem;
                    line-height: 0.36rem;
                    margin-bottom: 0.2rem;
                }
                .subTitle {
                    font-size: 0.28rem;
                    line-height: 0.28rem;
                }
            }
            .content-box{
                margin-top: 1.38rem;
                height: auto;
                flex-direction: column;
                align-items: center;
                padding-bottom: 0.2rem;
                img{
                    margin-top: -0.27rem;
                    width: 5rem;
                }
                >div{
                    margin-top: 0.30rem;
                    margin-left: 0.48rem;
                }
                .title{
                    font-size: 0.28rem;
                    line-height: 0.28rem;
                    margin-bottom: 0.16rem;
                }
                .words{
                    font-size: 0.2rem;
                    line-height: 0.4rem;
                    margin-bottom: 0.34rem;
                }
                .tag {
                    width: 5.65rem;
                    height: 1.1rem;
                    padding: 0.23rem 0.28rem 0.44rem 0.18rem;
                    font-size: 0.2rem;
                    line-height: 0.24rem;
                    background-size: cover;
                }
            }
        }
        
        /*.commerceErp-p6*/
        .commerceErp-p6 {
            height: auto;
            padding: 1.08rem 0 0.77rem;
            .commerceErp-p6-inner-content{
                margin-bottom: 0.86rem;
                .title{
                    font-size: 0.6rem;
                    line-height: 0.6rem;
                    margin-bottom: 0.33rem;
                }
                .subTitle{
                    font-size: 0.36rem;
                    line-height: 0.36rem;
                }
            }
            .item-box{
                flex-direction: column;
                align-items: center;
                margin-bottom: 0.92rem;
                .commerceErp-p6-item{
                    margin-right: 0 !important;
                    width: 3.2rem;
                    height: 4rem;
                    margin-bottom: 0.6rem;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .commerceErp-p6-item-top {
                    width: 100%;
                    height: 2.44rem;
                    img{
                        width: 0.93rem;
                        margin-bottom: 0.33rem;
                    }
                    p{
                        font-size: 0.24rem;
                        line-height: 0.24rem;
                    }
                }
                .commerceErp-p6-item-bottom {
                    width: 100%;
                    height: 1.56rem;
                    font-size: 0.2rem;
                    line-height: 0.3rem;
                }
            }
            .btn{
                width: 2.86rem;
                height: 0.82rem;
                font-size: 0.39rem;
                line-height: 0.82rem;
                border-radius: 0.41rem;
            }
        }
       
    } // media end

</style>
<!--景区票务-->
<template>
  <div class="scenicTicket" >
    <div id="banner" class="banner-box">
      <div class="banner-inner clearFloat">
        <div class="banner-words-box">

          <h1>景区票务一体化解决方案</h1>
          <div class="little-words">
            <div class="">售票</div>
            <div class="">取票</div>
            <div class="">检票</div>
            <div class="">管票</div>
          </div>
          <h2>简单易上手  SaaS云平台低投入</h2>
          <router-link to="/register" class="free-try-btn" target="_blank">申请试用</router-link>
        </div>
        <div class="banner-imgs">
          <img src="../../public/img/scenicTicket/index_banner_img1.png" alt="美匣云票务系统" class="lazyload" draggable="false">
        </div>
      </div>
    </div>
    <div class="index-content sce-p1">
      <div class="center-box sce-p1-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">智慧景区票务解决方案<div class="divider"></div></h2>
          <p>服务对象</p>
        </div>
         <div class="process-box">
            <ul>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_1.png" alt="">
                        <p>自然风景区</p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_2.png" alt="">
                        <p>森林公园</p>
                    </div>
                </li>    
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_3.png" alt="">
                        <p>漂流温泉</p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_4.png" alt="">
                        <p>雪山滑雪</p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_5.png" alt="">
                        <p>溶洞观光</p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_6.png" alt="">
                        <p>田园综合体</p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_7.png" alt="">
                        <p>文旅度假村 </p>
                    </div>
                </li>
                
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_8.png" alt="">
                        <p>博物馆</p>
                    </div>
                </li>
                    <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_9.png" alt="">
                        <p>剧院展馆 </p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_10.png" alt="">
                        <p>名人故居</p>
                    </div>
                </li>
                    <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_11.png" alt="">
                        <p>水上乐园</p>
                    </div>
                </li>
                <li class="sce-p1-item">
                    <div class="sce-p1-item-imgbox">
                        <img class="sce-p1-item-img" src="../../public/img/scenicTicket/p1/sce_p1_img_12.png" alt="">
                        <p>特色小镇</p>
                    </div>
                </li>    
            </ul>
        </div>
      </div>
    </div>
    <div class="index-content sce-p2">
      <div class="center-box sce-p2-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">景区票务管理系统<div class="divider"></div></h2>
          <h3>智慧景区票务架构</h3>
        </div>
        <div class="content-box clearFloat">
           <div class="process-box">
                <ul>
                    <li class="sce-p2-item">
                        <div class="sce-p2-icons">
                            <div class="circle circle1" >
                                <div class="circle-inner circle1-inner">
                                    售
                                </div>
                            </div>
                            <div>
                                <p>OTA平台售票<br/>旅行社/B端分销商售票<br/>公众号/小程序/官网售票<br/>窗口售票<br/>自助售取票机售票</p>
                            </div>
                        </div>
                    </li>
                    <li class="sce-p2-item">
                        <div class="sce-p2-icons">
                            <div class="circle circle2" >
                                <div class="circle-inner circle2-inner">
                                    取
                                </div>
                            </div>
                            <div>
                                <p>凭二维码/身份证/凭证码/手机号<br/>人工窗口取票<br/>自主售取票机取票</p>
                            </div>
                        </div>
                    </li>
                    <li class="sce-p2-item">
                        <div class="sce-p2-icons">
                           <div class="circle circle3" >
                                <div class="circle-inner circle3-inner">
                                    检
                                </div>
                            </div>
                            <div>
                                <p>纸质门票检验<br/>凭二维码/身份证/一卡通/手机号<br/>手持机核验或者闸机验票</p>
                            </div>
                        </div>
                    </li>
                    <li class="sce-p2-item">
                        <div class="sce-p2-icons">
                           <div class="circle circle4" >
                                <div class="circle-inner circle4-inner">
                                    管
                                </div>
                            </div>
                            <div>
                                <p>管理票务库存入园人数<br/>记录游客信息行为数据<br/>统计收支账务数据<br/>多维度统计各项数据<br/>多层级客户标签分类</p>
                            </div>
                        </div>
                    </li>
                    <li class="sce-p2-item">
                        <div class="sce-p2-icons">
                           <div class="circle circle5" >
                                <div class="circle-inner circle5-inner">
                                    销
                                </div>
                            </div>
                            <div>
                                <p>一站式综合电商平台<br/>票/游/吃/住/购皆可售<br/>支持多渠道多平台引流<br/>多种互动工具营销裂变<br/>全民分销助力营销转化</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </div>
     <div class="index-content sce-p3">
      <div class="center-box sce-p3-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">景区门票分销系统<div class="divider"></div></h2>
          <h3>多渠道门票分销 营销裂变</h3>
        </div>
        <div class="content-box clearFloat">
           <div class="process-box">
              <ul>
                  <li class="sce-p3-item">
                      <div class="sce-p3-icons">
                        <div class="scheme">
                          <div class="scheme_img">
                            <img src="../../public/img/scenicTicket/p3/sce_p3_img_01.png" alt="">
                          </div>
                          <div class="scheme_word">
                            <span>直客分销</span>
                            <p>配备官方小程序和微信公众号。C端直客不仅可以在线预订购票；也能一键分享分销返佣。自动生成宣传海报，一键分享至社交平台；购买即返佣模式，全民分销。</p>
                          </div>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p3-item">
                      <div class="sce-p3-icons">
                         <div class="scheme">
                          <div class="scheme_img">
                            <img src="../../public/img/scenicTicket/p3/sce_p3_img_02.png" alt="">
                          </div>
                          <div class="scheme_word">
                            <span>OTA分销</span>
                            <p>直连主流OTA和票务平台。实现商品和订单的无缝对接，实时同步订单、游客、库存等信息。配备发码核销功能，游客无需换票就可直接通过手持机或闸机入园。</p>
                          </div>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p3-item">
                      <div class="sce-p3-icons">
                         <div class="scheme">
                          <div class="scheme_img">
                            <img src="../../public/img/scenicTicket/p3/sce_p3_img_03.png" alt="">
                          </div>
                          <div class="scheme_word">
                            <span>同行分销</span>
                            <p>B端同行采销系统。支持分销商在线查位、下单预订、自动对账、购买团票；支持开通多个二级账号，可单独给旅行社、酒店、餐厅等景区合作方开设账号，销售门票。</p>
                          </div>
                        </div>
                      </div>
                  </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
    <div class="index-content sce-p4">
      <div class="center-box sce-p4-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">更符合当下的票务系统<div class="divider"></div></h2>
          <p>亮点优势</p>
        </div>
        <div class="content-box clearFloat">
          <div class="process-box">
              <ul>
                  <li class="sce-p4-item">
                      <div class="sce-p4-icons">
                        <div class="icon">
                            <img src="../../public/img/scenicTicket/p4/sce_p4_img_1.png" alt="">
                        </div>
                        <div class="word">
                          <span>全渠道覆盖</span>
                          <p>支持：抖音视频+美团携程OTA平台+票务平台等主流公域流量平台引流；也支持：商家自营商城、微信群、朋友圈、短信等私域流量分销引流</p>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p4-item">
                      <div class="sce-p4-icons">
                        <div class="icon">
                            <img src="../../public/img/scenicTicket/p4/sce_p4_img_2.png" alt="">
                        </div>
                        <div class="word">
                            <span>提升自营占比</span>
                            <p>搭建商家自营小程序。游客扫码购票或者通过公众号购票，实时出票，即时入园。减少OTA平台买票比例，降低抽成成本，提高订单利润率</p>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p4-item">
                      <div class="sce-p4-icons">
                        <div class="icon">
                            <img src="../../public/img/scenicTicket/p4/sce_p4_img_3.png" alt="">
                        </div>
                          <div class="word">
                            <span>智能化取检票</span>
                            <p>无票入园，游客通过小程序/美团等渠道购票，凭核销码或身份证入园；换票入园，游客通过官方小程序/售票窗口/自助设备换取入园凭证</p>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p4-item">
                      <div class="sce-p4-icons">
                        <div class="icon">
                            <img src="../../public/img/scenicTicket/p4/sce_p4_img_4.png" alt="">
                        </div>
                          <div class="word">
                            <span>直连出票核销</span>
                            <p>美匣云提供配套的手持机验票设备和闸机验票设备。打通票务系统，无需换票，凭核销码或身份证入园。支持一人一核，一码多核的核销场景</p>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p4-item">
                      <div class="sce-p4-icons">
                        <div class="icon">
                            <img src="../../public/img/scenicTicket/p4/sce_p4_img_5.png" alt="">
                        </div>
                          <div class="word">
                            <span>一站式电商平台</span>
                            <p>支持景区整合资源。围绕“票、游、吃、住、购、娱”等场景，上架各类型的商品，游客购买后可通过凭证码在对应的商户处进行核验后使用</p>
                        </div>
                      </div>
                  </li>
                  <li class="sce-p4-item">
                      <div class="sce-p4-icons">
                        <div class="icon">
                            <img src="../../public/img/scenicTicket/p4/sce_p4_img_6.png" alt="">
                        </div>
                          <div class="word">
                            <span>高性价比SAAS系统</span>
                            <p>SAAA云平台，按量计费。企业前期投入低，无需投入IT团队维护；售后服务好，专人维护，随时在线指导；不断升级迭代，功能不断更新优化</p>
                        </div>
                      </div>
                  </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
    <div class="index-content sce-p5">
      <div class="center-box sce-p5-inner">
        <div class="p-tit-box">
          <h2 class="p-tit">旅游景区景点具体解决方案<div class="divider"></div></h2>
          <h3>多种解决方案可选</h3>
        </div>
        <div class="content-box clearFloat">
          <div class="process-box">
            <div class="left-nav">
              <ul >
                <li class="sce-p5-item active-p5"><h2>电子票务系统</h2></li>
                <li class="sce-p5-item"><h2>票务分销系统</h2></li>
                <li class="sce-p5-item"><h2>会员管理系统</h2></li>
                <li class="sce-p5-item"><h2>小程序解决方案</h2></li>
                <li class="sce-p5-item"><h2>营销复购解决方案</h2></li>
                <li class="sce-p5-item"><h2>智慧景区管理方案</h2></li>
                <li class="sce-p5-item"><h2>硬件设备支持方案</h2></li>
              </ul>
            </div>
            <div class="right_content">
              <h1>{{data.title}}</h1>
              <p>{{data.explain}}</p>
              <div class="sce-p5-img">
                 <div class="sce-p5-img-item" v-for="(item,index) in objKeys" :key="index">
                   <div class="icon-p5">
                    <img :src="imgP5[index]" alt="">
                    <span v-if="!trueP5">{{item}}</span>
                  </div>
                  <div class="word-p5">
                    <span v-if="trueP5">{{data.word[index]}}</span>
                    <div v-else v-for="(itm,idx) in objValues[index]" :key="idx" ><i class="d1"></i><span>{{itm}}</span></div>
                  </div>
                 </div>
              </div>
            </div>
            
            </div>
        </div>
      </div>
    </div>

    
    <div class="index-content sce-p6">
      <div class="center-box sce-p6-inner">
        <div class="p-tit-box">
          <div class="p-tit">他们都在使用美匣云票务系统<div class="divider"></div></div>
          <p>部分案例展示</p>
        </div>
        <div class="content-box clearFloat">
          <div class="process-box">
            <div class="sce-p6-item">
             <div class="sce-p6-img1" title="景区票务系统案例1">
               <div class="example">
                <div class="example-img">
                  <img src="../../public/img/scenicTicket/p6/sce_p6_img_1_1.jpg" alt="">
                  <p>扫码查看案例</p>
                </div>
               <div class="seeDetail">查看详情<img src="../../public/img/scenicTicket/p6/detail.png" alt=""></div>
               </div>
             </div>
              <div class="sce-p6-word p6-word1">
                <h1>知名景点-上海浦江游</h1>
                <p>客户需求：需要在线购票、网购预约换票的一体化解决方案</p>
                <div class="project-p6">
                  <span class="project-p6-title">解决方案</span>
                  <div>
                    <p><span class="subtitle">售&nbsp;&nbsp;票：</span><span>游客通过窗口售票系统线下购票，或者通过黄浦江官方小程序商城和公众号预订游船票；</span></p>
                    <p><span class="subtitle">取检票：</span><span>通过小程序购票的游客可以直接预约具体班次，凭核销码验票登船；通过OTA平台购票游客，在收到取票码后，可以通过取票码在官方小程序商城或者售票窗口、自助设备上预约具体班次，凭核销码验票登船。</span></p>
                    <p><span class="subtitle">营&nbsp;&nbsp;销：</span><span>系统具有高延展性。景区后续可以整合周边商户，上架餐饮、娱乐、酒店等类型的商品至小程序商城，游客可以通过凭证码在对应的商户处进行核验后使用，将打造成商业综合体景区小程序商城效果。</span></p>
                  </div>
                </div>
             </div>
           </div>
            <div class="sce-p6-item">
              <div class="sce-p6-word p6-word2">
                <h1>田园综合体-达人村</h1>
                <p>客户需求：需要“售、取、 检票”一整套的票务系统解决方案</p>
                <div class="project-p6">
                  <span class="project-p6-title">解决方案</span>
                  <div>
                    <p><span class="subtitle">售&nbsp;&nbsp;票：</span><span>通过窗口售票系统线下购票，或者通过线上OTA平台和官方小程序购票，同步订单数据；</span></p>
                    <p><span class="subtitle">取检票：</span><span>直连OTA平台和小程序，直接出票发码。提供配套的手持机验票设备和闸机验票设备，游客可以凭借二维码、短信、手机号、身份证等信息或者纸质门票，通过手持机或闸机检票入园。</span></p>
                    <p><span class="subtitle">效&nbsp;&nbsp;果：</span><span>国庆7天累计接待9万多人，最高单日达2.5万人；官方小程序商城购票的游客占60%左右，其他线上渠道售票占30% 左右，窗口售票不到10%；90%以上游客选择无票直接凭核销码验票入园，有效提升游客的入园效率。</span></p>
                  </div>
                </div>
             </div>
              <div class="sce-p6-img2" title="景区票务系统案例2">
                <div class="example">
                  <div class="example-img">
                    <img src="../../public/img/scenicTicket/p6/sce_p6_img_2_1.jpg" alt="">
                    <p>扫码查看案例</p>
                  </div>
                <div class="seeDetail">查看详情<img src="../../public/img/scenicTicket/p6/detail.png" alt=""></div>
                </div>
             </div>
           </div>
           <div class="sce-p6-item">
              <div class="sce-p6-img3" title="景区票务系统案例3">
                <div class="example">
                <div class="example-img">
                    <img src="../../public/img/scenicTicket/p6/sce_p6_img_3_1.jpg" alt="">
                    <p>扫码查看案例</p>
                  </div>
                <div class="seeDetail">查看详情<img src="../../public/img/scenicTicket/p6/detail.png" alt=""></div>
                </div>
              </div>
              <div class="sce-p6-word p6-word2">
                <h1>综合商业体-荡口古镇</h1>
                <p>客户需求：需要“线上预约—售票—检票”一整套的票务系统解决方案</p>
                <div class="project-p6">
                  <span class="project-p6-title">解决方案</span>
                  <div>
                    <p><span class="subtitle">售&nbsp;&nbsp;票：</span><span>游客可以通过荡口古镇官方小程序商城在线实名预约大门票和购买游船票等收费项目；</span></p>
                    <p><span class="subtitle">取检票：</span><span>美匣云提供配套的手持机验票设备和闸机验票设备。游客凭收到的核销码或者身份证在景区大门核销后入园；游客购买了景区内的游船、酒店等其他收费项目，商户使用配备的手持机验票设备进行核销。</span></p>
                    <p><span class="subtitle">效&nbsp;&nbsp;果：</span><span>荡口古镇景区实行晚间入园预约机制，每个账号最多实名预约3张；19日-21日中秋小长假期间，接待游客超11.5万人次；国庆长假，客流量超过21万人次；高峰时段核销入园的游客数最高达到10000人/小时。</span></p>
                  </div>
                </div>
             </div>
           </div>
          </div>
        </div>
      </div>
    </div>

   
    <foot-box></foot-box>
    <pop-window></pop-window>
  </div>
</template>

<script>

import FootBox from '@/components/Footer.vue'
import PopWindow from '@/components/PopWindow.vue'
let vm;
export default {

  name: 'scenicTicket',
    data(){
      return {
          contentP5: [
            {
              title:'电子票务系统',
              explain:'美匣云票务系统采用SAAS架构部署，全面解决景区景点售票、取票、检票、营销、防疫问题，平台简单易操作，功能强大，适用范围广。',
              word: {
                '售':['线上订购','线下直售'],
                '取':['纸质票','电子票'],
                '检':['闸机','手持机'],
                '销':['渠道引流','全民营销']
              }
            },
            {
              title:'票务分销系统',
              explain:'美匣云票务分销系统，不仅支持OTA商家和同行企业，在线查位，下单预订，自动分销；还支持个人客户分销返佣模式，形成营销裂变，全民营销。',
              word: {
                '个人':['分销返佣','一键分享'],
                'OTA':['订单同步','发码核销'],
                '同行':['查位下单','自动对账']
              }
            },
            {
              title:'会员管理系统',
              explain:'配置专业scrm客户管理系统，管理用户信息，对客户精准画像，生成各种维度的数据汇总，方便企业对客户定位，以短信、图文、在线沟通等方式吸引客户再次复购。',
              word: {
                '管理':['基础信息','客户画像'],
                '分析':['数据汇总','维度分析'],
                '运营':['人群分级','精准营销'],
                '触达':['在线沟通','消息推送']
              }
            },
            {
              title:'小程序微商城解决方案',
              explain:'小程序微商城，不仅能为景区景点提供门票解决方案，也能把景区景点打造成一站式综合电商平台。不仅限于门票的售卖，还能售卖包含衣、食、住、行、玩、购等方面的各类商品，整合景区景点商家，充分利用商家资源。',
              word: {
                '获客引流':['OTA平台','微信平台'],
                '客户留存':['数据信息','行为跟踪'],
                '分销裂变':['分销返佣','一键复购'],
                '运营转化':['精准营销','二次消费']
              }
            },
            {
              title:'营销复购解决方案',
              explain:'景区景点可以根据自身特点，设置多样的营销方案。例如：套票组合、年卡次卡、会员等级权益等方式，绑定用户；同时通过短信、公众号图文、视频、聊天沟通、优惠推送等吸引用户二次消费。',
              word: {
                '卡/票':['套票','次/月/年卡'],
                '会员权益':['会员折扣','会员积分'],
                '营销联动':['朋友圈公众号','短信小程序']
              }
            },
            {
              title:'智慧景区管理方案',
              explain:'景区景点不仅限于门票的售卖，更想要打造成智慧景区，实现一部手机游景区的概念。这里面不仅需要整合景区商家，包括：衣、食、住、行、玩、购等各种商家集合，也需要管理游客游前、游中、游后全过程，能实现分时预约、限制人数、智能导览、旅游攻略等功能。',
              word: {
                '游前':['购票购卡','旅游攻略'],
                '游中':['语音讲解','商家推送'],
                '游后':['点评分享','推荐复购'],
                '防疫':['分时预约','实名制登记']
              }
            },
            {
              title:'硬件设备支持方案',
              explain:'硬件设备的支持，是票务系统中很重要的一环，售票、取票、检票都会用到，美匣科技提供下列硬件设备：闸机、手持机、门票打印机、自助售取票机给景区景点参考。',
              word: ['景区闸机','手持机','门票打印机','自助售取票机']
            },
          ],
          // btn: '立即试用体验'
          data:'',
          objKeys:'',
          objValues:'',
          imgNo1:[
              require('../../public/img/scenicTicket/p5/sce_p5_img_1.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_2.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_3.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_4.png')
            ],
          imgNo2:[
              require('../../public/img/scenicTicket/p5/sce_p5_img_5.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_6.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_7.png'),
              require('../../public/img/scenicTicket/p5/sce_p5_img_8.png')
            ],
          imgP5:'',
          trueP5:false,
      }
    },
  components: {
      FootBox,
      PopWindow,
  },
    methods: {
        // if ($('.banner').length > 0 || $('.about-us').length > 0) {  }
        // 动态效果
        _move() {
           if($('.scenicTicket').length > 0) {
               let $winHeight = $(window).height(); // 窗口可视区域的高度
               let $winScrollHeight = $(window).scrollTop() // 浏览器滚动的距离





               // 大标题
               let $pTit1 = $('.sce-p1-inner')
               let $pTit2 = $('.sce-p2-inner')
               let $pTit3 = $('.sce-p4-inner')
               let $pTit4 = $('.sce-p6-inner')
               let $pTit5 = $('.sce-p5-inner')
              //  let $pTit5 = $('.index-p5-inner')
               let $pTitZyMarket = $('.sce-p3-inner')
               let moveBoxArr = [$pTit1,$pTit2,$pTitZyMarket,$pTit3,$pTit4,$pTit5]
               moveBoxArr.forEach( (item,index) => {
                   if (($winHeight + $winScrollHeight) >= item.offset().top) {

                       $(item).find('.p-tit-box').addClass('animated fadeInUp')

                   }
               })

               //index-p1 动效
               if (($winHeight + $winScrollHeight) >= $('.sce-p1-inner').offset().top) {
                $('.sce-p1-inner .sce-p1-item').each(function (index,item) {
                    $(item).addClass('animated fadeInLeft')
                    $(item).css({
                        "animation-delay": .05 * index + "s"
                    })
                })

               }

               //sce-p2 动效
               if (($winHeight + $winScrollHeight) >= $('.sce-p2-inner').offset().top) {
              
                        $('.sce-p2-inner .sce-p2-item').each(function (index,item) {
                            $(item).find('.sce-p2-icons').addClass('animated fadeInLeft')
                            $(item).find('.sce-p2-icons').css({
                                "animation-delay": .4 * index + "s"
                            })
                        })

               }

              //sce-p3 动效
               if (($winHeight + $winScrollHeight) >= $('.sce-p3-inner').offset().top) {
                    $('.sce-p3-inner .sce-p3-item').each(function (index,item) {
                        $(item).find('.sce-p3-icons').addClass('animated fadeInLeft')
                        $(item).find('.sce-p3-icons').css({
                            "animation-delay": .4 * index + "s"
                        })
                    })

                }
               //sce-p4 动效
               if (($winHeight + $winScrollHeight) >= $('.sce-p4-inner').offset().top) {
                   $('.sce-p4-inner .sce-p4-item').each(function (index, item) {
                       $(item).addClass('animated fadeInLeft')
                       $(item).css({
                           "animation-delay": 0.15 * index + "s"
                       })
                   })
                 
               }

                //sce-p5 动效
               if (($winHeight + $winScrollHeight) >= $('.sce-p5-inner').offset().top) {
                  
                   $('.sce-p5-inner .left-nav').addClass('animated fadeInLeft')
                   $('.sce-p5-inner .right_content').addClass('animated fadeInRight')
                   $('.sce-p5-inner>div').css({
                       "animation-delay": 0.5 + "s"
                   })
               }

               //sce-p6 动效
               if (($winHeight + $winScrollHeight) >= $('.sce-p6-inner').offset().top) {
                   $('.sce-p6-inner .sce-p6-item:nth-child(odd)').addClass('animated fadeInLeft')
                   $('.sce-p6-inner .sce-p6-item:nth-child(even)').addClass('animated fadeInRight')
                   $('.sce-p6-inner .sce-p6-item').css({
                           "animation-delay": 0.3  + "s"
                       })
               }
               
                }
              },

        navAddClass() {
            $('.left-nav .sce-p5-item').mouseover(function () {
                $('.left-nav .sce-p5-item').removeClass('active-p5')
                $(this).addClass('active-p5')
                let index = $(this).index();
                vm.data = vm.contentP5[index];
                vm.objKeys = Object.keys(vm.data.word);
                vm.objValues = Object.values(vm.data.word);
                vm.imgP5 = vm.imgNo1;
                if(index == 3 || index == 4){
                  $('.sce-p5-img .sce-p5-img-item .icon-p5 span').css('fontSize','20px',);
                }else{
                  $('.sce-p5-img .sce-p5-img-item .icon-p5 span').css({
                    'fontSize':'24px',
                    'lineHeight':'24px'
                  });
                }
                if(index == 6){
                  vm.imgP5 = vm.imgNo2;
                  vm.trueP5 = true;
                  $('.sce-p5-img .sce-p5-img-item').css({
                    'borderTop':'none',
                    'justifyContent':'unset',
                    'fontSize':'16px'
                  });
                }else{
                  vm.imgP5 = vm.imgNo1;
                  vm.trueP5 = false;
                  $('.sce-p5-img .sce-p5-img-item').css({
                    'borderTop':'solid 1px #e5e5e5',
                    'justifyContent':'center',
                    'fontSize':'14px'
                  });
                }
            })

        },

        // 团队实力
        _p4Hover() {
            $('.sce-p6-inner .dbx-bg').hover(function (index, item) {
                $('.sce-p6-inner .dbx-bg').removeClass('hovered')
                $(this).addClass('hovered')
            },
            function (index, item) {
                $('.sce-p6-inner .dbx-bg').removeClass('hovered')
                $('.sce-p6-inner .num3-box .dbx-bg:nth-child(2)').addClass('hovered')
            }
            )
        },

    },
  mounted(){
    vm = this
    //  p1
      let canRun = true;
      let timer;
      vm._move();
      vm.navAddClass();
      $(window).scroll(function () {

          if (!canRun) {

              return
          }
          canRun = false
          timer = setTimeout(function () {

              vm._move()
              canRun = true
          }, 300)

      })

    //    p4
      // vm._p4Hover()
      vm.data = vm.contentP5[0];
      vm.objKeys = Object.keys(vm.data.word);
      vm.objValues = Object.values(vm.data.word);
      vm.imgP5 = vm.imgNo1;

      $('.seeDetail').each(function(i){
        $(this).hover(function(){
          $('.example').eq(i).animate({left:0});
        })
      })
      $('.example-img').each(function(i){
        $(this).mouseleave(function(){
         $('.example').eq(i).animate({left:'-234px'})
        })
      })    
  },

}
</script>
<style lang="less" >

  @bgBlue: #3b7ffc;
  .scenicTicket {
    width: 100%;
    min-width: 1200px;
    .little-words {
      font-size: 16px;
      margin-bottom: 38px;
      div {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        padding-left: 25px;
        color: #fff;
        i {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #5ddfff;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 8px;
          height: 8px;
          border: 3px solid rgba(79,134,242);
          border-radius: 50%;
          background-color: #5ddfff;
        }
      }
    }
    .banner-box {
        width: 100%;
        height: 600px;
        background-image: url("../../public/img/index/index_banner.png");
        background-size: cover;
        overflow: hidden;
        user-select: none;
    }
   
    .banner-words-box {
      margin-top: 200px;
    }
    .banner-words-box {

    h1, h2, h3 {
      color: #fff;
    }
    h1 {
        font-weight: bold;
        margin-bottom: 33px;
        font-size: 40px;
    }
    h2 {
        font-size: 31px;
        font-weight: 100;
    }
    h3 {
        margin-bottom: 55px;
        font-size: 20px;
    }
    
    .free-try-btn {
      margin-top: 31px;
      font-size: 18px;
    }

  }
    .p-tit-box  {
      opacity: 0;
    }

  }
 

  .banner-inner {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 1200px;
    height: 100%;
    margin: auto;
  }
  
  .scenicTicket .banner-imgs{
    top: 20px;
  }

  /*公共*/
  .banner-imgs {
    position: absolute;
    right: 0;
      transition: all .3s linear;
      &:hover {
          transform: scale(1.05);
      }
  }
  .free-try-btn {
    display: inline-block;
    width: 180px;
    height: 54px;
    line-height: 54px;
    border-radius: 30px;
    color: #fff;
  }

  .index-content {
    width: 100%;
    overflow: hidden;
  }
  .center-box {
    width: 1200px;
    margin: 0 auto;
  }

  
  /*p1*/
  .sce-p1-inner {
    position: relative;
    height: 720px;
     .process-box{
         padding-top: 39px;
            ul{
                flex-wrap: wrap;
            }
            .sce-p1-item{
                margin:0 20px 20px 0;
                float: left;
                
                .sce-p1-item-imgbox{
                    position:relative;
                    width: 180px;
                    height: 200px;
                    display: flex;
                    justify-content: center;

                }
                p{
                    position: absolute;
                    bottom: 19px;
                    font-size: 16px;
                    color: #666;
                }
                
                img{
                    position: absolute;
                    transition: all 0.5s;
                }
            }
        }
  }
  /*公共标题*/
  .p-tit-box {
    text-align: center;
    padding-top: 70px;
    .p-tit {
      position: relative;
      display: inline-block;
      font-size: 36px;
      color: #1a1a1a;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -100px;
        width: 79px;
        height: 6px;
        background: url("../../public/img/index/tit_left.png") no-repeat center/contain;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -100px;
        width: 79px;
        height: 6px;
        background: url("../../public/img/index/tit_right.png") no-repeat center/contain;
      }
      .divider {
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 59px;
          width: 232px;
          height: 3px;
          background: url('../../public/img/index/tit-line.png') no-repeat center/contain;

      }
    }
    p {
      display: block;
      position: relative;
      margin-top: 26px;
      line-height: 20px;
      color: #333;
      font-size: 16px;
    }
  }

.sce-p2{
    height: 645px;
}

  .sce-p2-inner {
    .process-box {
        padding-top: 71px;
        ul {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        .sce-p2-item {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: center;
            flex: 1;
            .sce-p2-icons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .circle{
                    width:120px;
                    height:120px;
                    border-radius:50%;
                    padding:10px;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: block;
                        width: 99px;
                        height: 99px;
                        background-color: #fff;
                        opacity: 0.1;
                        border-radius: 50%;
                    }
                }
                .circle-inner{
                    width:100px;
                    height:100px;
                    border-radius:50%;
                    color:#fff;
                    font-size:48px;
                    line-height:100px;
                }
                .circle1{
                    border:1px solid rgba(85,186,233,.3);
                    &:after{
                        content: '';
                        position: absolute;
                        top: 120px;
                        left: 50%;
                        display: block;
                        width: 1px;
                        height: 18px;
                        background-color: #55bae9;
                        opacity: 0.3;
                    }
                }
                .circle1-inner{
                    background-color: #3884c5;
                    &:after{
                        content: '';
                        position: absolute;
                        top: 50px;
                        right: -70px;
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-color: #5589e9;
                        border-radius: 10px;
                        opacity: 0.3;
                    }
                }
                .circle2{
                    border:1px solid rgba(85,85,233,.3);
                     &:after{
                        content: '';
                        position: absolute;
                        top: 120px;
                        left: 50%;
                        display: block;
                        width: 1px;
                        height: 18px;
                        background-color: #5555e9;
                        opacity: 0.3;
                    }
                }
                .circle2-inner{
                    background-color: #5555e9;
                    &:after{
                        content: '';
                        position: absolute;
                        top: 50px;
                        right: -70px;
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-color: #8d55e9;
                        border-radius: 10px;
                        opacity: 0.3;
                    }
                }
                .circle3{
                    border:1px solid rgba(227,113,196,.3);
                    &:after{
                        content: '';
                        position: absolute;
                        top: 120px;
                        left: 50%;
                        display: block;
                        width: 1px;
                        height: 18px;
                        background-color: #e371c4;
                        opacity: 0.3;
                    }
                }
                .circle3-inner{
                    background-color: #e371c4;
                    &:after{
                        content: '';
                        position: absolute;
                        top: 50px;
                        right: -70px;
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-color: #e16548;
                        border-radius: 10px;
                        opacity: 0.3;
                    }
                }
                .circle4{
                    border:1px solid rgba(225,180,72,.3);
                    &:after{
                        content: '';
                        position: absolute;
                        top: 120px;
                        left: 50%;
                        display: block;
                        width: 1px;
                        height: 18px;
                        background-color: #e1b448;
                        opacity: 0.3;
                    }
                }
                .circle4-inner{
                    background-color: #e1b448;
                    &:after{
                        content: '';
                        position: absolute;
                        top: 50px;
                        right: -70px;
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-color: #9fb943;
                        border-radius: 10px;
                        opacity: 0.3;
                    }
                }
                .circle5{
                    border:1px solid rgba(134,185,67, 0.3);
                     &:after{
                        content: '';
                        position: absolute;
                        top: 120px;
                        left: 50%;
                        display: block;
                        width: 1px;
                        height: 18px;
                        background-color: #86b943;
                        opacity: 0.3;
                    }
                }
                .circle5-inner{
                    background-color: #86b943;
                }
                p {
                    font-size: 14px;
                    color: #333;
                    line-height: 30px;
                    margin-top: 35px;
                }
            }
        }
        .sce-p2-item:last-child {
            .sce-p2-icons {
                i {
                    &:after {
                        display: none;
                    }

                }
            }
        }
    }
  }
   .sce-p3-inner {
    // img {
    //   width: 545px;
    // }
    .process-box {
        padding: 39px 0 70px;
        ul {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        .sce-p3-item {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: center;
            .sce-p3-icons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .scheme{
                  width: 380px;
                  height: 454px;
                  background-color: #ffffff;
                  border-radius: 30px;
                  border: solid 2px #eeeeee;
                }
                .scheme_img{
                  margin: 34px 0 40px 0;
                }
                .scheme_word{
                  padding-top: 33px;
                  border-top: 1px solid #e6e6e6;
                  margin: 0 20px;
                }
                span{
                  padding-bottom: 15px;
                  border-bottom: 2px solid #55bae9;
                  font-size: 22px;
                  color: #333;
                  font-weight: bold;
                }
                p {
                    font-size: 14px;
                    color: #545454;
                    line-height: 30px;
                    margin: 35px auto 0;
                    width: 294px;
                    text-align: left;
                }
            }
        }
    }
  }
   .sce-p4-inner {
    .process-box {
        padding: 39px 0 45px;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .sce-p4-item {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: center;
            width: 380px;
            height: 240px;
            background-color: #ffffff;
            box-shadow: 0px 0px 18px 0px rgba(71, 95, 241, 0.1);
            border-radius: 10px;
            margin-bottom: 25px;
            padding-top: 25px;
            .sce-p4-icons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
               .icon{
                 width: 56px;
                 height: 56px;
                 display: flex;
               }
               
                span{
                  font-size: 20px;
                  line-height: 20px;
                  color: #333;
                  margin: 28px 0 20px;
                  display: inline-block;
                }
                p {
                    font-size: 14px;
                    color: #808080;
                    line-height: 20px;
                    width: 322px;
                    text-align: left;
                }
            }
        }
       
    }
  }
   .sce-p5-inner {
    .process-box {
        padding: 39px 0 45px;
        display: flex;
        .left-nav{
          width: 160px;
          margin-right: 22px;
          padding-top: 10px;
          border-right: 1px solid #eee;
          display: block;
          ul{
            display: block;
          }
          .sce-p5-item{
            margin-bottom: 27px;
            width: 100%;
            cursor: pointer;
            h2{
              font-size: 16px;
              line-height: 40px;
              letter-spacing: 0px;
              color: #808080;
              font-weight: bold;
            }
          }
          .active-p5{
            color: #3a8eff !important;
            background-image: linear-gradient(to right,#fff,rgba(105,186,255,.5));
            position: relative;
            &:after{
                content:'';
                position: absolute;
                top: 0;
                right: -2px;
                display: block;
                width: 4px;
                height: 40px;
                background-image: linear-gradient(0deg, #49a1de 0%, #57b2ef 0%, #65c3ff 0%, #7290fd 67%, #7f5cfa 100%);
            }
          }
        }
        .right_content{
          width: 1018px;
          height: 480px;
          padding: 33px 30px;
          background-color: #ffffff;
          box-shadow: 0px 0px 18px 0px 
            rgba(71, 95, 241, 0.1);
          border-radius: 4px;
          h1{
            font-size: 28px;
            line-height: 28px;
            color: #333333;
            margin-bottom: 26px;
          }
          p{
            font-size: 14px;
            line-height: 24px;
            color: #333333;
          }
          .sce-p5-img{
            display: flex;
            justify-content: space-between;
            margin-top: 37px;
            .sce-p5-img-item{
              width: 220px;
              height: 280px;
              border-radius: 4px;
              border: solid 1px #e5e5e5;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .icon-p5{
                position: relative;
                display: flex;
                span{
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  font-size: 24px;
                  line-height: 24px;
                  color: #fff;
                  font-weight: bold;
                  transform: translate(-50%,-50%);
                  text-align: center;
                }
              }
              .word-p5{
                margin-top: 49px;
                >div:first-child{
                  margin-bottom: 20px;
                }
                i{
                  width: 10px;
                  height: 10px;
                  transform: rotate(45deg);
                  display: inline-block;
                  margin-right: 11px;
                }
                .d1{
                  background-color: #3b7ffc;
                }
                .d2{
                  background-color: #fe8e53;
                }
                .d3{
                  background-color: #59cd6f;
                }
                .d4{
                  background-color: #d381f2;
                }
              }
              
              
            }
          }
        }    
    }
  }
   .sce-p6-inner {

    .process-box {
        padding: 39px 0 45px;
        .sce-p6-item:not(:last-child){
          margin-bottom: 36px;
        }
        .sce-p6-item{
          display: flex;
          height: 320px;
          .sce-p6-img1{
            width: 416px;
            background: url('../../public/img/scenicTicket/p6/sce_p6_img_1.png') no-repeat;
            background-size: cover;
            position: relative;
            overflow:hidden;
          }
          .sce-p6-img2{
            width: 416px;
            background: url('../../public/img/scenicTicket/p6/sce_p6_img_2.png') no-repeat;
            background-size: cover;
            position: relative;
            overflow:hidden;
          }
          .sce-p6-img3{
            width: 416px;
            background: url('../../public/img/scenicTicket/p6/sce_p6_img_3.png') no-repeat;
            background-size: cover;
            position: relative;
            overflow:hidden;
          }
          .example{
            display:flex;
            align-items: flex-start;
            position:absolute;
            top:0;
            left:-234px;
            z-index: 10;
            .example-img{
              width:234px;
              background:#fff;
              padding:11px 16px 0;
              box-sizing:border-box;
              border-radius:0 0 4px 4px;
              img{
                width: 100%;
              }
              p{
                color: #4590f6;
                padding:14px 0;
                font-weight:bold;
                text-align:center;
                border-top:1px solid #e6e6e6;
              }
            }
            .seeDetail{
              background-color: #19bb5a;
              color: #fff;
              display: inline-block;
              border-radius: 0px 0px 19px;
              width: 114px;
              height: 38px;
              font-size: 16px;
              font-weight: bold;
              line-height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              img{
                margin-left:13px;
              }
            }
          }
          // .p6-word1,.p6-word2{
          //   padding-top: 38px;
          // }
          .p6-word1,.p6-word2{
            padding-top: 20px;
          }
          .sce-p6-word{
            background-color: #fff;
            flex: 1;
            padding-left: 40px;
            h1{
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 20px;
              letter-spacing: 0px;
              color: #333333;
              margin-bottom: 17px;
            }
            >p{
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 16px;
              letter-spacing: 0px;
              color: #545454;
              margin-bottom: 34px;
            }
            .project-p6{
              // background-color: #f7f7f7;
              // border-radius: 8px;
              // padding: 18px 0 10px;
              margin-right: 29px;
              width: 715px;
              .project-p6-title{
                width: 164px;
                height: 28px;
                background-image: linear-gradient(to right,rgba(0,104,233,.3),#fff);
                display: block;
                font-size: 16px;
                font-weight: bold;
                line-height: 28px;
                letter-spacing: 0px;
                color: #325499;
                padding-left: 24px;
              }
              .subtitle{
                font-weight:bold;
                font-family: SimHei;
                white-space: nowrap;
              }
              div{
                margin-top: 17px;
                line-height: 28px;
                font-size: 14px;
                color: #455c8b;
              }
              p{
                display: inline-flex;
              }
            }
          }
        }

        
      
       
    }
  }
  
  /*按钮公共*/
  .btn-box {
    margin-top: 75px;
    .b1 ,.b2{
      display: inline-block;
      width: 160px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border-radius: 23px;
      cursor: pointer;
      margin-right: 40px;
    }
  }

  /*p2*/
  .sce-p2 {
    background-color: #f8f8f8;
  }

  /*p3*/
  .sce-p4 {
    background-color: #f8f8f8;
  }
  /*p4*/

  .sce-p6 {
    // height: 840px;
    background: url("../../public/img/index/index_p4_bg.jpg") no-repeat center/cover;
    .p-tit {
      color: #fff;
      &:before {
        display: none;

      }
      &:after {
        display: none;

      }
      span {
        display: inline-block;
        margin-right: 20px;
      }
      span:last-child {
        margin-right: 0;
      }
    }
    .p-tit-box {
      p {
        font-size: 18px;
        color: #fff;
      }
    }
  }
  .sce-p6-content {
    padding-top: 75px;
    .num-box {
      display: flex;
      justify-content: center;
      .dbx-bg {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 208px;
        margin-right: 20px;
        background: url("../../public/img/index/dbx_white.png") no-repeat center/cover;
        user-select: none;
        .dbx-words {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 2;
          p {
            text-align: center;
          }
        }
        .hover-shadow {
          width: 200px;
          height: 232px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background: url("../../public/img/index/dbx_blue.png") no-repeat center/cover;
          opacity: 0;
          transition: all .5s ease;
          z-index: 1;
        }
        h1,p,span,i {
          transition: all .3s ease;
        }
        h1 {
          margin-bottom: 5px;
          text-align: center;
          font-size: 14px;
          span {
            color: @bgBlue;
            font-size: 36px;
          }
          i {
            color: @bgBlue;
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
          color: #333;
          white-space: nowrap;
        }
        &:hover {
          .hover-shadow {

            opacity: 1;
          }

          h1, p,span,i {
            color: #fff;
          }
        }
      }
        .hovered {
            .hover-shadow {
                opacity: 1;
            }
            h1, p,span,i {
                color: #fff!important;
            }
        }
    }
    .num4-box,.num3-box,.num2-box {
      .dbx-bg:last-child {
        margin-right: 0;
      }
    }
    .num3-box,.num2-box {
      margin-top: -35px;
    }
  }
  .sce-p6-content-wap {
    display: none;
  }
//   /*p5 成功案例*/
//  .index-p5 {
//    height:720px;
//  }
//   .index-p5-inner {
//     width: 1225px;
//     .case-box {
//       display: flex;
//       flex-wrap: wrap;
//       padding-top: 30px;
//       .case-item {
//         position: relative;
//         display: inline-block;
//         width: 245px;
//         height: 98px;
//         border-right: 1px solid #f2f2f2;
//         border-bottom: 1px solid #f2f2f2;
//         text-align: center;
//         cursor: pointer;
//         /*overflow: hidden;*/
//         transition: all .2s linear;
//         img {
//           display: inline-block;
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
//       }
//       .case-item:hover {
//         transform: translateY(-1px);
//         box-shadow: 0px 0px 10px rgba(0,0,0,.12);
//       }
//       .case-item:nth-child(5n) {
//         border-right: 0;
//       }
//       .case-item:nth-last-child(1),.case-item:nth-last-child(2),.case-item:nth-last-child(3),.case-item:nth-last-child(4),.case-item:nth-last-child(5) {
//         border-bottom: 0;
//       }

//       .case-item:nth-child(1),.case-item:nth-child(6),.case-item:nth-child(11),.case-item:nth-child(16) {
//         &:before {
//           content:'';
//           position: absolute;
//           left: -60px;
//           bottom: 0;
//           display: block;
//           width: 60px;
//           height: 1px;
//           background-color: #f2f2f2;

//         }
//       }
//       .case-item:nth-child(5),.case-item:nth-child(10),.case-item:nth-child(15),.case-item:nth-child(20) {
//         &:before {
//           content:'';
//           position: absolute;
//           right: -60px;
//           bottom: 0;
//           display: block;
//           width: 60px;
//           height: 1px;
//           background-color: #f2f2f2;

//         }
//       }
//       .case-item:nth-last-child(2),.case-item:nth-last-child(3),.case-item:nth-last-child(4),.case-item:nth-last-child(5) {
//         &:after {
//           content:'';
//           position: absolute;
//           right: -1px;
//           bottom: -60px;
//           display: block;
//           width: 1px;
//           height: 60px;
//           background-color: #f2f2f2;

//         }
//       }
//     }
//   }

  @import "../assets/css/wap.css";

  @media all and (max-width:750px) {
    
    .index-p5-inner .case-box .case-item:nth-last-child(2){
      border-bottom: 1px solid #f2f2f2;
    }
    .scenicTicket {
      min-width: auto;
      .little-words {
        font-size: .25rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        &>div {
          margin: 0 auto .2rem;
          width:1rem;
        }
      }
      .banner-inner {
        width: 7.5rem;
        .free-try-btn {
          margin-top: .3rem;
          font-size: .25rem;
        }
      }
    }

    .free-try-btn {
      width: 2.2rem;
      height: .68rem;
      line-height: .68rem;
      margin: auto;
    }
    .scenicTicket .banner-box {
      height:100vh;
    }

    
    .banner-imgs {
      right: 50%;
      transform: translateX(50%);
      &:hover {
        transform: scale(1) translateX(50%);
      }
    }
    .scenicTicket {

      .banner-imgs {
        top: 2.5rem;
        img {
          width: 4.7rem;
          height: auto;
        }
      }
      .banner-words-box {
        margin-top: 7.4rem;
      }
    }

    .scenicTicket .banner-words-box {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      h2 {
        font-size: .35rem;
      }
      h1 {
        font-size: .4rem;
        margin-bottom: .2rem;
      }
      h3 {
        font-size: .25rem;
        margin-bottom: .5rem;
      }

    }
    .banner-words-box .shebei-box > div {
      margin-right: .8rem;
      background-size: cover;
      padding-left: .4rem;
      font-size: .26rem;
    }
    .banner-words-box .shebei-box {
      &>div {
        background-image: none;
        position: relative;
        line-height: 1.3;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          background-image: url(../../public/img/index/index_icons1.png);
          background-repeat: no-repeat;
          background-size: 2.955rem 0.27rem;
        }
      }
      .app-icons-app {
        left: .1rem;
        &:after {
          width: .18rem;
          height: .27rem;
          background-position: 0 0;
        }

      }
      .app-icons-pc {
        margin-right: .6rem;
        &:after {
          width: .24rem;
          height: .225rem;
          background-position: -1.42rem 0;
        }

      }
      .app-icons-xcx {
        &:after {
          width: .3rem;
          height: .24rem;
          background-position: -2.65rem -.02rem;
        }

        margin-right: 0;
      }

    }





    .sce-p2, .sce-p4, .index-zyMarket {
      height: auto;
    }



    /* sce-p1 */
    .sce-p1-inner {
      width: 7.5rem;
      height: auto;
      .process-box ul{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
      .process-box .sce-p1-item {
        margin:0 0 20px;
        .sce-p1-item-imgbox{
          position: relative;
          width: 160px;
          height: 180px;
          display: flex;
          justify-content: center;
          img{
            width: 100%;
          }
        }
      }
      
      h1 {
        font-size: .45rem;
      }
      h2,h3 {
        font-size: .3rem;
        margin-top: .3rem;
      }
      h3 {
        font-size: .26rem;
        padding: 0 .25rem;
      }
    }

    /*  sce-p2*/
    .sce-p2-inner{
      .process-box{
        padding-top: 1rem;
        ul{
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          height: 18rem;
        }
        .sce-p2-item {
          width: 100%;
          flex: 1;
          .sce-p2-icons{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            >div:last-child{
              flex: 1;
              display: block;
              margin: auto;
            }
            p{
              margin-top: 0;
              font-size: 0.26rem;
            }
            .circle{
              position: relative;
              margin-right: 30px;
              &::before{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 99px;
                height: 99px;
                background-color: #fff;
                opacity: 0.1;
                border-radius: 50%;
              }
            }
            .circle1::after{
                content: '';
                position: absolute;
                top: 60px;
                left: 119px;
                display: block;
                width: 18px;
                height: 1px;
                background-color: #55bae9;
                opacity: 0.3;
            }
            .circle2::after{
                content: '';
                position: absolute;
                top: 60px;
                left: 119px;
                display: block;
                width: 18px;
                height: 1px;
                background-color: #5555e9;
                opacity: 0.3;
            }
            .circle3::after{
                content: '';
                position: absolute;
                top: 60px;
                left: 119px;
                display: block;
                width: 18px;
                height: 1px;
                background-color: #e371c4;
                opacity: 0.3;
            }
            .circle4::after{
                content: '';
                position: absolute;
                top: 60px;
                left: 119px;
                display: block;
                width: 18px;
                height: 1px;
                background-color: #e1b448;
                opacity: 0.3;
            }
            .circle5::after{
                content: '';
                position: absolute;
                top: 60px;
                left: 119px;
                display: block;
                width: 18px;
                height: 1px;
                background-color: #86b943;
                opacity: 0.3;
            }
            .circle1-inner::after{
                content: '';
                position: absolute;
                top: 140px;
                right: 50px;
                display: block;
                width: 20px;
                height: 20px;
                background-color: #5589e9;
                border-radius: 10px;
                opacity: 0.3;
            }
            .circle2-inner::after{
                content: '';
                position: absolute;
                top: 140px;
                right: 50px;
                display: block;
                width: 20px;
                height: 20px;
                background-color: #8d55e9;
                border-radius: 10px;
                opacity: 0.3;
            }
            .circle3-inner::after{
                content: '';
                position: absolute;
                top: 140px;
                right: 50px;
                display: block;
                width: 20px;
                height: 20px;
                background-color: #e16548;
                border-radius: 10px;
                opacity: 0.3;
            }
            .circle4-inner::after{
                content: '';
                position: absolute;
                top: 140px;
                right: 50px;
                display: block;
                width: 20px;
                height: 20px;
                background-color: #9fb943;
                border-radius: 10px;
                opacity: 0.3;
            }
          } 
        }
      } 
    }
    
    
    /*  sce-p3*/
    .sce-p3{
      .center-box{
        padding-bottom: 0;
      }
      .sce-p3-inner .process-box .sce-p3-item{
        margin-bottom: 20px;
        .sce-p3-icons .scheme{
          width: 7rem;
          img{
            width: 98%;
          }
        }

      }
    }
    /*  sce-p4*/
    .sce-p4{
      .center-box{
        padding-bottom: 0;
      }
      .sce-p4-item{
        width: auto !important;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
    }
    /*  sce-p5*/
    .sce-p5-inner .process-box .left-nav ul{
      width: 160px;
    }

    /*  sce-p2*/
    .center-box {
      width: 7.5rem;
      padding-bottom: 1rem;
    }

    /*大标题*/
    .p-tit-box {
      padding-top: .9rem;
      .p-tit {
        font-size: .4rem;
        .divider {
          top: .7rem;
          width: 2.5rem;
        }
      }
      p {
        font-size: .26rem;
        padding: 0 .2rem;
      }
    }
    .p-tit-box .p-tit:before {
      left: -1.2rem;
      width: 1rem;
      height: .12rem;
    }
    .p-tit-box .p-tit:after {
      right: -1.2rem;
      width: 1rem;
      height: .12rem;
    }

    .sce-p2-inner .p-tit-box p, .sce-p4-inner .p-tit-box p, .index-zyMarket-inner .p-tit-box p {
      margin-top: .4rem;
      line-height: 2;
    }
    .sce-p2-inner .content-box .right,.sce-p4-inner .content-box .right {
      right: 50%;
      transform: translateX(50%);
      top: .5rem;
    }
    .sce-p4-inner .content-box {
      margin-top: .5rem;
    }
    .index-p-img {
      img {
        width: 6rem;
      }
    }
    .sce-p2-inner .list {
      width: 7.5rem;
      padding: 6.5rem 0.6rem 0;
    }
    .sce-p4-inner .list  {
      width: 7.5rem;
      padding: 7rem 0.6rem 0;
    }
    .sce-p2-inner .list > li, .sce-p4-inner .list > li, .index-zyMarket-inner .list > li {
      white-space: nowrap;
    }
    .sce-p2-inner .icon-wrap, .sce-p4-inner .icon-wrap, .index-zyMarket-inner .icon-wrap {
      width: .5rem;
      height: .5rem;
      margin-right: .2rem;
    }
    .sce-p2-inner .icon-wrap .infront-box, .sce-p4-inner .icon-wrap .infront-box, .index-zyMarket-inner .icon-wrap .infront-box {
      width: .5rem;
      height: .5rem;
      line-height: .5rem;
      right: .1rem;
    }
    .sce-p2-inner .words, .sce-p4-inner .words, .index-zyMarket-inner .words {
      width: 85%;
    }
    .sce-p2-inner .icon-wrap, .sce-p4-inner .icon-wrap, .index-zyMarket-inner .icon-wrap, .sce-p2-inner .words, .sce-p4-inner .words, .index-zyMarket-inner .words {
      vertical-align: middle;
    }
    .sce-p2-inner .words  ,.sce-p4-inner .words , .index-zyMarket-inner .words {
      h2 {
        font-size: .3rem;
      }
      p {
        width: 100%;
        font-size: .26rem;
        white-space: normal;
        text-align: justify;
        color: #333;
      }
    }
    .btn-box {
      display: flex;
      justify-content: center;
      margin: 1rem auto 0;
    }
    .btn-box .b1, .btn-box .b2 {
      width: 2.2rem;
      height: .6rem;
      line-height: .6rem;
      font-size: .26rem;
    }
    .btn-box .b2 {
      margin-right: 0;
    }

  /*  资源市场*/
    .sce-p2-inner .content-box .left, .sce-p4-inner .content-box .left, .index-zyMarket-inner .content-box .left {
      float: none;
    }
    .index-zyMarket-inner .content-box {
      margin-top: 1rem;
    }

    .index-zyMarket {
      .index-p-img {
        text-align: center;
      }
    }
    .index-zyMarket-inner .list {
      width: 7.5rem;
      padding: .5rem 0.6rem 0;
    }
    .index-zyMarket-inner .right {
      position: relative;
      right: auto;
    }
  /*.sce-p6*/
    .sce-p6 {
      height: auto;
    }
    .sce-p6 .p-tit-box p {
      font-size: .26rem;
    }

    .sce-p6-content-wap {
      display: block;
      padding-top: .5rem;
      .num-box {
        display: flex;
        justify-content: center;
        .dbx-bg {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2.8rem;
          height: 2.9rem;
          margin-right: .2rem;
          background: url("../../public/img/index/dbx_white.png") no-repeat center/contain;
          user-select: none;
          .dbx-words {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            z-index: 2;
            p {
              text-align: center;
            }
          }
          .hover-shadow {
            width: 2.5rem;
            height: 2.86rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background: url("../../public/img/index/dbx_blue.png") no-repeat center/contain;
            opacity: 0;
            transition: all .5s ease;
            z-index: 1;
          }
          h1,p,span,i {
            transition: all .3s ease;
          }
          h1 {
            margin-bottom: 5px;
            text-align: center;
            font-size: 14px;
            span {
              color: @bgBlue;
              font-size: .5rem;
            }
            i {
              color: @bgBlue;
              font-size: 12px;
            }
          }
          p {
            font-size:12px;
            color: #333;
            white-space: normal;
          }
          &:hover {
            .hover-shadow {

              opacity: 1;
            }

            h1, p,span,i {
              color: #fff;
            }
          }
        }
        .dbx-bg:nth-child(3n) {
          margin-right: 0;
        }
        .hovered {
          .hover-shadow {
            opacity: 1;
          }
          h1, p,span,i {
            color: #fff!important;
          }
        }
      }
    }

    .sce-p6-content {
      display: none;

    }


  // .case8

  /*.index-p5*/
    .index-p5 {
      height: auto;
    }
    .index-p5-inner .case-box {
      padding-top: 1rem;
      padding-left: .1rem;
    }
    .index-p5-inner .case-box .case-item {
      /*width: 2.3rem;*/
      width: 33.29%;
      height: .97rem;
    }

    .index-p5-inner .case-box .case-item:nth-child(5n) {
      border-right: 1px solid #f2f2f2;
    }
    .index-p5-inner .case-box .case-item:nth-child(3n) {
      border-right: 0;
    }
    .index-p5-inner .case-box .case-item:nth-last-child(3), .index-p5-inner .case-box .case-item:nth-last-child(4), .index-p5-inner .case-box .case-item:nth-last-child(5) {
      border-bottom: 1px solid #f2f2f2;
    }
    .index-p5-inner .case-box .case-item:nth-child(1):before, .index-p5-inner .case-box .case-item:nth-child(6):before, .index-p5-inner .case-box .case-item:nth-child(11):before {

        display: none;


    }
    .index-p5-inner .case-box .case-item:nth-child(5):before, .index-p5-inner .case-box .case-item:nth-child(10):before, .index-p5-inner .case-box .case-item:nth-child(15):before {

        display: none;


    }
    .index-p5-inner .case-box .case-item:nth-last-child(2):after, .index-p5-inner .case-box .case-item:nth-last-child(3):after, .index-p5-inner .case-box .case-item:nth-last-child(4):after, .index-p5-inner .case-box .case-item:nth-last-child(5):after {

        display: none;

    }
    .mobileNoShow{
      display: none !important;
    }
  } // media end

  @media all and (max-width:376px) {
    .index-p1-inner .circle1 {
      top: 1.4rem;
    }
    .index-p1-inner .circle2 {
      top: 1.7rem;
    }
  }


</style>

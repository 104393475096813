import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import VueAMap from 'vue-amap'
import wxapi from '../public/js/wxapi'
import MetaInfo from 'vue-meta-info'
import 'animate.css'
import './assets/css/font.css'
Vue.config.productionTip = false
Vue.use(VueAMap)
Vue.use(MetaInfo)

VueAMap.initAMapApiLoader({
    key: 'YOUR_KEY',
    plugin: ['Autocomplete', 'PlaceSearch', 'Scale', 'OverView', 'ToolBar', 'MapType', 'PolyEditor', 'AMap.CircleEditor'],
    v: '1.4.4'
});


{
    let msgArr = [
        {
            page: '首页',
            title: '美匣科技|专注于旅游行业的软件系统服务商',
            keywords: '旅行社ERP系统,景区票务系统,微信小程序,抖音小程序,酒店会员商城,民宿会员免单商城',
            description: '美匣科技（400-060-7500）,为旅行社/景区景点/酒店民宿等旅游商家提供:旅行社ERP管理软件,微信小程序,抖音小程序,景区票务系统,酒店民宿预订系统,会员免单商城等软件系统服务.'
        },
        {
            page: '微商城小程序',
            title: '美匣云小程序|旅游微信/抖音小程序/旅游分销小程序商城',
            keywords: '微信小程序,抖音小程序,景区小程序,酒店小程序,民宿小程序,旅行社小程序,小程序功能定制开发,收客软件系统',
            description: '美匣云小程序ERP（400-060-7500）,专为景区景点/酒店民宿/旅行社提供官方微信和抖音小程序.既是C端直客收客预订软件系统,B端同业也能在线查位下单,一个小程序解决旅游商家收客分销难题.'
        },
        {
            page: '资源市场',
            title: '美匣云资源市场|旅游资源B2B分销平台，没有中间商赚差价',
            keywords: '旅游同业平台,旅游分销平台,旅游资源B2B平台销,旅行社分销系统,旅游资源供应商',
            description: '美匣云资源市场（400-060-7500）,旅游路线/酒店/民宿/景区门票/票务/大小交通等旅游资源免费共享分销平台,没有中间商赚差价.旅游供应商可以免费发布产品，旅游分销商可以任意采购旅游资源.'
        },
        {
            page: '旅行社ERP',
            title: '美匣云ERP|新一代智能化旅行社管理系统',
            keywords: '旅行社ERP管理系统,旅游SaaS云平台,旅游ERP管理系统,旅行社内部管理系统,旅行社办公OA软件系统,旅行社软件开发',
            description: '美匣云ERP（400-060-7500）,旅行社内部管理系统,为旅游OTA商家/组团社/地接社/批发商分销商，定制开发旅游SAAS管理系统平台，是市面上最完善的一体化管理旅游业务_订单_计调_资源_客户_财务的旅行社内部办公OA系统.'
        },
        {
            page: '景区票务',
            title: '美匣云票务系统|景区景点票务一体化解决方案',
            keywords: '票务系统,景区购票系统,景点自动售检票系统,售票管理软件,智慧景区电子票务系统,景区门票预约系统',
            description: '美匣云票务系统（400-060-7500）,专为各大景区景点提供智慧票务解决方案和软硬件设备支持方案,可免费试用.系统集景区窗口售票/小程序售票/取票/检票/管票/销售于一体.'
        },
        {
            page: '代理商招募',
            title: '代理商招募|打通上下游业务的旅游营销管理系统|美匣科技',
            keywords: '代理分销平台,代理商招募,旅游软件系统代理,旅游小程序分销代理,旅游电商系统',
            description: '美匣科技（400-060-7500）旅游生态系统:ERP系统+小程序微商城+票务系统+会员制商城+资源市场,五大产品代理商招募中.区别于传统旅游单一产品线模式,美匣系统集外部营销+内部管理+供应链服务为一体.'
        },
        {
            page: '公司咨询',
            title: '公司动态|行业资讯|升级日志—美匣科技',
            keywords: '旅行社ERP系统，旅行社管理系统，旅游小程序，景区小程序，酒店小程序，旅游电商系统，旅游分销系统，美匣科技',
            description: '美匣云旅游SaaS系统平台包含旅行社ERP系统、旅行社管理系统、旅游电商系统、批发商系统、地接社系统、旅游小程序、公众号商城、两级分销系统、资源分销平台等功能，全面解决旅行社货源、销售、内部管理等难题，助力旅行社实现数字化转型升级'
        },
        {
            page: '关于我们',
            title: '美匣科技|一站式旅游软件系统服务商',
            keywords: '美匣科技,美匣云,公司介绍,联系我们',
            description: '美匣科技（400-060-7500）,为旅行社/景区景点/酒店民宿等旅游商家提供强大完善的信息化系统和数字化营销方案.拥有:旅行社erp,小程序微商城,票务系统,酒店民宿预订系统,酒店民宿会员制商城等系统服务.'
        },
        {
            page: '抖音小程序',
            title: '为景区景点和酒店民宿提供抖音变现运营一站式服务|美匣云',
            keywords: '抖音小程序_抖音预约系统_抖音订单管理系统_抖音核销工具_抖音发码系统_抖音团购解决方案_抖音小店解决方案',
            description: '美匣科技（400-060-7500）_为旅行社/景区景点/酒店民宿等旅游商家提供抖音变现运营一站式服务:抖音小程序,抖音预约系统,抖音发码核销系统,抖音团购和小店解决方案等.'
        }
    ];

    

    router.beforeEach((to, from, next) =>{
        if (!window.initUrl) {
            // window.initUrl = location.href.split('#')[0]
            window.initUrl = location.origin
        }
        // console.log($('title').length)
        if(!$('title').length){
            $('head').append("<title></title>");
        }
        if(!$('meta[name="keywords"]').length){
            $('head').append("<meta name='keywords' content=''>");
        }
        if(!$('meta[name="description"]').length){
            $('head').append("<meta name='description' content=''>");
        }
        switch (to.meta.index) {
            case 1:
                $('title').text(msgArr[0].title);
                $('meta[name="keywords"]').attr('content', msgArr[0].keywords);
                $('meta[name="description"]').attr('content', msgArr[0].description);
                wxapi.wxRegister(msgArr[0])
                break;
            case 2:
                $('title').text(msgArr[1].title);
                $('meta[name="keywords"]').attr('content', msgArr[1].keywords);
                $('meta[name="description"]').attr('content', msgArr[1].description);
                wxapi.wxRegister(msgArr[1])
                break;
            case 3:
                $('title').text(msgArr[2].title);
                $('meta[name="keywords"]').attr('content', msgArr[2].keywords);
                $('meta[name="description"]').attr('content', msgArr[2].description);
                wxapi.wxRegister(msgArr[2])
                break;
            case 4:
                $('title').text(msgArr[3].title);
                $('meta[name="keywords"]').attr('content', msgArr[3].keywords);
                $('meta[name="description"]').attr('content', msgArr[3].description);
                wxapi.wxRegister(msgArr[3])
                break;
            case 5:
                $('title').text(msgArr[4].title);
                $('meta[name="keywords"]').attr('content', msgArr[4].keywords);
                $('meta[name="description"]').attr('content', msgArr[4].description);
                wxapi.wxRegister(msgArr[4])
                break;
            case 6:
                $('title').text(msgArr[5].title);
                $('meta[name="keywords"]').attr('content', msgArr[5].keywords);
                $('meta[name="description"]').attr('content', msgArr[5].description);
                wxapi.wxRegister(msgArr[5])
                break;
            case 7:
                $('title').text(msgArr[6].title);
                $('meta[name="keywords"]').attr('content', msgArr[6].keywords);
                $('meta[name="description"]').attr('content', msgArr[6].description);
                wxapi.wxRegister(msgArr[6])
                break;
            case 8:
                $('title').text(msgArr[7].title);
                $('meta[name="keywords"]').attr('content', msgArr[7].keywords);
                $('meta[name="description"]').attr('content', msgArr[7].description);
                wxapi.wxRegister(msgArr[7])
                break;
            case 9:
                $('title').text(msgArr[8].title);
                $('meta[name="keywords"]').attr('content', msgArr[8].keywords);
                $('meta[name="description"]').attr('content', msgArr[8].description);
                wxapi.wxRegister(msgArr[8])
                break;
            // default:
            //     $('title').text(msgArr[0].title);
            //     $('meta[name="keywords"]').attr('content', msgArr[0].keywords);
            //     $('meta[name="description"]').attr('content', msgArr[0].description);
            //     wxapi.wxRegister(msgArr[0])
        };
        next();

    });
}

new Vue({
    router,
    render: h => h(App),
    
    mounted () {
        document.dispatchEvent(new Event('render-event'))
    }
    
}).$mount('#app');
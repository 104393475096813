<template>
    <div class="footer">
        <div class="footer1">
            <div class="footer-box">
                <div class="about-nav">
                    <h1 class="footer-tit">关于 MAYTEK</h1>
                    <ul>
                        <li>
                            <router-link to="/wscSystem" class = 'foot-link'>微商城小程序</router-link>
                            <!-- <router-link to="/mayTekErp" class = 'foot-link'>旅行社ERP</router-link> -->
                            <router-link to="/commerceErp" class = 'foot-link'>电商组团ERP</router-link>
                            <router-link to="/visaSystem" class = 'foot-link'>签证系统</router-link>
                            <!-- <router-link to="/scenicTicket" class = 'foot-link'>景区票务</router-link>
                            <router-link to="/zyMarket" class = 'foot-link'>资源市场</router-link> -->
                            <!-- <router-link to="/distributor" class = 'foot-link'>代理商招募</router-link> -->
                            <!-- <router-link to="/douyin" class = 'foot-link'>抖音小程序</router-link> -->
                            <router-link to="/news" class = 'foot-link'>公司资讯</router-link>
                            <router-link to="/about" class = 'foot-link'>关于我们</router-link>
                            <!-- <a href="https://www.metaworks.vip/pinpainft" class = 'foot-link' target="_blank">NFT数藏方案</a> -->
                        </li>
                    </ul>
                </div>
                <div class="foot-contact-words">
                    <h1 class="footer-tit">联系我们</h1>
                    <div class="qq">官方咨询QQ：2985219589</div>
                    <div class="phone"><span>全国服务热线：</span>400-060-7500</div>
                    <div class="e-mail">官方邮箱：2985219589@qq.com</div>
                    <div class="address">地址：上海市松江区茸兴路407号6幢B202室<p>重庆市渝北区食品城大道18号重庆广告产业园15幢3A楼</p></div>
                </div>
                <div class="qrcode-box">
                    <div class="qrcode1">
                        <h1 class="footer-tit">官方微信客服</h1>
                        <img src="../../public/img/common/qc1.jpg" alt="">
                    </div>
                    <!-- <div class="qrcode2">
                        <h1 class="footer-tit">联系官方客服</h1>
                        <img src="../../public/img/common/qc2.jpg" alt="">
                    </div>  -->
                </div>
            </div>
        </div>
        <div class="footer2">
            <img src="../../public/img/common/logo_footer.png" alt="">
            <p><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16025219号-1</a>  上海美匣网络科技有限公司</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style lang="less">
    .footer {
        // border-top: 4px solid #086bff;
        .footer-tit {
            font-size: 16px;
            color: #fff;
            opacity: .2;
            margin-bottom: 44px;

        }
        .footer-box {
            display: flex;
            flex-wrap: nowrap;
            width: 1200px;
            margin: 0 auto;
        }
        .footer1 {
            padding-top: 55px;
            padding-bottom: 30px;
            background-color: #2a2a32;
        }
        .about-nav {
            margin-right: 80px;
            .foot-link {
                display: block;
                font-size: 12px;
                color: #fff;
                margin-bottom: 15px;
            }
        }
        .foot-contact-words {
            width: 612px;
            margin-right: 114px;
            .qq, .e-mail, .address {
                font-size: 16px;
                color: #fff;
            }
            .qq, .e-mail {
                margin-bottom: 19px;
            }
            .address p{
                text-indent: .48rem;
            }
            .phone {
                margin-bottom: 25px;
                font-size: 16px;
                color: #fff;
            }
        }
        .qrcode-box  {
            .qrcode1,.qrcode2 {
                display: inline-block;
                img {
                    width: 120px;
                }
            }
            .qrcode1 {
                // margin-right: 50px;
            }
            .footer-tit {
                text-align: center;
            }
        }
        .footer2 {
            padding: 10px 0;
            background-color: #27262c;
            text-align: center;
            p {
                margin-top: 5px;
                color: #595959;
                font-size: 12px;
                a{
                    color: #595959;
                }
            }
        }
    }


    @media all and (max-width: 750px) {
        .footer {
            width: 7.5rem;
            overflow: hidden;
            .footer1 {
                padding-top: .5rem;
            }
            .footer-box {
                width: 100%;
                flex-direction: column;
                flex-wrap: wrap;
                .qrcode-box {
                    text-align: center;
                }
            }
            .footer-tit {
                font-size: .3rem;
                margin-bottom: .5rem;
            }
            .about-nav,.foot-contact-words {
                width: auto;
                margin: 0 auto;
                text-align: center;
            }
            .about-nav {
                margin-bottom: .5rem;
                .foot-link {
                    font-size: .26rem;
                    margin-bottom: .3rem;
                }
            }
            .foot-contact-words  {
                margin-bottom: .5rem;
                .phone {
                    margin-bottom: .1rem;
                    font-size: .26rem;
                    span {
                        display: block;
                        margin-bottom: .1rem;
                        font-size: .26rem;
                    }
                }
                .qq {
                    margin-bottom: .1rem;
                    font-size: .26rem;
                }
                .e-mail {
                    font-size: .26rem;
                    margin-bottom: .1rem;
                }
                .address {
                    padding: 0 0.5rem;
                    font-size: .26rem;
                    p{
                        text-indent: unset;
                    }
                }

            }
            .qrcode-box .qrcode1 {
                // margin-right: 1.5rem;
            }
        }

    }
</style>